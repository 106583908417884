import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input("showspinner")
  public ShowSpinner: boolean = false;

}
