import { SchoolTypeModel} from '../entities/SchoolTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolTypeModel = new SchoolTypeModel();
}

export class SchoolTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolTypeModel> = <Array<SchoolTypeModel >>[]
}

export interface ISchoolTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolTypeListParams implements ISchoolTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

