import {IListItem } from '../models';
export class ScholarshipPaymentsDeadlineModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public FK_Scholarship:number = 0;
	public FK_Scholarship_Display:string = null;
	public Name:string = "";
	public NotifyParentSchools?:Date = null;
	public VerificationReportsDue?:Date = null;
	public PaymentSent?:Date = null;
}
