import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolCommitmentEditBaseComponent } from './schoolCommitmentEdit.base';

@Component({
	selector: 'app-schoolCommitmentEdit',
	templateUrl: './schoolCommitmentEdit.component.html',
})
export class SchoolCommitmentEditComponent extends SchoolCommitmentEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
