<div class="card">
  <div class="card-header">
    Student Grade Levels School Years Look Up <span class="right">
  <a title="Cancel" *ngIf="!ModalView" routerLink="/studentgradelevelsschoolyearslookuplist"><i class="fa fa-undo icon-link"></i></a>
  <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
  <i class="fa fa-save icon-link" (click)="save()"></i>
</span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        								<div class="col-6">
									<div class="form-group">
<span title="Required" class="required">*</span>
										<app-checkbox [(model)]="model.Archived" [label]="'Archived'"></app-checkbox>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Created Date</label><span title="Required" class="required">*</span>
<app-date-picker [(model)]="model.CreatedDate"></app-date-picker>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>School Year</label><span title="Required" class="required">*</span>
										<select [(ngModel)]="model.FK_SchoolYear" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_SchoolYear_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Grade Level</label><span title="Required" class="required">*</span>
										<select [(ngModel)]="model.FK_GradeLevel" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_GradeLevel_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Student</label><span title="Required" class="required">*</span>
										<app-typeahead-basic [(id)]="model.FK_Student" [searchapi]="'studentsListBox'"></app-typeahead-basic>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>State Application</label><span title="Required" class="required">*</span>
										<select [(ngModel)]="model.FK_State_Application" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_State_Application_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>School</label>
										<app-typeahead-basic [(id)]="model.FK_School" [searchapi]="'schoolsListBox'"></app-typeahead-basic>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Zengine Student Id</label>
										<input [(ngModel)]="model.ZengineStudentId" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Zengine Household Id</label>
										<input [(ngModel)]="model.ZengineHouseholdId" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Previous School District</label>
										<input [(ngModel)]="model.PreviousSchoolDistrict" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Previous School Type</label>
										<input [(ngModel)]="model.PreviousSchoolType" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Previous School Name</label>
										<textarea rows="4" [(ngModel)]="model.PreviousSchoolName" class="form-control"></textarea>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Name Of Zoned School</label>
										<textarea rows="4" [(ngModel)]="model.NameOfZonedSchool" class="form-control"></textarea>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Household</label>
										<input [(ngModel)]="model.FK_Household" type="text" class="form-control">
									</div>
								</div>


      </div>
      

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
