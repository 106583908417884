import { Component, OnInit, Directive } from '@angular/core';
import {  IRoleListParams } from '../../models/entities/RoleModel.response';
import { RoleModel } from '../../models/entities/RoleModel';
import { PagingData } from '../../paging/paging.component';
import { RoleListBaseComponent } from './roleList.base';

@Component({
	selector: 'app-roleList',
	templateUrl: './roleList.component.html',
})
export class RoleListComponent extends RoleListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
