import {IListItem } from '../models';
export class ZengineFormModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public ZengineFormId:number = 0;
	public ZengineFormName:string = "";
	public FK_ZengineFormType?:number = null;
	public FK_ZengineFormType_Display:string = null;
}
