import {IListItem } from '../models';
export class AccreditationModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_State:number = 0;
	public FK_State_Display:string = null;
	public ShortName:string = "";
	public FK_AccreditationType:number = 0;
	public FK_AccreditationType_Display:string = null;
	public Name?:string = null;
}
