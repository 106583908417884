<div class="body-login">
  <div class="d-flex flex-column align-items-center">
    <img src="assets/aaa-logo.png" class="p-5 mt-5" />
    <div class="card p-3">
      <div class="card-header pt-3">
        <div class="d-flex flex-column">
          <span class="text-header pb-3">
            Welcome{{ (username) ? ', ' + username : null }}
          </span>
          <span class="text-dark font-weight-light">
            Please create a password for your account.
          </span>
        </div>
      </div>
      <div class="card-body">
        <div class="form" *ngIf="stepNumber == 0">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold">Password</label>
                <input [(ngModel)]="model.Password" type="password" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold">Password Confirm</label>
                <input [(ngModel)]="model.Password2" type="password" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <button class="btn btn-primary form-control" type="button" (click)="goToSoftwareTerms()"
                  [disabled]="!validatePasswords()">Continue</button>
              </div>
            </div>
          </div>
        </div>

        <div class="form" *ngIf="stepNumber === 1">
          <div>
            Before you continue, you must review and confirm receipt of the Gardiner Handbook.
          </div>
          <div class="agreement-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut officiis at earum distinctio numquam quis rerum
            ullam nisi, voluptates quo fugiat saepe aliquid, non suscipit reiciendis sunt labore nobis ad.
            Atque aperiam quod explicabo laborum deserunt maxime, laudantium beatae corporis nulla deleniti commodi
            labore itaque rem enim eum libero fugiat minima laboriosam voluptas eligendi delectus repellat perspiciatis.
            Provident, esse eaque?
            Voluptatem quam eligendi commodi, repellat quidem adipisci. Ipsum, quis blanditiis saepe odio eaque magni,
            deserunt commodi molestias repellendus magnam non nemo distinctio similique facere. Quasi dignissimos
            perferendis magnam corrupti odit.
            Temporibus veniam corrupti aspernatur sed earum laborum vitae explicabo in. Accusamus ut sapiente
            dignissimos sequi magnam pariatur officiis vitae reprehenderit fugiat consequatur magni, voluptatum error
            debitis, nemo vero iusto exercitationem.
            Dolorum deserunt doloremque illo ullam repellendus incidunt hic soluta sint qui praesentium eum nemo
            perferendis sapiente, cupiditate omnis vel suscipit adipisci sunt nihil illum. Eius officiis doloribus esse
            numquam. Adipisci.
            Tempore eius, eaque, saepe animi minima quaerat id earum, temporibus maxime laudantium possimus illum at.
            Laboriosam libero velit, deserunt dolorum commodi ullam odit reprehenderit voluptas tempore culpa, excepturi
            odio beatae!
            Id ipsam iste facere veniam, possimus voluptas magnam reprehenderit nihil rem, alias officia sapiente quod
            esse consequuntur asperiores in voluptates nesciunt unde dolorem repellat, odio quas! Maiores quam officia
            amet.
            Impedit accusamus iure, accusantium corporis cum ullam est nemo laborum ea explicabo, sapiente quibusdam
            tempora sunt ex. Atque consequatur sint, facere voluptates nemo quam odio eum ipsam nulla similique aut.
            Quo velit perspiciatis vitae accusantium corporis voluptatum cumque cupiditate nemo nulla in, dolores
            voluptates sunt incidunt consectetur dignissimos aliquid nobis ut itaque totam voluptatem atque reiciendis?
            Temporibus aperiam ducimus distinctio.
            Id hic alias distinctio minus dolore facilis aliquid officia quisquam, similique ullam accusamus sed atque
            tempore molestias eos quas ea quae quam, quaerat voluptatibus. Praesentium molestiae facilis dolores cum
            qui.
            Hic corrupti quos facere voluptates iusto recusandae voluptatum praesentium dolorum repellat in modi unde,
            doloremque nihil dolore eligendi at placeat obcaecati nemo beatae ad, perferendis sunt? Praesentium nemo hic
            cupiditate.
            Delectus voluptates quasi natus quas architecto, aliquam perferendis quibusdam in ab quaerat rerum. Tempore
            perspiciatis magnam inventore culpa nam et accusantium ipsum est aut reprehenderit recusandae ducimus ea,
            molestias molestiae?
            Modi nobis inventore tenetur facere vel animi, veniam perferendis minus deleniti esse fuga aut itaque atque
            quisquam nisi, totam error recusandae dolores ipsam minima ut, hic possimus in necessitatibus. Nihil!
            Deleniti magnam incidunt sit perferendis cupiditate architecto consectetur et mollitia voluptas at eum
            voluptates explicabo provident officia veritatis quaerat fugiat commodi dicta, adipisci nesciunt aperiam
            perspiciatis aut nemo? Ducimus, magni?
            Possimus ipsa quos dicta harum nam officia quisquam accusantium modi ex praesentium! Nihil dolor porro iure.
            Sequi ab modi quas placeat deserunt. Deleniti veritatis tempore numquam voluptas asperiores similique
            debitis.
            Dolor sed expedita illo nulla debitis tempore cupiditate ipsam asperiores totam facilis! Veritatis
            aspernatur perspiciatis quaerat perferendis fugit consectetur quasi, similique eius quisquam praesentium id
            sit ratione, amet sed molestias.
            Suscipit unde illo sequi maxime adipisci doloribus ullam, quas repellendus accusamus placeat! Officia eos
            blanditiis cupiditate dolores beatae, dicta, quae doloremque omnis a ducimus eum ex optio necessitatibus
            assumenda at.
            Culpa voluptatem iure deserunt omnis, nesciunt autem suscipit eum laboriosam pariatur perspiciatis voluptate
            asperiores accusamus numquam, quaerat cum quidem fuga doloribus et. Molestiae quos saepe, id nulla beatae
            eligendi vitae.
            Facere nihil tempore ad, ipsam sit magni rerum vel ducimus ipsum consequuntur dolorem quos labore corrupti,
            deserunt quis minus eveniet a, blanditiis optio commodi. Iusto illo maiores tempora necessitatibus
            blanditiis.
            Eveniet ducimus reiciendis tenetur exercitationem dolorum accusantium quam perspiciatis unde veritatis
            mollitia amet quasi veniam, numquam, odio ullam praesentium molestiae natus repellat assumenda, expedita
            minima doloribus recusandae! Rem, explicabo excepturi.
            Ab nemo consequuntur, libero consequatur enim, cum rem dolorem totam fuga sapiente neque, asperiores
            corrupti dicta omnis cupiditate officiis illo repudiandae hic aliquid praesentium! Error, laboriosam.
            Delectus voluptatum earum modi.
            Quidem ducimus in maiores est necessitatibus voluptatum obcaecati reprehenderit numquam enim dignissimos sit
            eveniet at voluptas odit architecto eligendi tenetur cumque, quam iure veniam consequatur? Voluptatibus
            consectetur enim ipsam quidem.
            Harum voluptates totam consequatur quia praesentium vitae animi, rem, odio laborum facere itaque consectetur
            debitis nam suscipit nemo assumenda dolorem nihil. Molestias laboriosam adipisci iure exercitationem nobis
            blanditiis, quibusdam quo.
            Molestiae voluptate architecto veniam, quam dolorem numquam illum voluptatibus similique aspernatur ducimus?
            Eum laudantium voluptatum exercitationem perspiciatis saepe expedita earum nobis eaque ullam officia
            blanditiis tempora repudiandae, assumenda, quaerat rem.
            Tempora, harum deserunt illo facere voluptate dolore accusamus neque libero nihil provident eum doloremque,
            ducimus eaque beatae vel cumque saepe, commodi natus repellat perferendis magnam? Reiciendis accusantium
            minima nulla deleniti.
            Sit sed tempora amet. Iusto quisquam a corporis ipsum doloremque! Esse corporis libero distinctio excepturi
            ut, perspiciatis quibusdam placeat provident sequi reprehenderit ratione ducimus quam, aut culpa ad
            inventore asperiores.
            Odio placeat laborum eos blanditiis molestias quasi, natus necessitatibus neque asperiores optio? Voluptate
            eos accusamus eum deleniti eligendi et autem est sit? Sunt labore aliquid excepturi unde, accusamus earum
            alias?
            Eos libero quibusdam praesentium quo iste odio qui! Quasi, temporibus aliquam sint perspiciatis numquam hic
            perferendis. Iusto, maiores earum modi, dolorum esse assumenda laboriosam cumque odit, consectetur nulla
            nostrum vitae.
            Eveniet animi inventore nulla veniam repudiandae quae possimus voluptate quidem rerum corrupti pariatur
            officia ducimus aperiam harum ullam ad molestiae eaque itaque ab, excepturi similique. Possimus autem amet
            et soluta!
            Maxime porro dolores quis doloribus, ratione molestias, fugiat perspiciatis alias error dicta dolorem
            eligendi placeat et veniam labore hic expedita, officiis quae! Beatae ducimus recusandae perferendis
            temporibus necessitatibus molestiae amet.
            Quidem reiciendis qui esse, in consectetur voluptas quae corporis sapiente blanditiis natus consequatur
            neque aut excepturi, obcaecati culpa ea, ipsam expedita nobis sed dolorum iste aperiam vel aspernatur.
            Temporibus, mollitia?
            Eaque est laudantium corrupti neque quasi mollitia nesciunt, fuga labore tempora aperiam nihil debitis
            obcaecati nemo tempore quod natus, animi magnam, sit vitae id veniam facilis. Odit fugit excepturi
            voluptatem.
            Magni sint omnis praesentium tenetur nam, cumque minus, blanditiis error aliquid quisquam magnam velit vero
            et at mollitia voluptate, veniam eligendi iure eaque suscipit est. Illo dolorum aliquid saepe? Modi.
            Quasi illo, illum accusamus quis sit optio. Accusamus aspernatur quas et laborum, impedit iure, modi veniam
            numquam, quod amet fuga dolores magni! Nulla aliquid fugit delectus odit obcaecati! Consequatur, libero!
            Eveniet odit praesentium quisquam consectetur sit harum optio laborum dolore officiis ipsam accusamus enim
            debitis sed suscipit impedit porro sequi at itaque doloribus tempore quis, eaque unde! Nam, perferendis
            cumque.
            Cumque consequuntur culpa, veniam perferendis natus quod asperiores itaque excepturi sunt animi atque qui ab
            eius blanditiis! At est hic explicabo ratione quo alias earum, debitis doloremque cum voluptatem aliquam!
            Eaque magni, illum, accusantium facere similique tempore libero voluptatibus quam non suscipit ipsa
            blanditiis iste perferendis aliquid ea dicta totam sed sunt commodi fugit quidem delectus harum dolorum?
            Totam, maxime?
            Eos quibusdam distinctio velit consectetur beatae quis dicta rem maiores sit modi debitis dignissimos,
            officia aut nulla assumenda earum corrupti eius est perspiciatis inventore. Ut quam enim similique
            temporibus molestiae?
            Dolores placeat voluptate pariatur esse natus amet vel harum vitae inventore voluptatem ad veniam
            praesentium sunt maxime qui modi illum hic similique corporis ducimus quasi libero, fugiat enim rem!
            Distinctio.
            Officiis nulla reiciendis incidunt at exercitationem, deserunt earum veniam rem voluptatem sed, iste quas,
            quam id. Sapiente reiciendis ex aspernatur expedita dolor quisquam ipsa officiis aliquam repellendus.
            Repellat, vitae sed?
            Perferendis dicta ducimus sequi, natus at dolores deleniti dolore quaerat blanditiis itaque ab esse animi
            reprehenderit deserunt fugit voluptas voluptate sint! Architecto et illo velit facilis a, modi rem ducimus.
            Saepe cupiditate amet tempore doloremque iusto fugiat dolorum, aliquid odio mollitia id alias, maiores earum
            nam commodi harum sed. Quas saepe maxime molestiae debitis. Cumque labore corporis harum culpa molestiae.
            Fuga, neque aliquid. Soluta tempore, voluptas voluptatem exercitationem ea dicta culpa, voluptatum rerum
            perferendis esse similique vitae quis temporibus architecto corrupti voluptates mollitia aut dignissimos
            laudantium fugiat nisi, labore quod.
            Nemo, odio porro? Facere adipisci provident distinctio voluptates, nisi ratione reprehenderit ipsam natus
            nemo dolorum optio aspernatur temporibus sit neque, eius ex non quae ut incidunt earum excepturi. Quasi, ea.
            Quibusdam exercitationem placeat aut odio eligendi officia nemo deleniti dolore error autem! Obcaecati est
            dolor facere esse sit! Debitis eaque sint dolor, vero temporibus minus aliquid ratione dolores labore
            libero.
            Laudantium eaque iusto qui iure! Voluptate cupiditate amet excepturi at et deleniti error eligendi eos ullam
            repellat, voluptatum odio non distinctio iusto totam fugiat eum quisquam aliquid debitis ipsum ex.
            Ut accusantium laboriosam commodi tenetur officia, doloribus illum laudantium totam sed beatae similique
            molestias odit reprehenderit aspernatur sit perspiciatis nobis, error cupiditate aliquid eveniet! Ducimus
            libero recusandae quae aspernatur nemo!
            Quidem quam quia dolorem numquam officia vel sit iusto commodi illo autem iure dolorum, quos deserunt at quo
            ex asperiores! Ipsum consequuntur et perferendis. Magnam sunt minus nihil ullam vitae!
            Ipsum autem fuga error delectus ut, distinctio nisi tempora temporibus maiores aperiam ipsa pariatur vel
            eius veritatis at quidem soluta a voluptatem? Aut illo asperiores quisquam tenetur, dolore quo quae?
            Repellendus harum beatae veniam repudiandae at hic eos deleniti laborum voluptatum aliquam inventore maiores
            mollitia, accusamus debitis natus! Earum odit necessitatibus maiores neque reprehenderit rerum quidem iure
            similique doloribus eligendi.
          </div>
          <div class="row">
            <div class="col-12 pb-2">
              <input type="checkbox" [(ngModel)]="agreements" />
              I acknowledge receipt, understanding, and agreement to comply with the information, policies and
              procedures in the handbook.
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <button class="btn btn-primary form-control" type="button" (click)="submit()"
                  [disabled]="!validateAgreements()">Continue</button>
              </div>
            </div>
          </div>
        </div>

        <div class="form" *ngIf="stepNumber === 2">
          <div class="agreement-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut officiis at earum distinctio numquam quis rerum
            ullam nisi, voluptates quo fugiat saepe aliquid, non suscipit reiciendis sunt labore nobis ad.
            Atque aperiam quod explicabo laborum deserunt maxime, laudantium beatae corporis nulla deleniti commodi
            labore itaque rem enim eum libero fugiat minima laboriosam voluptas eligendi delectus repellat perspiciatis.
            Provident, esse eaque.
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <button class="btn btn-primary form-control" type="button" (click)="submit()">Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>