<div class="card">
    <div class="card-header">
        Vendor <span class="right">
            <a title="Cancel" *ngIf="!ModalView" routerLink="/vendorlist"><i class="fa fa-undo icon-link"></i></a>
            <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
            <i class="fa fa-save icon-link" (click)="save()"></i>
        </span>
    </div>
    <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
        <div class="form">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Name</label><span title="Required" class="required">*</span>
                        <textarea rows="4" [(ngModel)]="model.Name" class="form-control"></textarea>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Created Date</label><span title="Required" class="required">*</span>
                        <app-date-picker [(model)]="model.CreatedDate"></app-date-picker>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>State Vendor Physical</label><span title="Required" class="required">*</span>
                        <select [(ngModel)]="model.FK_State_VendorPhysical" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_State_VendorPhysical_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Physical Zipcode</label>
                        <input [(ngModel)]="model.Physical_Zipcode" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Vendor Code</label>
                        <input [(ngModel)]="model.VendorCode" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Fax Number</label>
                        <input [(ngModel)]="model.FaxNumber" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Email Address</label>
                        <input [(ngModel)]="model.EmailAddress" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Website</label>
                        <input [(ngModel)]="model.WebsiteURL" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Payment Bank Name</label>
                        <input [(ngModel)]="model.PaymentBankName" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Payment Bank Routing</label>
                        <input [(ngModel)]="model.PaymentBankRouting" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Payment Bank Account</label>
                        <input [(ngModel)]="model.PaymentBankAccount" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Payment Address</label>
                        <input [(ngModel)]="model.PaymentAddress1" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Payment Address</label>
                        <input [(ngModel)]="model.PaymentAddress2" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Payment City</label>
                        <input [(ngModel)]="model.PaymentCity" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>State Payment</label>
                        <select [(ngModel)]="model.FK_State_Payment" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_State_Payment_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Payment Zipcode</label>
                        <input [(ngModel)]="model.PaymentZipcode" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Payment Pay To</label>
                        <input [(ngModel)]="model.PaymentPayTo" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Login</label>
                        <app-typeahead-basic [(id)]="model.FK_Login" [searchapi]="'loginsListBox'"></app-typeahead-basic>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Phone</label>
                        <input [(ngModel)]="model.Phone" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Mailing Street Address</label>
                        <input [(ngModel)]="model.Mailing_StreetAddress1" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Mailing Street Address</label>
                        <input [(ngModel)]="model.Mailing_StreetAddress2" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Mailing City</label>
                        <input [(ngModel)]="model.Mailing_City" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>State Vendor Mailing</label>
                        <select [(ngModel)]="model.FK_State_VendorMailing" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_State_VendorMailing_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Mailing Zipcode</label>
                        <input [(ngModel)]="model.Mailing_Zipcode" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Physical Street Address</label>
                        <input [(ngModel)]="model.Physical_StreetAddress1" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Physical Street Address</label>
                        <input [(ngModel)]="model.Physical_StreetAddress2" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Physical City</label>
                        <input [(ngModel)]="model.Physical_City" type="text" class="form-control">
                    </div>
                </div>


            </div>


        </div>
    </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
