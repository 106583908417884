<div class="card">
  <div class="card-header">
    Login <span class="right"><a title="Cancel" routerLink="/userloginlist"><i class="fa fa-undo icon-link"></i></a> <i class="fa fa-save icon-link" (click)="save()"></i></span>
  </div>
  <div class="card-body">
    <div class="form">
      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-header">Detail</div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Email</label><span title="Required" class="required">*</span>
                    <input [(ngModel)]="model.Email" type="text" class="form-control">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Password</label><span title="Required" class="required">*</span>
                    <input [(ngModel)]="model.Password" type="text" class="form-control">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Password Confirm</label><span title="Required" class="required">*</span>
                    <input [(ngModel)]="model.Password2" type="text" class="form-control">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Is Enabled</label><span title="Required" class="required">*</span>
                    <input [(ngModel)]="model.IsEnabled" type="checkbox" class="form-control">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Two Factor Phone Number</label>
                    <input [(ngModel)]="model.TwoFactorPhoneNumber" type="text" class="form-control">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Roles</label>
                    <app-loginRolesLookUpCheckBoxListByLogin (saved)="childComponentSaved()" [commit]="commit_LoginRolesLookUp" [id]="model.PK_Id"></app-loginRolesLookUpCheckBoxListByLogin>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Created Date</label>
                    <div>{{model.CreatedDate}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>


    </div>
  </div>
</div>
