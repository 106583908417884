<div *ngIf="modalEdit||newModalEdit" class="modal" [style.left]="leftOffset" [style.top]="topOffset">
  <app-zengineFormEdit [modalview]="true" [id]="modalEdit" [level]="(level+.5)" (saved)="modalEditSaved($event)" (cancelled)="modalEdit=null;newModalEdit=false"></app-zengineFormEdit>
</div>
<div class="card">
  <div class="card-header">
    Zengine Form List <a class="right"  (click)="newModalEdit = true" ><i class="fa fa-plus" title="Add Zengine Form"></i></a>
    <div><app-search-input [placeholdertext]="'Search'" (changed)="fuzzySearchChange($event)" ></app-search-input></div>

  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div style="grid-template-columns: 100px auto auto auto auto auto ;" class="table">
          <div class="table-header" style="text-align:left">
            <i (click)="selectAll()" title="Select All" class="fa" [ngClass]="{ 'fa-check-square-o':selectAllStatus,'fa-square-o':!selectAllStatus}"></i>
            <i title='Delete Many' (click)='deleteMany()' class='fa fa-trash icon-link'></i>
          </div>
          <div class="table-header" (click)="sortChange('ZengineFormName')">Zengine Form Name <i *ngIf="search._sort=='ZengineFormName'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('ZengineFormId')">Zengine Form Id <i *ngIf="search._sort=='ZengineFormId'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_ZengineFormType_Display')">Zengine Form Type <i *ngIf="search._sort=='FK_ZengineFormType_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header">States</div>
          <div class="table-header">Years</div>

 <div><i title="Filter" (click)="searchSelected()" class="fa fa-filter icon-link"></i><i title="Filter" (click)="clearSearch()" class="fa fa-times icon-link"></i></div>
          <div class="center"><input class="filter-list" placeholder="Search" [(ngModel)]="search.zengineFormName_like" /></div>
          <div class="center">
            <input class="filter-list" placeholder="From" [(ngModel)]="search.zengineFormId_fromValue" />
            <input class="filter-list" placeholder="To" [(ngModel)]="search.zengineFormId_toValue" />
          </div>
          <div class="center"><input class="filter-list" placeholder="Search" [(ngModel)]="search.fK_ZengineFormType_like" /></div>
<div></div>
<div></div>



          <ng-container *ngFor="let x of model">
            <div>
              <span class='edit-tools'>
                <i (click)="x.Selected = !x.Selected" class="fa" [ngClass]="{ 'fa-check-square-o':x.Selected,'fa-square-o':!x.Selected}"></i>
                <a (click)="modalEdit = x.PK_Id"><i title='Edit' class='fa fa-pencil icon-link'></i></a> <i title='Delete' (click)='delete(x.PK_Id )' class='fa fa-trash icon-link'></i>
              </span>
            </div>
            <div>
              {{x.ZengineFormName}}
            </div>
            <div>
              {{x.ZengineFormId}}
            </div>
            
            <div>
              {{x.FK_ZengineFormType_Display}}
            </div>
            <div>
              {{x.StatesList}}
            </div>
            <div>
              {{x.YearsList}}
            </div>

          </ng-container>
        </div>

        <app-paging [showpagesize]="true" [data]="pagingData" (changed)="pagingChanged($event)"></app-paging>
      </div>
    </div>
  </div>
</div>

<app-spinner [showspinner]="ShowSpinner"></app-spinner>

