import {IListItem } from '../models';
export class SchoolEligibilityModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public HtmlContent:string = "";
	public PK_Id:number = 0;
	public IsHeaderContentOnly?:boolean = null;
	public RadioButton?:boolean = null;
	public ForStudent?:boolean = null;
	public ForSchool?:boolean = null;
	public FK_SchoolEligibility_Parent?:number = null;
	public FK_SchoolEligibility_Parent_Display:string = null;
}
