<div class="p-2">
  <p-tabView>
    <p-tabPanel header="All Vendors">
      <div class="row py-2">
        <div class="col-3">
          <div class="form-group">
            <label class="form-label font-weight-bold">Search Vendors</label>
            <input type="text" class="form-control w-100" [(ngModel)]="vendorsSearchText"
              (ngModelChange)="onSearchTextChanged()" />
          </div>
        </div>
        <div class="col-1">
          <div class="form-group">
            <label class="form-label font-weight-bold">State</label>
            <p-dropdown [options]="stateOptions" class="form-control no-border w-100" [(ngModel)]="stateSelected"
              (ngModelChange)="onStateChanged()">
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-12">
          <app-custom-table [data]="vendorsTableData" [columns]="vendorsTableColumns" [rows]="10" [showPaginator]="true"
            [showSummary]="true" [useLazyLoad]="true" (onLoadData)="loadVendorData($event)"
            [isLoading]="vendorsTableLoading" [totalRecords]="vendorsTableTotalRecords"
            (onRowTableClicked)="onVendorTableRowClick($event)">
          </app-custom-table>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Export">

    </p-tabPanel>
  </p-tabView>
</div>