import { Component, OnInit, Directive } from '@angular/core';
import {  IZengineFormFieldListParams } from '../../models/entities/ZengineFormFieldModel.response';
import { ZengineFormFieldModel } from '../../models/entities/ZengineFormFieldModel';
import { PagingData } from '../../paging/paging.component';
import { ZengineFormFieldListBaseComponent } from './zengineFormFieldList.base';

@Component({
	selector: 'app-zengineFormFieldList',
	templateUrl: './zengineFormFieldList.component.html',
})
export class ZengineFormFieldListComponent extends ZengineFormFieldListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
