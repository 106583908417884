import { Component, OnInit, Directive } from '@angular/core';
import {  IGradeLevelListParams } from '../../models/entities/GradeLevelModel.response';
import { GradeLevelModel } from '../../models/entities/GradeLevelModel';
import { PagingData } from '../../paging/paging.component';
import { GradeLevelListBaseComponent } from './gradeLevelList.base';

@Component({
	selector: 'app-gradeLevelList',
	templateUrl: './gradeLevelList.component.html',
})
export class GradeLevelListComponent extends GradeLevelListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
