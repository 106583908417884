import { ZengineFormStateLookUpModel} from '../entities/ZengineFormStateLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ZengineFormStateLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ZengineFormStateLookUpModel = new ZengineFormStateLookUpModel();
}

export class ZengineFormStateLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ZengineFormStateLookUpModel> = <Array<ZengineFormStateLookUpModel >>[]
}

export interface IZengineFormStateLookUpListParams {
	pK_Id?:number;
	fK_ZengineForm?:number;
	fK_ZengineForm_display?:string;
	fK_ZengineForm_like?:string;
	fK_ZengineForm_fromValue?:number;
	fK_ZengineForm_toValue?:number;
	fK_State?:number;
	fK_State_display?:string;
	fK_State_like?:string;
	fK_State_fromValue?:number;
	fK_State_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ZengineFormStateLookUpListParams implements IZengineFormStateLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_ZengineForm?:number=null;
	fK_ZengineForm_display?:string=null;
	fK_ZengineForm_like?:string=null;
	fK_ZengineForm_fromValue?:number=null;
	fK_ZengineForm_toValue?:number=null;
	fK_State?:number=null;
	fK_State_display?:string=null;
	fK_State_like?:string=null;
	fK_State_fromValue?:number=null;
	fK_State_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

