import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolCommitmentStatusTypeListParams } from '../../models/entities/SchoolCommitmentStatusTypeModel.response';
import { SchoolCommitmentStatusTypeModel } from '../../models/entities/SchoolCommitmentStatusTypeModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolCommitmentStatusTypeListBaseComponent } from './schoolCommitmentStatusTypeList.base';

@Component({
	selector: 'app-schoolCommitmentStatusTypeList',
	templateUrl: './schoolCommitmentStatusTypeList.component.html',
})
export class SchoolCommitmentStatusTypeListComponent extends SchoolCommitmentStatusTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
