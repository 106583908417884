import { SchoolTransactionModel} from '../entities/SchoolTransactionModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolTransactionResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolTransactionModel = new SchoolTransactionModel();
}

export class SchoolTransactionList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolTransactionModel> = <Array<SchoolTransactionModel >>[]
}

export interface ISchoolTransactionListParams {
	pK_Id?:number;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number;
	fK_TransactionStatusType?:number;
	fK_TransactionStatusType_display?:string;
	fK_TransactionStatusType_like?:string;
	fK_TransactionStatusType_fromValue?:number;
	fK_TransactionStatusType_toValue?:number;
	fK_TransactionType?:number;
	fK_TransactionType_display?:string;
	fK_TransactionType_like?:string;
	fK_TransactionType_fromValue?:number;
	fK_TransactionType_toValue?:number;
	amount?:number;
	amount_fromValue?:number;
	amount_toValue?:number;
	fK_Verification?:number;
	fK_Verification_display?:string;
	fK_Verification_like?:string;
	fK_Verification_fromValue?:number;
	fK_Verification_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolTransactionListParams implements ISchoolTransactionListParams, ISearchModel  {
	pK_Id?:number=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number=null;
	fK_TransactionStatusType?:number=null;
	fK_TransactionStatusType_display?:string=null;
	fK_TransactionStatusType_like?:string=null;
	fK_TransactionStatusType_fromValue?:number=null;
	fK_TransactionStatusType_toValue?:number=null;
	fK_TransactionType?:number=null;
	fK_TransactionType_display?:string=null;
	fK_TransactionType_like?:string=null;
	fK_TransactionType_fromValue?:number=null;
	fK_TransactionType_toValue?:number=null;
	amount?:number=null;
	amount_fromValue?:number=null;
	amount_toValue?:number=null;
	fK_Verification?:number=null;
	fK_Verification_display?:string=null;
	fK_Verification_like?:string=null;
	fK_Verification_fromValue?:number=null;
	fK_Verification_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

