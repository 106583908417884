import {IListItem } from '../models';
export class VendorCategoriesLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_Vendor:number = 0;
	public FK_Vendor_Display:string = null;
	public FK_ScholarshipDisbursementCategory:number = 0;
	public FK_ScholarshipDisbursementCategory_Display:string = null;
}
