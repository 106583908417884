import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolEligibilitiesResponsEditBaseComponent } from './schoolEligibilitiesResponsEdit.base';

@Component({
	selector: 'app-schoolEligibilitiesResponsEdit',
	templateUrl: './schoolEligibilitiesResponsEdit.component.html',
})
export class SchoolEligibilitiesResponsEditComponent extends SchoolEligibilitiesResponsEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
