import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/BaseComponent';
import { AwardDeterminationModel } from 'src/app/models/entities/AwardDeterminationModel';
import { AwardDeterminationList } from 'src/app/models/entities/AwardDeterminationModel.response';
import { GuardianModel } from 'src/app/models/entities/GuardianModel';
import { ScholarshipModel } from 'src/app/models/entities/ScholarshipModel';
import { aaaAPIFamilyService } from '../../../services/aaa.API.family.service';
import { SchoolModel } from '../../../models/entities/SchoolModel';
import { aaaAPIStudentService } from '../../../services/aaa.API.student.service';

import { ITableColumnDefinition } from '../../../interfaces/table-column-definition-interface';
import { FieldTypeDefinition } from '../../../types/field-type-definition-type';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription, timer } from 'rxjs';
import { aaaAPIAdminService } from 'src/app/services/aaa.API.admin.service';
import { CONSTANTS } from 'src/app/constants/constants';
import { SchoolCommitmentModel } from 'src/app/models/entities/SchoolCommitmentModel';

@Component({
  selector: 'app-family-dashboard-page',
  templateUrl: './family-dashboard-page.component.html',
  styleUrls: ['./family-dashboard-page.component.scss']
})
export class FamilyDashboardPageComponent extends BaseComponent implements OnInit {
  data: any[] = [];
  guardianModel: GuardianModel = null;
  scholarshipsList: ScholarshipModel[] = [];
  guardianScholarshipList: AwardDeterminationModel[] = [];
  findSchoolDisplay: boolean = false;
  schoolNameOrAddress: string = '';
  nameStudentSelected: string = '';
  codeStudentSelected: number;
  codeSchoolYearStudentSelected: number;
  schoolYearStudentSelected: string = '';
  schoolTableData: any[] = [];
  schoolTableColumns: ITableColumnDefinition[] = [];
  schoolSearchList: SchoolModel[] = [];
  actualAwardModel: AwardDeterminationModel = new AwardDeterminationModel();
  schoolCommitmentStudentDisplay: boolean = false;
  schoolToCommitment: SchoolModel = new SchoolModel();
  schoolFeesTableData: any[][] = [];
  schoolFeesTableColumns: ITableColumnDefinition[] = [];
  schoolFeesData: any[] = [];
  schoolModels: SchoolModel[] = [];
  searchSchoolText: string = '';
  lastLazyLoadEvent: LazyLoadEvent = null;
  lastSearchText: string = null;
  usersTableLoading: boolean = false;
  usersTableTotalRecords: number = null;
  SchoolCommitModel: SchoolCommitmentModel = new SchoolCommitmentModel();
  searchSubscription: Subscription;

  constructor(
    private familyService: aaaAPIFamilyService,
    private studentService: aaaAPIStudentService,
    private adminServices: aaaAPIAdminService
  ) {
    super();
  }

  ngOnInit(): void {
    this.familyService.getGuardianByLogin(this.gl.LoginId)
      .subscribe(response => {
        if (response.Success) {
          this.guardianModel = response.Data;
          this.pageLoad();
        }
      });
  }

  pageLoad() {
    this.familyService.getScholarshipList({})
      .subscribe(responseScholarships => {
        this.scholarshipsList = responseScholarships.Data;
        this.familyService.getAwardDeterminationList({ fK_Guardian: this.guardianModel.PK_Id })
          .subscribe(responseADList => {
            this.guardianScholarshipList = responseADList.Data;
            this.loadSchoolData(CONSTANTS.FirstLazyLoadEvent);
            this.guardianScholarshipList.forEach(item => {
              this.adminServices.getSchoolCommitmentList({
                fK_SchoolYear: item.FK_SchoolYear,
                fK_Student: item.FK_Student
              })
                .subscribe(responseCommitment => {
                  console.log('responseCommitment: ', responseCommitment.Data);
                  if (responseCommitment.Data.length > 0) {
                    const isSelected = responseCommitment.Data[0].FK_School != null;
                    item.Completed = isSelected;
                    item.CompletedValue = responseCommitment.Data[0].FK_SchoolCommitmentStatusType_Display == "Approved" ? 100 :
                      responseCommitment.Data[0].FK_SchoolCommitmentStatusType_Display == "PendingAAAReview" ? 66 : 33;
                    item.SchoolSelected = isSelected;
                    item.FK_School = responseCommitment.Data[0].FK_School;
                    item.FK_School_Display = responseCommitment.Data[0].FK_School_Display;
                  }
                  else {
                    item.Completed = false;
                    item.CompletedValue = 33;
                    item.SchoolSelected = false;
                    item.FK_School = null;
                    item.FK_School_Display = '';
                  }
                });
            });
          });
      });
  }

  onSearchTextChanged() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchSubscription = source
      .subscribe(() => {
        this.loadSchoolData(this.lastLazyLoadEvent);
        this.searchSubscription.unsubscribe();
      })
  }

  getIconText(row: any) {
    return (row.SchoolSelected) ? `Enrolled:<br>${row.FK_School_Display}` : `No School<br>Selected`;
  }

  goToStudentProfile(PK_Id: number) {
    this.gl.SelectedStudent = this.guardianScholarshipList.find(item => item.FK_Student == PK_Id).FK_Student_Display;
    this.gl.GoTo(`studentprofile/${PK_Id}`, null, null);
  }

  openFindSchool(row: any) {
    console.log('openFindSchool: ', row);
    this.familyService
      .getAwardDeterminationList({ fK_Student: row.FK_Student, fK_SchoolYear: row.FK_SchoolYear })
      .subscribe((res) => {
        console.log(res.Data);
        this.actualAwardModel = res.Data[0];
      });
    this.nameStudentSelected = row.FK_Student_Display;
    this.codeStudentSelected = row.FK_Student;
    this.schoolYearStudentSelected = row.FK_SchoolYear_Display;
    this.codeSchoolYearStudentSelected = row.FK_SchoolYear;
    this.findSchoolDisplay = true;
  }

  loadSchoolData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.usersTableLoading = true;
      const page = (this.searchSchoolText !== this.lastSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.searchSchoolText && this.searchSchoolText.trim().length > 0) ? this.searchSchoolText : null;
      this.lastSearchText = this.searchSchoolText;
      console.log('page: ', page, '  pageSize: ', pageSize);

      this.adminServices.doSearchSchoolList(searchText, null, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseLogins => {
          console.log(responseLogins);
          this.usersTableTotalRecords = responseLogins.RecordsCount;
          this.schoolModels = responseLogins.Data;
          this.generateSchoolTableData();
          this.usersTableLoading = false;
        });
    }
  }

  requestNewSchool() {

  }

  doViewButtonSchoolCommitment(e) {
    console.log('doViewButtonSchoolCommitment ', e);
    this.schoolToCommitment = e;
    this.studentService.getFeesBySchool(e.PK_Id).subscribe((x) => {
      if (x.Success) {
        this.generateSchoolFeesTableData(<Array<object>>x.Data);
      }
    });
    console.log(this.schoolToCommitment);
    this.schoolCommitmentStudentDisplay = true;
  }

  submitSchoolCommitment() {
    console.log('codeStudentSelected: ', this.codeStudentSelected);
    console.log('codeSchoolYearStudentSelected: ', this.codeSchoolYearStudentSelected);
    this.adminServices.getStudentGradeLevelsSchoolYearsLookUpList({ fK_Student: this.codeStudentSelected, fK_SchoolYear: this.codeSchoolYearStudentSelected })
      .subscribe(studentGradeLevel => {
        if (studentGradeLevel.Data.length > 0) {
          studentGradeLevel.Data[0].FK_School = this.schoolToCommitment.PK_Id;
          this.adminServices.updateStudentGradeLevelsSchoolYearsLookUp(studentGradeLevel.Data[0])
            .subscribe(response => {
              if (response.Success) {
                this.SchoolCommitModel.FK_School = this.schoolToCommitment.PK_Id;
                this.SchoolCommitModel.FK_SchoolCommitmentStatusType = 1; //PendingSchoolReview
                this.SchoolCommitModel.FK_Student = this.codeStudentSelected;
                this.SchoolCommitModel.FK_SchoolYear = this.codeSchoolYearStudentSelected;
                this.SchoolCommitModel.StudentStartDate = new Date();
                this.adminServices.insertSchoolCommitment(this.SchoolCommitModel).subscribe(res => {
                  if (res.Success) {
                    this.schoolCommitmentStudentDisplay = false;
                    this.findSchoolDisplay = false;
                    this.pageLoad();
                  }
                });
              }
            });
        }
      });

  }

  cancelProfile() {
    this.findSchoolDisplay = false;
  }

  cancelSchoolCommitment() {
    this.schoolCommitmentStudentDisplay = false;
  }

  private generateSchoolFeesTableData(dataSchoolFees: Array<object>) {
    console.log(dataSchoolFees.length);
    console.log(dataSchoolFees);
    this.schoolFeesTableData = [];
    this.schoolFeesData = [];
    this.generateSchoolFeesTableColumns(dataSchoolFees);

    if (dataSchoolFees !== undefined || dataSchoolFees.length != 0) {
      for (let index = 0; index < dataSchoolFees.length; index++) {
        let data = dataSchoolFees[index]['Data'];
        this.schoolFeesData.push(
          `${dataSchoolFees[index]['SchoolYear']} Fee Schedule`
        );
        this.schoolFeesTableData.push(data);
      }
    }
  }

  private generateSchoolFeesTableColumns(dataSchoolFees: Array<object>) {
    let columns: ITableColumnDefinition[] = [];
    let cols = dataSchoolFees[0];
    if (cols !== null && cols !== undefined) {
      let columnsSchoolFees = cols['Columns'];
      columns.push({
        fieldCaption: columnsSchoolFees[0],
        fieldName: columnsSchoolFees[0],
        fieldType: FieldTypeDefinition.String,
      });
      for (let index = 1; index < columnsSchoolFees.length; index++) {
        columns.push({
          fieldCaption: columnsSchoolFees[index],
          fieldName: columnsSchoolFees[index],
          fieldType: FieldTypeDefinition.Money,
        });
      }
    } else {
      columns.push({
        fieldCaption: 'Data',
        fieldName: 'Data',
        fieldType: FieldTypeDefinition.String,
      });
    }
    this.schoolFeesTableColumns = [...columns];
  }

  private generateSchoolTableData() {
    this.generateSchoolsTableColumn();
    this.schoolTableData = this.schoolModels.map(i => {
      return {
        'PK_Id': i.PK_Id,
        'State': i.FK_State_Physical_Display,
        'Name': i.Name,
        'Phone': i.Phone,
        'Physical_City': i.Physical_City,
        'Physical_StreetAddress1': i.Physical_StreetAddress1,
        'Physical_StreetAddress2': i.Physical_StreetAddress2,
        'FK_State_Physical_Display': i.FK_State_Physical_Display,
        'Physical_Zipcode': i.Physical_Zipcode,
        'FaxNumber': i.FaxNumber,
        'WebsiteURL': i.WebsiteURL,
        'EmailAddress': i.EmailAddress
      }
    });
    // this.schoolTableData = [];
    // this.familyService
    //   .getSchoolList({
    //     name_like: this.schoolNameOrAddress,
    //   })
    //   .subscribe((res) => {
    //     let gradeData = [];
    //     this.schoolSearchList = res.Data;
    //     console.log(res.Data);
    //     res.Data.forEach((school) => {
    //       let rowData = {};
    //       rowData['School Name'] = school.Name;
    //       rowData['Street Address'] = school.Physical_StreetAddress1;
    //       rowData['City'] = school.Physical_City;
    //       rowData['County'] = school.FK_County_Physical_Display;
    //       rowData['State'] = school.FK_State_Physical_Display;
    //       rowData['data'] = school;
    //       gradeData.push(rowData);
    //     });
    //     this.schoolTableData.push(gradeData);
    //   });
  }


  private generateSchoolsTableColumn() {
    let columns: ITableColumnDefinition[] = [];

    columns.push({
      fieldCaption: 'School Name',
      fieldName: 'Name',
      fieldType: FieldTypeDefinition.String,
    });

    columns.push({
      fieldCaption: 'Address',
      fieldName: 'Physical_StreetAddress1',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'City',
      fieldName: 'Physical_City',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'State',
      fieldName: 'State',
      fieldType: FieldTypeDefinition.String,
      width: '10%',
    });
    columns.push({
      fieldCaption: 'Phone Number',
      fieldName: 'Phone',
      fieldType: FieldTypeDefinition.String,
    });

    this.schoolTableColumns = [...columns];
  }
}
