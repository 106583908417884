import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginRolesLookUpEditBaseComponent } from './loginRolesLookUpEdit.base';

@Component({
	selector: 'app-loginRolesLookUpEdit',
	templateUrl: './loginRolesLookUpEdit.component.html',
})
export class LoginRolesLookUpEditComponent extends LoginRolesLookUpEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
