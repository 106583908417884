import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolEligibilitiesStateLookUpListParams } from '../../models/entities/SchoolEligibilitiesStateLookUpModel.response';
import { SchoolEligibilitiesStateLookUpModel } from '../../models/entities/SchoolEligibilitiesStateLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolEligibilitiesStateLookUpListBaseComponent } from './schoolEligibilitiesStateLookUpList.base';

@Component({
	selector: 'app-schoolEligibilitiesStateLookUpList',
	templateUrl: './schoolEligibilitiesStateLookUpList.component.html',
})
export class SchoolEligibilitiesStateLookUpListComponent extends SchoolEligibilitiesStateLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
