import { Component, OnInit, Directive } from '@angular/core';
import { ISchoolListParams,SchoolListParams } from '../../models/entities/SchoolModel.response';
import { SchoolModel} from '../../models/entities/SchoolModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class SchoolListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<SchoolModel> = <Array<SchoolModel>>[];
	public search: SchoolListParams = new SchoolListParams();
	protected baseNgOnInit() {
		this.search._sort='Name';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getSchoolList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.fK_County_Physical  = null;
		this.search.fK_County_Physical_display  = null;
		this.search.fK_County_Physical_like  = null;
		this.search.fK_County_Physical_fromValue  = null;
		this.search.fK_County_Physical_toValue  = null;
		this.search.name  = null;
		this.search.name_like  = null;
		this.search.pK_Id  = null;
		this.search.fK_State_Physical  = null;
		this.search.fK_State_Physical_display  = null;
		this.search.fK_State_Physical_like  = null;
		this.search.fK_State_Physical_fromValue  = null;
		this.search.fK_State_Physical_toValue  = null;
		this.search.physical_Zipcode  = null;
		this.search.physical_Zipcode_like  = null;
		this.search.isDeleted  = null;
		this.search.isDeleted_booleanValue  = null;
		this.search.phone  = null;
		this.search.phone_like  = null;
		this.search.district  = null;
		this.search.district_like  = null;
		this.search.mailing_StreetAddress1  = null;
		this.search.mailing_StreetAddress1_like  = null;
		this.search.mailing_StreetAddress2  = null;
		this.search.mailing_StreetAddress2_like  = null;
		this.search.mailing_City  = null;
		this.search.mailing_City_like  = null;
		this.search.fK_State_MailingAddress  = null;
		this.search.fK_State_MailingAddress_display  = null;
		this.search.fK_State_MailingAddress_like  = null;
		this.search.fK_State_MailingAddress_fromValue  = null;
		this.search.fK_State_MailingAddress_toValue  = null;
		this.search.mailing_Zipcode  = null;
		this.search.mailing_Zipcode_like  = null;
		this.search.fK_County_Mailing  = null;
		this.search.fK_County_Mailing_display  = null;
		this.search.fK_County_Mailing_like  = null;
		this.search.fK_County_Mailing_fromValue  = null;
		this.search.fK_County_Mailing_toValue  = null;
		this.search.physical_StreetAddress1  = null;
		this.search.physical_StreetAddress1_like  = null;
		this.search.physical_StreetAddress2  = null;
		this.search.physical_StreetAddress2_like  = null;
		this.search.physical_City  = null;
		this.search.physical_City_like  = null;
		this.search.dOECode  = null;
		this.search.dOECode_like  = null;
		this.search.faxNumber  = null;
		this.search.faxNumber_like  = null;
		this.search.nonProfit  = null;
		this.search.nonProfit_booleanValue  = null;
		this.search.religious  = null;
		this.search.religious_booleanValue  = null;
		this.search.religiousAffiliation  = null;
		this.search.religiousAffiliation_like  = null;
		this.search.genderServed  = null;
		this.search.genderServed_like  = null;
		this.search.emailAddress  = null;
		this.search.emailAddress_like  = null;
		this.search.websiteURL  = null;
		this.search.websiteURL_like  = null;
		this.search.createdDate  = null;
		this.search.createdDate_fromDate  = null;
		this.search.createdDate_toDate  = null;
		this.search.fK_Login_CreatedBy  = null;
		this.search.fK_Login_CreatedBy_display  = null;
		this.search.fK_Login_CreatedBy_like  = null;
		this.search.paymentBankName  = null;
		this.search.paymentBankName_like  = null;
		this.search.paymentBankRouting  = null;
		this.search.paymentBankRouting_like  = null;
		this.search.paymentBankAccount  = null;
		this.search.paymentBankAccount_like  = null;
		this.search.paymentAddress1  = null;
		this.search.paymentAddress1_like  = null;
		this.search.paymentAddress2  = null;
		this.search.paymentAddress2_like  = null;
		this.search.paymentCity  = null;
		this.search.paymentCity_like  = null;
		this.search.fK_State_Payment  = null;
		this.search.fK_State_Payment_display  = null;
		this.search.fK_State_Payment_like  = null;
		this.search.fK_State_Payment_fromValue  = null;
		this.search.fK_State_Payment_toValue  = null;
		this.search.paymentZipcode  = null;
		this.search.paymentZipcode_like  = null;
		this.search.paymentPayTo  = null;
		this.search.paymentPayTo_like  = null;
		this.search.fK_SchoolType  = null;
		this.search.fK_SchoolType_display  = null;
		this.search.fK_SchoolType_like  = null;
		this.search.fK_SchoolType_fromValue  = null;
		this.search.fK_SchoolType_toValue  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deleteSchool(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManySchool(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
