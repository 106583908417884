import {IListItem } from '../models';
export class ScholarshipDisbursementCategoriesLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_Scholarship:number = 0;
	public FK_Scholarship_Display:string = null;
	public FK_ScholarshipDisbursementCategory:number = 0;
	public FK_ScholarshipDisbursementCategory_Display:string = null;
	public IsEnabled:boolean = false;
	public PreAuthorizationRequired?:boolean = null;
	public LicensedInstructors?:boolean = null;
	public DocumentUploadForApproval?:boolean = null;
}
