import { Component, OnInit, Directive } from '@angular/core';
import { IWithdrawNoteListParams } from '../../models/entities/WithdrawNoteModel.response';
import { WithdrawNoteModel } from '../../models/entities/WithdrawNoteModel';
import { PagingData } from '../../paging/paging.component';
import { WithdrawNoteListBaseComponent } from './withdrawNoteList.base';

@Component({
    selector: 'app-withdrawNoteList',
    templateUrl: './withdrawNoteList.component.html',
})
export class WithdrawNoteListComponent extends WithdrawNoteListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
