import {IListItem } from '../models';
export class ZengineLoginModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public UserName:string = "";
	public Password:string = "";
	public ApiToken?:string = null;
	public DateUpdated?:Date = null;
}
