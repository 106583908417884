import { GuardianModel} from '../entities/GuardianModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class GuardianResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: GuardianModel = new GuardianModel();
}

export class GuardianList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<GuardianModel> = <Array<GuardianModel >>[]
}

export interface IGuardianListParams {
	fK_GuardianType?:number;
	fK_GuardianType_display?:string;
	fK_GuardianType_like?:string;
	fK_GuardianType_fromValue?:number;
	fK_GuardianType_toValue?:number;
	pK_Id?:number;
	fK_Login?:string;
	fK_Login_display?:string;
	fK_Login_like?:string;
	fK_Household?:number;
	fK_Household_fromValue?:number;
	fK_Household_toValue?:number;
	firstName?:string;
	firstName_like?:string;
	middleInitial?:string;
	middleInitial_like?:string;
	lastName?:string;
	lastName_like?:string;
	homePhone?:string;
	homePhone_like?:string;
	cellPhone?:string;
	cellPhone_like?:string;
	paymentBankName?:string;
	paymentBankName_like?:string;
	paymentBankRouting?:string;
	paymentBankRouting_like?:string;
	paymentBankAccount?:string;
	paymentBankAccount_like?:string;
	employer?:string;
	employer_like?:string;
	workPhone?:string;
	workPhone_like?:string;
	inviteEmailSent?:Date;
	inviteEmailSent_fromDate?:string;
	inviteEmailSent_toDate?:string;
	inviteEmailAccepted?:Date;
	inviteEmailAccepted_fromDate?:string;
	inviteEmailAccepted_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class GuardianListParams implements IGuardianListParams, ISearchModel  {
	fK_GuardianType?:number=null;
	fK_GuardianType_display?:string=null;
	fK_GuardianType_like?:string=null;
	fK_GuardianType_fromValue?:number=null;
	fK_GuardianType_toValue?:number=null;
	pK_Id?:number=null;
	fK_Login?:string=null;
	fK_Login_display?:string=null;
	fK_Login_like?:string=null;
	fK_Household?:number=null;
	fK_Household_fromValue?:number=null;
	fK_Household_toValue?:number=null;
	firstName?:string=null;
	firstName_like?:string=null;
	middleInitial?:string=null;
	middleInitial_like?:string=null;
	lastName?:string=null;
	lastName_like?:string=null;
	homePhone?:string=null;
	homePhone_like?:string=null;
	cellPhone?:string=null;
	cellPhone_like?:string=null;
	paymentBankName?:string=null;
	paymentBankName_like?:string=null;
	paymentBankRouting?:string=null;
	paymentBankRouting_like?:string=null;
	paymentBankAccount?:string=null;
	paymentBankAccount_like?:string=null;
	employer?:string=null;
	employer_like?:string=null;
	workPhone?:string=null;
	workPhone_like?:string=null;
	inviteEmailSent?:Date=null;
	inviteEmailSent_fromDate?:string=null;
	inviteEmailSent_toDate?:string=null;
	inviteEmailAccepted?:Date=null;
	inviteEmailAccepted_fromDate?:string=null;
	inviteEmailAccepted_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

