import { Injectable, EventEmitter } from '@angular/core';



export class JSMessageItem {
  public MessageType: string = null;
  public Value: any = null;
}


@Injectable({
  providedIn: 'root'
})
export class ErrorMessagesService {

  constructor() {

  }


  public spinnerChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private successMessageChange: EventEmitter<string> = new EventEmitter<string>();

  

  private jsMessageChange: EventEmitter<JSMessageItem> = new EventEmitter<JSMessageItem>();

  messages: string[] = [];
  private _successMessage: string = null;

  private _confirmMessage: string = null;
  private _confirmCallback: any = null;
  public set confirmMessage(value: string) {
    this._confirmMessage = value;
  }

  public get confirmMessage(): string {
    return this._confirmMessage;
  }

  get successMessage(): string {
    return this._successMessage;
  }
  set successMessage(value: string) {
    this._successMessage = value;
    this.successMessageChange.emit(value);
  }

  getSuccessMessageChange() {
    return this.successMessageChange;
  }


 
  confirm(mesg: string, next: any) {
    var me = this;
    me._confirmCallback = next;
    window.setTimeout(function () { me.confirmMessage = mesg; }, 50);
  }

  getJSMessageChange() {
    return this.jsMessageChange;
  }

  public Spinner(value: boolean) {
    var me = this;
    window.setTimeout(function () { me.showSpinner = value; }, 5);
  }

  private _showSpinner: boolean = false;

  public set showSpinner(value: boolean) {
    this._showSpinner = value;
    this.spinnerChange.emit(value);
  }

  public get showSpinner(): boolean {
    return this._showSpinner;
  }

  private parentSuccessMessage(message) {
    window.parent.postMessage({
      'func': 'successMessageShow',
      'message': message
    }, "*");
  }





  add(message: string) {
    this.messages.push(message);
  }

  addJsMessage(messageType: string, value: any) {
    this.jsMessageChange.emit(<JSMessageItem>{ MessageType: messageType, Value: value });
  }

  addRange(values: string[]) {
    this.messages.length = 0;
    for (var i = 0; i < values.length; i++) {
      this.messages.push(values[i]);
    }
  }

  clear(event): boolean {
    if (event) {
      event.stopPropagation();
    }
    this.messages.length = 0;
    this.successMessage = null;
    return false;
  }

  yes(event): boolean {
    if (event) {
      event.stopPropagation();
    }
    this.confirmMessage = null;
    this._confirmCallback(true);
    return false;
  }

  no(event): boolean {
    if (event) {
      event.stopPropagation();
    }
    this.confirmMessage = null;
    this._confirmCallback(false);
    return false;
  }
}

export class ConfirmMessageResponse {
  public Value: boolean = false;

}
