import { LazyLoadEvent } from "primeng/api";

export class CONSTANTS {
    public static RecordCount: number = 10000;
    public static SearchTimerTime: number = 600;
    public static FirstLazyLoadEvent: LazyLoadEvent = {
        filters: {},
        first: 1,
        rows: 10,
        sortField: null,
        sortOrder: null
    };
}