import { HouseholdModel} from '../entities/HouseholdModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class HouseholdResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: HouseholdModel = new HouseholdModel();
}

export class HouseholdList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<HouseholdModel> = <Array<HouseholdModel >>[]
}

export interface IHouseholdListParams {
	isDeleted?:boolean;
	isDeleted_booleanValue?:boolean;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	pK_Id?:number;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	numberOfGuardiansLivingInHome?:number;
	numberOfGuardiansLivingInHome_fromValue?:number;
	numberOfGuardiansLivingInHome_toValue?:number;
	numberOfChildrenLivingInHome?:number;
	numberOfChildrenLivingInHome_fromValue?:number;
	numberOfChildrenLivingInHome_toValue?:number;
	numberOfOtherPeopleLivingInHome?:number;
	numberOfOtherPeopleLivingInHome_fromValue?:number;
	numberOfOtherPeopleLivingInHome_toValue?:number;
	totalHouseholdSize?:number;
	totalHouseholdSize_fromValue?:number;
	totalHouseholdSize_toValue?:number;
	primarySpokenLanguage?:string;
	primarySpokenLanguage_like?:string;
	householdIncome?:number;
	householdIncome_fromValue?:number;
	householdIncome_toValue?:number;
	address1?:string;
	address1_like?:string;
	address2?:string;
	address2_like?:string;
	city?:string;
	city_like?:string;
	fK_State?:number;
	fK_State_display?:string;
	fK_State_like?:string;
	fK_State_fromValue?:number;
	fK_State_toValue?:number;
	zipcode?:string;
	zipcode_like?:string;
	fK_County?:number;
	fK_County_display?:string;
	fK_County_like?:string;
	fK_County_fromValue?:number;
	fK_County_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class HouseholdListParams implements IHouseholdListParams, ISearchModel  {
	isDeleted?:boolean=null;
	isDeleted_booleanValue?:boolean=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	pK_Id?:number=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	numberOfGuardiansLivingInHome?:number=null;
	numberOfGuardiansLivingInHome_fromValue?:number=null;
	numberOfGuardiansLivingInHome_toValue?:number=null;
	numberOfChildrenLivingInHome?:number=null;
	numberOfChildrenLivingInHome_fromValue?:number=null;
	numberOfChildrenLivingInHome_toValue?:number=null;
	numberOfOtherPeopleLivingInHome?:number=null;
	numberOfOtherPeopleLivingInHome_fromValue?:number=null;
	numberOfOtherPeopleLivingInHome_toValue?:number=null;
	totalHouseholdSize?:number=null;
	totalHouseholdSize_fromValue?:number=null;
	totalHouseholdSize_toValue?:number=null;
	primarySpokenLanguage?:string=null;
	primarySpokenLanguage_like?:string=null;
	householdIncome?:number=null;
	householdIncome_fromValue?:number=null;
	householdIncome_toValue?:number=null;
	address1?:string=null;
	address1_like?:string=null;
	address2?:string=null;
	address2_like?:string=null;
	city?:string=null;
	city_like?:string=null;
	fK_State?:number=null;
	fK_State_display?:string=null;
	fK_State_like?:string=null;
	fK_State_fromValue?:number=null;
	fK_State_toValue?:number=null;
	zipcode?:string=null;
	zipcode_like?:string=null;
	fK_County?:number=null;
	fK_County_display?:string=null;
	fK_County_like?:string=null;
	fK_County_fromValue?:number=null;
	fK_County_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

