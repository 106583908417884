<div class="card">
  <div class="card-header">
    Guardian Type <span class="right">
  <a title="Cancel" *ngIf="!ModalView" routerLink="/guardiantypelist"><i class="fa fa-undo icon-link"></i></a>
  <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
  <i class="fa fa-save icon-link" (click)="save()"></i>
</span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        								<div class="col-6">
									<div class="form-group">
										<label>Name</label><span title="Required" class="required">*</span>
										<input [(ngModel)]="model.Name" type="text" class="form-control">
									</div>
								</div>


      </div>
      

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
