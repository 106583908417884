import { DocumentModel} from '../entities/DocumentModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class DocumentResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: DocumentModel = new DocumentModel();
}

export class DocumentList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<DocumentModel> = <Array<DocumentModel >>[]
}

export interface IDocumentListParams {
	pK_Id?:string;
	fK_NoteType?:number;
	fK_NoteType_display?:string;
	fK_NoteType_like?:string;
	fK_NoteType_fromValue?:number;
	fK_NoteType_toValue?:number;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	fileName?:string;
	fileName_like?:string;
	fileExtension?:string;
	fileExtension_like?:string;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number;
	fK_AwardDetermination?:number;
	fK_AwardDetermination_display?:string;
	fK_AwardDetermination_like?:string;
	fK_AwardDetermination_fromValue?:number;
	fK_AwardDetermination_toValue?:number;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	fK_Vendor?:number;
	fK_Vendor_display?:string;
	fK_Vendor_like?:string;
	fK_Vendor_fromValue?:number;
	fK_Vendor_toValue?:number;
	fK_Scholarship?:number;
	fK_Scholarship_display?:string;
	fK_Scholarship_like?:string;
	fK_Scholarship_fromValue?:number;
	fK_Scholarship_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class DocumentListParams implements IDocumentListParams, ISearchModel  {
	pK_Id?:string=null;
	fK_NoteType?:number=null;
	fK_NoteType_display?:string=null;
	fK_NoteType_like?:string=null;
	fK_NoteType_fromValue?:number=null;
	fK_NoteType_toValue?:number=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	fileName?:string=null;
	fileName_like?:string=null;
	fileExtension?:string=null;
	fileExtension_like?:string=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number=null;
	fK_AwardDetermination?:number=null;
	fK_AwardDetermination_display?:string=null;
	fK_AwardDetermination_like?:string=null;
	fK_AwardDetermination_fromValue?:number=null;
	fK_AwardDetermination_toValue?:number=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	fK_Vendor?:number=null;
	fK_Vendor_display?:string=null;
	fK_Vendor_like?:string=null;
	fK_Vendor_fromValue?:number=null;
	fK_Vendor_toValue?:number=null;
	fK_Scholarship?:number=null;
	fK_Scholarship_display?:string=null;
	fK_Scholarship_like?:string=null;
	fK_Scholarship_fromValue?:number=null;
	fK_Scholarship_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

