import { PagingChanged, PagingData } from "./paging/paging.component";
import { ISearchModel, IListItem } from "./models/models";
import { BaseComponent } from "./BaseComponent";
import { OnInit, Directive, Input } from "@angular/core";
import { ActivatedRoute } from '@angular/router';



@Directive()
export class BaseListComponent extends BaseComponent {
  constructor(r?: ActivatedRoute) {
    super(r);
    //this.pagingData = new PagingData();
  }

  protected getDataComplete() {

  }

  protected baseNgOnInit() {
    super.baseNgOnInit();
    this.search._page = 1;
    this.search._pageSize = 50;

    var me = this;
    window.setTimeout(function () {
      console.log('base ngInit');
      if (!me.id && me.route) {
        var v = me.route.snapshot.paramMap.get("id");
        if (v) {
          console.log('id set from url:' + v);
          me.id = v;
        } else {
          console.log('getData from baseNgOnInit. Route exists with no id set.');
          me.getData();
        }

      } else if (!me.id) {
        console.log('getData from baseNgOnInit. No route or id set.');
        me.getData();
      }
    }, 100);

  }

  public _id: any = null;


  @Input('id')
  public set id(value: any) {
    this._id = value;
    if (value) {
      console.log('getData from id set');
      this.getData();
    }
  }

  public get id(): any {
    return this._id;
  }

  public pagingData: PagingData = new PagingData();

  protected getData() {

  }

  protected model: Array<IListItem> = null;

  public search: ISearchModel;

  public selectAllStatus: boolean = false;
  public selectAll() {
    this.selectAllStatus = !this.selectAllStatus;
    this.model.forEach(x => {
      x.Selected = this.selectAllStatus;
    });

  }

  public pagingChanged(e: PagingChanged) {
    this.pagingData.Page = e.Page;
    this.pagingData.PageSize = e.PageSize;
    this.search._page = e.Page;
    this.search._pageSize = e.PageSize;
    console.log('getData from pagingChanged');
    this.getData();
  }

  public sortChange(columnName: string) {
    if (this.search._sort) {
      if (this.search._sort != columnName) {
        this.search._sortDesc = false;
      } else {
        this.search._sortDesc = !this.search._sortDesc;
      }
    }
    this.search._sort = columnName;
    console.log('getData from sortChange');
    this.getData();
  }

  public searchChange(column: string, value: string) {

    this.search[column] = value;
    console.log('getData from searchChange');
    console.log(this.search);
    //this.getData();
  }


  public searchSelected() {
    console.log('getData from searchSelected');
    this.getData();
  }

  public fuzzySearchChange(value: string) {
    this.search._fuzzySearch = value;
    console.log('getData from fuzzySearchChange');
    this.getData();
  }



  confirmDelete(next: any) {
    var sub = this.vl.confirm("Are you sure you want to delete this item?", function (resp: boolean) {
      if (resp) {
        next();
      }
    });

  }

  public modalEdit: any = null;
  public newModalEdit: boolean = false;

  public modalEditSaved(value?: number) {

    this.newModalEdit = false;
    this.modalEdit = null;
    console.log('getData from modalEditSaved');
    this.getData();
  }

  //public get newModalEditToken(): any {
  //  return new Date();
  //}



}
