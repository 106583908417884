<div *ngIf="pendingAwardStatus.length > 0">
    <div class="letter-content">
        <!-- Logo -->
        <div class="d-flex flex-column align-items-center py-3">
            <img src="../../../../assets/aaa-logo-blue.png" alt="logo" width="30%">
        </div>
        <!-- Content -->
        <!-- Eligible and Waitlisted -->
        <div
            *ngIf="pendingAwardStatus[0].CurrentAwardDeterminationStatus && eligibleAndWaitListed.includes(pendingAwardStatus[0].CurrentAwardDeterminationStatus.PK_Id)">
            <div class="p-content">
                <p>
                    {{ pendingAwardStatus[0].CreatedDate | date:'longDate' }}
                </p>
                <br />
                <p>
                    {{ pendingAwardStatus[0].Guardian.FirstName}} {{pendingAwardStatus[0].Guardian.LastName}}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.Address1}}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.City }},
                    {{ pendingAwardStatus[0].Guardian.Household.FK_State_Display }},
                    {{ pendingAwardStatus[0].Guardian.Household.Zipcode }}
                </p>
            </div>

            <div class="p-content">
                <p class="font-weight-bold">
                    Re: {{ pendingAwardStatus[0].FK_SchoolYear_Display }} Scholarship:
                    {{ pendingAwardStatus[0].FK_Scholarship_Display }}
                </p>
            </div>

            <div class="p-content">
                <p>
                    <span class="underline">Eligible Student Name:</span>
                    <span> {{ pendingAwardStatus[0].FK_Student_Display }}</span>
                </p>
                <p>
                    <span class="underline">Eligible For Up To:</span>
                    <span> {{ pendingAwardStatus[0].AwardAmount | currency }}</span>
                </p>
            </div>

            <div class="p-content">
                <p>
                    We are pleased to report that the scholarship for the student is eligible and approved for the
                    {{pendingAwardStatus[0].FK_SchoolYear_Display}} school year.Your student will remain on our
                    waiting list until funding becomes available. At that time, we will update the student's status to
                    Awarded and Funded and notify you via email of the exciting update to your student's funding status!
                </p>
                <p>
                    Next Steps:
                </p>
                <ol>
                    <li>
                        Confirm below that you are accepting the award.
                    </li>
                    <li>
                        View, Read, and Accept the conditions specified in the AAA Scholarship Handbook
                    </li>
                    <li>
                        If/When you are notified that your student is funded, you will be asked again to confirm your
                        intent and acceptance of scholarship funds and can proceed with use of scholarship funds.
                    </li>
                </ol>
            </div>
            <div class="p-content">
                <p>
                    Thank you,
                </p>
                <p>
                    AAA Scholarship Team
                </p>
                <p>
                    **Please note that this scholarship can be transferred to any eligible school during the school
                    year if the proper procedures are followed.<br />
                    Please find the school that will best meet your child's needs.
                </p>
            </div>
        </div>
        <!-- Eligible and Funded -->
        <div
            *ngIf="pendingAwardStatus[0].CurrentAwardDeterminationStatus && eligibleAndFunded.includes(pendingAwardStatus[0].CurrentAwardDeterminationStatus.PK_Id)">
            <div class="p-content">
                <p>
                    {{ pendingAwardStatus[0].CreatedDate | date:'longDate' }}
                </p>
                <br />
                <p>
                    {{ pendingAwardStatus[0].Guardian.FirstName }} {{ pendingAwardStatus[0].Guardian.LastName }}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.Address1 }}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.City }},
                    {{ pendingAwardStatus[0].Guardian.Household.FK_State_Display }},
                    {{ pendingAwardStatus[0].Guardian.Household.Zipcode }}
                </p>
            </div>

            <div class="p-content">
                <p class="font-weight-bold">
                    Re: {{ pendingAwardStatus[0].FK_SchoolYear_Display }} Scholarship:
                    {{ pendingAwardStatus[0].FK_Scholarship_Display }}
                </p>
            </div>

            <div class="p-content">
                <p>
                    <span class="underline">Eligible Student Name:</span>
                    <span>{{ pendingAwardStatus[0].FK_Student_Display }}</span>
                </p>
                <p>
                    <span class="underline">Eligible For Up To:</span>
                    <span>{{ pendingAwardStatus[0].AwardAmount | currency }} *Amount subject to change</span>
                </p>
            </div>

            <div class="p-content">
                <p>
                    We are pleased to report that, through the generosity ofour donors, we have funding available for
                    the above student to receive a scholarship to the eligible school of your choice!
                </p>
                <p>
                    Next Steps:
                </p>
                <ol>
                    <li>
                        Confirm below that you are accepting the award.
                    </li>
                    <li>
                        View, Read, and Accept the conditions specified in the AAA Scholarship Handbook
                    </li>
                    <li>
                        Select an eligible school for your student and begin the School commitment process
                        before DATE/DEADLINE
                    </li>
                </ol>
                <p>
                    If you do not initiate the School Commitment process by the deadline, your student will forfeit the
                    scholarship and will not receive funding for {{ pendingAwardStatus[0].FK_SchoolYear_Display }}.
                    Confirming your acceptance here and completing the SCF is the ONLY way we know where your child is
                    attending school and that you wish to accept and use this scholarship.
                </p>
            </div>
            <div class="p-content">
                <p>
                    Thank you,
                </p>
                <p>
                    AAA Scholarship Team
                </p>
                <p>
                    **Please note that this scholarship can be transferred to any eligible school during the school
                    year if the proper procedures are followed.<br />
                    Please find the school that will best meet your child's needs.
                </p>
            </div>
        </div>
        <!-- Denied Status -->
        <div
            *ngIf="pendingAwardStatus[0].CurrentAwardDeterminationStatus && denied.includes(pendingAwardStatus[0].CurrentAwardDeterminationStatus.PK_Id)">
            <div class="p-content">
                <p>
                    {{ pendingAwardStatus[0].CreatedDate | date:'longDate' }}
                </p>
                <br />
                <p>
                    {{ pendingAwardStatus[0].Guardian.FirstName }} {{ pendingAwardStatus[0].Guardian.LastName }}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.Address1 }}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.City }},
                    {{ pendingAwardStatus[0].Guardian.Household.FK_State_Display }},
                    {{ pendingAwardStatus[0].Guardian.Household.Zipcode }}
                </p>
            </div>

            <div class="p-content">
                <p class="font-weight-bold">
                    Re: {{ pendingAwardStatus[0].FK_SchoolYear_Display }} Scholarship:
                    {{ pendingAwardStatus[0].FK_Scholarship_Display }}
                </p>
            </div>

            <div class="p-content">
                <p>
                    <span class="underline">Student Name:</span>
                    <span> {{ pendingAwardStatus[0].FK_Student_Display }}</span>
                </p>
                <p>
                    <span class="underline">Reason for Ineligibility: </span>
                    <span> {{ pendingAwardStatus[0].DeterminationReason }}</span>
                </p>
            </div>

            <div class="p-content">
                <p>
                    We regret to report that the child listed below has been identified as not eligible for an AAA
                    Scholarship for the {{ pendingAwardStatus[0].FK_SchoolYear_Display }} academic year.
                </p>
                <p>
                    We want you to know that we feel the sadness of the families who may not benefit from this program.
                    Every day our office receives phone calls and emails from parents all over theState who are deeply
                    worried for their children. We are able to help many. We cannot help all. Those loving families,
                    like yours, that we cannot help have our pledge; AAA Scholarships will not stop in its efforts until
                    ALL parents have the opportunity to choose their child’s school. AAA Scholarships will stay in touch
                    so that we can keep you posted on any new programs that may give you the opportunity to choose your
                    child’s school. Thank you again for your interest in AAA Scholarships.
                </p>
                <p>
                    Next Steps:
                </p>
                <p>

                </p>
                <p>
                    If you feel this determination is incorrect, you may submit an appeal below to the Processing Team.
                    The appeal may only clarify the information that was included on your application; it may not offer
                    new information that was not originally included.
                </p>
            </div>
            <div class="p-content">
                <p>
                    Thank you,
                </p>
                <p>
                    AAA Scholarship Team
                </p>
            </div>
        </div>
        <!-- Gardiner Prefunding Status-->
        <div
            *ngIf="pendingAwardStatus[0].CurrentAwardDeterminationStatus && gardinerPrefunding.includes(pendingAwardStatus[0].CurrentAwardDeterminationStatus.PK_Id)">
            <div class="p-content">
                <p>
                    {{ pendingAwardStatus[0].CreatedDate | date:'longDate' }}
                </p>
                <br />
                <p>
                    {{ pendingAwardStatus[0].Guardian.FirstName }} {{ pendingAwardStatus[0].Guardian.LastName }}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.Address1 }}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.City }},
                    {{ pendingAwardStatus[0].Guardian.Household.FK_State_Display }},
                    {{ pendingAwardStatus[0].Guardian.Household.Zipcode }}
                </p>
            </div>

            <div class="p-content">
                <p class="font-weight-bold">
                    Re: {{pendingAwardStatus[0].FK_SchoolYear_Display}} Gardiner Scholarship: Eligible – Pending
                    Funding
                </p>
            </div>

            <div class="p-content">
                <p>
                    Dear {{ pendingAwardStatus[0].Guardian.FirstName }}
                    {{ pendingAwardStatus[0].Guardian.LastName}},
                </p>
                <p>
                    We are happy to report that we have completed processing your
                    {{pendingAwardStatus[0].FK_SchoolYear_Display}} school year application for the student listed
                    below and have deemed them to be eligible for the Gardiner Scholarship Program.
                </p>
            </div>

            <div class="p-content text-center">
                <span class="font-weight-bold underline">Eligible Student's Name:</span><br />
                <span class="font-weight-bold">{{ pendingAwardStatus[0].FK_Student_Display }}</span>
            </div>

            <div class="p-content">
                <p class="font-weight-bold">
                    Next Steps:
                </p>
                <ol>
                    <li>
                        <span>
                            We have submitted this eligible student's data to the Florida Department of Education
                            (FL DOE) for final funding determination.
                        </span>
                        <span class="font-weight-bold underline">
                            We do not expect to hear back from the FL DOE about
                            your student's funding determination until late July or early August of 2020
                        </span>
                    </li>
                    <li>
                        You will receive an email notification once the FL DOE has made their final determination on
                        funding for your student's Gardiner Scholarship account. At that time you can log back in
                        and we will provide you with instructions on your next steps.
                    </li>
                </ol>
                <p>
                    <span class="font-weight-bold">
                        Please remember
                    </span>
                    <span>
                        that scholarship funding is not guaranteed, so proceed with caution if you decide to sign
                        an agreement with a school or provider before receiving our follow-up letter.
                    </span>
                </p>
                <p>
                    As always, please visit our website at <a href="www.AAAScholarships.org"
                        target="_blank">www.AAAScholarships.org</a> for our Parent/School Handbook, Frequently Asked
                    Questions, Private School Navigator, and other helpful information.
                </p>
                <p>
                    If you need additional assistance, please feel to call us at 1-888-707-2465, extension 1, or
                    e-mail us at AAAGardiner@aaascholarships.org with any questions or concerns you may have.
                </p>
                <p>
                    We wish you and your child a very happy school year!
                </p>
                <p>
                    Sincerely,
                </p>
                <p>
                    Your AAA Gardiner Scholarship Team
                </p>
            </div>
        </div>
        <!-- Empowerment Status-->
        <div
            *ngIf="pendingAwardStatus[0].CurrentAwardDeterminationStatus && empowerment.includes(pendingAwardStatus[0].CurrentAwardDeterminationStatus.PK_Id)">
            <div class="p-content">
                <p>
                    {{ pendingAwardStatus[0].CreatedDate | date:'longDate' }}
                </p>
                <br />
                <p>
                    {{ pendingAwardStatus[0].Guardian.FirstName }} {{ pendingAwardStatus[0].Guardian.LastName }}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.Address1 }}
                </p>
                <p>
                    {{ pendingAwardStatus[0].Guardian.Household.City }},
                    {{ pendingAwardStatus[0].Guardian.Household.FK_State_Display }},
                    {{ pendingAwardStatus[0].Guardian.Household.Zipcode }}
                </p>
            </div>

            <div class="p-content">
                <p class="font-weight-bold">
                    Re: {{pendingAwardStatus[0].FK_SchoolYear_Display}} Scholarship: Florida Empowerment
                    Scholarship-Eligible
                    Funding
                </p>
            </div>

            <div class="p-content">
                <p>
                    <span class="underline">Student Name:</span>
                    <span> {{ pendingAwardStatus[0].FK_Student_Display }}</span>
                </p>
            </div>

            <div class="p-content">
                <p>
                    We are pleased to report that, based on your application information, your student is eligible for
                    Florida Empowerment Scholarship funding!
                </p>
                <p>
                    The Florida Department of Education administers the FES program and your information will be sent to
                    them to further determine eligibility for FES.
                </p>
            </div>

            <div class="p-content">
                <p class="font-weight-bold">
                    Next Steps:
                </p>
                <p>
                    Please contact the FL DOE for more information on the FES program and your status going forward.
                </p>
                <p>
                    Department of Education<br />
                    Office of Financial Assistance- Scholarships: 1-888-827-2004<br />
                    <a href="http://www.fldoe.org/schools/school-choice/k-12-scholarship-programs/fes/" target="_blank">
                        http://www.fldoe.org/schools/school-choice/k-12-scholarship-programs/fes/
                    </a>
                </p>
                <p>
                    Thank you,
                </p>
                <p>
                    AAA Scholarship Team
                </p>
            </div>
        </div>
        <!-- Buttons Actions -->
        <div class="row py-3"
            *ngIf="pendingAwardStatus[0].CurrentAwardDeterminationStatus && !denied.includes(pendingAwardStatus[0].CurrentAwardDeterminationStatus.PK_Id)">
            <div class="col-md-6 d-flex justify-content-end py-3">
                <button class="btn btn-primary w-25 mx-3" (click)="onAcceptClick()">
                    Accept
                </button>
            </div>
            <div class="col-md-6 d-flex justify-content-start py-3">
                <button class="btn btn-secondary w-25 mx-3" (click)="onForfeitClick()">
                    Forfeit
                </button>
            </div>
        </div>
        <div class="row py-3"
            *ngIf="pendingAwardStatus[0].CurrentAwardDeterminationStatus && denied.includes(pendingAwardStatus[0].CurrentAwardDeterminationStatus.PK_Id)">
            <div class="col-md-6 d-flex justify-content-end py-3">
                <button class="btn btn-secondary w-25 mx-3" (click)="onAppealClick()">
                    Appeal
                </button>
            </div>
            <div class="col-md-6 d-flex justify-content-start py-3">
                <button class="btn btn-primary w-25 mx-3" (click)="onAcceptDeterminationClick()">
                    Accept Determination
                </button>
            </div>
        </div>
        <!-- Forfeit Reason -->
        <div class="p-content" *ngIf="showForfeitReason">
            <div class="row py-3">
                <div class="col-12">
                    <textarea [(ngModel)]="forfeitNotesText" placeholder="Please provide your forfeit reason here"
                        class="w-100" rows="5">
                    </textarea>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-md-6 d-flex justify-content-end py-3">
                    <button class="btn btn-primary w-25 mx-3" (click)="onForfeitConfirmClick()"
                        [disabled]="!hasForfeitNotesText">
                        Yes
                    </button>
                </div>
                <div class="col-md-6 d-flex justify-content-start py-3">
                    <button class="btn btn-secondary w-25 mx-3" (click)="onForfeitCancelClick()">
                        No
                    </button>
                </div>
            </div>
        </div>
        <!-- Appeal Reason -->
        <div class="p-content" *ngIf="showAppealReason">
            <div class="row py-3">
                <div class="col-12">
                    <textarea [(ngModel)]="notesText" placeholder="Please provide your notes here" class="w-100"
                        rows="5">
                    </textarea>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-md-6 d-flex justify-content-end py-3">
                    <button class="btn btn-primary w-25 mx-3" (click)="onSubmitClick()" [disabled]="!hasNotesText">
                        Submit
                    </button>
                </div>
                <div class="col-md-6 d-flex justify-content-start py-3">
                    <button class="btn btn-secondary w-25 mx-3" (click)="onCancelClick()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
        <!-- Summary -->
        <div class="letter-summary d-flex flex-column align-items-center py-3">
            <span>Corporate Office: P.O. Box #15719,</span>
            <span>Tampa, FL 33684-0719</span>
            <span>888-707-2465</span>
            <span>www.AAAScholarships.org</span>
        </div>
    </div>
</div>