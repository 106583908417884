import { Component, OnInit, Directive } from '@angular/core';
import { IScholarshipPaymentsDeadlineListParams,ScholarshipPaymentsDeadlineListParams } from '../../models/entities/ScholarshipPaymentsDeadlineModel.response';
import { ScholarshipPaymentsDeadlineModel} from '../../models/entities/ScholarshipPaymentsDeadlineModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class ScholarshipPaymentsDeadlineListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<ScholarshipPaymentsDeadlineModel> = <Array<ScholarshipPaymentsDeadlineModel>>[];
	public search: ScholarshipPaymentsDeadlineListParams = new ScholarshipPaymentsDeadlineListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getScholarshipPaymentsDeadlineList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.pK_Id  = null;
		this.search.fK_SchoolYear  = null;
		this.search.fK_SchoolYear_display  = null;
		this.search.fK_SchoolYear_like  = null;
		this.search.fK_SchoolYear_fromValue  = null;
		this.search.fK_SchoolYear_toValue  = null;
		this.search.fK_Scholarship  = null;
		this.search.fK_Scholarship_display  = null;
		this.search.fK_Scholarship_like  = null;
		this.search.fK_Scholarship_fromValue  = null;
		this.search.fK_Scholarship_toValue  = null;
		this.search.name  = null;
		this.search.name_like  = null;
		this.search.notifyParentSchools  = null;
		this.search.notifyParentSchools_fromDate  = null;
		this.search.notifyParentSchools_toDate  = null;
		this.search.verificationReportsDue  = null;
		this.search.verificationReportsDue_fromDate  = null;
		this.search.verificationReportsDue_toDate  = null;
		this.search.paymentSent  = null;
		this.search.paymentSent_fromDate  = null;
		this.search.paymentSent_toDate  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deleteScholarshipPaymentsDeadline(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManyScholarshipPaymentsDeadline(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
