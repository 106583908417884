import { VerificationModel} from '../entities/VerificationModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class VerificationResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: VerificationModel = new VerificationModel();
}

export class VerificationList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<VerificationModel> = <Array<VerificationModel >>[]
}

export interface IVerificationListParams {
	pK_Id?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number;
	fK_AwardDetermination?:number;
	fK_AwardDetermination_display?:string;
	fK_AwardDetermination_like?:string;
	fK_AwardDetermination_fromValue?:number;
	fK_AwardDetermination_toValue?:number;
	fK_GradingPeriodType?:number;
	fK_GradingPeriodType_display?:string;
	fK_GradingPeriodType_like?:string;
	fK_GradingPeriodType_fromValue?:number;
	fK_GradingPeriodType_toValue?:number;
	numberOfAbsences?:number;
	numberOfAbsences_fromValue?:number;
	numberOfAbsences_toValue?:number;
	householdCurrent?:boolean;
	householdCurrent_booleanValue?:boolean;
	stillEnrolled?:boolean;
	stillEnrolled_booleanValue?:boolean;
	fK_VerificationStatusType?:number;
	fK_VerificationStatusType_display?:string;
	fK_VerificationStatusType_like?:string;
	fK_VerificationStatusType_fromValue?:number;
	fK_VerificationStatusType_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class VerificationListParams implements IVerificationListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number=null;
	fK_AwardDetermination?:number=null;
	fK_AwardDetermination_display?:string=null;
	fK_AwardDetermination_like?:string=null;
	fK_AwardDetermination_fromValue?:number=null;
	fK_AwardDetermination_toValue?:number=null;
	fK_GradingPeriodType?:number=null;
	fK_GradingPeriodType_display?:string=null;
	fK_GradingPeriodType_like?:string=null;
	fK_GradingPeriodType_fromValue?:number=null;
	fK_GradingPeriodType_toValue?:number=null;
	numberOfAbsences?:number=null;
	numberOfAbsences_fromValue?:number=null;
	numberOfAbsences_toValue?:number=null;
	householdCurrent?:boolean=null;
	householdCurrent_booleanValue?:boolean=null;
	stillEnrolled?:boolean=null;
	stillEnrolled_booleanValue?:boolean=null;
	fK_VerificationStatusType?:number=null;
	fK_VerificationStatusType_display?:string=null;
	fK_VerificationStatusType_like?:string=null;
	fK_VerificationStatusType_fromValue?:number=null;
	fK_VerificationStatusType_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

