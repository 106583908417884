import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITableColumnDefinition } from '../../interfaces/table-column-definition-interface';
import { FieldTypeDefinition } from 'src/app/types/field-type-definition-type';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit {
  @Input()
  set data(data: any[]) {
    this._data = data;
  }
  get data(): any[] {
    return this._data;
  }

  @Input()
  set columns(columns: ITableColumnDefinition[]) {
    this._columns = columns;
  }
  get columns(): ITableColumnDefinition[] {
    return this._columns;
  }

  @Input()
  set rows(rows: number) {
    this._rows = rows;
  }
  get rows(): number {
    return this._rows;
  }

  @Input()
  set showSummary(showSummary: boolean) {
    this._showSummary = showSummary;
  }
  get showSummary(): boolean {
    return this._showSummary;
  }

  @Input()
  set showIcon(showIcon: boolean) {
    this._showIcon = showIcon;
  }
  get showIcon(): boolean {
    return this._showIcon;
  }

  @Input()
  set showPaginator(showPaginator: boolean) {
    this._showPaginator = showPaginator;
  }
  get showPaginator(): boolean {
    return this.showPaginator;
  }

  @Input()
  set showEditButton(showEditButton: boolean) {
    this._showEditButton = showEditButton;
  }
  get showEditButton(): boolean {
    return this._showEditButton;
  }

  @Input()
  set showDeleteButton(showDeleteButton: boolean) {
    this._showDeleteButton = showDeleteButton;
  }
  get showDeleteButton(): boolean {
    return this._showDeleteButton;
  }

  @Input()
  set showViewButton(showViewButton: boolean) {
    this._showViewButton = showViewButton;
  }
  get showViewButton(): boolean {
    return this._showViewButton;
  }

  @Input()
  set totalRecords(totalRecords: number) {
    this._totalRecords = totalRecords;
  }
  get totalRecords(): number {
    return this._totalRecords;
  }

  @Input()
  set isLoading(isLoading: boolean) {
    this._loading = isLoading;
  }
  get isLoading(): boolean {
    return this._loading;
  }

  @Input()
  set useLazyLoad(useLazyLoad: boolean) {
    this._useLazyLoad = useLazyLoad;
  }
  get useLazyLoad(): boolean {
    return this._useLazyLoad;
  }

  @Output() onRowTableClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onViewButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCustomButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoadData: EventEmitter<LazyLoadEvent> = new EventEmitter<LazyLoadEvent>();

  _data: any[] = [];
  _columns: ITableColumnDefinition[] = [];
  _showSummary: boolean = false;
  _showIcon: boolean = false;
  _showPaginator: boolean = false;
  _showEditButton: boolean = false;
  _showDeleteButton: boolean = false;
  _showViewButton: boolean = false;
  _rows: number = 10; //default rows to show in table
  _totalRecords: number = null;
  _loading: boolean = false;
  _useLazyLoad: boolean = false;

  fieldTypeDefinitionType: any = FieldTypeDefinition;

  constructor() { }

  ngOnInit() { }

  onRowSelect(event) { }

  onRowUnselect(event) { }

  doRowClicked(data: any) {
    this.onRowTableClicked.emit(data);
  }

  showRecordCount(): boolean {
    if (this._data.length > 0 && this._showSummary == true) {
      return true;
    }
  }

  doEditButton(data: any) {
    this.onEditButtonClicked.emit(data);
  }

  doDeleteButton(data: any) {
    this.onDeleteButtonClicked.emit(data);
  }

  doViewButton(data: any) {
    this.onViewButtonClicked.emit(data);
  }

  doCustomButton(data: any) {
    this.onCustomButtonClicked.emit(data);
  }

  loadData(lazyEvent: LazyLoadEvent) {
    this.onLoadData.emit(lazyEvent);
  }
}
