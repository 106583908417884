import { Component, OnInit, Directive } from '@angular/core';
import {  IAwardAcceptanceStatusTypeListParams } from '../../models/entities/AwardAcceptanceStatusTypeModel.response';
import { AwardAcceptanceStatusTypeModel } from '../../models/entities/AwardAcceptanceStatusTypeModel';
import { PagingData } from '../../paging/paging.component';
import { AwardAcceptanceStatusTypeListBaseComponent } from './awardAcceptanceStatusTypeList.base';

@Component({
	selector: 'app-awardAcceptanceStatusTypeList',
	templateUrl: './awardAcceptanceStatusTypeList.component.html',
})
export class AwardAcceptanceStatusTypeListComponent extends AwardAcceptanceStatusTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
