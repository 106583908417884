import {IListItem } from '../models';
export class StudentModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public CreatedDate:Date = null;
	public FirstName:string = "";
	public LastName:string = "";
	public FK_GenderType:number = 0;
	public FK_GenderType_Display:string = null;
	public FK_RaceType?:number = null;
	public FK_RaceType_Display:string = null;
	public BirthDate?:Date = null;
	public SocialSecurityNumber?:string = null;
	public MiddleInitial?:string = null;
	public IsDeleted?:boolean = null;
	public FK_Login_CreatedBy?:string = null;
	public FK_Login_CreatedBy_Display:string = null;
}
