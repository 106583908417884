import { Component, OnInit, Directive } from '@angular/core';
import {  ILoginRolesLookUpListParams } from '../../models/entities/LoginRolesLookUpModel.response';
import { LoginRolesLookUpModel } from '../../models/entities/LoginRolesLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { LoginRolesLookUpListBaseComponent } from './loginRolesLookUpList.base';

@Component({
	selector: 'app-loginRolesLookUpList',
	templateUrl: './loginRolesLookUpList.component.html',
})
export class LoginRolesLookUpListComponent extends LoginRolesLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
