import { GuardianReimbursementStatusTypeModel} from '../entities/GuardianReimbursementStatusTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class GuardianReimbursementStatusTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: GuardianReimbursementStatusTypeModel = new GuardianReimbursementStatusTypeModel();
}

export class GuardianReimbursementStatusTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<GuardianReimbursementStatusTypeModel> = <Array<GuardianReimbursementStatusTypeModel >>[]
}

export interface IGuardianReimbursementStatusTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class GuardianReimbursementStatusTypeListParams implements IGuardianReimbursementStatusTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

