import { Component, OnInit, Directive } from '@angular/core';
import {  ICountyListParams } from '../../models/entities/CountyModel.response';
import { CountyModel } from '../../models/entities/CountyModel';
import { PagingData } from '../../paging/paging.component';
import { CountyListBaseComponent } from './countyList.base';

@Component({
	selector: 'app-countyList',
	templateUrl: './countyList.component.html',
})
export class CountyListComponent extends CountyListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
