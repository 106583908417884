<div class="card">
  <div class="card-header">
    Award Determination <span class="right">
      <a title="Cancel" *ngIf="!ModalView" routerLink="/awarddeterminationlist"><i class="fa fa-undo icon-link"></i></a>
      <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
      <i class="fa fa-save icon-link" (click)="save()"></i>
    </span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Created Date</label><span title="Required" class="required">*</span>
            <app-date-picker [(model)]="model.CreatedDate"></app-date-picker>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Guardian</label><span title="Required" class="required">*</span>
            <app-typeahead-basic [(id)]="model.FK_Guardian" [searchapi]="'guardiansListBox'"></app-typeahead-basic>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Scholarship</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_Scholarship" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_Scholarship_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Student</label><span title="Required" class="required">*</span>
            <app-typeahead-basic [(id)]="model.FK_Student" [searchapi]="'studentsListBox'"></app-typeahead-basic>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>School Year</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_SchoolYear" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_SchoolYear_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Grade Level</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_GradeLevel" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_GradeLevel_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
<span title="Required" class="required">*</span>
            <app-checkbox [(model)]="model.WasNotificationSent" [label]="'Was Notification Sent'"></app-checkbox>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Award Amount</label>
            <input [(ngModel)]="model.AwardAmount" type="text" class="form-control">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Determination Reason</label>
            <textarea rows="4" [(ngModel)]="model.DeterminationReason" class="form-control"></textarea>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
