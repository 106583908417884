import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GradeLevelEditBaseComponent } from './gradeLevelEdit.base';

@Component({
	selector: 'app-gradeLevelEdit',
	templateUrl: './gradeLevelEdit.component.html',
})
export class GradeLevelEditComponent extends GradeLevelEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
