import { Component, OnInit, Directive } from '@angular/core';
import { IHouseholdListParams,HouseholdListParams } from '../../models/entities/HouseholdModel.response';
import { HouseholdModel} from '../../models/entities/HouseholdModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class HouseholdListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<HouseholdModel> = <Array<HouseholdModel>>[];
	public search: HouseholdListParams = new HouseholdListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getHouseholdList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.isDeleted  = null;
		this.search.isDeleted_booleanValue  = null;
		this.search.createdDate  = null;
		this.search.createdDate_fromDate  = null;
		this.search.createdDate_toDate  = null;
		this.search.pK_Id  = null;
		this.search.fK_Login_CreatedBy  = null;
		this.search.fK_Login_CreatedBy_display  = null;
		this.search.fK_Login_CreatedBy_like  = null;
		this.search.numberOfGuardiansLivingInHome  = null;
		this.search.numberOfGuardiansLivingInHome_fromValue  = null;
		this.search.numberOfGuardiansLivingInHome_toValue  = null;
		this.search.numberOfChildrenLivingInHome  = null;
		this.search.numberOfChildrenLivingInHome_fromValue  = null;
		this.search.numberOfChildrenLivingInHome_toValue  = null;
		this.search.numberOfOtherPeopleLivingInHome  = null;
		this.search.numberOfOtherPeopleLivingInHome_fromValue  = null;
		this.search.numberOfOtherPeopleLivingInHome_toValue  = null;
		this.search.totalHouseholdSize  = null;
		this.search.totalHouseholdSize_fromValue  = null;
		this.search.totalHouseholdSize_toValue  = null;
		this.search.primarySpokenLanguage  = null;
		this.search.primarySpokenLanguage_like  = null;
		this.search.householdIncome  = null;
		this.search.householdIncome_fromValue  = null;
		this.search.householdIncome_toValue  = null;
		this.search.address1  = null;
		this.search.address1_like  = null;
		this.search.address2  = null;
		this.search.address2_like  = null;
		this.search.city  = null;
		this.search.city_like  = null;
		this.search.fK_State  = null;
		this.search.fK_State_display  = null;
		this.search.fK_State_like  = null;
		this.search.fK_State_fromValue  = null;
		this.search.fK_State_toValue  = null;
		this.search.zipcode  = null;
		this.search.zipcode_like  = null;
		this.search.fK_County  = null;
		this.search.fK_County_display  = null;
		this.search.fK_County_like  = null;
		this.search.fK_County_fromValue  = null;
		this.search.fK_County_toValue  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deleteHousehold(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManyHousehold(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
