import { Component, OnInit, Directive } from '@angular/core';
import {  IStudentDisabilityLookUpListParams } from '../../models/entities/StudentDisabilityLookUpModel.response';
import { StudentDisabilityLookUpModel } from '../../models/entities/StudentDisabilityLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { StudentDisabilityLookUpListBaseComponent } from './studentDisabilityLookUpList.base';

@Component({
	selector: 'app-studentDisabilityLookUpList',
	templateUrl: './studentDisabilityLookUpList.component.html',
})
export class StudentDisabilityLookUpListComponent extends StudentDisabilityLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
