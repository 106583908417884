import { aaaAPIService } from "./services/aaa.API.service";
import { NgZone, Directive, Input } from "@angular/core";
import { GlobalsService } from "./services/globals.service";
import { ServiceLocator } from "./ServiceLocator";

import { ActivatedRoute } from "@angular/router";
import { ErrorMessagesService } from "./services/error-messages.service";




@Directive()
export class BaseComponent {

  public gl: GlobalsService = null;

  protected baseNgOnInit() {

  }



  protected resetSession() {
    this.gl.LoginId = null;
    this.gl.LoginFullName = null;
    this.gl.LoginName = null;
    this.gl.LoginRoles = [];
  }

  constructor(rt?: ActivatedRoute, anonymous?: boolean) {
    this.gl = ServiceLocator.injector.get(GlobalsService);
    this.route = rt;
  }

  private _api: aaaAPIService = null;
  public get api(): aaaAPIService {
    if (this._api == null) {
      this._api = ServiceLocator.injector.get(aaaAPIService);
    }
    return this._api;

  }

  public route: ActivatedRoute = null;


  private _vl: ErrorMessagesService = null;
  public get vl(): ErrorMessagesService {
    if (this._vl == null) {
      this._vl = ServiceLocator.injector.get(ErrorMessagesService);
    }
    return this._vl;

  }




  private _zone: NgZone = null;
  public get zone(): NgZone {
    if (this._zone == null) {
      this._zone = ServiceLocator.injector.get(NgZone);
    }
    return this._zone;
  }

  public ShowSpinner: boolean = false;

  @Input('level')
  public level: number = 0;

  public get leftOffset(): string {
    return (5 + this.level) + "%";
  }

  public get topOffset(): string {
    return (5 + this.level) + "%";
  }


  public DateCheck(value: any) {
    if (!value) {
      return value;
    }

    var d = new Date(Date.parse(value));
    if (d.getTime() === d.getTime()) {
      return d;
    }

    return value;

  }

  @Input('modalview')
  public ModalView: boolean = false;

}
