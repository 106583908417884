import { Component, OnInit, Directive } from '@angular/core';
import { LoginRolesLookUpCheckBoxListByLoginBase } from './loginRolesLookUpCheckBoxListByLogin.base';
@Component({
  selector: 'app-loginRolesLookUpCheckBoxListByLogin',
  templateUrl: './loginRolesLookUpCheckBoxListByLogin.component.html',
})
export class LoginRolesLookUpCheckBoxListByLoginComponent extends LoginRolesLookUpCheckBoxListByLoginBase implements OnInit {
  constructor() { super(); }
  ngOnInit() { super.baseNgOnInit(); }
  
}
