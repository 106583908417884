import {IListItem } from '../models';
export class VendorModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public Name:string = "";
	public CreatedDate:Date = null;
	public FK_State_VendorPhysical:number = 0;
	public FK_State_VendorPhysical_Display:string = null;
	public PK_Id:number = 0;
	public IsDeleted?:boolean = null;
	public Physical_Zipcode?:string = null;
	public VendorCode?:string = null;
	public FaxNumber?:string = null;
	public EmailAddress?:string = null;
	public WebsiteURL?:string = null;
	public FK_Login_CreatedBy?:string = null;
	public FK_Login_CreatedBy_Display:string = null;
	public PaymentBankName?:string = null;
	public PaymentBankRouting?:string = null;
	public PaymentBankAccount?:string = null;
	public PaymentAddress1?:string = null;
	public PaymentAddress2?:string = null;
	public PaymentCity?:string = null;
	public FK_State_Payment?:number = null;
	public FK_State_Payment_Display:string = null;
	public PaymentZipcode?:string = null;
	public PaymentPayTo?:string = null;
	public FK_Login?:string = null;
	public FK_Login_Display:string = null;
	public Phone?:string = null;
	public Mailing_StreetAddress1?:string = null;
	public Mailing_StreetAddress2?:string = null;
	public Mailing_City?:string = null;
	public FK_State_VendorMailing?:number = null;
	public FK_State_VendorMailing_Display:string = null;
	public Mailing_Zipcode?:string = null;
	public Physical_StreetAddress1?:string = null;
	public Physical_StreetAddress2?:string = null;
	public Physical_City?:string = null;
}
