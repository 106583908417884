import * as moment from 'moment';

export class UTILS {
    public static getSchoolYearDisplay(startDate: Date, endDate: Date) {
        const currentDate = moment();
        let schoolYearText = '';
        if (currentDate.toDate() >= moment(startDate).toDate() && currentDate.toDate() <= moment(endDate).toDate()) {
            schoolYearText = 'Current Year';
        } else {
            const date = moment(endDate);
            let diff = date.diff(currentDate, 'years', true);
            if (diff > 0) {
                schoolYearText = 'Next Year';
            } else {
                if (Math.abs(diff) < 1) {
                    diff = 1;
                }
                if (diff > 1) {
                    schoolYearText = 'Past Year';
                } else {
                    schoolYearText = 'Last Year';
                }
            }
        }
        return `${schoolYearText} (${moment(startDate).year()}-${moment(endDate).year()})`;
    }
}