<div class="card">
  <div class="card-header">
    Withdraw <span class="right">
      <a title="Cancel" *ngIf="!ModalView" routerLink="/withdrawlist"><i class="fa fa-undo icon-link"></i></a>
      <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
      <i class="fa fa-save icon-link" (click)="save()"></i>
    </span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Student Grade Levels School Years Look Up</label><span title="Required" class="required">*</span>
            <input [(ngModel)]="model.FK_StudentGradeLevelsSchoolYearsLookUp" type="text" class="form-control">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Last Day</label><span title="Required" class="required">*</span>
            <app-date-picker [(model)]="model.LastDay"></app-date-picker>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Balance Due</label><span title="Required" class="required">*</span>
            <input [(ngModel)]="model.BalanceDue" type="text" class="form-control">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Created Date</label><span title="Required" class="required">*</span>
            <app-date-picker [(model)]="model.CreatedDate"></app-date-picker>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Withdraw Reason Type</label>
            <select [(ngModel)]="model.FK_WithdrawReasonType" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_WithdrawReasonType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
