import { AwardDeterminationStatusTypeModel} from '../entities/AwardDeterminationStatusTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class AwardDeterminationStatusTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: AwardDeterminationStatusTypeModel = new AwardDeterminationStatusTypeModel();
}

export class AwardDeterminationStatusTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<AwardDeterminationStatusTypeModel> = <Array<AwardDeterminationStatusTypeModel >>[]
}

export interface IAwardDeterminationStatusTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class AwardDeterminationStatusTypeListParams implements IAwardDeterminationStatusTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

