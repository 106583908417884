import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactsLookUpEditBaseComponent } from './contactsLookUpEdit.base';

@Component({
    selector: 'app-contactsLookUpEdit',
    templateUrl: './contactsLookUpEdit.component.html',
})
export class ContactsLookUpEditComponent extends ContactsLookUpEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
