import {IListItem } from '../models';
export class ZengineFormFieldModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_ZengineForm:number = 0;
	public FK_ZengineForm_Display:string = null;
	public FieldId:number = 0;
	public Label?:string = null;
	public Description?:string = null;
	public FieldType?:string = null;
}
