import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AwardDeterminationStatusLookUpEditBaseComponent } from './awardDeterminationStatusLookUpEdit.base';

@Component({
	selector: 'app-awardDeterminationStatusLookUpEdit',
	templateUrl: './awardDeterminationStatusLookUpEdit.component.html',
})
export class AwardDeterminationStatusLookUpEditComponent extends AwardDeterminationStatusLookUpEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
