<div class="p-2">
  <div class="row mb-5">
    <div class="col-12">
      <h2>All Scholarships</h2>
    </div>
  </div>
  <div class="row mb-3 d-flex align-items-end">
    <div class="col-3 d-flex flex-column">
      <span class="form-label font-weight-bold">Search Scholarship</span>
      <input type="text" class="form-control" [(ngModel)]="scholarshipsSearchText"
        (ngModelChange)="onSearchScholarshipsTextChanged()" />
    </div>
    <div class="col-2 d-flex flex-column">
      <span class="form-label font-weight-bold">State</span>
      <p-dropdown [options]="statesOptions" class="form-control no-border" [(ngModel)]="stateModel"
        (ngModelChange)="onStateChanged()">
      </p-dropdown>
    </div>
    <div class="col-3 d-flex flex-column">
      <span class="form-label font-weight-bold">School Year</span>
      <p-dropdown [options]="schoolYearOptions" class="form-control no-border" [(ngModel)]="schoolYearModel"
        (ngModelChange)="onSchoolYearChanged()">
      </p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-custom-table [data]="scholarshipsTableData" [rows]="5" showPaginator="true"
        [columns]="scholarshipsTableColumns" [showSummary]="true" [useLazyLoad]="true"
        (onLoadData)="loadScholarshipsData($event)" [isLoading]="scholarshipsTableLoading"
        [totalRecords]="scholarshipsTableTotalRecords" (onRowTableClicked)="doScholarshipShowProfile($event)">
      </app-custom-table>
    </div>
  </div>
</div>