import { Component, OnInit, Directive } from '@angular/core';
import { IVerificationListParams,VerificationListParams } from '../../models/entities/VerificationModel.response';
import { VerificationModel} from '../../models/entities/VerificationModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class VerificationListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<VerificationModel> = <Array<VerificationModel>>[];
	public search: VerificationListParams = new VerificationListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getVerificationList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.pK_Id  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp_fromValue  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp_toValue  = null;
		this.search.fK_AwardDetermination  = null;
		this.search.fK_AwardDetermination_display  = null;
		this.search.fK_AwardDetermination_like  = null;
		this.search.fK_AwardDetermination_fromValue  = null;
		this.search.fK_AwardDetermination_toValue  = null;
		this.search.fK_GradingPeriodType  = null;
		this.search.fK_GradingPeriodType_display  = null;
		this.search.fK_GradingPeriodType_like  = null;
		this.search.fK_GradingPeriodType_fromValue  = null;
		this.search.fK_GradingPeriodType_toValue  = null;
		this.search.numberOfAbsences  = null;
		this.search.numberOfAbsences_fromValue  = null;
		this.search.numberOfAbsences_toValue  = null;
		this.search.householdCurrent  = null;
		this.search.householdCurrent_booleanValue  = null;
		this.search.stillEnrolled  = null;
		this.search.stillEnrolled_booleanValue  = null;
		this.search.fK_VerificationStatusType  = null;
		this.search.fK_VerificationStatusType_display  = null;
		this.search.fK_VerificationStatusType_like  = null;
		this.search.fK_VerificationStatusType_fromValue  = null;
		this.search.fK_VerificationStatusType_toValue  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deleteVerification(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManyVerification(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
