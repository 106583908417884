import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.css']
})
export class SyncComponent extends BaseComponent implements OnInit {

  constructor(r: ActivatedRoute) {
    super(r, true);
  }

  ngOnInit(): void {
    super.baseNgOnInit();
  }

  public model: string = null;

  public form: number = null;

  public recordsJason: string = null;




  protected loadOptions() {

  }


  public sync() {
    var me = this;

    var sub = this.api.syncZengineForms(this.model).subscribe(x => {

      if (x.Success) {


      }
      sub.unsubscribe();
    });
  }

  public showRecords() {
    var me = this;
    var sub = this.api.syncZengineRecords(this.model, this.form).subscribe(x => {
      if (x.Success) {

        this.recordsJason = JSON.stringify(x);
      }
      sub.unsubscribe();
    });
  }



}

