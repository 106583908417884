import { VendorTransactionModel} from '../entities/VendorTransactionModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class VendorTransactionResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: VendorTransactionModel = new VendorTransactionModel();
}

export class VendorTransactionList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<VendorTransactionModel> = <Array<VendorTransactionModel >>[]
}

export interface IVendorTransactionListParams {
	fK_Vendor?:number;
	fK_Vendor_display?:string;
	fK_Vendor_like?:string;
	fK_Vendor_fromValue?:number;
	fK_Vendor_toValue?:number;
	fK_Student?:number;
	fK_Student_display?:string;
	fK_Student_like?:string;
	fK_Student_fromValue?:number;
	fK_Student_toValue?:number;
	fK_Guardian?:number;
	fK_Guardian_display?:string;
	fK_Guardian_like?:string;
	fK_Guardian_fromValue?:number;
	fK_Guardian_toValue?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	fK_TransactionType?:number;
	fK_TransactionType_display?:string;
	fK_TransactionType_like?:string;
	fK_TransactionType_fromValue?:number;
	fK_TransactionType_toValue?:number;
	fK_TransactionStatusType?:number;
	fK_TransactionStatusType_display?:string;
	fK_TransactionStatusType_like?:string;
	fK_TransactionStatusType_fromValue?:number;
	fK_TransactionStatusType_toValue?:number;
	pK_Id?:number;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	amount?:number;
	amount_fromValue?:number;
	amount_toValue?:number;
	fK_PreAuthorization?:number;
	fK_PreAuthorization_display?:string;
	fK_PreAuthorization_like?:string;
	fK_PreAuthorization_fromValue?:number;
	fK_PreAuthorization_toValue?:number;
	description?:string;
	description_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class VendorTransactionListParams implements IVendorTransactionListParams, ISearchModel  {
	fK_Vendor?:number=null;
	fK_Vendor_display?:string=null;
	fK_Vendor_like?:string=null;
	fK_Vendor_fromValue?:number=null;
	fK_Vendor_toValue?:number=null;
	fK_Student?:number=null;
	fK_Student_display?:string=null;
	fK_Student_like?:string=null;
	fK_Student_fromValue?:number=null;
	fK_Student_toValue?:number=null;
	fK_Guardian?:number=null;
	fK_Guardian_display?:string=null;
	fK_Guardian_like?:string=null;
	fK_Guardian_fromValue?:number=null;
	fK_Guardian_toValue?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	fK_TransactionType?:number=null;
	fK_TransactionType_display?:string=null;
	fK_TransactionType_like?:string=null;
	fK_TransactionType_fromValue?:number=null;
	fK_TransactionType_toValue?:number=null;
	fK_TransactionStatusType?:number=null;
	fK_TransactionStatusType_display?:string=null;
	fK_TransactionStatusType_like?:string=null;
	fK_TransactionStatusType_fromValue?:number=null;
	fK_TransactionStatusType_toValue?:number=null;
	pK_Id?:number=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	amount?:number=null;
	amount_fromValue?:number=null;
	amount_toValue?:number=null;
	fK_PreAuthorization?:number=null;
	fK_PreAuthorization_display?:string=null;
	fK_PreAuthorization_like?:string=null;
	fK_PreAuthorization_fromValue?:number=null;
	fK_PreAuthorization_toValue?:number=null;
	description?:string=null;
	description_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

