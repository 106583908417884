<div class="dashboard-container">
  <p-table [value]="guardianScholarshipList" dataKey="PK_Id" styleClass="dashboard-table">
    <ng-template pTemplate="body" let-row let-expanded="expanded">
      <tr class="table-data {{ (expanded) ? 'active' : ''}}">
        <td class="p-0 pt-2 m-0">
          <div class="row">
            <div class="col-9 m-0 p-0">
              <div class="row px-3 pt-2">
                <div class="col-4 m-0 p-0">
                  <h5 class="form-label btn p-0 m-0" (click)="goToStudentProfile(row.FK_Student)">
                    {{ row?.FK_Student_Display }}
                  </h5>
                </div>
                <div class="col-4 m-0 p-0">
                  <h5 class="form-label">{{ row.AwardAmount | currency:'USD':'symbol':'1.0' }}</h5>
                </div>
                <div class="col-4 m-0 p-0">
                  <h5 class="form-label">{{ row.AwardAmount | currency:'USD':'symbol':'1.0' }}</h5>
                </div>
              </div>
              <div class="row px-3 pb-2">
                <div class="col-4 m-0 p-0">
                  <label class="text-subtitle">Grade {{ row?.FK_GradeLevel_Display }}</label>
                </div>
                <div class="col-4 m-0 p-0">
                  <label class="text-subtitle">{{ row.FK_SchoolYear_Display }} School Year</label>
                </div>
                <div class="col-4 m-0 p-0">
                  <label class="text-subtitle">Remaining</label>
                </div>
              </div>
            </div>
            <div class="col-3 d-flex flex-row justify-content-end m-0 p-0">

              <div class="px-3 py-2 dashboard-icon " *ngIf="!row.SchoolSelected" (click)="openFindSchool(row)">
                <span><i class="fas fa-school fa-2x"></i></span>
                <span class="text-subtitle" [innerHTML]="getIconText(row)"></span>
              </div>
              <div class="px-3 py-2 dashboard-icon " *ngIf="row.SchoolSelected">
                <span><i class="fas fa-school fa-2x"></i></span>
                <span class="text-subtitle" [innerHTML]="getIconText(row)"></span>
              </div>
              <div class="px-3 py-2 dashboard-icon" *ngIf="row.SchoolSelected">
                <span><i class="fas fa-money-check-alt fa-2x"></i></span>
                <span class="text-subtitle">Payment<br>Requests</span>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr class="table-data-expanded" *ngIf="expanded">
        <td>
          <div class="row m-0">
            <div class="col-4 px-3 py-2">
              <label class="text-subtitle">Find a School</label>
            </div>
            <div class="col-4 px-3 py-2">
              <label class="text-subtitle">Receive School Confirmation</label>
            </div>
            <div class="col-4 px-3 py-2">
              <label class="text-subtitle">Complete Commitment Process</label>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-12 p-0 dashboard-pb {{ (row.Completed) ? 'completed' : ''}}">
              <p-progressBar [value]="row.CompletedValue" [showValue]="false"></p-progressBar>
            </div>
          </div>
          <div class="row m-0 p-2" *ngIf="!row.SchoolSelected">
            <div class="col-12">
              <div id="u349" class="ax_default paragraph">
                <div id="u349_div" class=""></div>
                <div id="u349_text" class="text " style="left: 0px; width: 609px; transform-origin: 304.5px 17.5px;">
                  <p><span style="font-family:'Arial-BoldMT', 'Arial Bold', 'Arial';font-weight:700;">Congratulations!
                    </span></p>
                  <p><span style="font-family:'ArialMT', 'Arial';font-weight:400;">You have been awarded up to $7,500
                      for
                      the 2020-2021 School Year for Cindy Martin.</span></p>
                </div>
              </div>
              <div id="u351" class="ax_default paragraph">
                <div id="u351_div" class=""></div>
                <div id="u351_text" class="text " style="left: 0px; width: 721px; transform-origin: 360.5px 26.5px;">
                  <p><span style="font-family:'Arial-BoldMT', 'Arial Bold', 'Arial';font-weight:700;">What do I need to
                      do
                      next?</span></p>
                  <p><span style="font-family:'ArialMT', 'Arial';font-weight:400;">• Find and commit to an eligible
                      school
                      and complete the School Commitment Form by MM/DD/YYYY.</span></p>
                  <p><span style="font-family:'ArialMT', 'Arial';font-weight:400;">• The school you select will then
                      have
                      until MM/DD/YYYY to confirm your choice.</span></p>
                </div>
              </div>
              <button type="button" class="btn btn-primary" (click)="openFindSchool(row)">Find School</button>
            </div>
          </div>
          <div class="row m-0 p-2" *ngIf="row.SchoolSelected">
            <div class="col-12">
              <div id="u276" class="ax_default paragraph">
                <div id="u276_div" class=""></div>
                <div id="u276_text" class="text " style="left: 0px; width: 794px; transform-origin: 397px 26.5px;">
                  <p><span style="font-family:'Arial-BoldMT', 'Arial Bold', 'Arial';font-weight:700;">What do I need to
                      do
                      next?</span></p>
                  <p><span style="font-family:'ArialMT', 'Arial';font-weight:400;">• Currently no action is required for
                      this student.</span></p>
                  <p><span style="font-family:'ArialMT', 'Arial';font-weight:400;">• You may still withdraw enroll this
                      student and enroll in another private school, or you may elect homeschooling.</span></p>
                </div>
              </div>
              <button type="button" class="btn btn-outline-secondary">Withdraw and Find Another School</button>
            </div>
          </div>
        </td>
      </tr>
      <tr class="table-data table-summary mb-2 {{ (expanded) ? 'active' : ''}}">
        <td class="p-0 m-0">
          <div class="row" [pRowToggler]="row">
            <div class="col-12 btn d-flex justify-content-start align-items-center">
              <span class="mr-2">
                <i class="fas fa-angle-double-up" *ngIf="expanded"></i>
                <i class="fas fa-angle-double-down" *ngIf="!expanded"></i>
              </span>
              <span class="text-summary">{{ row.FK_Scholarship_Display }}</span>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion"></ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td>There are no students to show.</td>
      </tr>
    </ng-template>
  </p-table>
  <p-sidebar [(visible)]="findSchoolDisplay" position="right" [style]="{ width: '60%' }" [showCloseIcon]="false">
    <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
      <div class="row p-1">
        <div class="col-12 d-flex justify-content-between">
          <h4 class="font-weight-bold">
            Find a School for {{ nameStudentSelected }}
          </h4>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <p>
            {{schoolYearStudentSelected}} Award: ${{ actualAwardModel?.AwardAmount }}
          </p>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-9">
          <div class="form-group">
            <label class="form-label font-weight-bold">Search by School Name or Address</label>
            <input type="text" class="form-control" [(ngModel)]="searchSchoolText"
              (ngModelChange)="onSearchTextChanged()" />
          </div>
        </div>
        <div class="col-3 d-flex align-items-end pb-3">
          <a class="link" (click)="op.toggle($event)">Request a New School</a>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'" [style]="{ width: '450px' }">
            <p>
              <span class="font-weight-bold">Request New School</span>
            </p>
            <p>
              If you would like to attend a School that is not already
              approved by AAA Scholarships, you can submit a request for
              them to apply for approval.
            </p>
            <p>
              Please note that the School must accept the invitation and
              be approved by AAA Scholarships for acceptance of
              scholarship funds.
            </p>
            <div class="d-flex">
              <button class="btn btn-primary mr-2" type="button" (click)="requestNewSchool()">
                Request New School
              </button>
              <a class="link" type="button" (click)="op.hide()">
                Nevermind, return to my form
              </a>
            </div>
          </p-overlayPanel>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <!-- <app-custom-table [data]="schoolTableData[0]" [columns]="schoolTableColumns" [rows]="10"
            (onRowTableClicked)="doViewButtonSchoolCommitment($event)">
          </app-custom-table> -->
          <app-custom-table [data]="schoolTableData" [rows]="10" showPaginator="true" [columns]="schoolTableColumns"
            [showSummary]="true" [useLazyLoad]="true" (onLoadData)="loadSchoolData($event)"
            [isLoading]="usersTableLoading" [totalRecords]="usersTableTotalRecords"
            (onRowTableClicked)="doViewButtonSchoolCommitment($event)">
          </app-custom-table>
        </div>
      </div>
      <div class="row p-1 mt-5">
        <div class="col-12">
          <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelProfile()">
            Cancel
          </button>
        </div>
      </div>
    </p-scrollPanel>
  </p-sidebar>
  <p-sidebar [(visible)]="schoolCommitmentStudentDisplay" position="right" [style]="{ width: '60%' }"
    [showCloseIcon]="false">
    <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
      <div class="row p-1">
        <div class="col-12 d-flex justify-content-between">
          <h4 class="font-weight-bold">
            School Commitment for {{ nameStudentSelected }}
          </h4>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <p>
            {{schoolYearStudentSelected}} Award: ${{ actualAwardModel?.AwardAmount }}
          </p>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <p>
            You have selected
            <strong>{{ schoolToCommitment.Name }}</strong> to start the
            School Commitment Process. Please review the information below
            before submitting your choice.
          </p>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <p>
            Once you submit, you will not be able to start this process
            with another school.
          </p>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-md-auto">
          <p>
            <i class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;&nbsp;
            {{ schoolToCommitment.Physical_StreetAddress1 }},
            {{ schoolToCommitment.Physical_StreetAddress2 }}
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {{ schoolToCommitment.Physical_City }},
            {{ schoolToCommitment.FK_State_Physical_Display }}
            {{ schoolToCommitment.Physical_Zipcode }}
          </p>
        </div>
        <div class="col-md-auto">
          <p *ngIf="schoolToCommitment.Phone">
            <i class="fas fa-phone"></i>&nbsp;&nbsp;&nbsp;
            {{ schoolToCommitment.Phone }}
          </p>
          <p *ngIf="schoolToCommitment.FaxNumber">
            <i class="fas fa-fax"></i>&nbsp;&nbsp;&nbsp;
            {{ schoolToCommitment.FaxNumber }}
          </p>
        </div>
        <div class="col-md-auto">
          <p *ngIf="schoolToCommitment.WebsiteURL">
            <i class="fas fa-laptop"></i>&nbsp;&nbsp;&nbsp;
            {{ schoolToCommitment.WebsiteURL }}
          </p>
          <p *ngIf="schoolToCommitment.EmailAddress">
            <i class="fas fa-envelope"></i>&nbsp;&nbsp;&nbsp;
            {{ schoolToCommitment.EmailAddress }}
          </p>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12" *ngIf="schoolFeesData[0]">
          <p-tabView>
            <p-tabPanel [header]="item" *ngFor="let item of schoolFeesData; let i = index" [selected]="i == 0">
              <app-custom-table [data]="schoolFeesTableData[i]" [columns]="schoolFeesTableColumns" [rows]="10">
              </app-custom-table>
              {{ item.content }}
            </p-tabPanel>
          </p-tabView>
        </div>
        <div class="col-12" *ngIf="!schoolFeesData[0]">
          <p>
            No Fee Schedule data for the School
            {{ schoolToCommitment.Name }}
          </p>
        </div>
      </div>
      <div class="row p-1 mt-5">
        <div class="col-12">
          <button class="btn btn-primary mx-2 w-50" type="button" (click)="submitSchoolCommitment()"
            [disabled]="!schoolFeesData[0]">
            Begin School Commitment Form
          </button>
          <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelSchoolCommitment()">
            Cancel
          </button>
        </div>
      </div>
    </p-scrollPanel>
  </p-sidebar>
</div>