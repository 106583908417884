import { Component, OnInit, Directive } from '@angular/core';
import { ZengineFormModel } from '../../models/entities/ZengineFormModel';
import { ListItemModel, IListItem, UpdateEvent } from '../../models/models';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class ZengineFormEditBaseComponent extends BaseEditComponent  {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	public model: ZengineFormModel = <ZengineFormModel>{};
	public fK_ZengineFormType_options:Array<ListItemModel> = <Array<ListItemModel>>[];

	protected baseNgOnInit() {
		super.baseNgOnInit();
	}

	public commit_ZengineFormStateLookUp: UpdateEvent = null;
	public commit_ZengineFormYearLookUp: UpdateEvent = null;

	protected loadOptions() {
				this.getFK_ZengineFormType_Options();
	}

	protected getData() {
	this.ShowSpinner = true;
		var sub = this.api.getZengineForm(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
			}
			this.ShowSpinner = false;
			this.getDataComplete();
			sub.unsubscribe();
		});
	}
	public getFK_ZengineFormType_Options(clear?: boolean) {
		if (clear) {  this.model.FK_ZengineFormType = null; }
		var sub = this.api.getZengineFormTypeList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_ZengineFormType_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateZengineForm(this.model).subscribe(x=>{
			if (x.Success) {
				this.commit_ZengineFormStateLookUp = <UpdateEvent>{ Id: this.id, Stamp: new Date() }
				this.commit_ZengineFormYearLookUp = <UpdateEvent>{ Id: this.id, Stamp: new Date() }
				this.saved.emit(this.id);
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertZengineForm(this.model).subscribe(x=>{
			if (x.Success) {
				this.commit_ZengineFormStateLookUp = <UpdateEvent>{ Id: x.Data, Stamp: new Date() }
				this.commit_ZengineFormYearLookUp = <UpdateEvent>{ Id: x.Data, Stamp: new Date() }
				this._id = x.Data;
				this.saved.emit(x.Data);
			}
			sub.unsubscribe();
		});
		}
	}
}
