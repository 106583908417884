import { SchoolGradeLevelsLookUpModel} from '../entities/SchoolGradeLevelsLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolGradeLevelsLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolGradeLevelsLookUpModel = new SchoolGradeLevelsLookUpModel();
}

export class SchoolGradeLevelsLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolGradeLevelsLookUpModel> = <Array<SchoolGradeLevelsLookUpModel >>[]
}

export interface ISchoolGradeLevelsLookUpListParams {
	pK_Id?:number;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	fK_GradeLevel?:number;
	fK_GradeLevel_display?:string;
	fK_GradeLevel_like?:string;
	fK_GradeLevel_fromValue?:number;
	fK_GradeLevel_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolGradeLevelsLookUpListParams implements ISchoolGradeLevelsLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	fK_GradeLevel?:number=null;
	fK_GradeLevel_display?:string=null;
	fK_GradeLevel_like?:string=null;
	fK_GradeLevel_fromValue?:number=null;
	fK_GradeLevel_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

