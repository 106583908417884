import { SchoolEligibilityModel} from '../entities/SchoolEligibilityModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolEligibilityResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolEligibilityModel = new SchoolEligibilityModel();
}

export class SchoolEligibilityList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolEligibilityModel> = <Array<SchoolEligibilityModel >>[]
}

export interface ISchoolEligibilityListParams {
	htmlContent?:string;
	htmlContent_like?:string;
	pK_Id?:number;
	isHeaderContentOnly?:boolean;
	isHeaderContentOnly_booleanValue?:boolean;
	radioButton?:boolean;
	radioButton_booleanValue?:boolean;
	forStudent?:boolean;
	forStudent_booleanValue?:boolean;
	forSchool?:boolean;
	forSchool_booleanValue?:boolean;
	fK_SchoolEligibility_Parent?:number;
	fK_SchoolEligibility_Parent_display?:string;
	fK_SchoolEligibility_Parent_like?:string;
	fK_SchoolEligibility_Parent_fromValue?:number;
	fK_SchoolEligibility_Parent_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolEligibilityListParams implements ISchoolEligibilityListParams, ISearchModel  {
	htmlContent?:string=null;
	htmlContent_like?:string=null;
	pK_Id?:number=null;
	isHeaderContentOnly?:boolean=null;
	isHeaderContentOnly_booleanValue?:boolean=null;
	radioButton?:boolean=null;
	radioButton_booleanValue?:boolean=null;
	forStudent?:boolean=null;
	forStudent_booleanValue?:boolean=null;
	forSchool?:boolean=null;
	forSchool_booleanValue?:boolean=null;
	fK_SchoolEligibility_Parent?:number=null;
	fK_SchoolEligibility_Parent_display?:string=null;
	fK_SchoolEligibility_Parent_like?:string=null;
	fK_SchoolEligibility_Parent_fromValue?:number=null;
	fK_SchoolEligibility_Parent_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

