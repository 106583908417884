import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService, LazyLoadEvent } from 'primeng/api';

import { aaaAPIVendorService } from '../../../services/aaa.API.vendor.service';
import { BaseComponent } from '../../../BaseComponent';
import { ContactModel } from '../../../models/entities/ContactModel';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { VendorModel } from '../../../models/entities/VendorModel';
import { VendorCategoriesLookUpModel } from '../../../models/entities/VendorCategoriesLookUpModel';
import { InsertResponse, ManagerResponse, RoleType } from 'src/app/models/models';
import { forkJoin, Observable, Subscription, timer } from 'rxjs';
import { VendorResponse } from 'src/app/models/entities/VendorModel.response';
import { ContactResponse } from 'src/app/models/entities/ContactModel.response';
import { ITableColumnDefinition } from 'src/app/interfaces/table-column-definition-interface';
import { VendorServiceProviderModel } from 'src/app/models/entities/VendorServiceProviderModel';
import { CONSTANTS } from 'src/app/constants/constants';
import { FieldTypeDefinition } from 'src/app/types/field-type-definition-type';

@Component({
  selector: 'app-vendor-profile-page',
  templateUrl: './vendor-profile-page.component.html',
  styleUrls: ['./vendor-profile-page.component.scss']
})
export class VendorProfilePageComponent extends BaseComponent implements OnInit {
  pageNumber: number = 1;
  model: VendorModel = null;
  editModel: VendorModel = new VendorModel;
  contactModel: ContactModel = new ContactModel;
  editContactModel: ContactModel = new ContactModel;
  editProviderModel: ContactModel = new ContactModel;

  editViewDisplay: boolean = false;
  editBankAccountViewDisplay: boolean = false;
  editContactViewDisplay: boolean = false;
  editCategoryViewDisplay: boolean = false;
  editProviderViewDisplay: boolean = false;
  categoryList: VendorCategoriesLookUpModel[] = [];

  providersSearchText: string = null;
  providersActive: boolean = true;
  providersTableData: any[] = [];
  providersTableColumns: ITableColumnDefinition[] = [];
  providersTableLoading: boolean = false;
  providersTableTotalRecords: number = null;
  providersList: VendorServiceProviderModel[] = [];

  lastLazyLoadEvent: LazyLoadEvent = null;
  lastSearchText: string = null;
  rowSelected: any = null;
  searchSubscription: Subscription;

  stateOptions: SelectItem[] = [];
  categoryCheckList: SelectItem[] = [];
  isSamePhysicalAddress: boolean = false;
  isVendorWithGardinerStudents: boolean = true;

  protected vendorId: number = null;

  constructor(
    protected vendorService: aaaAPIVendorService,
    private confirmationService: ConfirmationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute, false);
  }

  public isVendorAdmin(): boolean {
    return this.gl.LoginRoles.includes(RoleType.ServiceProviderAdmin) || this.gl.LoginRoles.includes(RoleType.Admin);
  }

  public isVendorUser(): boolean {
    return this.gl.LoginRoles.includes(RoleType.ServiceProviderUser);
  }

  public hasVendorId(): boolean {
    return this.vendorId && this.vendorId > 0;
  }

  public getContactData(loginId?: string): Observable<ContactResponse> {
    if (this.hasVendorId()) {
      return this.vendorService.getContactByVendorId(this.vendorId);
    }
    return this.vendorService.getContactByLogin(loginId);
  }

  public getVendorData(contactId?: number): Observable<VendorResponse> {
    if (this.hasVendorId()) {
      return this.vendorService.getVendor(this.vendorId);
    }
    return this.vendorService.getVendorByContactId(contactId);
  }

  private isValidContactModel(contactModel: ContactModel): boolean {
    const model = contactModel;
    return (
      model.FirstName && model.FirstName.trim().length > 0 &&
      model.LastName && model.LastName.trim().length > 0 &&
      model.Title && model.Title.trim().length > 0 &&
      model.Phone && model.Phone.trim().length > 0 &&
      model.Email && model.Email.trim().length > 0
    );
  }

  ngOnInit(): void {
    this.getContactData(this.gl.LoginId)
      .subscribe((res) => {
        if (res.Success) {
          this.contactModel = res.Data;
          this.vendorService.getVendorByContactId(this.contactModel.PK_Id)
            .subscribe((resVendor) => {
              this.model = resVendor.Data;
              this.loadVendorCategoriesData();
              this.loadGeneralProvidersData();
            });
        }
      });
    this.vendorService.getStateList({ active: true })
      .subscribe((res) => {
        this.stateOptions = res.Data.map(item => {
          return {
            label: item.ListDisplay,
            value: item.PK_Id,
          } as SelectItem;
        });
      });
  }

  doChangePage(pageNumber: number) {
    if (pageNumber != this.pageNumber) {
      this.pageNumber = pageNumber;
    }
  }

  /*
    Vendor Methods
  */

  get isMailingAddressSamePhysicalAddressView() {
    return this.isMailingAddressSamePhysicalAddress(this.model);
  }

  get isMailingAddressSamePhysicalAddressEdit() {
    return this.isMailingAddressSamePhysicalAddress(this.editModel);
  }

  get hasBankAccount() {
    return (this.model?.PaymentBankAccount && this.model?.PaymentBankAccount.trim().length > 0);
  }

  get getFilteredBankAccount() {
    let s = this.model.PaymentBankAccount.trim();
    let filteredText = s.substring(s.length - 4, s.length);
    filteredText = filteredText.padStart(s.length, "*");
    return filteredText;
  }

  private isMailingAddressSamePhysicalAddress(model: VendorModel): boolean {
    if (model) {
      return (
        model.Mailing_StreetAddress1 == model.Physical_StreetAddress1 &&
        model.Mailing_StreetAddress2 == model.Physical_StreetAddress2 &&
        model.Mailing_City == model.Physical_City &&
        model.FK_State_VendorMailing == model.FK_State_VendorPhysical &&
        model.Mailing_Zipcode == model.Physical_Zipcode
      );
    }
    return false;
  }

  doVendorEdit() {
    this.editModel = { ...this.model };
    this.isSamePhysicalAddress = this.isMailingAddressSamePhysicalAddressEdit;
    this.onSamePhysicalAddressChange();
    this.editViewDisplay = true;
  }

  saveProfile() {
    this.vendorService.updateVendor(this.editModel)
      .subscribe((res) => {
        if (res.Success) {
          this.model = { ...this.editModel };
          this.editViewDisplay = false;
        }
      });
  }

  cancelProfile() {
    this.editViewDisplay = false;
  }

  private copyPhysicalAddressToMailingAddress() {
    this.editModel.Mailing_StreetAddress1 = this.editModel.Physical_StreetAddress1;
    this.editModel.Mailing_StreetAddress2 = this.editModel.Physical_StreetAddress2;
    this.editModel.Mailing_City = this.editModel.Physical_City;
    this.editModel.FK_State_VendorMailing = this.editModel.FK_State_VendorPhysical;
    this.editModel.FK_State_VendorMailing_Display = this.editModel.FK_State_VendorPhysical_Display;
    this.editModel.Mailing_Zipcode = this.editModel.Physical_Zipcode;
  }

  onSamePhysicalAddressChange() {
    if (this.isSamePhysicalAddress) {
      this.copyPhysicalAddressToMailingAddress();
    } else {
      this.editModel.Mailing_StreetAddress1 = this.model.Mailing_StreetAddress1;
      this.editModel.Mailing_StreetAddress2 = this.model.Mailing_StreetAddress2;
      this.editModel.Mailing_City = this.model.Mailing_City;
      this.editModel.FK_State_VendorMailing = this.model.FK_State_VendorPhysical;
      this.editModel.FK_State_VendorMailing_Display = this.model.FK_State_VendorPhysical_Display;
      this.editModel.Mailing_Zipcode = this.model.Mailing_Zipcode;
    }
  }

  onPhysicalAddressChange() {
    if (this.isSamePhysicalAddress) {
      this.editModel.Mailing_StreetAddress1 = this.editModel.Physical_StreetAddress1;
      this.editModel.Mailing_StreetAddress2 = this.editModel.Physical_StreetAddress2;
      this.editModel.Mailing_City = this.editModel.Physical_City;
      this.editModel.FK_State_VendorMailing = this.editModel.FK_State_VendorPhysical;
      this.editModel.FK_State_VendorMailing_Display = this.editModel.FK_State_VendorPhysical_Display;
      this.editModel.Mailing_Zipcode = this.editModel.Physical_Zipcode;
    }
  }

  isValidProfile(): boolean {
    const model = this.editModel;
    return (
      model.Name &&
      model.Name.trim().length > 0 &&
      model.Physical_StreetAddress1 &&
      model.Physical_StreetAddress1.trim().length > 0 &&
      model.Physical_City &&
      model.Physical_City.trim().length > 0 &&
      model.FK_State_VendorPhysical &&
      model.FK_State_VendorPhysical > 0 &&
      model.Physical_Zipcode &&
      model.Physical_Zipcode.trim().length > 0 &&
      model.Mailing_StreetAddress1 &&
      model.Mailing_StreetAddress1.trim().length > 0 &&
      model.Mailing_City &&
      model.Mailing_City.trim().length > 0 &&
      model.FK_State_VendorMailing &&
      model.FK_State_VendorMailing > 0 &&
      model.Mailing_Zipcode &&
      model.Mailing_Zipcode.trim().length > 0
    );
  }

  /*
    Contact Methods
  */

  doPrimaryContactEdit() {
    this.editContactModel = { ...this.contactModel };
    this.editContactViewDisplay = true;
  }

  saveContact() {
    this.vendorService.updateContact(this.editContactModel)
      .subscribe((res) => {
        if (res.Success) {
          this.contactModel = { ...this.editContactModel };
          this.editContactViewDisplay = false;
        }
      });
    this.editContactViewDisplay = false;
  }

  cancelContact() {
    this.editContactViewDisplay = false;
  }

  isValidContact() {
    return this.isValidContactModel(this.editContactModel);
  }

  /*
    Bank Account Methods
  */

  doAddBankAccount() {
    this.editModel = { ...this.model };
    this.editBankAccountViewDisplay = true;
  }

  saveBankAccount() {
    this.vendorService.updateVendor(this.editModel)
      .subscribe((res) => {
        if (res.Success) {
          this.model = { ...this.editModel };
          this.editBankAccountViewDisplay = false;
        }
      });
  }

  cancelBankAccount() {
    this.editBankAccountViewDisplay = false;
  }

  isValidBankAccount(): boolean {
    const model = this.editModel;
    return (
      model.PaymentBankName &&
      model.PaymentBankName.trim().length > 0 &&
      model.PaymentBankRouting &&
      model.PaymentBankRouting.trim().length > 0 &&
      model.PaymentBankAccount &&
      model.PaymentBankAccount.trim().length > 0
    );
  }

  /*
    Categories Methods
  */

  protected loadVendorCategoriesData() {
    this.vendorService.getVendorCategoriesLookUpList({ fK_Vendor: this.model.PK_Id })
      .subscribe(resCategories => {
        this.vendorService.getScholarshipDisbursementCategoryList({})
          .subscribe(resSDC => {
            this.categoryList = resCategories.Data;
            this.categoryCheckList = resSDC.Data.map(item => {
              return {
                label: item.PK_Id.toString(),
                title: item.Name,
                value: (this.categoryList.findIndex(ci => ci.FK_ScholarshipDisbursementCategory == item.PK_Id) !== -1)
              }
            });
          });
      });
  }

  doApplyForAdditionalCategories() {
    this.editCategoryViewDisplay = true;
  }

  saveCategory() {
    const selectedCategories = this.categoryCheckList.filter(item => item.value);
    const toDelete = this.categoryList.map(item => this.vendorService.deleteVendorCategoriesLookUp(item.PK_Id));
    const toAdd = selectedCategories.map(item => {
      let vendorCategory = new VendorCategoriesLookUpModel;
      vendorCategory.FK_Vendor = this.model.PK_Id;
      vendorCategory.FK_ScholarshipDisbursementCategory = parseInt(item.label);
      return this.vendorService.insertVendorCategoriesLookUp(vendorCategory);
    });
    if (toDelete.length > 0) {
      forkJoin(toDelete)
        .subscribe(responseDelete => {
          if (responseDelete.length == toDelete.length) {
            if (toAdd.length > 0) {
              forkJoin(toAdd)
                .subscribe(responseAdd => {
                  if (responseAdd.length == toAdd.length) {
                    this.loadVendorCategoriesData();
                    this.editCategoryViewDisplay = false;
                  }
                });
            } else {
              this.loadVendorCategoriesData();
              this.editCategoryViewDisplay = false;
            }
          }
        });
    } else {
      if (toAdd.length > 0) {
        forkJoin(toAdd)
          .subscribe(responseAdd => {
            if (responseAdd.length == toAdd.length) {
              this.loadVendorCategoriesData();
              this.editCategoryViewDisplay = false;
            }
          });
      } else {
        this.loadVendorCategoriesData();
        this.editCategoryViewDisplay = false;
      }
    }
  }

  cancelCategory() {
    this.editCategoryViewDisplay = false;
  }

  /*
    Providers Methods
  */

  protected loadGeneralProvidersData() {
    this.generateProvidersTableColumns();
    this.loadProviderData(CONSTANTS.FirstLazyLoadEvent);
  }

  protected generateProvidersTableColumns() {
    this.providersTableColumns = [];
    this.providersTableColumns.push({
      fieldCaption: 'Status',
      fieldName: 'StatusIcon',
      fieldType: FieldTypeDefinition.FontAwesomeIcon,
      showTooltip: true,
      tooltipFieldName: 'StatusDescription'
    });
    this.providersTableColumns.push({
      fieldCaption: 'Name',
      fieldName: 'ProviderName',
      fieldType: FieldTypeDefinition.String,
      sortable: true,
      width: '20%'
    });
    this.providersTableColumns.push({
      fieldCaption: 'Email',
      fieldName: 'EmailAddress',
      fieldType: FieldTypeDefinition.String,
      width: '40%'
    });
    this.providersTableColumns.push({
      fieldCaption: 'Categories',
      fieldName: 'Categories',
      fieldType: FieldTypeDefinition.String
    });
  }

  protected generateProvidersTableData() {
    this.providersTableData = this.providersList.map(item => {
      const icon = `fas fa-2x ${(item.ContactModel && item.ContactModel.IsEnabled) ? 'fa-check-circle icon-green' : 'fa-times-circle icon-red'}`;
      return {
        'PK_Id': item.PK_Id,
        'Status': true,
        'StatusIcon': icon,
        'StatusDescription': (item.ContactModel && item.ContactModel.IsEnabled) ? 'Enabled' : 'Disabled',
        'FK_Vendor': item.VendorModel,
        'FK_Contact': item.ContactModel,
        'ProviderName': (item.ContactModel) ? `${item.ContactModel.FirstName} ${item.ContactModel.LastName}` : '',
        'EmailAddress': (item.ContactModel) ? item.ContactModel.Email : '',
        'Categories': null
      };
    });
    /*
    this.providersTableData.forEach(item => {
      this.vendorService.getVendorCategoriesLookUpList({ fK_Vendor: item['PK_Id'] })
        .subscribe(responseVendorCategories => {
          if (responseVendorCategories.Data.length > 0) {
            item['Categories'] = responseLoginRoles.Data.map(lr => lr.FK_Role_Display).join(", ");
          }
        });
    });
    */
    this.providersTableLoading = false;
  }

  loadProviderData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.providersTableLoading = true;
      const page = (this.providersSearchText !== this.lastSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.providersSearchText && this.providersSearchText.trim().length > 0) ? this.providersSearchText : null;
      const isEnabled = (this.providersActive) ? true : null;
      this.lastSearchText = this.providersSearchText;

      this.vendorService.doSearchVendorServiceProviderList(this.model.PK_Id, searchText, isEnabled, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseServiceProviders => {
          this.providersTableTotalRecords = responseServiceProviders.RecordsCount;
          this.providersList = responseServiceProviders.Data;
          this.generateProvidersTableData();
        });
    }
  }

  addProvider() {
    this.editProviderModel = new ContactModel;
    this.editProviderViewDisplay = true;
  }

  isValidProvider(): boolean {
    return this.isValidContactModel(this.editProviderModel);
  }

  protected callContactProviderService(): Observable<InsertResponse | ManagerResponse> {
    console.log(this.editProviderModel);
    if (!(this.editProviderModel.PK_Id && this.editProviderModel.PK_Id > 0)) {
      return this.vendorService.insertContact(this.editProviderModel);
    } else {
      return this.vendorService.updateContact(this.editProviderModel);
    }
  }

  saveProvider() {
    this.callContactProviderService()
      .subscribe(response => {
        if (response.Success) {
          if (!(this.editProviderModel.PK_Id && this.editProviderModel.PK_Id > 0)) {
            const insertResponse = response as InsertResponse;
            this.editProviderModel.PK_Id = parseInt(insertResponse.Data);
            let vendorServiceProviderModel = new VendorServiceProviderModel;
            vendorServiceProviderModel.FK_Vendor = this.model.PK_Id;
            vendorServiceProviderModel.FK_Contact = this.editProviderModel.PK_Id;
            this.vendorService.insertVendorServiceProvider(vendorServiceProviderModel)
              .subscribe(responseVSP => {
                if (responseVSP.Success) {
                  this.loadProviderData(this.lastLazyLoadEvent);
                  this.editProviderViewDisplay = false;
                }
              });
          } else {
            this.loadProviderData(this.lastLazyLoadEvent);
            this.editProviderViewDisplay = false;
          }
        }
      });
  }

  cancelProvider() {
    this.editProviderViewDisplay = false;
  }

  onProvidersActiveChange() {
    this.loadProviderData(this.lastLazyLoadEvent);
  }

  onProviderTableRowClick(row: any) {
    const contactModel = row['FK_Contact'] as ContactModel;
    this.editProviderModel = { ...contactModel };
    this.editProviderViewDisplay = true;
  }

  onSearchTextChanged() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchSubscription = source
      .subscribe(() => {
        this.loadProviderData(this.lastLazyLoadEvent);
        this.searchSubscription.unsubscribe();
      })
  }
}
