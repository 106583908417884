import { Component, OnInit, Directive } from '@angular/core';
import { IPreAuthorizationListParams,PreAuthorizationListParams } from '../../models/entities/PreAuthorizationModel.response';
import { PreAuthorizationModel} from '../../models/entities/PreAuthorizationModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class PreAuthorizationListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<PreAuthorizationModel> = <Array<PreAuthorizationModel>>[];
	public search: PreAuthorizationListParams = new PreAuthorizationListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getPreAuthorizationList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.pK_Id  = null;
		this.search.fK_Vendor  = null;
		this.search.fK_Vendor_display  = null;
		this.search.fK_Vendor_like  = null;
		this.search.fK_Vendor_fromValue  = null;
		this.search.fK_Vendor_toValue  = null;
		this.search.createdDate  = null;
		this.search.createdDate_fromDate  = null;
		this.search.createdDate_toDate  = null;
		this.search.serviceDescription  = null;
		this.search.serviceDescription_like  = null;
		this.search.educationalDescription  = null;
		this.search.educationalDescription_like  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp_fromValue  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp_toValue  = null;
		this.search.amount  = null;
		this.search.amount_fromValue  = null;
		this.search.amount_toValue  = null;
		this.search.fK_PreAuthorizationStatusType  = null;
		this.search.fK_PreAuthorizationStatusType_display  = null;
		this.search.fK_PreAuthorizationStatusType_like  = null;
		this.search.fK_PreAuthorizationStatusType_fromValue  = null;
		this.search.fK_PreAuthorizationStatusType_toValue  = null;
		this.search.dateApproved  = null;
		this.search.dateApproved_fromDate  = null;
		this.search.dateApproved_toDate  = null;
		this.search.fK_Login_ApprovedBy  = null;
		this.search.fK_Login_ApprovedBy_display  = null;
		this.search.fK_Login_ApprovedBy_like  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deletePreAuthorization(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManyPreAuthorization(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
