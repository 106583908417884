<div class="card">
    <div class="card-header">
        School Eligibilities Respons <span class="right">
            <a title="Cancel" *ngIf="!ModalView" routerLink="/schooleligibilitiesresponslist"><i class="fa fa-undo icon-link"></i></a>
            <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
            <i class="fa fa-save icon-link" (click)="save()"></i>
        </span>
    </div>
    <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
        <div class="form">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>School Eligibility</label><span title="Required" class="required">*</span>
                        <select [(ngModel)]="model.FK_SchoolEligibility" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_SchoolEligibility_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>School</label>
                        <app-typeahead-basic [(id)]="model.FK_School" [searchapi]="'schoolsListBox'"></app-typeahead-basic>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Guardian</label>
                        <app-typeahead-basic [(id)]="model.FK_Guardian" [searchapi]="'guardiansListBox'"></app-typeahead-basic>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">

                        <app-checkbox [(model)]="model.ValueBoolean" [label]="'Value Boolean'"></app-checkbox>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Login Accepted By</label>
                        <app-typeahead-basic [(id)]="model.FK_Login_AcceptedBy" [searchapi]="'loginsListBox'"></app-typeahead-basic>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>School Year</label>
                        <select [(ngModel)]="model.FK_SchoolYear" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_SchoolYear_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>


            </div>


        </div>
    </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
