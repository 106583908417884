import { Component, OnInit, Directive } from '@angular/core';
import {  IVerificationListParams } from '../../models/entities/VerificationModel.response';
import { VerificationModel } from '../../models/entities/VerificationModel';
import { PagingData } from '../../paging/paging.component';
import { VerificationListBaseComponent } from './verificationList.base';

@Component({
	selector: 'app-verificationList',
	templateUrl: './verificationList.component.html',
})
export class VerificationListComponent extends VerificationListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
