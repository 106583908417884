import { Component, OnInit } from '@angular/core';
import { ITableColumnDefinition } from 'src/app/interfaces/table-column-definition-interface';
import { FieldTypeDefinition } from 'src/app/types/field-type-definition-type';
import { aaaAPIAdminService } from 'src/app/services/aaa.API.admin.service';
import { BaseComponent } from 'src/app/BaseComponent';
import { CONSTANTS } from '../../../constants/constants';
import { StudentGradeLevelsSchoolYearsLookUpModel } from 'src/app/models/entities/StudentGradeLevelsSchoolYearsLookUpModel';
import { Subscription, timer } from 'rxjs';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { AwardDeterminationModel } from 'src/app/models/entities/AwardDeterminationModel';
import { aaaAPISchoolService } from 'src/app/services/aaa.API.school.service';
import * as moment from 'moment';
import { SchoolCalendarLookUpModel } from 'src/app/models/entities/SchoolCalendarLookUpModel';

interface Option {
  name: string,
  code: string
}

@Component({
  selector: 'app-admin-allstudents',
  templateUrl: './admin-allstudents.component.html',
  styleUrls: ['./admin-allstudents.component.scss']
})
export class AdminAllstudentsComponent extends BaseComponent implements OnInit {
  searchScholarshipText: string = '';
  statesOptions: Option[];
  state: Option;
  schoolYearOptions: Option[];
  schoolYear: Option;
  schoolModels: AwardDeterminationModel[] = [];
  schoolCalendar: SchoolCalendarLookUpModel = null;
  schoolTableData: any[] = [];
  originalschoolTableData: any[] = [];
  schoolTableColumns: ITableColumnDefinition[] = [];

  lastLazyLoadEvent: LazyLoadEvent = null;
  lastSearchText: string = null;
  usersTableLoading: boolean = false;
  usersTableTotalRecords: number = null;

  schoolYearOptions2: SelectItem[] = [];
  searchSubscription: Subscription;

  constructor(
    private adminServices: aaaAPIAdminService,
    private schoolService: aaaAPISchoolService) {
    super(null, false);

  }

  ngOnInit(): void {
    this.adminServices.getStateList({ _sort: 'Name', active: true })
      .subscribe((states) => {
        this.statesOptions = [
          { name: 'All States', code: 'All' },
        ];
        states.Data.forEach((state) => {
          let stateObj = { name: state.Name, code: state.Abbreviation };
          this.statesOptions.push(stateObj);
        });
        this.loadSchoolCalendarList();
        this.loadSchoolData(CONSTANTS.FirstLazyLoadEvent);
      });
  }


  private loadSchoolCalendarList() {
    this.schoolService
      .getSchoolCalendarLookUpList({
        fK_School: 3078,
        _sort: 'FK_SchoolYear',
        _sortDesc: true,
      })
      .subscribe((resCalendar) => {
        this.schoolYearOptions2 = resCalendar.Data.map((item) => {
          return {
            label: this.getSchoolYearDisplay(item),
            value: item,
          } as SelectItem;
        });
        if (!this.schoolCalendar) {
          this.schoolCalendar =
            this.schoolYearOptions2.length > 0
              ? this.schoolYearOptions2.find(item => item.label.includes('Current Year')).value
              : null;
        }
      });

  }

  private getSchoolYearDisplay(schoolCalendar: SchoolCalendarLookUpModel) {
    const currentDate = moment();
    let schoolYearText = '';
    if (currentDate.toDate() >= moment(schoolCalendar.SchoolYearStart).toDate() && currentDate.toDate() <= moment(schoolCalendar.SchoolYearEnd).toDate()) {
      schoolYearText = 'Current Year';
    } else {
      const date = moment(schoolCalendar.SchoolYearEnd);
      let diff = date.diff(currentDate, 'years', true);
      if (diff > 0) {
        schoolYearText = 'Next Year';
      } else {
        if (Math.abs(diff) < 1) {
          diff = 1;
        }
        if (diff > 1) {
          schoolYearText = 'Past Year';
        } else {
          schoolYearText = 'Last Year';
        }
      }
    }
    return `${schoolYearText} (${schoolCalendar.FK_SchoolYear_Display})`;
  }

  searchScholarship(e) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchSubscription = source
      .subscribe(() => {
        this.loadSchoolData(this.lastLazyLoadEvent);
        this.searchSubscription.unsubscribe();
      })
  }

  changeState(e) {
    this.loadSchoolData(this.lastLazyLoadEvent);
  }

  onSearchTextChanged() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchSubscription = source
      .subscribe(() => {
        this.loadSchoolData(this.lastLazyLoadEvent);
        this.searchSubscription.unsubscribe();
      })
  }

  loadSchoolData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.usersTableLoading = true;
      const page = (this.searchScholarshipText !== this.lastSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const statefilter = this.state;
      const schoolYearFilter = !this.schoolCalendar ? null : this.schoolCalendar.FK_SchoolYear;
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.searchScholarshipText && this.searchScholarshipText.trim().length > 0) ? this.searchScholarshipText : null;
      this.lastSearchText = this.searchScholarshipText;
      console.log('page: ', page, '  pageSize: ', pageSize);

      this.adminServices.doSearchAwardDeterminationList(searchText, statefilter?.name, schoolYearFilter?.toString(), null, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseLogins => {
          console.log(responseLogins);
          this.usersTableTotalRecords = responseLogins.RecordsCount;
          this.schoolModels = responseLogins.Data;
          this.generateSchoolsTableData();
          this.usersTableLoading = false;
        });

    }
  }

  private generateSchoolsTableColumn() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Status',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.Boolean,
      width: '10%'
    });
    columns.push({
      fieldCaption: 'Student Name',
      fieldName: 'Name',
      fieldType: FieldTypeDefinition.String,
      sortable: true,
    });
    columns.push({
      fieldCaption: 'Grade',
      fieldName: 'Grade',
      fieldType: FieldTypeDefinition.String,
      width: '10%'
    });
    columns.push({
      fieldCaption: 'Guardian Name',
      fieldName: 'GuardianName',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'State',
      fieldName: 'State',
      fieldType: FieldTypeDefinition.String,
      width: '10%'
    });
    columns.push({
      fieldCaption: 'Scholarship Type',
      fieldName: 'ScholarshipType',
      fieldType: FieldTypeDefinition.String,
    });

    this.schoolTableColumns = [...columns];
  }

  openStudent(e) {
    this.gl.SelectedStudent = `${e.Name}`;
    this.gl.GoTo(`studentprofile/${e.FK_Student}`, null, null);
  }

  private generateSchoolsTableData() {
    this.generateSchoolsTableColumn();
    this.schoolTableData = this.schoolModels.map(i => {
      return {
        'PK_Id': i.PK_Id,
        'Name': i.FK_Student_Display,
        'Grade': i.FK_GradeLevel_Display,
        'GuardianName': i.FK_Guardian_Display,
        'State': i.FK_State_Display,
        'ScholarshipType': i.FK_Scholarship_Display,
        'FK_Student': i.FK_Student
      }
    });

    this.schoolTableData.forEach((s) => {
      // this.adminServices.getSchoolGradeLevelsLookUpList({ fK_School: s.SchoolId })
      //   .subscribe((grade) => {
      //     if (grade.Data && grade.Data.length > 0) {
      //       s.Grades = grade.Data[0].FK_GradeLevel_Display + ' - ' + grade.Data[grade.Data.length - 1].FK_GradeLevel_Display;
      //     }
      //   })
    });
    this.originalschoolTableData = this.schoolTableData;
  }
}
