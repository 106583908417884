import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VendorServiceProviderEditBaseComponent } from './vendorServiceProviderEdit.base';

@Component({
    selector: 'app-vendorServiceProviderEdit',
    templateUrl: './vendorServiceProviderEdit.component.html',
})
export class VendorServiceProviderEditComponent extends VendorServiceProviderEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
