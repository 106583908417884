import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as uuid from 'uuid';

import { BaseComponent } from '../../../BaseComponent';
import { AwardDeterminationModel } from '../../../models/entities/AwardDeterminationModel';
import { FeeTypeModel } from '../../../models/entities/FeeTypeModel';
import { GuardianModel } from '../../../models/entities/GuardianModel';
import { HouseholdModel } from '../../../models/entities/HouseholdModel';
import { SchoolCommitmentModel } from '../../../models/entities/SchoolCommitmentModel';
import { SchoolEligibilitiesStateLookUpModel } from '../../../models/entities/SchoolEligibilitiesStateLookUpModel';
import { SchoolFeeModel } from '../../../models/entities/SchoolFeeModel';
import { SchoolModel } from '../../../models/entities/SchoolModel';
import { StudentGradeLevelsSchoolYearsLookUpModel } from '../../../models/entities/StudentGradeLevelsSchoolYearsLookUpModel';
import { aaaAPISchoolService } from '../../../services/aaa.API.school.service';
import { FieldTypeDefinition } from '../../../types/field-type-definition-type';
import { ITableColumnDefinition } from '../../../interfaces/table-column-definition-interface';
import { SchoolFeeListModel } from '../../../models/entities/SchoolFeeListModel';
import { FeeTypes, InsertResponse } from '../../../models/models';
import { CONSTANTS } from '../../../constants/constants';
import { forkJoin } from 'rxjs';
import { SchoolCommitmentFeesLookUpModel } from 'src/app/models/entities/SchoolCommitmentFeesLookUpModel';
import { SchoolEligibilityModel } from 'src/app/models/entities/SchoolEligibilityModel';
import { SchoolEligibilitiesResponsModel } from 'src/app/models/entities/SchoolEligibilitiesResponsModel';

@Component({
  selector: 'app-school-dashboard-page',
  templateUrl: './school-dashboard-page.component.html',
  styleUrls: ['./school-dashboard-page.component.scss']
})
export class SchoolDashboardPageComponent extends BaseComponent implements OnInit {
  allStudentsTableData: any[] = [];
  allStudentsTableDataOriginal: any[] = [];
  allStudentsTableColumns: ITableColumnDefinition[] = [];
  pendingStudentsTableData: any[] = [];
  pendingStudentsTableColumns: ITableColumnDefinition[] = [];
  model: SchoolModel;
  schoolFeeModel: SchoolFeeListModel = null;
  studentGradeLevelSchoolYearList: StudentGradeLevelsSchoolYearsLookUpModel[] = [];
  schoolCommitmentList: SchoolCommitmentModel[] = [];
  searchText: string = null;

  editViewDisplay: boolean = false;
  selectedAwardDetermination: AwardDeterminationModel = null;
  selectedRow: any;
  selectedGuardian: GuardianModel = null;
  selectedHousehold: HouseholdModel = null;
  startDate: Date = new Date();
  schoolEligibilitiesList: SchoolEligibilitiesStateLookUpModel[] = [];
  schoolEligibilitiesResponses: any = {};
  feeTypesList: FeeTypeModel[] = [];
  feeTypesListForDiscount: FeeTypeModel[] = [];
  schoolFeeList: SchoolFeeModel[] = [];
  schoolAllFeeList: SchoolFeeModel[] = [];
  customSchoolFeeList: SchoolFeeModel[] = [];
  schoolFeeTableData: any[] = [];
  schoolFeeTableColumns: ITableColumnDefinition[] = [];
  discountFeeModel: any = {};
  calculatedTotal: number = 0;
  scfConfirmed: boolean = false;
  yearCalendarRange: string = '';

  constructor(
    private schoolService: aaaAPISchoolService
  ) {
    super(null, false);
  }

  ngOnInit(): void {
    this.pageLoad();
  }

  private pageLoad() {
    this.schoolService.getContactByLogin(this.gl.LoginId)
      .subscribe(response => {
        if (response.Success) {
          this.schoolService
            .getSchoolByContactId(response.Data.PK_Id)
            .subscribe((resSchool) => {
              this.model = resSchool.Data;
              this.schoolService.getStudentGradeLevelsSchoolYearsLookUpList({ fK_School: this.model.PK_Id })
                .subscribe(responseSGLSY => {
                  this.studentGradeLevelSchoolYearList = responseSGLSY.Data;
                  this.generateAllStudentsTableData();
                });
              this.schoolService.getSchoolCommitmentList({ fK_School: this.model.PK_Id, fK_SchoolCommitmentStatusType: 1 })
                .subscribe(responseSC => {
                  this.schoolCommitmentList = responseSC.Data;
                  this.generatePendingStudentsTableData();
                });
              this.schoolService
                .getSchoolEligibilitiesStateLookUpList({
                  fK_State: this.model.FK_State_Physical,
                  _sort: 'PK_Id',
                })
                .subscribe((resSchoolEligibilitiesState) => {
                  this.schoolService
                    .getSchoolEligibilityList({ forStudent: true })
                    .subscribe((resSchoolEligibilities) => {
                      const eligibilitiesForSchool = resSchoolEligibilities.Data.filter(
                        (item) =>
                          item.ForSchool &&
                          item.FK_SchoolEligibility_Parent == null
                      ).map((item) => item.PK_Id);
                      this.schoolEligibilitiesList = resSchoolEligibilitiesState.Data.filter(
                        (item) =>
                          eligibilitiesForSchool.includes(
                            item.FK_SchoolEligibility
                          )
                      );
                    });
                });
            });
          this.schoolService.getFeeTypeList({ _sort: 'PK_Id' })
            .subscribe((res) => {
              this.feeTypesList = res.Data;
              this.feeTypesListForDiscount = this.feeTypesList.filter(item => item.PK_Id !== FeeTypes.Other);
            });
        }
      });
    const currentYear = moment().year();
    this.yearCalendarRange = `${currentYear - 5}:${currentYear + 5}`;
  }
  private generateAllStudentsTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Student Name',
      fieldName: 'StudentName',
      fieldType: FieldTypeDefinition.String,
      sortable: true
    });
    columns.push({
      fieldCaption: 'Grade',
      fieldName: 'GradeLevel',
      fieldType: FieldTypeDefinition.Integer,
    });
    columns.push({
      fieldCaption: 'Guardian Name',
      fieldName: 'GuardianName',
      fieldType: FieldTypeDefinition.String,
      width: '70%'
    });
    this.allStudentsTableColumns = [...columns];
  }

  private generateAllStudentsTableData() {
    this.generateAllStudentsTableColumns();
    this.allStudentsTableData = this.studentGradeLevelSchoolYearList.map(item => {
      return {
        'FK_Student': item.FK_Student,
        'StudentName': item.FK_Student_Display,
        'GradeLevel': item.FK_GradeLevel_Display,
        'GuardianName': ''
      };
    });
    this.allStudentsTableData.forEach(item => {
      //this.schoolService.getGuardian
    });
    this.allStudentsTableDataOriginal = [...this.allStudentsTableData];
  }

  private generatePendingStudentsTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Student Name',
      fieldName: 'StudentName',
      fieldType: FieldTypeDefinition.String,
      sortable: true
    });
    columns.push({
      fieldCaption: 'Grade',
      fieldName: 'GradeLevel',
      fieldType: FieldTypeDefinition.Integer,
    });
    columns.push({
      fieldCaption: 'Guardian Name',
      fieldName: 'GuardianName',
      fieldType: FieldTypeDefinition.String,
      width: '25%'
    });
    columns.push({
      fieldCaption: 'Status Form Type',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.String,
    });

    this.pendingStudentsTableColumns = [...columns];
  }

  private generatePendingStudentsTableData() {
    this.generatePendingStudentsTableColumns();
    this.pendingStudentsTableData = this.schoolCommitmentList.map(item => {
      return {
        'FK_Student': item.FK_Student,
        'StudentName': item.FK_Student_Display,
        'FK_SchoolYear': item.FK_SchoolYear,
        'FK_SchoolYear_Display': item.FK_SchoolYear_Display,
        'GradeLevel': '',
        'GuardianName': '',
        'Status': item.FK_SchoolCommitmentStatusType_Display,
        'AwardDeterminationData': null,
        'PK_Id': item.PK_Id
      };
    });
    this.pendingStudentsTableData.forEach(item => {
      // Getting Award Determination Data and Guardian Name
      this.schoolService.getAwardDeterminationList({ fK_Student: item.FK_Student, fK_SchoolYear: item.fK_SchoolYear })
        .subscribe(response => {
          if (response.Data.length > 0) {
            const awardDetermination = response.Data[0];
            item.GradeLevel = awardDetermination.FK_GradeLevel_Display;
            item.GuardianName = awardDetermination.FK_Guardian_Display;
            item.AwardDeterminationData = awardDetermination;
          }
        });
    });
  }

  doSearch() {
    if (this.searchText && this.searchText.trim().length > 0) {
      this.allStudentsTableData = this.allStudentsTableDataOriginal.filter(item => {
        const studentName: string = item.StudentName;
        return (studentName.toUpperCase().includes(this.searchText.trim().toUpperCase()));
      });
    } else {
      if (this.allStudentsTableData.length !== this.allStudentsTableDataOriginal.length) {
        this.allStudentsTableData = [...this.allStudentsTableDataOriginal];
      }
    }
  }

  doPendingStudentsRowClicked(row: any) {
    this.selectedAwardDetermination = row.AwardDeterminationData as AwardDeterminationModel;
    this.selectedRow = row;
    this.schoolService.getGuardian(this.selectedAwardDetermination.FK_Guardian)
      .subscribe(responseG => {
        if (responseG.Success) {
          this.selectedGuardian = responseG.Data;
          this.schoolService.getHousehold(this.selectedGuardian.FK_Household)
            .subscribe(responseH => {
              if (responseH.Success) {
                this.selectedHousehold = responseH.Data;
                this.loadSchoolFeeList(this.selectedAwardDetermination.FK_GradeLevel, this.selectedAwardDetermination.FK_SchoolYear);
                this.editViewDisplay = true;
              }
            });
        }
      });
  }

  doAllStudentsRowClicked(row: any) {
    this.gl.SelectedStudent = row.StudentName;
    this.gl.GoTo(`studentprofile/${row.FK_Student}`);
  }

  /*
    School Commitment Form Methods
  */

  private loadSchoolFeeList(gradeLevel: number, schoolYear: number) {
    this.schoolService.getSchoolFeeList({
      fK_School: this.model.PK_Id,
      fK_SchoolYear: schoolYear,
      _sort: 'FK_GradeLevel',
      _pageSize: CONSTANTS.RecordCount
    })
      .subscribe((responseFees) => {
        this.schoolFeeList = responseFees.Data.filter(item => gradeLevel >= item.FK_GradeLevel && gradeLevel <= item.FK_GradeLevel_To);
        this.customSchoolFeeList = [];
        this.updateSchoolFeeList();
      });
  }

  private updateSchoolFeeList() {
    this.schoolAllFeeList = [
      ...this.schoolFeeList,
      ...this.customSchoolFeeList
    ];
    this.generateSchoolFeeTableData();
    // Preparing Discount
    this.discountFeeModel = {};
    this.feeTypesList.forEach(item => {
      if (item.PK_Id !== FeeTypes.Other) {
        this.discountFeeModel[item.PK_Id] = 0;

        /*
          discountFeeModel[1] = 0;
          discountFeeModel[2] = 100;

        */
      }
    });
    // making calculation
    this.calculateTotal();
  }

  private generateSchoolFeeTableData() {
    this.generateSchoolFeeTableColumns();

    this.schoolFeeTableData = [];
    let rowData = {};
    this.schoolFeeModel = new SchoolFeeListModel();
    const firstItem = this.schoolAllFeeList[0];
    this.schoolFeeModel.Id = uuid.v4();
    this.schoolFeeModel.FK_GradeLevel = firstItem.FK_GradeLevel;
    this.schoolFeeModel.FK_GradeLevel_Display = firstItem.FK_GradeLevel_Display;
    this.schoolFeeModel.FK_GradeLevel_To = firstItem.FK_GradeLevel_To;
    this.schoolFeeModel.FK_GradeLevel_To_Display = firstItem.FK_GradeLevel_To_Display;
    this.schoolFeeModel.List = [];
    rowData['Grade'] =
      this.schoolFeeModel.FK_GradeLevel_Display ==
        this.schoolFeeModel.FK_GradeLevel_To_Display
        ? this.schoolFeeModel.FK_GradeLevel_Display
        : `${this.schoolFeeModel.FK_GradeLevel_Display}-${this.schoolFeeModel.FK_GradeLevel_To_Display}`;
    rowData['OtherToolTipValues'] = [];
    this.schoolAllFeeList.forEach((feeItem) => {
      if (rowData[feeItem.FK_FeeType_Display]) {
        rowData[feeItem.FK_FeeType_Display] += feeItem.Amount;
      } else {
        rowData[feeItem.FK_FeeType_Display] = feeItem.Amount;
      }
      if (feeItem.FK_FeeType == FeeTypes.Other) {
        if (feeItem.Description && feeItem.Description.trim().length > 0) {
          rowData['OtherToolTipValues'].push(feeItem.Description);
        }
      }
      this.schoolFeeModel.List.push(feeItem);
    });
    rowData['OtherToolTipText'] = (rowData['OtherToolTipValues'] as string[]).join(", ");
    rowData['Total'] = this.schoolFeeModel.Total;
    rowData['RawData'] = Object.assign(
      Object.create(this.schoolFeeModel),
      this.schoolFeeModel
    );
    this.schoolFeeTableData.push(rowData);
  }

  private generateSchoolFeeTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Grade',
      fieldName: 'Grade',
      fieldType: FieldTypeDefinition.Integer,
    });
    this.feeTypesList.forEach((feeType) => {
      const isFeeTypeOther = feeType.PK_Id == FeeTypes.Other;
      columns.push({
        fieldCaption: feeType.Name,
        fieldName: feeType.Name,
        fieldType: FieldTypeDefinition.Money,
        showTooltip: isFeeTypeOther,
        tooltipFieldName: isFeeTypeOther ? 'OtherToolTipText' : null
      });
    });
    columns.push({
      fieldCaption: 'Total',
      fieldName: 'Total',
      fieldType: FieldTypeDefinition.Money,
    });
    this.schoolFeeTableColumns = [...columns];
  }

  calculateTotal() {
    let totalDiscount = 0;
    this.feeTypesList.forEach(item => {
      if (item.PK_Id !== FeeTypes.Other) {
        totalDiscount += this.discountFeeModel[item.PK_Id]
      }
    });
    this.calculatedTotal = this.schoolFeeModel.Total - totalDiscount;
  }

  addCustomFee() {
    console.log('schoolFeeModel: ', this.schoolFeeModel);
    let customSchoolFee = new SchoolFeeModel;
    customSchoolFee.Description = null;
    customSchoolFee.FK_FeeType = FeeTypes.Other;
    customSchoolFee.FK_FeeType_Display = 'Other';
    customSchoolFee.Amount = 0;
    customSchoolFee.CreatedDate = moment().toDate();
    customSchoolFee.FK_School = this.model.PK_Id;
    customSchoolFee.FK_SchoolYear = this.selectedAwardDetermination.FK_SchoolYear;
    customSchoolFee.FK_GradeLevel = this.schoolFeeModel.List[0].FK_GradeLevel;
    customSchoolFee.FK_GradeLevel_To = this.schoolFeeModel.List[0].FK_GradeLevel_To;
    customSchoolFee.FK_Login_CreatedBy = this.gl.LoginId;
    this.customSchoolFeeList.push(customSchoolFee);
  }

  updateFeeTable() {
    //debugger;
    this.updateSchoolFeeList();
  }

  isValidSCF(): boolean {
    let answers: boolean = true;
    let validStartDate: boolean = this.startDate && this.startDate !== null;
    this.schoolEligibilitiesList.forEach(item => answers = answers && this.schoolEligibilitiesResponses[item.PK_Id]);
    return (validStartDate && answers && this.scfConfirmed);
  }

  submitSCF() {
    console.log('customSchoolFeeList: ', this.customSchoolFeeList);
    console.log('schoolFeeModel.List: ', this.schoolFeeModel.List);
    console.log('discountFeeModel: ', this.discountFeeModel);
    //save custom fees
    if (this.customSchoolFeeList.length > 0) {
      let responses = [];
      responses = this.customSchoolFeeList.map(schoolFee =>
        this.schoolService.insertSchoolFee(schoolFee));
      forkJoin(responses).subscribe(res => {
        if (res.length == this.customSchoolFeeList.length) {
          for (let i in res) {
            this.customSchoolFeeList[i].PK_Id = (res[i] as InsertResponse).Data;
          }
          let feeResponses = [];
          for (let schoolFee of this.customSchoolFeeList) {
            let model: SchoolCommitmentFeesLookUpModel = new SchoolCommitmentFeesLookUpModel();
            model.FK_SchoolFee = schoolFee.PK_Id;
            model.FK_SchoolCommitment = this.selectedRow.PK_Id;
            model.DiscountAmount = 0;
            feeResponses.push(this.schoolService.insertSchoolCommitmentFeesLookUp(model));
          }

          forkJoin(feeResponses).subscribe((res) => {
            if (res.length == feeResponses.length) {
              for (let i of (res as InsertResponse[])) {
                console.log('final response: ', i);
              }
            }
          });
        }
      });
    }

    //save normal fees
    let responses = [];
    for (let schoolFee of this.schoolFeeModel.List) {
      if (schoolFee.PK_Id > 0) {
        let model: SchoolCommitmentFeesLookUpModel = new SchoolCommitmentFeesLookUpModel();
        model.FK_SchoolFee = schoolFee.PK_Id;
        model.FK_SchoolCommitment = this.selectedRow.PK_Id;
        model.DiscountAmount = this.discountFeeModel[schoolFee.FK_FeeType];
        responses.push(this.schoolService.insertSchoolCommitmentFeesLookUp(model));
      }
    }
    forkJoin(responses).subscribe((res) => {
      if (res.length == responses.length) {

        //save eligibility responses
        let eligibilityResponses = [];
        for (let eligibility of this.schoolEligibilitiesList) {
          let eligibilityModel: SchoolEligibilitiesResponsModel = new SchoolEligibilitiesResponsModel();
          eligibilityModel.FK_School = this.model.PK_Id;
          eligibilityModel.FK_Guardian = this.selectedGuardian.PK_Id;
          eligibilityModel.FK_SchoolEligibility = eligibility.PK_Id;
          eligibilityModel.ValueBoolean = true;
          eligibilityModel.FK_SchoolYear = this.selectedRow.FK_SchoolYear;
          eligibilityResponses.push(this.schoolService.insertSchoolEligibilitiesRespons(eligibilityModel));
        }
        forkJoin(eligibilityResponses).subscribe(res => {

          //update School Commitment Status Type
          let schoolCommitmentModel: SchoolCommitmentModel = new SchoolCommitmentModel();
          schoolCommitmentModel.FK_SchoolCommitmentStatusType = 3;
          schoolCommitmentModel.PK_Id = (this.selectedRow as SchoolCommitmentModel).PK_Id;
          schoolCommitmentModel.FK_School = this.model.PK_Id;
          schoolCommitmentModel.FK_Student = (this.selectedRow as SchoolCommitmentModel).FK_Student;
          schoolCommitmentModel.FK_SchoolYear = (this.selectedRow as SchoolCommitmentModel).FK_SchoolYear;
          schoolCommitmentModel.StudentStartDate = this.startDate;
          this.schoolService.updateSchoolCommitment(schoolCommitmentModel).subscribe(res => {
            if (res.Success) {
              this.editViewDisplay = false;
              this.pageLoad();
            }
          });
        });
      }
    });
  }

  cancelSCF() {
    this.editViewDisplay = false;
  }
}
