import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScholarshipDisbursementCategoryEditBaseComponent } from './scholarshipDisbursementCategoryEdit.base';

@Component({
    selector: 'app-scholarshipDisbursementCategoryEdit',
    templateUrl: './scholarshipDisbursementCategoryEdit.component.html',
})
export class ScholarshipDisbursementCategoryEditComponent extends ScholarshipDisbursementCategoryEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
