import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolTypeListParams } from '../../models/entities/SchoolTypeModel.response';
import { SchoolTypeModel } from '../../models/entities/SchoolTypeModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolTypeListBaseComponent } from './schoolTypeList.base';

@Component({
	selector: 'app-schoolTypeList',
	templateUrl: './schoolTypeList.component.html',
})
export class SchoolTypeListComponent extends SchoolTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
