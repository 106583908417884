import { SchoolAccreditationsLookUpModel} from '../entities/SchoolAccreditationsLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolAccreditationsLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolAccreditationsLookUpModel = new SchoolAccreditationsLookUpModel();
}

export class SchoolAccreditationsLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolAccreditationsLookUpModel> = <Array<SchoolAccreditationsLookUpModel >>[]
}

export interface ISchoolAccreditationsLookUpListParams {
	pK_Id?:number;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	fK_Accreditation?:number;
	fK_Accreditation_display?:string;
	fK_Accreditation_like?:string;
	fK_Accreditation_fromValue?:number;
	fK_Accreditation_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolAccreditationsLookUpListParams implements ISchoolAccreditationsLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	fK_Accreditation?:number=null;
	fK_Accreditation_display?:string=null;
	fK_Accreditation_like?:string=null;
	fK_Accreditation_fromValue?:number=null;
	fK_Accreditation_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

