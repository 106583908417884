import {IListItem } from '../models';
export class PreAuthorizationModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_Vendor:number = 0;
	public FK_Vendor_Display:string = null;
	public CreatedDate:Date = null;
	public ServiceDescription:string = "";
	public EducationalDescription:string = "";
	public FK_StudentGradeLevelsSchoolYearsLookUp:number = 0;
	public Amount:number = 0;
	public FK_PreAuthorizationStatusType:number = 0;
	public FK_PreAuthorizationStatusType_Display:string = null;
	public DateApproved?:Date = null;
	public FK_Login_ApprovedBy?:string = null;
	public FK_Login_ApprovedBy_Display:string = null;
}
