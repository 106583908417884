<div *ngIf="modalEdit||newModalEdit" class="modal" [style.left]="leftOffset" [style.top]="topOffset">
  <app-scholarshipPaymentsDeadlineEdit [modalview]="true" [id]="modalEdit" [level]="(level+.5)" (saved)="modalEditSaved($event)" (cancelled)="modalEdit=null;newModalEdit=false"></app-scholarshipPaymentsDeadlineEdit>
</div>
<div class="card">
  <div class="card-header">
    Scholarship Payments Deadline List <a class="right"  (click)="newModalEdit = true" ><i class="fa fa-plus" title="Add Scholarship Payments Deadline"></i></a>
    <div><app-search-input [placeholdertext]="'Search'" (changed)="fuzzySearchChange($event)" ></app-search-input></div>

  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div style="grid-template-columns: 100px auto auto auto auto auto auto ;" class="table">
          <div class="table-header" style="text-align:left">
            <i (click)="selectAll()" title="Select All" class="fa" [ngClass]="{ 'fa-check-square-o':selectAllStatus,'fa-square-o':!selectAllStatus}"></i>
            <i title='Delete Many' (click)='deleteMany()' class='fa fa-trash icon-link'></i>
          </div>
          <div class="table-header" (click)="sortChange('Name')">Name <i *ngIf="search._sort=='Name'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_SchoolYear_Display')">School Year <i *ngIf="search._sort=='FK_SchoolYear_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_Scholarship_Display')">Scholarship <i *ngIf="search._sort=='FK_Scholarship_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('NotifyParentSchools')">Notify Parent Schools <i *ngIf="search._sort=='NotifyParentSchools'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('PaymentSent')">Payment Sent <i *ngIf="search._sort=='PaymentSent'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('VerificationReportsDue')">Verification Reports Due <i *ngIf="search._sort=='VerificationReportsDue'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>


          <div><i title="Filter" (click)="searchSelected()" class="fa fa-filter icon-link"></i><i title="Filter" (click)="clearSearch()" class="fa fa-times icon-link"></i></div>
          <div class="center"><input class="filter-list" placeholder="Search" [(ngModel)]="search.name_like" /></div>
          <div class="center"><input class="filter-list" placeholder="Search" [(ngModel)]="search.fK_SchoolYear_like" /></div>
          <div class="center"><input class="filter-list" placeholder="Search" [(ngModel)]="search.fK_Scholarship_like" /></div>
          <div class="center">
            <input class="filter-list" placeholder="From" [(ngModel)]="search.notifyParentSchools_fromDate" />
            <input class="filter-list" placeholder="To" [(ngModel)]="search.notifyParentSchools_toDate" />
          </div>
          <div class="center">
            <input class="filter-list" placeholder="From" [(ngModel)]="search.paymentSent_fromDate" />
            <input class="filter-list" placeholder="To" [(ngModel)]="search.paymentSent_toDate" />
          </div>
          <div class="center">
            <input class="filter-list" placeholder="From" [(ngModel)]="search.verificationReportsDue_fromDate" />
            <input class="filter-list" placeholder="To" [(ngModel)]="search.verificationReportsDue_toDate" />
          </div>


          <ng-container *ngFor="let x of model">
            <div>
              <span class='edit-tools'>
                <i (click)="x.Selected = !x.Selected" class="fa" [ngClass]="{ 'fa-check-square-o':x.Selected,'fa-square-o':!x.Selected}"></i>
                <a (click)="modalEdit = x.PK_Id"><i title='Edit' class='fa fa-pencil icon-link'></i></a> <i title='Delete' (click)='delete(x.PK_Id )' class='fa fa-trash icon-link'></i>
              </span>
            </div>
            <div>
              {{x.Name}}
            </div>
            <div>
              {{x.FK_SchoolYear_Display}}
            </div>
            <div>
              {{x.FK_Scholarship_Display}}
            </div>
            <div>
              {{gl.FormatDateShort(x.NotifyParentSchools)}}
            </div>
            <div>
              {{gl.FormatDateShort(x.PaymentSent)}}
            </div>
            <div>
              {{gl.FormatDateShort(x.VerificationReportsDue)}}
            </div>

          </ng-container>
        </div>

        <app-paging [showpagesize]="true" [data]="pagingData" (changed)="pagingChanged($event)"></app-paging>
      </div>
    </div>
  </div>
</div>

<app-spinner [showspinner]="ShowSpinner"></app-spinner>

