import { Component, OnInit, Directive } from '@angular/core';
import {  IZengineMappingLookUpListParams } from '../../models/entities/ZengineMappingLookUpModel.response';
import { ZengineMappingLookUpModel } from '../../models/entities/ZengineMappingLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { ZengineMappingLookUpListBaseComponent } from './zengineMappingLookUpList.base';

@Component({
	selector: 'app-zengineMappingLookUpList',
	templateUrl: './zengineMappingLookUpList.component.html',
})
export class ZengineMappingLookUpListComponent extends ZengineMappingLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
