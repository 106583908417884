import { Component, OnInit, Directive } from '@angular/core';
import { IScholarshipDisbursementCategoryListParams } from '../../models/entities/ScholarshipDisbursementCategoryModel.response';
import { ScholarshipDisbursementCategoryModel } from '../../models/entities/ScholarshipDisbursementCategoryModel';
import { PagingData } from '../../paging/paging.component';
import { ScholarshipDisbursementCategoryListBaseComponent } from './scholarshipDisbursementCategoryList.base';

@Component({
    selector: 'app-scholarshipDisbursementCategoryList',
    templateUrl: './scholarshipDisbursementCategoryList.component.html',
})
export class ScholarshipDisbursementCategoryListComponent extends ScholarshipDisbursementCategoryListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
