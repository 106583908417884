import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerificationStatusTypeEditBaseComponent } from './verificationStatusTypeEdit.base';

@Component({
	selector: 'app-verificationStatusTypeEdit',
	templateUrl: './verificationStatusTypeEdit.component.html',
})
export class VerificationStatusTypeEditComponent extends VerificationStatusTypeEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
