import { Component, OnInit } from '@angular/core';
import { BaseEditComponent } from '../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';
import { PasswordResetPost, RoleType } from '../models/models';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-first-time-login',
  templateUrl: './first-time-login.component.html',
})
export class FirstTimeLoginComponent
  extends BaseEditComponent
  implements OnInit {
  username: String = null;
  stepNumber = 0;
  agreements: boolean = false;
  roles: Array<number> = new Array<number>();

  constructor(
    r: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super(r);
    console.log(this, r, recaptchaV3Service);
    this.resetSession();
  }

  public model: PasswordResetPost = new PasswordResetPost();

  ngOnInit(): void {
    super.baseNgOnInit();
  }

  protected getData() {
    console.log(this.id);
    this.api.getUserByToken(this.id).subscribe((x) => {
      if (x.Success) {
        this.username = x.Data;
      } else {
        this.gl.GoTo('userlogin', null);
      }
    });
    this.api.getRolesByToken(this.id).subscribe((res) => {
      if (res.Success){
        res.Data.forEach((data) => {this.roles.push(data.FK_Role)});
      }
    });
    console.log('roles:::', this.roles)
  }

  validatePasswords(): boolean {
    return (
      this.model.Password &&
      this.model.Password.length > 0 &&
      this.model.Password2 &&
      this.model.Password2.length > 0 &&
      this.model.Password == this.model.Password2
    );
  }

  goToSoftwareTerms() {
    if (this.roles.includes(RoleType.ServiceProviderAdmin) || this.roles.includes(RoleType.ServiceProviderUser)){
      this.stepNumber = 2;
    } else {
      this.stepNumber = 1;
    }
  }

  validateAgreements(): boolean {
    return this.agreements;
  }

  submit() {
    var sub = this.recaptchaV3Service
      .execute('firstTimeLogin')
      .subscribe((token) => {
        this.submit2(token);
        sub.unsubscribe();
      });
  }

  private submit2(token: string) {
    var me = this;
    this.model.Token = this.id;
    var sub = this.api.doPasswordReset(this.model, token).subscribe((x) => {
      if (x.Success) {
        if (x.Data.Token) {
          me.gl.LoginRoles = x.Data.Roles;
          me.gl.LoginId = x.Data.Id;
          me.gl.LoginName = x.Data.Email;
          me.gl.LoginFullName = x.Data.FullName;
          me.gl.LoginTime = new Date();

          me.gl.Token = x.Data.Token;
          if (this.roles.includes(RoleType.ServiceProviderAdmin) || this.roles.includes(RoleType.ServiceProviderUser)){
            me.gl.GoTo('vendorprofile');
          } else {
            me.gl.GoTo('home', null, me.zone);
          }
        }
      }
      sub.unsubscribe();
    });
  }
}
