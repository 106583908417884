
<div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
  </div>
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1"><i (click)="clearFilter()" class="fa fa-remove"></i></span>
  </div>
  <input [(ngModel)]="text" (ngModelChange)="textChanged($event)" type="text" class="form-control small-input" style="min-width:100px" [placeholder]="_placeHolder" aria-label="Filter" aria-describedby="basic-addon1">

</div>
