import {IListItem } from '../models';
export class AmazonRequestModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_PreAuthorization:number = 0;
	public FK_PreAuthorization_Display:string = null;
	public ShoppingListUrl:string = "";
	public NumberOfItems:number = 0;
}
