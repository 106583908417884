<div class="pagination-panel row" *ngIf="_data">
  <div class="col-1">
    <select (change)="changePageSize($event.target.value)" [ngModel]="_data.PageSize" *ngIf="showpagesize" title="Page Size" class="form-control small-select">
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="500">500</option>
      <option value="1000">1000</option>
    </select>
  </div>

  <div class="col-11">
    <ul *ngIf="pagesList && pagesList.length" class="pagination pagination-sm">
      <li class="page-item">
        <a class="page-link" title="First Page" href="javascript:void(0)" (click)="changePage(1)"><i class="fa fa-angle-left"></i><i class="fa fa-angle-left"></i></a>
      </li>
      <li class="page-item">
        <a class="page-link" title="Previous Page" href="javascript:void(0)" (click)="changePage(_data.Page-1)"><i class="fa fa-angle-left"></i></a>
      </li>
      <li class="page-item" [ngClass]="{'active':p==_data.Page}" *ngFor="let p of pagesList; trackBy: trackByFn">
        <a class="page-link" title="Page" href="javascript:void(0)" (click)="changePage(p)">{{p}}</a>
      </li>
      <li class="page-item">
        <a class="page-link" title="Next Page"  href="javascript:void(0)" (click)="changePage(_data.Page+1)"><i class="fa fa-angle-right"></i></a>
      </li>
      <li class="page-item">
        <a class="page-link" title="Last Page" href="javascript:void(0)" (click)="changePage(maxPage)"><i class="fa fa-angle-right"></i><i class="fa fa-angle-right"></i></a>
      </li>
    </ul>
  </div>

</div>
