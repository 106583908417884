<div class="card">
  <div class="card-header">
    Student <span class="right">
  <a title="Cancel" *ngIf="!ModalView" routerLink="/studentlist"><i class="fa fa-undo icon-link"></i></a>
  <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
  <i class="fa fa-save icon-link" (click)="save()"></i>
</span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        								<div class="col-6">
									<div class="form-group">
										<label>Created Date</label><span title="Required" class="required">*</span>
<app-date-picker [(model)]="model.CreatedDate"></app-date-picker>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>First Name</label><span title="Required" class="required">*</span>
										<input [(ngModel)]="model.FirstName" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Last Name</label><span title="Required" class="required">*</span>
										<input [(ngModel)]="model.LastName" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Gender Type</label><span title="Required" class="required">*</span>
										<select [(ngModel)]="model.FK_GenderType" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_GenderType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Race Type</label>
										<select [(ngModel)]="model.FK_RaceType" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_RaceType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Birth Date</label>
<app-date-picker [(model)]="model.BirthDate"></app-date-picker>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Social Security Number</label>
										<input [(ngModel)]="model.SocialSecurityNumber" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Middle Initial</label>
										<input [(ngModel)]="model.MiddleInitial" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label></label>
									<app-studentDisabilityLookUpCheckBoxListByStudent (saved)="childComponentSaved()"  [commit]="commit_StudentDisabilityLookUp" [id]="model.PK_Id"></app-studentDisabilityLookUpCheckBoxListByStudent>
									</div>
								</div>


      </div>
      

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
