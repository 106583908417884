import {IListItem } from '../models';
export class ZengineMappingLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public FK_State:number = 0;
	public FK_State_Display:string = null;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public FK_ZengineMappingOption:number = 0;
	public FK_ZengineMappingOption_Display:string = null;
	public FK_ZengineFormField:number = 0;
	public FK_ZengineFormField_Display:string = null;
	public PK_Id:number = 0;
	public FK_ZengineForm:number = 0;
	public FK_ZengineForm_Display:string = null;
	public FK_Scholarship?:number = null;
	public FK_Scholarship_Display:string = null;
}
