import {IListItem } from '../models';
export class GuardianModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public FK_GuardianType:number = 0;
	public FK_GuardianType_Display:string = null;
	public PK_Id:number = 0;
	public FK_Login:string = "";
	public FK_Login_Display:string = null;
	public FK_Household:number = 0;
	public FirstName:string = "";
	public MiddleInitial?:string = null;
	public LastName?:string = null;
	public HomePhone?:string = null;
	public CellPhone?:string = null;
	public PaymentBankName?:string = null;
	public PaymentBankRouting?:string = null;
	public PaymentBankAccount?:string = null;
	public Employer?:string = null;
	public WorkPhone?:string = null;
	public InviteEmailSent?:Date = null;
	public InviteEmailAccepted?:Date = null;
}
