import { Component, OnInit, Directive } from '@angular/core';
import {  IAwardDeterminationStatusTypeListParams } from '../../models/entities/AwardDeterminationStatusTypeModel.response';
import { AwardDeterminationStatusTypeModel } from '../../models/entities/AwardDeterminationStatusTypeModel';
import { PagingData } from '../../paging/paging.component';
import { AwardDeterminationStatusTypeListBaseComponent } from './awardDeterminationStatusTypeList.base';

@Component({
	selector: 'app-awardDeterminationStatusTypeList',
	templateUrl: './awardDeterminationStatusTypeList.component.html',
})
export class AwardDeterminationStatusTypeListComponent extends AwardDeterminationStatusTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
