import { Component, OnInit } from '@angular/core';
import { ITableColumnDefinition } from '../../../interfaces/table-column-definition-interface';
import { FieldTypeDefinition } from '../../../types/field-type-definition-type';
import { aaaAPIAdminService } from '../../../services/aaa.API.admin.service';
import { ScholarshipModel } from '../../../models/entities/ScholarshipModel';
import { BaseComponent } from '../../../BaseComponent';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { StateModel } from '../../../models/entities/StateModel';
import { SchoolYearModel } from '../../../models/entities/SchoolYearModel';
import { forkJoin, Subscription, timer } from 'rxjs';
import { CONSTANTS } from '../../../constants/constants';
import { StateList } from '../../../models/entities/StateModel.response';
import { SchoolYearList } from '../../../models/entities/SchoolYearModel.response';

@Component({
  selector: 'app-admin-scholarship-settings-page',
  templateUrl: './admin-scholarship-settings-page.component.html',
  styleUrls: ['./admin-scholarship-settings-page.component.scss']
})
export class AdminScholarshipSettingsPageComponent extends BaseComponent implements OnInit {
  statesOptions: SelectItem[];
  stateModel: StateModel = null;
  schoolYearOptions: SelectItem[];
  schoolYearModel: SchoolYearModel = null;

  scholarshipsSearchText: string = null;
  scholarshipsActive: boolean = true;
  scholarshipsTableData: any[] = [];
  scholarshipsTableColumns: ITableColumnDefinition[] = [];
  scholarshipsTableLoading: boolean = false;
  scholarshipsTableTotalRecords: number = null;
  scholarshipsList: ScholarshipModel[] = [];

  lastLazyLoadEvent: LazyLoadEvent = null;
  lastSearchText: string = null;
  rowSelected: any = null;
  searchSubscription: Subscription;

  constructor(private adminService: aaaAPIAdminService) {
    super(null, false);
  }

  ngOnInit(): void {
    forkJoin([
      this.adminService.getStateList({ _sort: 'Name', active: true }),
      this.adminService.getSchoolYearList({ _sort: 'Name' })
    ]).subscribe(responses => {
      const statesResponse: StateList = responses[0] as StateList;
      const schoolYearResponse: SchoolYearList = responses[1] as SchoolYearList;

      this.statesOptions = [
        {
          label: 'All States',
          value: null
        },
        ...statesResponse.Data.map(item => {
          return {
            label: item.ListDisplay,
            value: item,
          } as SelectItem;
        })
      ];
      this.schoolYearOptions = [
        {
          label: 'All School Years',
          value: null
        },
        ...schoolYearResponse.Data.map(item => {
          return {
            label: item.ListDisplay,
            value: item
          } as SelectItem;
        })
      ];
      this.loadScholarshipsData(CONSTANTS.FirstLazyLoadEvent);
    });
  }

  onSearchScholarshipsTextChanged() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchSubscription = source
      .subscribe(() => {
        this.loadScholarshipsData(this.lastLazyLoadEvent);
        this.searchSubscription.unsubscribe();
      });
  }

  onStateChanged() {
    this.loadScholarshipsData(this.lastLazyLoadEvent);
  }

  onSchoolYearChanged() {
    this.loadScholarshipsData(this.lastLazyLoadEvent);
  }

  doScholarshipShowProfile(event) {
    console.log(event);
    this.gl.GoTo(`scholarship/${event.PK_Id}`, null, null);
  }

  private generateScholarshipsTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'State',
      fieldName: 'StateAbbreviation',
      fieldType: FieldTypeDefinition.String,
      width: '20%',
    });
    columns.push({
      fieldCaption: 'Scholarship Name',
      fieldName: 'Name',
      fieldType: FieldTypeDefinition.String,
      sortable: true
    });
    this.scholarshipsTableColumns = [...columns];
  }

  private generateScholarshipsTableData() {
    this.generateScholarshipsTableColumns();
    this.scholarshipsTableData = this.scholarshipsList.map(item => {
      return {
        'PK_Id': item.PK_Id,
        'FK_State': item.FK_State,
        'StateAbbreviation': null,
        'Name': item.ListDisplay
      };
    });
    this.scholarshipsTableData.forEach(item => {
      const state = this.statesOptions.find(elem => elem.value && elem.value.PK_Id == item.FK_State).value as StateModel;
      item.StateAbbreviation = state.Abbreviation;
    });
    this.scholarshipsTableLoading = false;
  }

  loadScholarshipsData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.scholarshipsTableLoading = true;
      const page = (this.scholarshipsSearchText !== this.lastSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.scholarshipsSearchText && this.scholarshipsSearchText.trim().length > 0) ? this.scholarshipsSearchText : null;
      const state = (this.stateModel) ? this.stateModel.PK_Id : null;
      const schoolYear = (this.schoolYearModel) ? this.schoolYearModel.PK_Id : null;;
      this.lastSearchText = this.scholarshipsSearchText;

      this.adminService.getScholarshipList({ _fuzzySearch: searchText, fK_State: state, fK_SchoolYear: schoolYear, _sort: sortField, _sortDesc: sortFieldDesc, _page: page, _pageSize: pageSize })
        .subscribe(responseScholarships => {
          this.scholarshipsTableTotalRecords = responseScholarships.RecordsCount;
          this.scholarshipsList = responseScholarships.Data;
          this.generateScholarshipsTableData();
        });
    }
  }
}
