import { ZengineMappingOptionModel} from '../entities/ZengineMappingOptionModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ZengineMappingOptionResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ZengineMappingOptionModel = new ZengineMappingOptionModel();
}

export class ZengineMappingOptionList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ZengineMappingOptionModel> = <Array<ZengineMappingOptionModel >>[]
}

export interface IZengineMappingOptionListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ZengineMappingOptionListParams implements IZengineMappingOptionListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

