import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolTransactionListParams } from '../../models/entities/SchoolTransactionModel.response';
import { SchoolTransactionModel } from '../../models/entities/SchoolTransactionModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolTransactionListBaseComponent } from './schoolTransactionList.base';

@Component({
	selector: 'app-schoolTransactionList',
	templateUrl: './schoolTransactionList.component.html',
})
export class SchoolTransactionListComponent extends SchoolTransactionListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
