import { Component, OnInit, Directive } from '@angular/core';
import { ISchoolScholarshipsLookUpListParams,SchoolScholarshipsLookUpListParams } from '../../models/entities/SchoolScholarshipsLookUpModel.response';
import { SchoolScholarshipsLookUpModel} from '../../models/entities/SchoolScholarshipsLookUpModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class SchoolScholarshipsLookUpListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<SchoolScholarshipsLookUpModel> = <Array<SchoolScholarshipsLookUpModel>>[];
	public search: SchoolScholarshipsLookUpListParams = new SchoolScholarshipsLookUpListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getSchoolScholarshipsLookUpList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.pK_Id  = null;
		this.search.fK_School  = null;
		this.search.fK_School_display  = null;
		this.search.fK_School_like  = null;
		this.search.fK_School_fromValue  = null;
		this.search.fK_School_toValue  = null;
		this.search.fK_Scholarship  = null;
		this.search.fK_Scholarship_display  = null;
		this.search.fK_Scholarship_like  = null;
		this.search.fK_Scholarship_fromValue  = null;
		this.search.fK_Scholarship_toValue  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deleteSchoolScholarshipsLookUp(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManySchoolScholarshipsLookUp(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
