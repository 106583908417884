import { Component, OnInit, Directive } from '@angular/core';
import {  IAwardDeterminationStatusLookUpListParams } from '../../models/entities/AwardDeterminationStatusLookUpModel.response';
import { AwardDeterminationStatusLookUpModel } from '../../models/entities/AwardDeterminationStatusLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { AwardDeterminationStatusLookUpListBaseComponent } from './awardDeterminationStatusLookUpList.base';

@Component({
	selector: 'app-awardDeterminationStatusLookUpList',
	templateUrl: './awardDeterminationStatusLookUpList.component.html',
})
export class AwardDeterminationStatusLookUpListComponent extends AwardDeterminationStatusLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
