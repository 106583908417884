import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/BaseComponent';
import { ContactModel } from 'src/app/models/entities/ContactModel';
import { LoginModel } from 'src/app/models/entities/LoginModel';
import { LoginRolesLookUpModel } from 'src/app/models/entities/LoginRolesLookUpModel';
import { RoleModel } from 'src/app/models/entities/RoleModel';
import { ManagerResponse, RoleType } from 'src/app/models/models';
import { aaaAPIAdminService } from 'src/app/services/aaa.API.admin.service';

@Component({
  selector: 'app-admin-user-profile-page',
  templateUrl: './admin-user-profile-page.component.html',
  styleUrls: ['./admin-user-profile-page.component.scss']
})
export class AdminUserProfilePageComponent extends BaseComponent implements OnInit {
  usersTypeList: RoleModel[] = [];
  userTypeOptions: SelectItem[] = [];
  userTypeSelected: RoleModel = null;
  userTypeModel: RoleModel = null;
  userLoginRolesId: number[] = [];
  model: LoginModel = new LoginModel;
  contactModel: ContactModel = new ContactModel;
  editModel: LoginModel = new LoginModel;
  editContactModel: ContactModel = new ContactModel;

  editUserViewDisplay: boolean = false;
  private userId: string;

  constructor(
    private adminService: aaaAPIAdminService,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute, false);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .subscribe(param => {
        this.userId = param.get('id');
        this.loadData();
      });
  }

  get isEnabledUser(): boolean {
    if (this.editModel) {
      return this.editModel.IsEnabled;
    }
    return false;
  }

  private getUserLoginRoles() {
    this.adminService.getLoginRolesLookUpList({ fK_Login: this.userId })
      .subscribe(responseLoginRoles => {
        if (responseLoginRoles.Data.length > 0) {
          const loginRol = responseLoginRoles.Data[0];
          this.userTypeModel = this.usersTypeList.find(item => item.PK_Id == loginRol.FK_Role);
          this.userLoginRolesId = responseLoginRoles.Data.map(item => item.PK_Id);
        } else {
          this.userTypeModel = this.usersTypeList.find(item => item.PK_Id == RoleType.Admin);
        }
      });
  }

  private loadData() {
    this.adminService.getRoleList({})
      .subscribe(responseRoles => {
        this.usersTypeList = responseRoles.Data;
        this.userTypeOptions = this.usersTypeList.map(item => {
          return {
            label: item.Name,
            value: item,
          } as SelectItem;
        });
        this.userTypeSelected = this.userTypeOptions[0].value as RoleModel;
        this.adminService.getLogin(this.userId)
          .subscribe(loginResponse => {
            if (loginResponse.Success) {
              this.model = loginResponse.Data;
            }
          });
        this.adminService.getContactList({ fK_Login: this.userId })
          .subscribe(contactResponse => {
            if (contactResponse.Data.length > 0) {
              this.contactModel = contactResponse.Data[0];
            }
          });
        this.getUserLoginRoles();
      });
  }

  doUserEdit() {
    this.editModel = { ... this.model };
    this.editContactModel = { ... this.contactModel };
    this.userTypeSelected = { ...this.userTypeModel };
    this.editUserViewDisplay = true;
  }

  isValidContact(): boolean {
    const model = this.editContactModel;
    return (
      this.userTypeSelected &&
      model.FirstName && model.FirstName.trim().length > 0 &&
      model.LastName && model.LastName.trim().length > 0 &&
      model.Title && model.Title.trim().length > 0 &&
      model.Email && model.Email.trim().length > 0
    );
  }

  saveUser() {
    this.editModel.Email = this.editContactModel.Email;
    this.editContactModel.FK_Login = this.editModel.PK_Id;
    const contactExist = this.editContactModel.PK_Id && this.editContactModel.PK_Id > 0;
    let loginRoleLookup: LoginRolesLookUpModel = new LoginRolesLookUpModel;
    loginRoleLookup.FK_Login = this.editModel.PK_Id;
    loginRoleLookup.FK_Role = this.userTypeSelected.PK_Id;
    const endpointCalls = [
      this.adminService.updateLogin(this.editModel),
      (contactExist) ?
        this.adminService.updateContact(this.editContactModel) :
        this.adminService.insertContact(this.editContactModel),
      this.adminService.deleteManyLoginRolesLookUp(this.userLoginRolesId),
      this.adminService.insertLoginRolesLookUp(loginRoleLookup)
    ];
    forkJoin(endpointCalls)
      .subscribe(responses => {
        if (responses.length == 4) {
          const loginResponse = responses[0] as ManagerResponse;
          const contactResponse = responses[1] as ManagerResponse;
          if (loginResponse.Success) {
            this.model = { ...this.editModel };
          }
          if (contactResponse.Success) {
            this.contactModel = { ... this.editContactModel };
          }
          this.userTypeModel = { ...this.userTypeSelected };
          this.getUserLoginRoles();
          this.editUserViewDisplay = false;
        }
      });
  }

  cancelUser() {
    this.editUserViewDisplay = false;
  }
}
