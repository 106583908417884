import { AwardAcceptanceStatusTypeModel} from '../entities/AwardAcceptanceStatusTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class AwardAcceptanceStatusTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: AwardAcceptanceStatusTypeModel = new AwardAcceptanceStatusTypeModel();
}

export class AwardAcceptanceStatusTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<AwardAcceptanceStatusTypeModel> = <Array<AwardAcceptanceStatusTypeModel >>[]
}

export interface IAwardAcceptanceStatusTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class AwardAcceptanceStatusTypeListParams implements IAwardAcceptanceStatusTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

