import { Component, OnInit, Directive } from '@angular/core';
import { IAwardDeterminationListParams,AwardDeterminationListParams } from '../../models/entities/AwardDeterminationModel.response';
import { AwardDeterminationModel} from '../../models/entities/AwardDeterminationModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class AwardDeterminationListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<AwardDeterminationModel> = <Array<AwardDeterminationModel>>[];
	public search: AwardDeterminationListParams = new AwardDeterminationListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getAwardDeterminationList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.createdDate  = null;
		this.search.createdDate_fromDate  = null;
		this.search.createdDate_toDate  = null;
		this.search.fK_Guardian  = null;
		this.search.fK_Guardian_display  = null;
		this.search.fK_Guardian_like  = null;
		this.search.fK_Guardian_fromValue  = null;
		this.search.fK_Guardian_toValue  = null;
		this.search.fK_Scholarship  = null;
		this.search.fK_Scholarship_display  = null;
		this.search.fK_Scholarship_like  = null;
		this.search.fK_Scholarship_fromValue  = null;
		this.search.fK_Scholarship_toValue  = null;
		this.search.fK_Student  = null;
		this.search.fK_Student_display  = null;
		this.search.fK_Student_like  = null;
		this.search.fK_Student_fromValue  = null;
		this.search.fK_Student_toValue  = null;
		this.search.fK_SchoolYear  = null;
		this.search.fK_SchoolYear_display  = null;
		this.search.fK_SchoolYear_like  = null;
		this.search.fK_SchoolYear_fromValue  = null;
		this.search.fK_SchoolYear_toValue  = null;
		this.search.fK_GradeLevel  = null;
		this.search.fK_GradeLevel_display  = null;
		this.search.fK_GradeLevel_like  = null;
		this.search.fK_GradeLevel_fromValue  = null;
		this.search.fK_GradeLevel_toValue  = null;
		this.search.pK_Id  = null;
		this.search.wasNotificationSent  = null;
		this.search.wasNotificationSent_booleanValue  = null;
		this.search.isDeleted  = null;
		this.search.isDeleted_booleanValue  = null;
		this.search.fK_Login_CreatedBy  = null;
		this.search.fK_Login_CreatedBy_display  = null;
		this.search.fK_Login_CreatedBy_like  = null;
		this.search.awardAmount  = null;
		this.search.awardAmount_fromValue  = null;
		this.search.awardAmount_toValue  = null;
		this.search.determinationReason  = null;
		this.search.determinationReason_like  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deleteAwardDetermination(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManyAwardDetermination(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
