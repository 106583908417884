import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolEligibilitiesStateLookUpEditBaseComponent } from './schoolEligibilitiesStateLookUpEdit.base';

@Component({
	selector: 'app-schoolEligibilitiesStateLookUpEdit',
	templateUrl: './schoolEligibilitiesStateLookUpEdit.component.html',
})
export class SchoolEligibilitiesStateLookUpEditComponent extends SchoolEligibilitiesStateLookUpEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
