import {IListItem } from '../models';
export class SchoolAccreditationsLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_School:number = 0;
	public FK_School_Display:string = null;
	public FK_Accreditation:number = 0;
	public FK_Accreditation_Display:string = null;
}
