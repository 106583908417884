import { ContactModel} from '../entities/ContactModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ContactResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ContactModel = new ContactModel();
}

export class ContactList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ContactModel> = <Array<ContactModel >>[]
}

export interface IContactListParams {
	pK_Id?:number;
	firstName?:string;
	firstName_like?:string;
	lastName?:string;
	lastName_like?:string;
	title?:string;
	title_like?:string;
	phone?:string;
	phone_like?:string;
	email?:string;
	email_like?:string;
	fK_Login?:string;
	fK_Login_display?:string;
	fK_Login_like?:string;
	extension?:number;
	extension_fromValue?:number;
	extension_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ContactListParams implements IContactListParams, ISearchModel  {
	pK_Id?:number=null;
	firstName?:string=null;
	firstName_like?:string=null;
	lastName?:string=null;
	lastName_like?:string=null;
	title?:string=null;
	title_like?:string=null;
	phone?:string=null;
	phone_like?:string=null;
	email?:string=null;
	email_like?:string=null;
	fK_Login?:string=null;
	fK_Login_display?:string=null;
	fK_Login_like?:string=null;
	extension?:number=null;
	extension_fromValue?:number=null;
	extension_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

