import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { Subscription, timer } from 'rxjs';
import { BaseComponent } from 'src/app/BaseComponent';
import { CONSTANTS } from 'src/app/constants/constants';
import { ITableColumnDefinition } from 'src/app/interfaces/table-column-definition-interface';
import { StateModel } from 'src/app/models/entities/StateModel';
import { VendorModel } from 'src/app/models/entities/VendorModel';
import { aaaAPIAdminService } from 'src/app/services/aaa.API.admin.service';
import { FieldTypeDefinition } from 'src/app/types/field-type-definition-type';

@Component({
  selector: 'app-admin-vendors-page',
  templateUrl: './admin-vendors-page.component.html',
  styleUrls: ['./admin-vendors-page.component.scss']
})
export class AdminVendorsPageComponent extends BaseComponent implements OnInit {
  vendorsSearchText: string = null;
  vendorsTableData: any[] = [];
  vendorsTableColumns: ITableColumnDefinition[] = [];
  vendorsTableLoading: boolean = false;
  vendorsTableTotalRecords: number = null;
  vendorsList: VendorModel[] = [];
  stateList: StateModel[] = [];
  stateOptions: SelectItem[] = [];
  stateSelected: StateModel = null;

  lastLazyLoadEvent: LazyLoadEvent = null;
  lastSearchText: string = null;
  rowSelected: any = null;

  searchSubscription: Subscription;

  constructor(
    private adminService: aaaAPIAdminService
  ) {
    super(null, false);
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.generateVendorsTableColumns();
    this.adminService.getStateList({ active: true })
      .subscribe(responseStates => {
        this.stateList = [...responseStates.Data];
        this.stateOptions = [
          {
            label: 'All States',
            value: null
          },
          ...this.stateList.map(item => {
            return {
              label: item.Name,
              value: item,
            } as SelectItem;
          })
        ];
        this.loadVendorData(CONSTANTS.FirstLazyLoadEvent);
      });
  }

  private generateVendorsTableColumns() {
    this.vendorsTableColumns = [];
    this.vendorsTableColumns.push({
      fieldCaption: 'Status',
      fieldName: 'StatusIcon',
      fieldType: FieldTypeDefinition.FontAwesomeIcon,
      showTooltip: true,
      tooltipFieldName: 'StatusDescription'
    });
    this.vendorsTableColumns.push({
      fieldCaption: 'Vendor Name',
      fieldName: 'Name',
      fieldType: FieldTypeDefinition.String,
      sortable: true
    });
    this.vendorsTableColumns.push({
      fieldCaption: 'Address',
      fieldName: 'Address',
      fieldType: FieldTypeDefinition.String
    });
    this.vendorsTableColumns.push({
      fieldCaption: 'City',
      fieldName: 'City',
      fieldType: FieldTypeDefinition.String
    });
    this.vendorsTableColumns.push({
      fieldCaption: 'State',
      fieldName: 'State',
      fieldType: FieldTypeDefinition.String
    });
    this.vendorsTableColumns.push({
      fieldCaption: 'Categories',
      fieldName: 'VendorCategoriesText',
      fieldType: FieldTypeDefinition.String
    });
    this.vendorsTableColumns.push({
      fieldCaption: 'Phone Number',
      fieldName: 'PhoneNumber',
      fieldType: FieldTypeDefinition.String
    });
    this.vendorsTableColumns.push({
      fieldCaption: 'Contact Name',
      fieldName: 'ContactName',
      fieldType: FieldTypeDefinition.String
    });
  }

  private generateVendorsTableData() {
    this.vendorsTableData = this.vendorsList.map(item => {
      const icon = `fas fa-2x ${(!item.IsDeleted) ? 'fa-check-circle icon-green' : 'fa-times-circle icon-red'}`;
      return {
        'PK_Id': item.PK_Id,
        'Status': true,
        'StatusIcon': icon,
        'StatusDescription': item.IsDeleted ? 'Enabled' : 'Disabled',
        'Name': item.Name,
        'Address': (item.Physical_StreetAddress2 && item.Physical_StreetAddress2.trim().length > 0) ? `${item.Physical_StreetAddress1} ${item.Physical_StreetAddress2}` : item.Physical_StreetAddress1,
        'City': item.Physical_City,
        'State': (item.FK_State_VendorPhysical) ? this.stateList.find(s => s.PK_Id == item.FK_State_VendorPhysical).Name : '',
        'VendorCategories': null,
        'VendorCategoriesText': null,
        'PhoneNumber': item.Phone,
        'ContactName': (item.ContactModel) ? (item.ContactModel.LastName) ? `${item.ContactModel.FirstName} ${item.ContactModel.LastName}` : item.ContactModel.FirstName : ''
      };
    });
    this.vendorsTableData.forEach(item => {
      this.adminService.getVendorCategoriesLookUpList({ fK_Vendor: item['PK_Id'] })
        .subscribe(responseVendorCategories => {
          if (responseVendorCategories.Data.length > 0) {
            item['VendorCategories'] = responseVendorCategories.Data;
            item['VendorCategoriesText'] = responseVendorCategories.Data.map(vc => vc.FK_ScholarshipDisbursementCategory_Display).join(", ");
          }
        });
    });
    this.vendorsTableLoading = false;
  }

  loadVendorData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.vendorsTableLoading = true;
      const page = (this.vendorsSearchText !== this.lastSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.vendorsSearchText && this.vendorsSearchText.trim().length > 0) ? this.vendorsSearchText : null;
      const state = (this.stateSelected) ? this.stateSelected.PK_Id : null;
      this.lastSearchText = this.vendorsSearchText;

      this.adminService.doSearchVendorList(searchText, state, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseLogins => {
          this.vendorsTableTotalRecords = responseLogins.RecordsCount;
          this.vendorsList = responseLogins.Data;
          this.generateVendorsTableData();
        });
    }
  }

  onVendorTableRowClick(row: any) {
    console.log(row);
    /*
    const contactModel = row['FK_Contact'] as ContactModel;
    if (contactModel) {
      this.gl.SelectedUser = `${contactModel.FirstName} ${contactModel.LastName}`;
    } else {
      this.gl.SelectedUser = 'N/A';
    }
    this.gl.GoTo(`adminuserprofile/${row['PK_Id']}`);
    */
    this.gl.GoTo(`adminvendorprofile/${row['PK_Id']}`);
  }

  onSearchTextChanged() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchSubscription = source
      .subscribe(() => {
        this.loadVendorData(this.lastLazyLoadEvent);
        this.searchSubscription.unsubscribe();
      });
  }

  onStateChanged() {
    this.loadVendorData(this.lastLazyLoadEvent);
  }
}
