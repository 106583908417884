<div class="card">
  <div class="card-header">
    Verification <span class="right">
      <a title="Cancel" *ngIf="!ModalView" routerLink="/verificationlist"><i class="fa fa-undo icon-link"></i></a>
      <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
      <i class="fa fa-save icon-link" (click)="save()"></i>
    </span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Student Grade Levels School Years Look Up</label><span title="Required" class="required">*</span>
            <input [(ngModel)]="model.FK_StudentGradeLevelsSchoolYearsLookUp" type="text" class="form-control">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Award Determination</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_AwardDetermination" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_AwardDetermination_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Grading Period Type</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_GradingPeriodType" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_GradingPeriodType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Number Of Absences</label><span title="Required" class="required">*</span>
            <input [(ngModel)]="model.NumberOfAbsences" type="text" class="form-control">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
<span title="Required" class="required">*</span>
            <app-checkbox [(model)]="model.HouseholdCurrent" [label]="'Household Current'"></app-checkbox>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
<span title="Required" class="required">*</span>
            <app-checkbox [(model)]="model.StillEnrolled" [label]="'Still Enrolled'"></app-checkbox>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Verification Status Type</label>
            <select [(ngModel)]="model.FK_VerificationStatusType" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_VerificationStatusType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
