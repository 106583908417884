import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolCommitmentListParams } from '../../models/entities/SchoolCommitmentModel.response';
import { SchoolCommitmentModel } from '../../models/entities/SchoolCommitmentModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolCommitmentListBaseComponent } from './schoolCommitmentList.base';

@Component({
	selector: 'app-schoolCommitmentList',
	templateUrl: './schoolCommitmentList.component.html',
})
export class SchoolCommitmentListComponent extends SchoolCommitmentListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
