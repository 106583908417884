import { Component, OnInit, Directive } from '@angular/core';
import {  IScholarshipHandbookListParams } from '../../models/entities/ScholarshipHandbookModel.response';
import { ScholarshipHandbookModel } from '../../models/entities/ScholarshipHandbookModel';
import { PagingData } from '../../paging/paging.component';
import { ScholarshipHandbookListBaseComponent } from './scholarshipHandbookList.base';

@Component({
	selector: 'app-scholarshipHandbookList',
	templateUrl: './scholarshipHandbookList.component.html',
})
export class ScholarshipHandbookListComponent extends ScholarshipHandbookListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
