import {IListItem } from '../models';
export class SchoolModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public FK_County_Physical:number = 0;
	public FK_County_Physical_Display:string = null;
	public Name:string = "";
	public PK_Id:number = 0;
	public FK_State_Physical:number = 0;
	public FK_State_Physical_Display:string = null;
	public Physical_Zipcode?:string = null;
	public IsDeleted?:boolean = null;
	public Phone?:string = null;
	public District?:string = null;
	public Mailing_StreetAddress1?:string = null;
	public Mailing_StreetAddress2?:string = null;
	public Mailing_City?:string = null;
	public FK_State_MailingAddress?:number = null;
	public FK_State_MailingAddress_Display:string = null;
	public Mailing_Zipcode?:string = null;
	public FK_County_Mailing?:number = null;
	public FK_County_Mailing_Display:string = null;
	public Physical_StreetAddress1?:string = null;
	public Physical_StreetAddress2?:string = null;
	public Physical_City?:string = null;
	public DOECode?:string = null;
	public FaxNumber?:string = null;
	public NonProfit?:boolean = null;
	public Religious?:boolean = null;
	public ReligiousAffiliation?:string = null;
	public GenderServed?:string = null;
	public EmailAddress?:string = null;
	public WebsiteURL?:string = null;
	public CreatedDate?:Date = null;
	public FK_Login_CreatedBy?:string = null;
	public FK_Login_CreatedBy_Display:string = null;
	public PaymentBankName?:string = null;
	public PaymentBankRouting?:string = null;
	public PaymentBankAccount?:string = null;
	public PaymentAddress1?:string = null;
	public PaymentAddress2?:string = null;
	public PaymentCity?:string = null;
	public FK_State_Payment?:number = null;
	public FK_State_Payment_Display:string = null;
	public PaymentZipcode?:string = null;
	public PaymentPayTo?:string = null;
	public FK_SchoolType?:number = null;
	public FK_SchoolType_Display:string = null;
}
