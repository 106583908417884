<div>
  <div class="row">
    <div class="col-3">
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 1"></i>
          <span (click)="doChangePage(1)" class="btn font-weight-bold">Family Information</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 2"></i>
          <span (click)="doChangePage(2)" class="btn font-weight-bold">Document Center</span>
        </div>
      </div>
      <div class="row" *ngIf="haveGardinerStudents">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 3"></i>
          <span (click)="doChangePage(3)" class="btn font-weight-bold">Bank Account</span>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div *ngIf="pageNumber == 1" class="form">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2 class="text-header">Family Profile</h2>
            <button type="button" class="btn btn-primary" (click)="doFamilyProfileEdit()">
              <i class="fas fa-pen"></i> Edit
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <h3 class="text-subtitle">Contact Information</h3>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <div class="form-group">
              <div class="no-padding">
                <span class="form-control no-border">{{ model?.FirstName }} {{ model?.LastName }}</span>
                <span class="form-control no-border">{{ emailAddress }}</span>
                <span class="form-control no-border">{{ model?.CellPhone }} / {{ model?.WorkPhone }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row p-1 mt-3">
          <div class="col-12">
            <h2 class="text-subtitle">Home Address</h2>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <div class="form-group">
              <div class="no-padding">
                <span class="form-control no-border">{{ houseHoldModel?.Address1 }}</span>
                <span class="form-control no-border" *ngIf="houseHoldModel?.Address2">
                  {{ houseHoldModel?.Address2 }}
                </span>
                <span class="form-control no-border">
                  {{ houseHoldModel?.City }}, {{ houseHoldModel?.FK_State_Display }} {{ houseHoldModel?.Zipcode }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row p-1 mt-3">
          <div class="col-12">
            <h2 class="text-subtitle">Mailing Address</h2>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <div class="form-group">
              <span class="form-control no-border">
                My Mailing Address is the same as my Home Address
              </span>
            </div>
          </div>
        </div>

        <p-sidebar [(visible)]="editViewDisplay" position="right" [style]="{width:'60%'}" [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class=" row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h2 class="text-header">Update Profile</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <h3 class="text-subtitle">Contact Information</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">First Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.FirstName" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Last Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.LastName" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Email Address<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="emailAddress" [disabled]="true" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">
                    Primary Phone Number<span class="text-danger">*</span>
                  </label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.CellPhone" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Secondary Phone Number</label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.WorkPhone" />
                </div>
              </div>
            </div>
            <div class="row p-1 mt-3">
              <div class="col-12">
                <h2 class="text-subtitle">Home Address</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Address Line 1<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editHouseHoldModel.Address1" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Address Line 2</label>
                  <input type="text" class="form-control" [(ngModel)]="editHouseHoldModel.Address2" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">City<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editHouseHoldModel.City" />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label class="form-label font-weight-bold">State<span class="text-danger">*</span></label>
                  <p-dropdown [options]="stateOptions" class="form-control no-border"
                    [(ngModel)]="editHouseHoldModel.FK_State">
                  </p-dropdown>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label class="form-label font-weight-bold">ZIP Code<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editHouseHoldModel.Zipcode" />
                </div>
              </div>
            </div>
            <div class="row p-1 mt-3">
              <div class="col-12">
                <h2 class="text-subtitle">Mailing Address</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="d-flex justify-content-left align-items-center">
                  <input type="checkbox" checked="checked" [disabled]="true" class="mr-1" />
                  <span class="form-control no-border">
                    My Mailing Address is the same as my Home Address
                  </span>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveFamilyProfile()"
                  [disabled]="!isValidFamilyProfile()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelFamilyProfile()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 2">
        <!-- TODO: add the page 2 content here -->
      </div>
      <div *ngIf="pageNumber == 3">
        <div class="row p-1">
          <div class="col-12">
            <h2 class="text-header">Bank Account</h2>
          </div>
        </div>
        <div class="row p-1 mt-3">
          <div class="col-12">
            <div class="form-group">
              <h3 class="text-subtitle">Bank Account Information</h3>
              <span>
                Add your bank account information to receive direct deposit of funds for your Gardiner Scholarship
                student(s).
              </span>
            </div>
          </div>
        </div>
        <div class="row p-1 mt-3" *ngIf="!hasBankAccount">
          <div class="col-12">
            <button type="button" class="btn btn-primary" (click)="doAddBankAccount()">
              Add Bank Account
            </button>
          </div>
        </div>
        <div *ngIf="hasBankAccount">
          <div class="row p-1">
            <div class="col-12">
              <div class="form-group">
                <span class="form-control no-border">{{ getFilteredBankAccount }}</span>
              </div>
            </div>
          </div>
          <div class="row p-1 mt-3">
            <div class="col-12">
              <button type="button" class="btn btn-primary" (click)="doAddBankAccount()">
                Update Account
              </button>
            </div>
          </div>
        </div>

        <p-sidebar [(visible)]="editBankAccountViewDisplay" position="right" [style]="{width:'60%'}"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class=" row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h2 class="text-header">Link a Bank Account</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Account Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.PaymentBankName" />
                  <span class="text-italic">Name your account for easy selection later. (e.g. “Checking Account”)</span>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Routing Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.PaymentBankRouting" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Account Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.PaymentBankAccount" maxlength="9" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveBankAccount()"
                  [disabled]="!isValidBankAccount()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelBankAccount()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog key="messageDialog"></p-confirmDialog>
<p-confirmDialog key="confirmDialog" styleClass="custom-confirm-dialog"></p-confirmDialog>