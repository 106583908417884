import { Component, OnInit, Directive } from '@angular/core';
import { IVendorServiceProviderListParams } from '../../models/entities/VendorServiceProviderModel.response';
import { VendorServiceProviderModel } from '../../models/entities/VendorServiceProviderModel';
import { PagingData } from '../../paging/paging.component';
import { VendorServiceProviderListBaseComponent } from './vendorServiceProviderList.base';

@Component({
    selector: 'app-vendorServiceProviderList',
    templateUrl: './vendorServiceProviderList.component.html',
})
export class VendorServiceProviderListComponent extends VendorServiceProviderListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
