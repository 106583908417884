<div>
  <div class="row">
    <div class="col-3">
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 1"></i>
          <span (click)="doChangePage(1)" class="btn font-weight-bold">
            Student Summary</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 2"></i>
          <span (click)="doChangePage(2)" class="btn font-weight-bold">
            Enrollment Verification</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 3"></i>
          <span (click)="doChangePage(3)" class="btn font-weight-bold">
            Payments</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 4"></i>
          <span (click)="doChangePage(4)" class="btn font-weight-bold">
            Household Information</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 5"></i>
          <span (click)="doChangePage(5)" class="btn font-weight-bold">
            Document Library</span>
        </div>
      </div>

    </div>
    <div class="col-8">
      <div *ngIf="pageNumber == 1" class="form">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h1 class="text-header">
              {{ model?.FirstName }} {{ model?.LastName }}
            </h1>
          </div>
          <div class="col-12 d-flex justify-content-between">
            <h4>
              Grade
              {{ studentGradeLevelsSchoolYearsModel?.FK_GradeLevel_Display }} <i class="fa fa-question-circle"
                pTooltip="To update grade level, please contact AAA Administration" tooltipPosition="right"></i>
            </h4>
          </div>
        </div>
        <div *ngIf="loginEditRole">
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Documents</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <a href="{{
                  studentGradeLevelsSchoolYearsModel?.FK_GradeLevel_Display
                }}" class="link">
                <i class="fas fa-file-pdf"></i>
                {{ studentGradeLevelsSchoolYearsModel?.FK_School }}Jan Martin -
                2020-2021 Award Determination Letter.pdf
              </a>
            </div>
          </div>
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Current School</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12" *ngIf="
                schoolModel.Name &&
                actualAwardModel.FK_AwardDeterminationStatusType === 0
              ">
              <p>
                Commitment in Progress: <strong>{{ schoolModel?.Name }}</strong>
              </p>
              <p><strong>What do I need to do?</strong></p>
              <ul>
                <li>
                  The school will be notified to complete the information required
                  for the next step.
                </li>
                <li>
                  You will receive an email notification once the school has
                  processed your form. At that time, you may log back in to review
                  and accept the finalized form.
                </li>
              </ul>
            </div>
            <div class="col-12" *ngIf="
                schoolModel.Name &&
                actualAwardModel.FK_AwardDeterminationStatusType !== 0
              ">
              <p>{{ schoolModel?.Name }}</p>
              <p>{{ schoolModel?.Physical_StreetAddress1 }}</p>
              <p>
                {{ schoolModel?.Physical_City }}, {{ stateModel?.Abbreviation }}
                {{ schoolModel?.Physical_Zipcode }}
              </p>
              <button type="button" class="btn btn-light" (click)="withdrawSchool()">
                Withdraw from School
              </button>
            </div>
            <div class="col-12" *ngIf="!schoolModel.Name">
              <p>Not currently enrolled.</p>
              <button type="button" class="btn btn-primary" (click)="openFindSchool()">
                Find a School
              </button>
            </div>
          </div>
          <div class="row p-1 mt-4" *ngIf="scholarshipModel.FK_ScholarshipType_Display == 'Disability'">
            <div class="col-12">
              <h4>{{ scholarshipModel?.Name }}</h4>
            </div>
          </div>
          <div class="row p-1" *ngIf="scholarshipModel.FK_ScholarshipType_Display == 'Disability'">
            <div class="col-12">
              <p>...4151</p>
              <button type="button" class="btn btn-outline-secondary" (click)="updateAccount()">
                Update Account
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="!loginEditRole">
          <div class="row p-1">
            <div class="col-4">
              <span class="form-label font-weight-bold">School Year</span>
              <p-dropdown [options]="schoolYearOptions2" class="form-control no-border" [(ngModel)]="schoolCalendar"
                (ngModelChange)="pageLoad()">
              </p-dropdown>
            </div>
          </div>
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Parent/Guardian Information</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <p class="mb-0">{{ guardianModel.FirstName }} {{ guardianModel.LastName }}</p>
              <p class="mb-0">{{ guardianEmail }}</p>
              <p class="mb-0">{{ guardianModel.CellPhone }}</p>
            </div>
          </div>
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Home Address</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <p class="mb-0">{{ houseHoldModel.Address1 }}</p>
              <p class="mb-0">{{ houseHoldModel.City }}, {{ guardianStateAbrevation }} {{ houseHoldModel.Zipcode }}</p>
            </div>
          </div>
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Mailing Address</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <p>My Mailing Address is the same as my Home Address</p>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <button type="button" class="btn btn-primary" (click)="sendMessage()">
                Send a Message to Parent/Guardian
              </button>
            </div>
          </div>
        </div>

        <p-sidebar [(visible)]="findSchoolDisplay" position="right" [style]="{ width: '60%' }" [showCloseIcon]="false">
          <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
            <div class="row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h4 class="font-weight-bold">
                  Find a School for {{ model?.FirstName }} {{ model?.LastName }}
                </h4>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <p>
                  {{
                  studentGradeLevelsSchoolYearsModel?.FK_SchoolYear_Display
                  }}
                  Award: ${{ actualAwardModel?.AwardAmount }}
                </p>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-9">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Search by School Name or Address</label>
                  <input type="text" class="form-control" (change)="searchSchool($event)" />
                </div>
              </div>
              <div class="col-3 d-flex align-items-end pb-3">
                <a class="link" (click)="op.toggle($event)">Request a New School</a>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'" [style]="{ width: '450px' }">
                  <p>
                    <span class="font-weight-bold">Request New School</span>
                  </p>
                  <p>
                    If you would like to attend a School that is not already
                    approved by AAA Scholarships, you can submit a request for
                    them to apply for approval.
                  </p>
                  <p>
                    Please note that the School must accept the invitation and
                    be approved by AAA Scholarships for acceptance of
                    scholarship funds.
                  </p>
                  <div class="d-flex">
                    <button class="btn btn-primary mr-2" type="button" (click)="requestNewSchool()">
                      Request New School
                    </button>
                    <a class="link" type="button" (click)="op.hide()">
                      Nevermind, return to my form
                    </a>
                  </div>
                </p-overlayPanel>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <app-custom-table [data]="schoolTableData[0]" [columns]="schoolTableColumns" [rows]="10"
                  (onRowTableClicked)="doViewButtonSchoolCommitment($event)">
                </app-custom-table>
              </div>
            </div>
            <div class="row p-1 mt-5">
              <div class="col-12">
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelProfile()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>

        <p-sidebar [(visible)]="schoolCommitmentStudentDisplay" position="right" [style]="{ width: '60%' }"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
            <div class="row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h4 class="font-weight-bold">
                  School Commitment for {{ model?.FirstName }}
                  {{ model?.LastName }}
                </h4>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <p>
                  {{
                  studentGradeLevelsSchoolYearsModel?.FK_SchoolYear_Display
                  }}
                  Award: ${{ actualAwardModel?.AwardAmount }}
                </p>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <p>
                  You have selected
                  <strong>{{ schoolToCommitment.Name }}</strong> to start the
                  School Commitment Process. Please review the information below
                  before submitting your choice.
                </p>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <p>
                  Once you submit, you will not be able to start this process
                  with another school.
                </p>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-md-auto">
                <p>
                  <i class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;&nbsp;
                  {{ schoolToCommitment.Physical_StreetAddress1 }},
                  {{ schoolToCommitment.Physical_StreetAddress2 }}
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ schoolToCommitment.Physical_City }},
                  {{ schoolToCommitment.FK_State_Physical_Display }}
                  {{ schoolToCommitment.Physical_Zipcode }}
                </p>
              </div>
              <div class="col-md-auto">
                <p *ngIf="schoolToCommitment.Phone">
                  <i class="fas fa-phone"></i>&nbsp;&nbsp;&nbsp;
                  {{ schoolToCommitment.Phone }}
                </p>
                <p *ngIf="schoolToCommitment.FaxNumber">
                  <i class="fas fa-fax"></i>&nbsp;&nbsp;&nbsp;
                  {{ schoolToCommitment.FaxNumber }}
                </p>
              </div>
              <div class="col-md-auto">
                <p *ngIf="schoolToCommitment.WebsiteURL">
                  <i class="fas fa-laptop"></i>&nbsp;&nbsp;&nbsp;
                  {{ schoolToCommitment.WebsiteURL }}
                </p>
                <p *ngIf="schoolToCommitment.EmailAddress">
                  <i class="fas fa-envelope"></i>&nbsp;&nbsp;&nbsp;
                  {{ schoolToCommitment.EmailAddress }}
                </p>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12" *ngIf="schoolFeesData[0]">
                <p-tabView>
                  <p-tabPanel [header]="item" *ngFor="let item of schoolFeesData; let i = index" [selected]="i == 0">
                    <app-custom-table [data]="schoolFeesTableData[i]" [columns]="schoolFeesTableColumns" [rows]="10">
                    </app-custom-table>
                    {{ item.content }}
                  </p-tabPanel>
                </p-tabView>
              </div>
              <div class="col-12" *ngIf="!schoolFeesData[0]">
                <p>
                  No Fee Schedule data for the School
                  {{ schoolToCommitment.Name }}
                </p>
              </div>
            </div>
            <div class="row p-1 mt-5">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="submitSchoolCommitment()"
                  [disabled]="!schoolFeesData[0]">
                  Submit School Commitment Form
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelSchoolCommitment()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 2">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h1 class="text-header">
              {{ model?.FirstName }} {{ model?.LastName }}
            </h1>
          </div>
          <div class="col-12 d-flex justify-content-between">
            <h4>
              Grade
              {{ studentGradeLevelsSchoolYearsModel?.FK_GradeLevel_Display }}
            </h4>
          </div>
        </div>
        <div class="row p-1 mt-4">
          <div class="col-12">
            <h4>Enrollment Verification</h4>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12" *ngIf="awardModels[0]">
            <div class="row p-1">
              <div class="col-12">
                <app-custom-table [data]="awardTableData[0]" [columns]="awardTableColumns" [rows]="10"
                  (onRowTableClicked)="openEnrollmentVerificationModal($event)">
                </app-custom-table>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="!awardModels[0]">
            <p>No School selected.</p>
            <button *ngIf="loginEditRole" type="button" class="btn btn-primary"
              (click)="doChangePage(1); openFindSchool()">
              Find a School
            </button>
          </div>
        </div>
        <p-sidebar [(visible)]="enrollmentVerificationDisplay" position="right" [style]="{ width: '60%' }"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
            <div class="row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h4 class="font-weight-bold">
                  Enrollment Verification
                </h4>
              </div>
            </div>
            <div class="row p-1 mt-5">
              <div class="col-12">
                <p>
                  From {{ enrollmentVerificationData.startDate | date }} to {{ enrollmentVerificationData.endDate | date
                  }}, was {{ enrollmentVerificationData.studentName }} enrolled in {{
                  enrollmentVerificationData.schoolName }}?*
                </p>
                <div class="p-field-radiobutton">
                  <p-radioButton name="question1" value="Yes" [(ngModel)]="question1" inputId="question1Yes">
                  </p-radioButton>
                  <label for="question1Yes">Yes</label>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton name="question1" value="No" [(ngModel)]="question1" inputId="question1No">
                  </p-radioButton>
                  <label for="question1No">No</label>
                </div>
              </div>
            </div>
            <div class="row p-1 mt-5">
              <div class="col-12">
                <p>
                  {{ enrollmentVerificationData.schoolName }} has submitted the following information for this student.
                </p>
              </div>
            </div>
            <div class="row p-1 mt-5">
              <div class="col-12">
                <p>
                  Is this information correct?*
                </p>
                <div class="p-field-radiobutton">
                  <p-radioButton name="question2" value="Yes" [(ngModel)]="question2" inputId="question2Yes">
                  </p-radioButton>
                  <label for="question2Yes">Yes</label>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton name="question2" value="No" [(ngModel)]="question2" inputId="question2No">
                  </p-radioButton>
                  <label for="question2No">No</label>
                </div>
                <div *ngIf="question2 === 'No'" class="mt-5">
                  <label>Explain:</label>
                  <textarea class="form-control" [(ngModel)]="explainText"></textarea>
                </div>
              </div>
            </div>
            <div class="row p-1 mt-5">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveEnrollmentVerification()"
                  [disabled]="!isValidEnrollmentVerification()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelEnrollmentVerification()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 3">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h1 class="text-header">
              {{ model?.FirstName }} {{ model?.LastName }}
            </h1>
          </div>
          <div class="col-12 d-flex justify-content-between">
            <h4>
              Grade
              {{ studentGradeLevelsSchoolYearsModel?.FK_GradeLevel_Display }}
            </h4>
          </div>
        </div>
        <div class="row p-1 mt-4" *ngIf="loginEditRole">
          <div class="col-12">
            <h4>Award Details</h4>
          </div>
        </div>
        <div class="row p-1" *ngIf="loginEditRole">
          <div class="col-3">
            <p>Total Award</p>
            <p>Amount Remaining</p>
          </div>
          <div class="col-3">
            <p>${{ actualAwardModel?.AwardAmount }}</p>
            <p>${{ actualAwardModel?.AwardAmount }}</p>
          </div>
          <div class="col-6">
            <button class="btn btn-outline-secondary" (click)="onPaymentViewDetails()">
              View Details
            </button>
          </div>
        </div>
        <div class="row p-1 mt-4">
          <div class="col-12">
            <h4 *ngIf="loginEditRole">Tuition & Fees</h4>
            <h4 *ngIf="!loginEditRole">Payments</h4>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12" *ngIf="schoolTransactionModels[0]">
            <div class="row p-1">
              <div class="col-12">
                <app-custom-table [data]="paymentsTableData[0]" [columns]="paymentsTableColumns" [rows]="10">
                </app-custom-table>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="!schoolTransactionModels[0]">
            <p>No School selected.</p>
            <button *ngIf="loginEditRole" type="button" class="btn btn-primary"
              (click)="doChangePage(1); openFindSchool()">
              Find a School
            </button>
          </div>
        </div>
        <div *ngIf="loginEditRole">
          <div class="row p-1 mt-4" *ngIf="scholarshipModel.FK_ScholarshipType_Display == 'Disability'">
            <div class="col-12">
              <h4>Payment Authorizations & Requests</h4>
            </div>
          </div>
          <div class="row p-1" *ngIf="scholarshipModel.FK_ScholarshipType_Display == 'Disability'">
            <div class="col-md-auto">
              <button *ngIf="loginEditRole" type="button" class="btn btn-primary"
                (click)="openCreateNewPaymentVendor()">
                Create a New Payment Request
              </button>
            </div>
            <div class="col-md-auto">
              <button *ngIf="loginEditRole" type="button" class="btn btn-light" (click)="openInviteNewVendor()">
                Invite a New Vendor/Service Provider
              </button>
            </div>
          </div>
          <div class="row p-1" *ngIf="scholarshipModel.FK_ScholarshipType_Display == 'Disability'">
            <div class="col-5">
              <label class="form-label font-weight-bold">Search</label>
              <input type="text" class="form-control" (change)="searchVendorTransaction($event)" />
            </div>
          </div>
          <div class="row p-1" *ngIf="scholarshipModel.FK_ScholarshipType_Display == 'Disability'">
            <div class="col-12">
              <app-custom-table [data]="vendorsTableData[0]" [columns]="vendorsTableColumns" [rows]="3"
                [showPaginator]="true">
              </app-custom-table>
            </div>
          </div>
        </div>

        <p-sidebar [(visible)]="createNewPaymentVendorDisplay" position="right" [style]="{ width: '60%' }"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
            <div class="row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h4 class="font-weight-bold">Create a New Payment Request</h4>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Vendor<span class="text-danger">*</span></label>
                  <p-dropdown [options]="vendorTransactionOptions" class="form-control no-border"
                    [(ngModel)]="newVendorTransactionModel.FK_Vendor" (ngModelChange)="onVendorChange($event)">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Description<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="newVendorTransactionModel.Description" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Amount<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="newVendorTransactionModel.Amount" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveVendorTransaction()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelCreateVendorTransaction()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>

        <p-sidebar [(visible)]="editPaymentDetailsViewDisplay" position="right" [style]="{ width: '60%' }"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
            <div class="row p-1 mb-3">
              <div class="col-12 d-flex justify-content-between">
                <h4 class="font-weight-bold">Balance Details</h4>
              </div>
            </div>
            <div class="row p-0 m-0 mt-3">
              <div class="col-3 d-flex justify-content-start">
                <span>Total Award</span>
              </div>
              <div class="col-3 d-flex justify-content-end">
                <span>{{ actualAwardModel?.AwardAmount | currency:'USD':'symbol':'1.2' }}</span>
              </div>
            </div>
            <!-- Amount Remaining -->
            <div class="row p-0 m-0 my-3">
              <div class="col-3 d-flex justify-content-start">
                <span>Amount Remaining</span>
              </div>
              <div class="col-3 d-flex justify-content-end">
                <span>{{ actualAwardModel?.AwardAmount | currency:'USD':'symbol':'1.2' }}</span>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="onClosePaymentDetails()">
                  Close
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 4">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h1 class="text-header">
              {{ model?.FirstName }} {{ model?.LastName }}
            </h1>
          </div>
          <div class="col-12 d-flex justify-content-between">
            <h4>
              Grade
              {{ studentGradeLevelsSchoolYearsModel?.FK_GradeLevel_Display }}
            </h4>
          </div>
        </div>
        <div *ngIf="loginEditRole">
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Documents</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <a href="{{
                  studentGradeLevelsSchoolYearsModel?.FK_GradeLevel_Display
                }}" class="link">
                <i class="fas fa-file-pdf"></i>
                {{ studentGradeLevelsSchoolYearsModel?.FK_School }}Jan Martin -
                2020-2021 Award Determination Letter.pdf
              </a>
            </div>
          </div>
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Current School</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12" *ngIf="
                schoolModel.Name &&
                actualAwardModel.FK_AwardDeterminationStatusType === 0
              ">
              <p>
                Commitment in Progress: <strong>{{ schoolModel?.Name }}</strong>
              </p>
              <p><strong>What do I need to do?</strong></p>
              <ul>
                <li>
                  The school will be notified to complete the information required
                  for the next step.
                </li>
                <li>
                  You will receive an email notification once the school has
                  processed your form. At that time, you may log back in to review
                  and accept the finalized form.
                </li>
              </ul>
            </div>
            <div class="col-12" *ngIf="
                schoolModel.Name &&
                actualAwardModel.FK_AwardDeterminationStatusType !== 0
              ">
              <p>{{ schoolModel?.Name }}</p>
              <p>{{ schoolModel?.Physical_StreetAddress1 }}</p>
              <p>
                {{ schoolModel?.Physical_City }}, {{ stateModel?.Abbreviation }}
                {{ schoolModel?.Physical_Zipcode }}
              </p>
              <button type="button" class="btn btn-light" (click)="withdrawSchool()">
                Withdraw from School
              </button>
            </div>
            <div class="col-12" *ngIf="!schoolModel.Name">
              <p>Not currently enrolled.</p>
              <button type="button" class="btn btn-primary" (click)="openFindSchool()">
                Find a School
              </button>
            </div>
          </div>
          <div class="row p-1 mt-4" *ngIf="scholarshipModel.FK_ScholarshipType_Display == 'Disability'">
            <div class="col-12">
              <h4>{{ scholarshipModel?.Name }}</h4>
            </div>
          </div>
          <div class="row p-1" *ngIf="scholarshipModel.FK_ScholarshipType_Display == 'Disability'">
            <div class="col-12">
              <p>...4151</p>
              <button type="button" class="btn btn-outline-secondary" (click)="updateAccount()">
                Update Account
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="!loginEditRole">
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Contact Information</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <p class="mb-0">{{ guardianModel.FirstName }} {{ guardianModel.LastName }}</p>
              <p class="mb-0">{{ guardianEmail }}</p>
              <p class="mb-0">{{ guardianModel.CellPhone }}</p>
            </div>
          </div>
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Home Address</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <p class="mb-0">{{ houseHoldModel.Address1 }}</p>
              <p class="mb-0">{{ houseHoldModel.City }}, {{ guardianStateAbrevation }} {{ houseHoldModel.Zipcode }}</p>
            </div>
          </div>
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>Mailing Address</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <p>My Mailing Address is the same as my Home Address</p>
            </div>
          </div>
          <div class="row p-1 mt-4">
            <div class="col-12">
              <h4>All Students</h4>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-4">
              <span class="form-label font-weight-bold">School Year</span>
              <p-dropdown [options]="schoolYearOptions2" class="form-control no-border" [(ngModel)]="schoolCalendar"
                (ngModelChange)="changeState($event)">
              </p-dropdown>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <app-custom-table [data]="studentTableData" [rows]="10" showPaginator="true"
                [columns]="studentTableColumns" [showSummary]="true" [useLazyLoad]="true"
                (onLoadData)="loadStudentData($event)" [isLoading]="usersTableLoading"
                [totalRecords]="usersTableTotalRecords">
              </app-custom-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>