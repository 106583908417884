import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceLocator } from './ServiceLocator';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SearchInputComponent } from './search-input/search-input.component';
import { AdminNavComponent } from './admin-nav/admin-nav.component';
import { PagingComponent } from './paging/paging.component';
import { ValidationComponent } from './validation/validation.component';
import { TypeaheadBasicComponent } from './typeahead-basic/typeahead-basic.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

// Primeng Modules and Services
// Services
import { ConfirmationService } from 'primeng/api';

// Modules
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';

//BeginWriteOnceImports
import { AccreditationListComponent } from './entities/accreditation/accreditationList.component';
import { AccreditationEditComponent } from './entities/accreditation/accreditationEdit.component';
import { PreAuthorizationStatusTypeListComponent } from './entities/preAuthorizationStatusType/preAuthorizationStatusTypeList.component';
import { PreAuthorizationStatusTypeEditComponent } from './entities/preAuthorizationStatusType/preAuthorizationStatusTypeEdit.component';
import { SchoolEligibilitiesStateLookUpListComponent } from './entities/schoolEligibilitiesStateLookUp/schoolEligibilitiesStateLookUpList.component';
import { SchoolEligibilitiesStateLookUpEditComponent } from './entities/schoolEligibilitiesStateLookUp/schoolEligibilitiesStateLookUpEdit.component';
import { PrepaidCollegeTransferStatusTypeListComponent } from './entities/prepaidCollegeTransferStatusType/prepaidCollegeTransferStatusTypeList.component';
import { PrepaidCollegeTransferStatusTypeEditComponent } from './entities/prepaidCollegeTransferStatusType/prepaidCollegeTransferStatusTypeEdit.component';
import { ScholarshipPaymentsDeadlineListComponent } from './entities/scholarshipPaymentsDeadline/scholarshipPaymentsDeadlineList.component';
import { ScholarshipPaymentsDeadlineEditComponent } from './entities/scholarshipPaymentsDeadline/scholarshipPaymentsDeadlineEdit.component';
import { ScholarshipTypeListComponent } from './entities/scholarshipType/scholarshipTypeList.component';
import { ScholarshipTypeEditComponent } from './entities/scholarshipType/scholarshipTypeEdit.component';
import { SchoolCommitmentStatusTypeListComponent } from './entities/schoolCommitmentStatusType/schoolCommitmentStatusTypeList.component';
import { SchoolCommitmentStatusTypeEditComponent } from './entities/schoolCommitmentStatusType/schoolCommitmentStatusTypeEdit.component';
import { FeeTypeListComponent } from './entities/feeType/feeTypeList.component';
import { FeeTypeEditComponent } from './entities/feeType/feeTypeEdit.component';
import { TransactionTypeListComponent } from './entities/transactionType/transactionTypeList.component';
import { TransactionTypeEditComponent } from './entities/transactionType/transactionTypeEdit.component';
import { WithdrawReasonTypeListComponent } from './entities/withdrawReasonType/withdrawReasonTypeList.component';
import { WithdrawReasonTypeEditComponent } from './entities/withdrawReasonType/withdrawReasonTypeEdit.component';
import { TransactionStatusTypeListComponent } from './entities/transactionStatusType/transactionStatusTypeList.component';
import { TransactionStatusTypeEditComponent } from './entities/transactionStatusType/transactionStatusTypeEdit.component';
import { VerificationStatusTypeListComponent } from './entities/verificationStatusType/verificationStatusTypeList.component';
import { VerificationStatusTypeEditComponent } from './entities/verificationStatusType/verificationStatusTypeEdit.component';
import { GenderTypeListComponent } from './entities/genderType/genderTypeList.component';
import { GenderTypeEditComponent } from './entities/genderType/genderTypeEdit.component';
import { LoginListComponent } from './entities/login/loginList.component';
import { LoginEditComponent } from './entities/login/loginEdit.component';
import { AmazonRequestListComponent } from './entities/amazonRequest/amazonRequestList.component';
import { AmazonRequestEditComponent } from './entities/amazonRequest/amazonRequestEdit.component';
import { SchoolTransactionListComponent } from './entities/schoolTransaction/schoolTransactionList.component';
import { SchoolTransactionEditComponent } from './entities/schoolTransaction/schoolTransactionEdit.component';
import { HouseholdListComponent } from './entities/household/householdList.component';
import { HouseholdEditComponent } from './entities/household/householdEdit.component';
import { RaceTypeListComponent } from './entities/raceType/raceTypeList.component';
import { RaceTypeEditComponent } from './entities/raceType/raceTypeEdit.component';
import { GradingPeriodTypeListComponent } from './entities/gradingPeriodType/gradingPeriodTypeList.component';
import { GradingPeriodTypeEditComponent } from './entities/gradingPeriodType/gradingPeriodTypeEdit.component';
import { VendorTransactionListComponent } from './entities/vendorTransaction/vendorTransactionList.component';
import { VendorTransactionEditComponent } from './entities/vendorTransaction/vendorTransactionEdit.component';
import { SchoolCommitmentListComponent } from './entities/schoolCommitment/schoolCommitmentList.component';
import { SchoolCommitmentEditComponent } from './entities/schoolCommitment/schoolCommitmentEdit.component';
import { GuardianListComponent } from './entities/guardian/guardianList.component';
import { GuardianEditComponent } from './entities/guardian/guardianEdit.component';
import { AwardDeterminationStatusTypeListComponent } from './entities/awardDeterminationStatusType/awardDeterminationStatusTypeList.component';
import { AwardDeterminationStatusTypeEditComponent } from './entities/awardDeterminationStatusType/awardDeterminationStatusTypeEdit.component';
import { SchoolCommitmentFeesLookUpListComponent } from './entities/schoolCommitmentFeesLookUp/schoolCommitmentFeesLookUpList.component';
import { SchoolCommitmentFeesLookUpEditComponent } from './entities/schoolCommitmentFeesLookUp/schoolCommitmentFeesLookUpEdit.component';
import { AwardDeterminationListComponent } from './entities/awardDetermination/awardDeterminationList.component';
import { AwardDeterminationEditComponent } from './entities/awardDetermination/awardDeterminationEdit.component';
import { RoleListComponent } from './entities/role/roleList.component';
import { RoleEditComponent } from './entities/role/roleEdit.component';
import { FeeDiscountListComponent } from './entities/feeDiscount/feeDiscountList.component';
import { FeeDiscountEditComponent } from './entities/feeDiscount/feeDiscountEdit.component';
import { StudentListComponent } from './entities/student/studentList.component';
import { StudentEditComponent } from './entities/student/studentEdit.component';
import { VendorServiceProviderListComponent } from './entities/vendorServiceProvider/vendorServiceProviderList.component';
import { VendorServiceProviderEditComponent } from './entities/vendorServiceProvider/vendorServiceProviderEdit.component';
import { ZengineMappingOptionListComponent } from './entities/zengineMappingOption/zengineMappingOptionList.component';
import { ZengineMappingOptionEditComponent } from './entities/zengineMappingOption/zengineMappingOptionEdit.component';
import { VerificationListComponent } from './entities/verification/verificationList.component';
import { VerificationEditComponent } from './entities/verification/verificationEdit.component';
import { ContactListComponent } from './entities/contact/contactList.component';
import { ContactEditComponent } from './entities/contact/contactEdit.component';
import { StudentGradeLevelsSchoolYearsLookUpListComponent } from './entities/studentGradeLevelsSchoolYearsLookUp/studentGradeLevelsSchoolYearsLookUpList.component';
import { StudentGradeLevelsSchoolYearsLookUpEditComponent } from './entities/studentGradeLevelsSchoolYearsLookUp/studentGradeLevelsSchoolYearsLookUpEdit.component';
import { ZengineMappingLookUpListComponent } from './entities/zengineMappingLookUp/zengineMappingLookUpList.component';
import { ZengineMappingLookUpEditComponent } from './entities/zengineMappingLookUp/zengineMappingLookUpEdit.component';
import { LoginRolesLookUpListComponent } from './entities/loginRolesLookUp/loginRolesLookUpList.component';
import { LoginRolesLookUpEditComponent } from './entities/loginRolesLookUp/loginRolesLookUpEdit.component';
import { AwardAcceptanceStatusLookUpListComponent } from './entities/awardAcceptanceStatusLookUp/awardAcceptanceStatusLookUpList.component';
import { AwardAcceptanceStatusLookUpEditComponent } from './entities/awardAcceptanceStatusLookUp/awardAcceptanceStatusLookUpEdit.component';
import { WithdrawListComponent } from './entities/withdraw/withdrawList.component';
import { WithdrawEditComponent } from './entities/withdraw/withdrawEdit.component';
import { ZengineFormListComponent } from './entities/zengineForm/zengineFormList.component';
import { ZengineFormEditComponent } from './entities/zengineForm/zengineFormEdit.component';
import { SchoolCalendarLookUpListComponent } from './entities/schoolCalendarLookUp/schoolCalendarLookUpList.component';
import { SchoolCalendarLookUpEditComponent } from './entities/schoolCalendarLookUp/schoolCalendarLookUpEdit.component';
import { VendorCategoriesLookUpListComponent } from './entities/vendorCategoriesLookUp/vendorCategoriesLookUpList.component';
import { VendorCategoriesLookUpEditComponent } from './entities/vendorCategoriesLookUp/vendorCategoriesLookUpEdit.component';
import { PreAuthorizationListComponent } from './entities/preAuthorization/preAuthorizationList.component';
import { PreAuthorizationEditComponent } from './entities/preAuthorization/preAuthorizationEdit.component';
import { AwardAcceptanceStatusTypeListComponent } from './entities/awardAcceptanceStatusType/awardAcceptanceStatusTypeList.component';
import { AwardAcceptanceStatusTypeEditComponent } from './entities/awardAcceptanceStatusType/awardAcceptanceStatusTypeEdit.component';
import { ZengineFormStateLookUpListComponent } from './entities/zengineFormStateLookUp/zengineFormStateLookUpList.component';
import { ZengineFormStateLookUpEditComponent } from './entities/zengineFormStateLookUp/zengineFormStateLookUpEdit.component';
import { ZengineFormTypeListComponent } from './entities/zengineFormType/zengineFormTypeList.component';
import { ZengineFormTypeEditComponent } from './entities/zengineFormType/zengineFormTypeEdit.component';
import { ScholarshipDisbursementCategoriesLookUpListComponent } from './entities/scholarshipDisbursementCategoriesLookUp/scholarshipDisbursementCategoriesLookUpList.component';
import { ScholarshipDisbursementCategoriesLookUpEditComponent } from './entities/scholarshipDisbursementCategoriesLookUp/scholarshipDisbursementCategoriesLookUpEdit.component';
import { ContactsLookUpListComponent } from './entities/contactsLookUp/contactsLookUpList.component';
import { ContactsLookUpEditComponent } from './entities/contactsLookUp/contactsLookUpEdit.component';
import { StateListComponent } from './entities/state/stateList.component';
import { StateEditComponent } from './entities/state/stateEdit.component';
import { GuardianReimbursementListItemListComponent } from './entities/guardianReimbursementListItem/guardianReimbursementListItemList.component';
import { GuardianReimbursementListItemEditComponent } from './entities/guardianReimbursementListItem/guardianReimbursementListItemEdit.component';
import { SchoolEligibilitiesResponsListComponent } from './entities/schoolEligibilitiesRespons/schoolEligibilitiesResponsList.component';
import { SchoolEligibilitiesResponsEditComponent } from './entities/schoolEligibilitiesRespons/schoolEligibilitiesResponsEdit.component';
import { SchoolTypeListComponent } from './entities/schoolType/schoolTypeList.component';
import { SchoolTypeEditComponent } from './entities/schoolType/schoolTypeEdit.component';
import { SchoolFeeListComponent } from './entities/schoolFee/schoolFeeList.component';
import { SchoolFeeEditComponent } from './entities/schoolFee/schoolFeeEdit.component';
import { SchoolAccreditationsLookUpListComponent } from './entities/schoolAccreditationsLookUp/schoolAccreditationsLookUpList.component';
import { SchoolAccreditationsLookUpEditComponent } from './entities/schoolAccreditationsLookUp/schoolAccreditationsLookUpEdit.component';
import { AccreditationsLookUpListComponent } from './entities/accreditationsLookUp/accreditationsLookUpList.component';
import { AccreditationsLookUpEditComponent } from './entities/accreditationsLookUp/accreditationsLookUpEdit.component';
import { AwardDeterminationStatusLookUpListComponent } from './entities/awardDeterminationStatusLookUp/awardDeterminationStatusLookUpList.component';
import { AwardDeterminationStatusLookUpEditComponent } from './entities/awardDeterminationStatusLookUp/awardDeterminationStatusLookUpEdit.component';
import { GuardianReimbursementListComponent } from './entities/guardianReimbursement/guardianReimbursementList.component';
import { GuardianReimbursementEditComponent } from './entities/guardianReimbursement/guardianReimbursementEdit.component';
import { SchoolListComponent } from './entities/school/schoolList.component';
import { SchoolEditComponent } from './entities/school/schoolEdit.component';
import { SchoolCommitmentNoteListComponent } from './entities/schoolCommitmentNote/schoolCommitmentNoteList.component';
import { SchoolCommitmentNoteEditComponent } from './entities/schoolCommitmentNote/schoolCommitmentNoteEdit.component';
import { PrepaidCollegeTransferListComponent } from './entities/prepaidCollegeTransfer/prepaidCollegeTransferList.component';
import { PrepaidCollegeTransferEditComponent } from './entities/prepaidCollegeTransfer/prepaidCollegeTransferEdit.component';
import { SchoolEligibilityListComponent } from './entities/schoolEligibility/schoolEligibilityList.component';
import { SchoolEligibilityEditComponent } from './entities/schoolEligibility/schoolEligibilityEdit.component';
import { StudentNoteListComponent } from './entities/studentNote/studentNoteList.component';
import { StudentNoteEditComponent } from './entities/studentNote/studentNoteEdit.component';
import { ZengineFormFieldListComponent } from './entities/zengineFormField/zengineFormFieldList.component';
import { ZengineFormFieldEditComponent } from './entities/zengineFormField/zengineFormFieldEdit.component';
import { SchoolScholarshipsLookUpListComponent } from './entities/schoolScholarshipsLookUp/schoolScholarshipsLookUpList.component';
import { SchoolScholarshipsLookUpEditComponent } from './entities/schoolScholarshipsLookUp/schoolScholarshipsLookUpEdit.component';
import { SchoolTransactionNoteListComponent } from './entities/schoolTransactionNote/schoolTransactionNoteList.component';
import { SchoolTransactionNoteEditComponent } from './entities/schoolTransactionNote/schoolTransactionNoteEdit.component';
import { GradeLevelListComponent } from './entities/gradeLevel/gradeLevelList.component';
import { GradeLevelEditComponent } from './entities/gradeLevel/gradeLevelEdit.component';
import { VendorListComponent } from './entities/vendor/vendorList.component';
import { VendorEditComponent } from './entities/vendor/vendorEdit.component';
import { StudentDisabilityLookUpListComponent } from './entities/studentDisabilityLookUp/studentDisabilityLookUpList.component';
import { StudentDisabilityLookUpEditComponent } from './entities/studentDisabilityLookUp/studentDisabilityLookUpEdit.component';
import { ScholarshipHandbookListComponent } from './entities/scholarshipHandbook/scholarshipHandbookList.component';
import { VerificationNoteListComponent } from './entities/verificationNote/verificationNoteList.component';
import { VerificationNoteEditComponent } from './entities/verificationNote/verificationNoteEdit.component';
import { VendorTransactionNoteListComponent } from './entities/vendorTransactionNote/vendorTransactionNoteList.component';
import { VendorTransactionNoteEditComponent } from './entities/vendorTransactionNote/vendorTransactionNoteEdit.component';
import { NoteTypeListComponent } from './entities/noteType/noteTypeList.component';
import { NoteTypeEditComponent } from './entities/noteType/noteTypeEdit.component';
import { SchoolGradeLevelsLookUpListComponent } from './entities/schoolGradeLevelsLookUp/schoolGradeLevelsLookUpList.component';
import { SchoolGradeLevelsLookUpEditComponent } from './entities/schoolGradeLevelsLookUp/schoolGradeLevelsLookUpEdit.component';
import { WithdrawNoteListComponent } from './entities/withdrawNote/withdrawNoteList.component';
import { WithdrawNoteEditComponent } from './entities/withdrawNote/withdrawNoteEdit.component';
import { DocumentListComponent } from './entities/document/documentList.component';
import { DocumentEditComponent } from './entities/document/documentEdit.component';
import { ZengineFormYearLookUpListComponent } from './entities/zengineFormYearLookUp/zengineFormYearLookUpList.component';
import { ZengineFormYearLookUpEditComponent } from './entities/zengineFormYearLookUp/zengineFormYearLookUpEdit.component';
import { DisabilityListComponent } from './entities/disability/disabilityList.component';
import { DisabilityEditComponent } from './entities/disability/disabilityEdit.component';
import { CountyListComponent } from './entities/county/countyList.component';
import { CountyEditComponent } from './entities/county/countyEdit.component';
import { AccreditationTypeListComponent } from './entities/accreditationType/accreditationTypeList.component';
import { AccreditationTypeEditComponent } from './entities/accreditationType/accreditationTypeEdit.component';
import { ScholarshipListComponent } from './entities/scholarship/scholarshipList.component';
import { ScholarshipEditComponent } from './entities/scholarship/scholarshipEdit.component';
import { ScholarshipDisbursementCategoryListComponent } from './entities/scholarshipDisbursementCategory/scholarshipDisbursementCategoryList.component';
import { ScholarshipDisbursementCategoryEditComponent } from './entities/scholarshipDisbursementCategory/scholarshipDisbursementCategoryEdit.component';
import { SchoolYearListComponent } from './entities/schoolYear/schoolYearList.component';
import { SchoolYearEditComponent } from './entities/schoolYear/schoolYearEdit.component';
import { GuardianReimbursementStatusTypeListComponent } from './entities/guardianReimbursementStatusType/guardianReimbursementStatusTypeList.component';
import { GuardianReimbursementStatusTypeEditComponent } from './entities/guardianReimbursementStatusType/guardianReimbursementStatusTypeEdit.component';
import { GuardianTypeListComponent } from './entities/guardianType/guardianTypeList.component';
import { GuardianTypeEditComponent } from './entities/guardianType/guardianTypeEdit.component';
import { LoginRolesLookUpCheckBoxListByLoginComponent } from './entities/loginRolesLookUp/loginRolesLookUpCheckBoxListByLogin.component';
import { StudentDisabilityLookUpCheckBoxListByStudentComponent } from './entities/studentDisabilityLookUp/studentDisabilityLookUpCheckBoxListByStudent.component';
import { ZengineFormStateLookUpCheckBoxListByZengineFormComponent } from './entities/zengineFormStateLookUp/zengineFormStateLookUpCheckBoxListByZengineForm.component';
import { ZengineFormYearLookUpCheckBoxListByZengineFormComponent } from './entities/zengineFormYearLookUp/zengineFormYearLookUpCheckBoxListByZengineForm.component';
//EndWriteOnceImports

import { DoUserLoginComponent } from './user-login/do-user-login.component';
import { UserLoginEditComponent } from './user-login/user-login-Edit.component';
import { UserLoginListComponent } from './user-login/user-login-List.component';
import { DoResetPasswordComponent } from './reset-password/do-reset-password.component';
import { FirstTimeLoginComponent } from './user-login/first-time-login.component';
import { ScholarshipManagerComponent } from './student/scholarship-manager/scholarship-manager.component';
import { SyncComponent } from './zengine/sync/sync.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ZengineLoginListComponent } from './entities/zengineLogin/zengineLoginList.component';
import { ZengineLoginEditComponent } from './entities/zengineLogin/zengineLoginEdit.component';

// Custom Components
import { CustomTableComponent } from './components/custom-table/custom-table.component';

// Pages
import { SchoolProfilePageComponent } from './pages/school/school-profile-page/school-profile-page.component';
import { SchoolDashboardPageComponent } from './pages/school/school-dashboard-page/school-dashboard-page.component';
import { FamilyProfilePageComponent } from './pages/family/family-profile-page/family-profile-page.component';
import { FamilyDashboardPageComponent } from './pages/family/family-dashboard-page/family-dashboard-page.component';
import { FamilyAwardStatusPageComponent } from './pages/family/family-award-status-page/family-award-status-page.component';
import { StudentProfilePageComponent } from './pages/family/student-profile/student-profile-page.component';
import { VendorDashboardPageComponent } from './pages/vendor/vendor-dashboard-page/vendor-dashboard-page.component';
import { VendorProfilePageComponent } from './pages/vendor/vendor-profile-page/vendor-profile-page.component';
import { AdminDashboardPageComponent } from './pages/admin/admin-dashboard-page/admin-dashboard-page.component';
import { AdminScholarshipSettingsPageComponent } from './pages/admin/admin-scholarship-settings/admin-scholarship-settings-page.component';
import { AdminScholarshipPageComponent } from './pages/admin/admin-scholarship/admin-scholarship-page.component';
import { AdminAllSchoolsComponent } from './pages/admin/admin-allschools/admin-allschools.component';
import { AdminSchoolProfilePageComponent } from './pages/admin/admin-school-profile-page/admin-school-profile-page.component';
import { AdminAllstudentsComponent } from './pages/admin/admin-allstudents/admin-allstudents.component';
import { AdminUsersPageComponent } from './pages/admin/admin-users-page/admin-users-page.component';
import { AdminUserProfilePageComponent } from './pages/admin/admin-user-profile-page/admin-user-profile-page.component';
import { AdminVendorsPageComponent } from './pages/admin/admin-vendors-page/admin-vendors-page.component';
import { AdminVendorProfilePageComponent } from './pages/admin/admin-vendor-profile-page/admin-vendor-profile-page.component';

@NgModule({
  declarations: [
    AppComponent,
    //BeginWriteOnceDeclarations
		AccreditationListComponent,
		AccreditationEditComponent,
		PreAuthorizationStatusTypeListComponent,
		PreAuthorizationStatusTypeEditComponent,
		SchoolEligibilitiesStateLookUpListComponent,
		SchoolEligibilitiesStateLookUpEditComponent,
		PrepaidCollegeTransferStatusTypeListComponent,
		PrepaidCollegeTransferStatusTypeEditComponent,
		ScholarshipPaymentsDeadlineListComponent,
		ScholarshipPaymentsDeadlineEditComponent,
		ScholarshipTypeListComponent,
		ScholarshipTypeEditComponent,
		SchoolCommitmentStatusTypeListComponent,
		SchoolCommitmentStatusTypeEditComponent,
		FeeTypeListComponent,
		FeeTypeEditComponent,
		TransactionTypeListComponent,
		TransactionTypeEditComponent,
		WithdrawReasonTypeListComponent,
		WithdrawReasonTypeEditComponent,
		TransactionStatusTypeListComponent,
		TransactionStatusTypeEditComponent,
		VerificationStatusTypeListComponent,
		VerificationStatusTypeEditComponent,
		GenderTypeListComponent,
		GenderTypeEditComponent,
		LoginListComponent,
		LoginEditComponent,
		AmazonRequestListComponent,
		AmazonRequestEditComponent,
		SchoolTransactionListComponent,
		SchoolTransactionEditComponent,
		HouseholdListComponent,
		HouseholdEditComponent,
		RaceTypeListComponent,
		RaceTypeEditComponent,
		GradingPeriodTypeListComponent,
		GradingPeriodTypeEditComponent,
		VendorTransactionListComponent,
		VendorTransactionEditComponent,
		SchoolCommitmentListComponent,
		SchoolCommitmentEditComponent,
		GuardianListComponent,
		GuardianEditComponent,
		AwardDeterminationStatusTypeListComponent,
		AwardDeterminationStatusTypeEditComponent,
		SchoolCommitmentFeesLookUpListComponent,
		SchoolCommitmentFeesLookUpEditComponent,
		AwardDeterminationListComponent,
		AwardDeterminationEditComponent,
		RoleListComponent,
		RoleEditComponent,
		FeeDiscountListComponent,
		FeeDiscountEditComponent,
		StudentListComponent,
		StudentEditComponent,
		VendorServiceProviderListComponent,
		VendorServiceProviderEditComponent,
		ZengineMappingOptionListComponent,
		ZengineMappingOptionEditComponent,
		VerificationListComponent,
		VerificationEditComponent,
		ContactListComponent,
		ContactEditComponent,
		StudentGradeLevelsSchoolYearsLookUpListComponent,
		StudentGradeLevelsSchoolYearsLookUpEditComponent,
		ZengineMappingLookUpListComponent,
		ZengineMappingLookUpEditComponent,
		LoginRolesLookUpListComponent,
		LoginRolesLookUpEditComponent,
		AwardAcceptanceStatusLookUpListComponent,
		AwardAcceptanceStatusLookUpEditComponent,
		WithdrawListComponent,
		WithdrawEditComponent,
		ZengineFormListComponent,
		ZengineFormEditComponent,
		SchoolCalendarLookUpListComponent,
		SchoolCalendarLookUpEditComponent,
		VendorCategoriesLookUpListComponent,
		VendorCategoriesLookUpEditComponent,
		PreAuthorizationListComponent,
		PreAuthorizationEditComponent,
		AwardAcceptanceStatusTypeListComponent,
		AwardAcceptanceStatusTypeEditComponent,
		ZengineFormStateLookUpListComponent,
		ZengineFormStateLookUpEditComponent,
		ZengineFormTypeListComponent,
		ZengineFormTypeEditComponent,
		ScholarshipDisbursementCategoriesLookUpListComponent,
		ScholarshipDisbursementCategoriesLookUpEditComponent,
		ContactsLookUpListComponent,
		ContactsLookUpEditComponent,
		StateListComponent,
		StateEditComponent,
		GuardianReimbursementListItemListComponent,
		GuardianReimbursementListItemEditComponent,
		SchoolEligibilitiesResponsListComponent,
		SchoolEligibilitiesResponsEditComponent,
		SchoolTypeListComponent,
		SchoolTypeEditComponent,
		SchoolFeeListComponent,
		SchoolFeeEditComponent,
		SchoolAccreditationsLookUpListComponent,
		SchoolAccreditationsLookUpEditComponent,
		AccreditationsLookUpListComponent,
		AccreditationsLookUpEditComponent,
		AwardDeterminationStatusLookUpListComponent,
		AwardDeterminationStatusLookUpEditComponent,
		GuardianReimbursementListComponent,
		GuardianReimbursementEditComponent,
		SchoolListComponent,
		SchoolEditComponent,
		SchoolCommitmentNoteListComponent,
		SchoolCommitmentNoteEditComponent,
		PrepaidCollegeTransferListComponent,
		PrepaidCollegeTransferEditComponent,
		SchoolEligibilityListComponent,
		SchoolEligibilityEditComponent,
		StudentNoteListComponent,
		StudentNoteEditComponent,
		ZengineFormFieldListComponent,
		ZengineFormFieldEditComponent,
		SchoolScholarshipsLookUpListComponent,
		SchoolScholarshipsLookUpEditComponent,
		SchoolTransactionNoteListComponent,
		SchoolTransactionNoteEditComponent,
		GradeLevelListComponent,
		GradeLevelEditComponent,
		VendorListComponent,
		VendorEditComponent,
		StudentDisabilityLookUpListComponent,
		StudentDisabilityLookUpEditComponent,
		ScholarshipHandbookListComponent,
		VerificationNoteListComponent,
		VerificationNoteEditComponent,
		VendorTransactionNoteListComponent,
		VendorTransactionNoteEditComponent,
		NoteTypeListComponent,
		NoteTypeEditComponent,
		SchoolGradeLevelsLookUpListComponent,
		SchoolGradeLevelsLookUpEditComponent,
		WithdrawNoteListComponent,
		WithdrawNoteEditComponent,
		DocumentListComponent,
		DocumentEditComponent,
		ZengineFormYearLookUpListComponent,
		ZengineFormYearLookUpEditComponent,
		DisabilityListComponent,
		DisabilityEditComponent,
		CountyListComponent,
		CountyEditComponent,
		AccreditationTypeListComponent,
		AccreditationTypeEditComponent,
		ScholarshipListComponent,
		ScholarshipEditComponent,
		ScholarshipDisbursementCategoryListComponent,
		ScholarshipDisbursementCategoryEditComponent,
		SchoolYearListComponent,
		SchoolYearEditComponent,
		GuardianReimbursementStatusTypeListComponent,
		GuardianReimbursementStatusTypeEditComponent,
		GuardianTypeListComponent,
		GuardianTypeEditComponent,
LoginRolesLookUpCheckBoxListByLoginComponent,
StudentDisabilityLookUpCheckBoxListByStudentComponent,
ZengineFormStateLookUpCheckBoxListByZengineFormComponent,
ZengineFormYearLookUpCheckBoxListByZengineFormComponent,
//EndWriteOnceDeclarations
    PagingComponent,
    AdminNavComponent,
    SearchInputComponent,
    ValidationComponent,
    HomeComponent,
    TypeaheadBasicComponent,
    DoUserLoginComponent,
    UserLoginEditComponent,
    UserLoginListComponent,
    DoResetPasswordComponent,
    FirstTimeLoginComponent,
    ScholarshipManagerComponent,
    SyncComponent,
    CheckboxComponent,
    SpinnerComponent,
    DatePickerComponent,
    ZengineLoginListComponent,
    ZengineLoginEditComponent,
    // Custom Components
    CustomTableComponent,
    // Pages
    SchoolProfilePageComponent,
    SchoolDashboardPageComponent,
    FamilyProfilePageComponent,
    FamilyDashboardPageComponent,
    FamilyAwardStatusPageComponent,
    StudentProfilePageComponent,
    VendorDashboardPageComponent,
    VendorProfilePageComponent,
    AdminDashboardPageComponent,
    AdminScholarshipSettingsPageComponent,
    AdminScholarshipPageComponent,
    AdminAllSchoolsComponent,
    AdminSchoolProfilePageComponent,
    AdminAllstudentsComponent,
    AdminUsersPageComponent,
    AdminUserProfilePageComponent,
    AdminVendorsPageComponent,
    AdminVendorProfilePageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    RouterModule,
    HttpClientModule,
    RecaptchaV3Module,
    // PrimeNG
    TableModule,
    SidebarModule,
    ScrollPanelModule,
    DropdownModule,
    CalendarModule,
    InputNumberModule,
    RadioButtonModule,
    ConfirmDialogModule,
    MenuModule,
    ProgressBarModule,
    TabViewModule,
    InputSwitchModule,
    OverlayPanelModule,
    BreadcrumbModule,
    TooltipModule,
    CheckboxModule,
    InputTextareaModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LfCx8cZAAAAABl2xHpTe3k34OubBNSoKPpAzOK7',
    },
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(private injector: Injector) {
    // Create global Service Injector.
    ServiceLocator.injector = this.injector;
  }
}
