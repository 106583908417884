import { Component, OnInit, Directive } from '@angular/core';
import { INoteTypeListParams } from '../../models/entities/NoteTypeModel.response';
import { NoteTypeModel } from '../../models/entities/NoteTypeModel';
import { PagingData } from '../../paging/paging.component';
import { NoteTypeListBaseComponent } from './noteTypeList.base';

@Component({
    selector: 'app-noteTypeList',
    templateUrl: './noteTypeList.component.html',
})
export class NoteTypeListComponent extends NoteTypeListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
