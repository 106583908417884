import { Component, OnInit, Directive } from '@angular/core';
import { StudentDisabilityLookUpCheckBoxListByStudentBase } from './studentDisabilityLookUpCheckBoxListByStudent.base';
@Component({
  selector: 'app-studentDisabilityLookUpCheckBoxListByStudent',
  templateUrl: './studentDisabilityLookUpCheckBoxListByStudent.component.html',
})
export class StudentDisabilityLookUpCheckBoxListByStudentComponent extends StudentDisabilityLookUpCheckBoxListByStudentBase implements OnInit {
  constructor() { super(); }
  ngOnInit() { super.baseNgOnInit(); }
  
}
