import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { LazyLoadEvent, SelectItem } from 'primeng/api';

import { ITableColumnDefinition } from '../../../interfaces/table-column-definition-interface';
import { FieldTypeDefinition } from '../../../types/field-type-definition-type';
import { aaaAPIAdminService } from '../../../services/aaa.API.admin.service';
import { ScholarshipModel } from '../../../models/entities/ScholarshipModel';
import { StateModel } from '../../../models/entities/StateModel';
import { CONSTANTS } from '../../../constants/constants';
import { ScholarshipDisbursementCategoriesLookUpModel } from '../../../models/entities/ScholarshipDisbursementCategoriesLookUpModel';
import { ScholarshipDisbursementCategoryModel } from '../../../models/entities/ScholarshipDisbursementCategoryModel';
import { ScholarshipHandbookModel } from '../../../models/entities/ScholarshipHandbookModel';
import { DocumentModel } from '../../../models/entities/DocumentModel';
import { BaseComponent } from '../../../BaseComponent';
import { AccreditationsLookUpModel } from '../../../models/entities/AccreditationsLookUpModel';
import { SchoolEligibilityModel } from '../../../models/entities/SchoolEligibilityModel';
import { AccreditationModel } from '../../../models/entities/AccreditationModel';
import { InsertResponse, ManagerResponse } from 'src/app/models/models';
import { SchoolEligibilityModelBase } from 'src/app/models/entities/SchoolEligibilityModel.base';
import { SchoolEligibilitiesStateLookUpModel } from 'src/app/models/entities/SchoolEligibilitiesStateLookUpModel';

@Component({
  selector: 'app-admin-scholarship-page',
  templateUrl: './admin-scholarship-page.component.html',
  styleUrls: ['./admin-scholarship-page.component.scss']
})
export class AdminScholarshipPageComponent extends BaseComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input('id') id: number;
  searchCategoryText: string = '';
  pageNumber: number = 1;
  schoolYearOptions: SelectItem[] = [];
  schoolYear: SelectItem[] = [];
  state: StateModel = null;
  stateOptions: SelectItem[] = [];
  stateSelected: StateModel;
  selectedScholarship: ScholarshipModel = null;
  scholarshipOption: SelectItem = null;
  scholarshipOptions: SelectItem[] = [];
  accreditationTypeOptions: SelectItem[] = [];
  stateList: StateModel[] = [];
  DisbursementCategoryID: number = 0;
  scholarship: ScholarshipModel = new ScholarshipModel();
  disbursementCategory: ScholarshipDisbursementCategoriesLookUpModel = new ScholarshipDisbursementCategoriesLookUpModel();
  stateScholarship: StateModel = new StateModel();

  editViewDisplay: boolean = false;
  editViewDisplayCategory: boolean = false;
  editSchoolAccreditationViewDisplay: boolean = false;
  editSchoolEligibilityViewDisplay: boolean = false;

  question1: string;
  disbursementCategories: ScholarshipDisbursementCategoriesLookUpModel[] = [];
  categoryModel: ScholarshipDisbursementCategoryModel = new ScholarshipDisbursementCategoryModel();

  scholarshipHandbookModel: ScholarshipHandbookModel = null;
  handbookDocumentModel: DocumentModel = null;
  fileModel: any;

  paymentsTableData: any[] = [];
  originalPaymentsTableData: any[] = [];
  paymentsTableColumns: ITableColumnDefinition[] = [];

  disbursementCategoriesTableData: any[] = [];
  disbursementCategoriesTableColumns: ITableColumnDefinition[] = [];
  disbursementCategoriesTableLoading: boolean = false;
  disbursementCategoriesTotalRecords: number = null;
  lastDisbursementCategoriesLazyLoadEvent: LazyLoadEvent = null;
  lastDisbursementCategoriesSearchText: string = null;
  searchDisbursementCategoriesSubscription: Subscription;

  accreditationsList: AccreditationModel[] = [];
  accreditationModel: AccreditationModel = new AccreditationModel;
  accreditationsTableData: any[] = [];
  accreditationsTableColumns: ITableColumnDefinition[] = [];
  accreditationsTableLoading: boolean = false;
  accreditationsTableTotalRecords: number = null;
  lastAccreditationsLazyLoadEvent: LazyLoadEvent = null;
  searchAccreditationText: string = null;
  lastAccreditationsSearchText: string = null;
  searchAccreditationsSubscription: Subscription;

  eligibilitiesList: SchoolEligibilityModel[] = [];
  eligibilityModel: SchoolEligibilityModel = new SchoolEligibilityModel;
  eligibilitiesTableData: any[] = [];
  eligibilitiesTableColumns: ITableColumnDefinition[] = [];
  eligibilitiesTableLoading: boolean = false;
  eligibilitiesTableTotalRecords: number = null;
  lastEligibilitiesLazyLoadEvent: LazyLoadEvent = null;
  searchEligibilitiesText: string = null;
  lastEligibilitiesSearchText: string = null;
  searchEligibilitiesSubscription: Subscription;

  constructor(
    private adminService: aaaAPIAdminService,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute, null);
  }

  ngOnInit(): void {
    this.route.params
      .subscribe((params) => {
        this.id = parseInt(params['id']);

        this.adminService.getScholarship(this.id)
          .subscribe((scholarshipResponse) => {
            this.scholarship = scholarshipResponse.Data;
            this.adminService.getState(this.scholarship.FK_State)
              .subscribe((stateResponse) => {
                this.stateScholarship = stateResponse.Data;
                // Only to show the default Scholarship on the search options
                this.scholarshipOptions = [
                  {
                    label: this.scholarship.Name,
                    value: this.scholarship.PK_Id.toString()
                  }
                ];
                this.loadDisburmentCategoriesData(CONSTANTS.FirstLazyLoadEvent);
                let firstLazyLoadEvent = CONSTANTS.FirstLazyLoadEvent;
                firstLazyLoadEvent.rows = 5;
                this.loadAccreditationsData(firstLazyLoadEvent);
                this.loadEligibilitiesData(firstLazyLoadEvent);
                this.loadScholarshipHandbookData();
              });
            this.adminService.getAccreditationTypeList({})
              .subscribe(responseAT => {
                this.accreditationTypeOptions = [
                  ...responseAT.Data.map(item => {
                    return {
                      label: item.ListDisplay,
                      value: item.PK_Id
                    } as SelectItem;
                  })
                ];
              })
          });

        this.adminService.getStateList({ active: true })
          .subscribe(responseStates => {
            this.stateList = [...responseStates.Data];
          });

        this.adminService.getSchoolYearList({ _sort: 'Name' })
          .subscribe(schoolYearResponse => {
            this.schoolYearOptions = [
              {
                label: 'All School Years',
                value: null
              },
              ...schoolYearResponse.Data.map(item => {
                return {
                  label: item.ListDisplay,
                  value: item
                } as SelectItem;
              })
            ];
          });
        this.generatePaymentsTableData();
      });
  }

  doChangePage(pageNumber: number) {
    if (pageNumber != this.pageNumber) {
      this.pageNumber = pageNumber;
    }
  }

  doScholarshipEdit() {
    this.editViewDisplay = true;
  }

  doCategoryEdit(e) {
    this.DisbursementCategoryID = e.DisbursementCategoryID;
    this.adminService.getScholarshipDisbursementCategoriesLookUpList({ pK_Id: this.DisbursementCategoryID })
      .subscribe(response => {
        this.disbursementCategory = response.Data[0];
      });
    this.editViewDisplayCategory = true;
  }

  private generatePaymentsTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Payment',
      fieldName: 'Payment',
      fieldType: FieldTypeDefinition.String,
      sortable: true,
    });
    columns.push({
      fieldCaption: 'Notify Parents/Schools',
      fieldName: 'NotifyParentsSchools',
      fieldType: FieldTypeDefinition.Date,
    });
    columns.push({
      fieldCaption: 'Verification Reports Due',
      fieldName: 'VerificationReportsDue',
      fieldType: FieldTypeDefinition.Date,
    });
    columns.push({
      fieldCaption: 'ACH Payment Sent',
      fieldName: 'ACHPaymentSent',
      fieldType: FieldTypeDefinition.Date,
    });
    this.paymentsTableColumns = [...columns];
  }

  private randomDates() {
    let start = new Date(2018, 0, 1);
    let end = new Date();
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }

  private generatePaymentsTableData() {
    this.generatePaymentsTableColumns();
    this.paymentsTableData = [];
    this.adminService.getScholarshipPaymentsDeadlineList({ fK_Scholarship: this.id })
      .subscribe(payment => {
        this.paymentsTableData.push(payment.Data);
      });
    this.originalPaymentsTableData = this.paymentsTableData;
  }

  isValidProfile(): boolean {
    const model = this.scholarship;
    return (
      model.Name &&
      model.Name.trim().length > 0 &&
      model.LengthOfScholarshipYears &&
      model.LengthOfScholarshipYears > 0);
  }

  saveScholarship() {
    this.adminService.updateScholarship(this.scholarship).subscribe(res => {
      if (res.Success) {
        this.editViewDisplay = false;
      }
    });
  }

  saveDisbursementCategory() {
    if (this.disbursementCategory.PK_Id > 0) {
      this.adminService.updateScholarshipDisbursementCategoriesLookUp(this.disbursementCategory)
        .subscribe(res => {
          if (res.Success) {
            this.loadDisburmentCategoriesData(this.lastDisbursementCategoriesLazyLoadEvent);
          }
        });
      this.categoryModel.Name = this.disbursementCategory.FK_ScholarshipDisbursementCategory_Display;
      this.categoryModel.PK_Id = this.disbursementCategory.FK_ScholarshipDisbursementCategory;
      this.adminService.updateScholarshipDisbursementCategory(this.categoryModel)
        .subscribe(res => {
          if (res.Success) {
            this.editViewDisplayCategory = false;
          }
        });
    }
    else {
      this.categoryModel.Name = this.disbursementCategory.FK_ScholarshipDisbursementCategory_Display;
      this.adminService.insertScholarshipDisbursementCategory(this.categoryModel)
        .subscribe(res => {
          if (res.Success) {
            this.disbursementCategory.FK_ScholarshipDisbursementCategory = res.Data;
            this.disbursementCategory.FK_ScholarshipDisbursementCategory_Display = this.categoryModel.Name;
            this.disbursementCategory.FK_Scholarship = this.scholarship.PK_Id;
            this.disbursementCategory.FK_Scholarship_Display = this.scholarship.Name;
            this.adminService.insertScholarshipDisbursementCategoriesLookUp(this.disbursementCategory)
              .subscribe(res => {
                if (res.Success) {
                  this.loadDisburmentCategoriesData(this.lastDisbursementCategoriesLazyLoadEvent);
                  this.editViewDisplayCategory = false;
                }
              })
          }
        });
    }
  }

  addCategory() {
    this.editViewDisplayCategory = true;
  }

  onSearchTextChanged() {
    if (this.searchDisbursementCategoriesSubscription) {
      this.searchDisbursementCategoriesSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchDisbursementCategoriesSubscription = source
      .subscribe(() => {
        this.loadDisburmentCategoriesData(this.lastDisbursementCategoriesLazyLoadEvent);
        this.searchDisbursementCategoriesSubscription.unsubscribe();
      });
  }

  changeState() {
    this.loadDisburmentCategoriesData(this.lastDisbursementCategoriesLazyLoadEvent);
  }

  loadDisburmentCategoriesData(event: LazyLoadEvent) {
    if (event) {
      this.lastDisbursementCategoriesLazyLoadEvent = event;
      this.disbursementCategoriesTableLoading = true;
      const page = (this.searchCategoryText !== this.lastDisbursementCategoriesSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const statefilter = this.stateScholarship.Name;
      const scholarshipfilter = this.scholarshipOption;
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.searchCategoryText && this.searchCategoryText.trim().length > 0) ? this.searchCategoryText : null;
      this.lastDisbursementCategoriesSearchText = this.searchCategoryText;

      this.adminService.doSearchDisbursementCategories
        (searchText, statefilter, scholarshipfilter?.value, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseDisbursementCategories => {
          this.disbursementCategoriesTotalRecords = responseDisbursementCategories.RecordsCount;
          this.disbursementCategories = responseDisbursementCategories.Data;
          this.generateDisbursementCategoriesTableData();
          this.disbursementCategoriesTableLoading = false;
        });
    }
  }

  private generateDisbursementCategoriesTableData() {
    this.generateDisbursementCategoriesTableColumn();
    this.disbursementCategoriesTableData = this.disbursementCategories.map(i => {
      const iconPreAuth = `fas fa-2x ${(!i.PreAuthorizationRequired) ? 'fa-times-circle icon-red' : 'fa-check-circle icon-green'}`;
      const iconLicensedInstructors = `fas fa-2x ${(!i.LicensedInstructors) ? 'fa-times-circle icon-red' : 'fa-check-circle icon-green'}`;
      const iconDocumentUploadForApproval = `fas fa-2x ${(!i.DocumentUploadForApproval) ? 'fa-times-circle icon-red' : 'fa-check-circle icon-green'}`;
      return {
        'DisbursementCategoryID': i.PK_Id,
        'Status': `fas fa-2x fa-check-circle icon-green`,
        'Category': i.FK_ScholarshipDisbursementCategory_Display,
        'State': this.stateScholarship.Name,
        'PreAuth': iconPreAuth,
        'LicensedProfessional': iconLicensedInstructors,
        'DocumentUploadForApproval': iconDocumentUploadForApproval,
      }
    });
  }

  private generateDisbursementCategoriesTableColumn() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Status',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.FontAwesomeIcon,
    });
    columns.push({
      fieldCaption: 'Vendor Category',
      fieldName: 'Category',
      fieldType: FieldTypeDefinition.String,
      width: '30%'
    });
    columns.push({
      fieldCaption: 'State',
      fieldName: 'State',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'PreAuth',
      fieldName: 'PreAuth',
      fieldType: FieldTypeDefinition.FontAwesomeIcon,
      showTooltip: false,
    });
    columns.push({
      fieldCaption: 'Licensed Professional',
      fieldName: 'LicensedProfessional',
      fieldType: FieldTypeDefinition.FontAwesomeIcon,
      showTooltip: false,
    });
    columns.push({
      fieldCaption: 'Document Upload For Approval',
      fieldName: 'DocumentUploadForApproval',
      fieldType: FieldTypeDefinition.FontAwesomeIcon,
      showTooltip: false,
    });
    this.disbursementCategoriesTableColumns = [...columns];
  }

  cancelScholarship() {
    this.editViewDisplay = false;
  }

  cancelDisbursementCategory() {
    this.editViewDisplayCategory = false;
  }

  /*
    School Accreditation and Eligibilities Methods
  */

  loadAccreditationsData(event: LazyLoadEvent) {
    if (event) {
      this.lastAccreditationsLazyLoadEvent = event;
      this.accreditationsTableLoading = true;
      const page = (this.searchAccreditationText !== this.lastAccreditationsSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const state = this.scholarship.FK_State.toString();
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.searchAccreditationText && this.searchAccreditationText.trim().length > 0) ? this.searchAccreditationText : null;
      this.lastAccreditationsSearchText = this.searchAccreditationText;

      this.adminService.doSearchAccreditations(searchText, state, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseAccreditations => {
          this.accreditationsTableTotalRecords = responseAccreditations.RecordsCount;
          this.accreditationsList = responseAccreditations.Data;
          this.generateAccreditationTableData();
          this.accreditationsTableLoading = false;
        });
    }
  }

  private generateAccreditationTableData() {
    this.generateAccreditationTableColumn();
    this.accreditationsTableData = this.accreditationsList.map(item => {
      return {
        'PK_Id': item.PK_Id,
        'Name': item.Name,
        'ShortName': item.ShortName,
        'Type': item.FK_AccreditationType_Display,
        'Model': item
      }
    });
  }

  private generateAccreditationTableColumn() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Accreditation Name',
      fieldName: 'Name',
      fieldType: FieldTypeDefinition.String,
      width: '50%',
      sortable: true
    });
    columns.push({
      fieldCaption: 'Accreditation Short Name',
      fieldName: 'ShortName',
      fieldType: FieldTypeDefinition.String,
      width: '30%',
      sortable: true
    });
    columns.push({
      fieldCaption: 'Accreditation Type',
      fieldName: 'Type',
      fieldType: FieldTypeDefinition.String,
      showTooltip: false,
    });
    this.accreditationsTableColumns = [...columns];
  }

  onSearchAccreditationTextChanged() {
    if (this.searchAccreditationsSubscription) {
      this.searchAccreditationsSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchAccreditationsSubscription = source
      .subscribe(() => {
        this.loadAccreditationsData(this.lastAccreditationsLazyLoadEvent);
        this.searchAccreditationsSubscription.unsubscribe();
      });
  }

  loadEligibilitiesData(event: LazyLoadEvent) {
    if (event) {
      this.lastEligibilitiesLazyLoadEvent = event;
      this.eligibilitiesTableLoading = true;
      const page = (this.searchEligibilitiesText !== this.lastEligibilitiesSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const state = this.scholarship.FK_State.toString();
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.searchEligibilitiesText && this.searchEligibilitiesText.trim().length > 0) ? this.searchEligibilitiesText : null;
      this.lastEligibilitiesSearchText = this.searchEligibilitiesText;

      this.adminService.doSearchEligibilities(searchText, state, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseEligibilities => {
          this.eligibilitiesTableTotalRecords = responseEligibilities.RecordsCount;
          this.eligibilitiesList = responseEligibilities.Data;
          this.generateEligibilitiesTableData();
          this.eligibilitiesTableLoading = false;
        });
    }
  }

  private generateEligibilitiesTableData() {
    this.generateEligibilitiesTableColumn();
    this.eligibilitiesTableData = this.eligibilitiesList.map(item => {
      const iconForStudent = `fas fa-2x ${(item.ForStudent) ? 'fa-check-circle icon-green' : 'fa-times-circle icon-red'}`;
      const iconForSchool = `fas fa-2x ${(item.ForSchool) ? 'fa-check-circle icon-green' : 'fa-times-circle icon-red'}`;
      return {
        'PK_Id': item.PK_Id,
        'HtmlContent': item.HtmlContent,
        'ForStudentIcon': iconForStudent,
        'ForSchoolIcon': iconForSchool,
        'Model': item
      }
    });
  }

  private generateEligibilitiesTableColumn() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Content',
      fieldName: 'HtmlContent',
      fieldType: FieldTypeDefinition.HTML,
      width: '60%',
      sortable: true
    });
    columns.push({
      fieldCaption: 'For Student',
      fieldName: 'ForStudentIcon',
      fieldType: FieldTypeDefinition.FontAwesomeIcon
    });
    columns.push({
      fieldCaption: 'For School',
      fieldName: 'ForSchoolIcon',
      fieldType: FieldTypeDefinition.FontAwesomeIcon
    });
    this.eligibilitiesTableColumns = [...columns];
  }

  onSearchEligibilitiesTextChanged() {
    if (this.searchEligibilitiesSubscription) {
      this.searchEligibilitiesSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchEligibilitiesSubscription = source
      .subscribe(() => {
        this.loadEligibilitiesData(this.lastEligibilitiesLazyLoadEvent);
        this.searchEligibilitiesSubscription.unsubscribe();
      });
  }

  addAccreditation() {
    this.accreditationModel = new AccreditationModel;
    this.editSchoolAccreditationViewDisplay = true;
  }

  doAccreditationEdit(row: any) {
    this.accreditationModel = { ...row.Model as AccreditationModel };
    this.editSchoolAccreditationViewDisplay = true;
  }

  saveAccreditation() {
    let service: Observable<ManagerResponse | InsertResponse> = null;
    if (this.accreditationModel.PK_Id > 0) {
      service = this.adminService.updateAccreditation(this.accreditationModel);
    } else {
      this.accreditationModel.FK_State = this.scholarship.FK_State;
      service = this.adminService.insertAccreditation(this.accreditationModel);
    }
    service.subscribe(responseAccreditation => {
      if (responseAccreditation.Success) {
        this.editSchoolAccreditationViewDisplay = false;
        this.loadAccreditationsData(this.lastAccreditationsLazyLoadEvent);
      }
    });
  }

  cancelAccreditation() {
    this.editSchoolAccreditationViewDisplay = false;
  }

  isValidAccreditation(): boolean {
    const model = this.accreditationModel;
    return (
      model.Name &&
      model.Name.trim().length > 0 &&
      model.ShortName &&
      model.ShortName.trim().length > 0);
  }

  addEligibility() {
    this.eligibilityModel = new SchoolEligibilityModel;
    this.editSchoolEligibilityViewDisplay = true;
  }

  doEligibilityEdit(row: any) {
    this.eligibilityModel = { ...row.Model as SchoolEligibilityModel };
    this.editSchoolEligibilityViewDisplay = true;
  }

  saveEligibilities() {
    let service: Observable<ManagerResponse | InsertResponse> = null;
    if (this.eligibilityModel.PK_Id > 0) {
      service = this.adminService.updateSchoolEligibility(this.eligibilityModel);
    } else {
      this.eligibilityModel.IsHeaderContentOnly = null;
      this.eligibilityModel.RadioButton = false;
      this.eligibilityModel.FK_SchoolEligibility_Parent = null;
      service = this.adminService.insertSchoolEligibility(this.eligibilityModel);
    }
    service.subscribe(responseEligibility => {
      if (responseEligibility.Success) {
        if (this.eligibilityModel.PK_Id == 0) {
          let eligibilityStateModel = new SchoolEligibilitiesStateLookUpModel;
          eligibilityStateModel.FK_SchoolEligibility = (responseEligibility as InsertResponse).Data;
          eligibilityStateModel.FK_State = this.scholarship.FK_State;
          this.adminService.insertSchoolEligibilitiesStateLookUp(eligibilityStateModel)
            .subscribe(responseSESL => {
              if (responseSESL.Success) {
                this.editSchoolEligibilityViewDisplay = false;
                this.loadEligibilitiesData(this.lastEligibilitiesLazyLoadEvent);
              }
            });
        } else {
          this.editSchoolEligibilityViewDisplay = false;
          this.loadEligibilitiesData(this.lastEligibilitiesLazyLoadEvent);
        }
      }
    });
  }

  cancelEligibilities() {
    this.editSchoolEligibilityViewDisplay = false;
  }

  isValidEligibility(): boolean {
    const model = this.eligibilityModel;
    return (
      model.HtmlContent &&
      model.HtmlContent.trim().length > 0)
  }

  /*
    Award Matrices Methods
  */

  /*
    Handbook Methods
  */

  private loadScholarshipHandbookData() {
    this.adminService.getScholarshipHandbookList({ fK_Scholarship: this.scholarship.PK_Id })
      .subscribe(responseSH => {
        if (responseSH.Success && responseSH.Data.length > 0) {
          this.scholarshipHandbookModel = responseSH.Data[0];
          this.adminService.getDocument(this.scholarshipHandbookModel.FK_Document)
            .subscribe(responseDocument => {
              if (responseDocument.Success) {
                this.handbookDocumentModel = responseDocument.Data;
              }
            });
        }
      });
  }

  saveHandbookDocument() {
    const model = this.scholarshipHandbookModel;
    if (model.PK_Id && model.PK_Id > 0) {
      this.adminService.updateScholarshipHandbook(model)
        .subscribe(response => {
          if (response.Success) {
            this.loadScholarshipHandbookData();
          }
        });
    } else {
      this.adminService.insertScholarshipHandbook(model)
        .subscribe(response => {
          if (response.Success) {
            this.loadScholarshipHandbookData();
          }
        });
    }
  }

  processFile(event: any) {
    const file = event.target.files[0];
    if (file) {
      if (!this.scholarshipHandbookModel) {
        this.scholarshipHandbookModel = new ScholarshipHandbookModel;
      }
      this.scholarshipHandbookModel.CreatedDate = moment().toDate();
      this.scholarshipHandbookModel.FileName = file.name;
      this.scholarshipHandbookModel.FileExtension = '.pdf';
      this.scholarshipHandbookModel.FK_Login_CreatedBy = this.gl.LoginId;
      this.scholarshipHandbookModel.FK_Scholarship = this.scholarship.PK_Id;
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        const binaryData = evt.target.result;
        this.scholarshipHandbookModel.FileData = btoa(binaryData);
        this.fileInput.nativeElement.value = '';
        this.saveHandbookDocument();
      };
      reader.readAsBinaryString(file);
    }
  }

  showHandbook() {
    let pdfWindow = window.open("")
    pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.scholarshipHandbookModel.FileData) + "'></iframe>");
  }
}
