import { Component, OnInit, Directive } from '@angular/core';
import { IAccreditationsLookUpListParams } from '../../models/entities/AccreditationsLookUpModel.response';
import { AccreditationsLookUpModel } from '../../models/entities/AccreditationsLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { AccreditationsLookUpListBaseComponent } from './accreditationsLookUpList.base';

@Component({
    selector: 'app-accreditationsLookUpList',
    templateUrl: './accreditationsLookUpList.component.html',
})
export class AccreditationsLookUpListComponent extends AccreditationsLookUpListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
