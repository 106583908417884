import { Component, OnInit, Directive } from '@angular/core';
import { ISchoolTransactionListParams,SchoolTransactionListParams } from '../../models/entities/SchoolTransactionModel.response';
import { SchoolTransactionModel} from '../../models/entities/SchoolTransactionModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class SchoolTransactionListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<SchoolTransactionModel> = <Array<SchoolTransactionModel>>[];
	public search: SchoolTransactionListParams = new SchoolTransactionListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getSchoolTransactionList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.pK_Id  = null;
		this.search.createdDate  = null;
		this.search.createdDate_fromDate  = null;
		this.search.createdDate_toDate  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp_fromValue  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp_toValue  = null;
		this.search.fK_TransactionStatusType  = null;
		this.search.fK_TransactionStatusType_display  = null;
		this.search.fK_TransactionStatusType_like  = null;
		this.search.fK_TransactionStatusType_fromValue  = null;
		this.search.fK_TransactionStatusType_toValue  = null;
		this.search.fK_TransactionType  = null;
		this.search.fK_TransactionType_display  = null;
		this.search.fK_TransactionType_like  = null;
		this.search.fK_TransactionType_fromValue  = null;
		this.search.fK_TransactionType_toValue  = null;
		this.search.amount  = null;
		this.search.amount_fromValue  = null;
		this.search.amount_toValue  = null;
		this.search.fK_Verification  = null;
		this.search.fK_Verification_display  = null;
		this.search.fK_Verification_like  = null;
		this.search.fK_Verification_fromValue  = null;
		this.search.fK_Verification_toValue  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deleteSchoolTransaction(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManySchoolTransaction(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
