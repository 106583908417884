import { SchoolCalendarLookUpModel} from '../entities/SchoolCalendarLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolCalendarLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolCalendarLookUpModel = new SchoolCalendarLookUpModel();
}

export class SchoolCalendarLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolCalendarLookUpModel> = <Array<SchoolCalendarLookUpModel >>[]
}

export interface ISchoolCalendarLookUpListParams {
	pK_Id?:number;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	schoolYearStart?:Date;
	schoolYearStart_fromDate?:string;
	schoolYearStart_toDate?:string;
	schoolYearEnd?:Date;
	schoolYearEnd_fromDate?:string;
	schoolYearEnd_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolCalendarLookUpListParams implements ISchoolCalendarLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	schoolYearStart?:Date=null;
	schoolYearStart_fromDate?:string=null;
	schoolYearStart_toDate?:string=null;
	schoolYearEnd?:Date=null;
	schoolYearEnd_fromDate?:string=null;
	schoolYearEnd_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

