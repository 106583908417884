import { AmazonRequestModel} from '../entities/AmazonRequestModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class AmazonRequestResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: AmazonRequestModel = new AmazonRequestModel();
}

export class AmazonRequestList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<AmazonRequestModel> = <Array<AmazonRequestModel >>[]
}

export interface IAmazonRequestListParams {
	pK_Id?:number;
	fK_PreAuthorization?:number;
	fK_PreAuthorization_display?:string;
	fK_PreAuthorization_like?:string;
	fK_PreAuthorization_fromValue?:number;
	fK_PreAuthorization_toValue?:number;
	shoppingListUrl?:string;
	shoppingListUrl_like?:string;
	numberOfItems?:number;
	numberOfItems_fromValue?:number;
	numberOfItems_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class AmazonRequestListParams implements IAmazonRequestListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_PreAuthorization?:number=null;
	fK_PreAuthorization_display?:string=null;
	fK_PreAuthorization_like?:string=null;
	fK_PreAuthorization_fromValue?:number=null;
	fK_PreAuthorization_toValue?:number=null;
	shoppingListUrl?:string=null;
	shoppingListUrl_like?:string=null;
	numberOfItems?:number=null;
	numberOfItems_fromValue?:number=null;
	numberOfItems_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

