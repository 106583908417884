import {IListItem } from '../models';
export class ContactsLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_Contact:number = 0;
	public FK_Contact_Display:string = null;
	public FK_School?:number = null;
	public FK_School_Display:string = null;
	public FK_Vendor?:number = null;
	public FK_Vendor_Display:string = null;
}
