import {  OnInit, Directive } from '@angular/core';
import { BaseCheckBoxListComponent } from '../../BaseCheckBoxListComponent';
@Directive()
export class StudentDisabilityLookUpCheckBoxListByStudentBase extends BaseCheckBoxListComponent  {
	constructor() {  super();   }
	protected baseNgOnInit() { super.baseNgOnInit(); }
	protected getData() {
	var sub = this.api.getStudentDisabilityLookUpCheckBoxListByStudent(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.getDataComplete();
			}
			sub.unsubscribe();
		});
	}

	public save() {
		var sub = this.api.saveStudentDisabilityLookUpCheckBoxListByStudent(this.id, this.model).subscribe(x => {
			if (x.Success) { this.saved.emit(null);  }
			sub.unsubscribe();
		 });
	}
}



