import { TransactionTypeModel} from '../entities/TransactionTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class TransactionTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: TransactionTypeModel = new TransactionTypeModel();
}

export class TransactionTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<TransactionTypeModel> = <Array<TransactionTypeModel >>[]
}

export interface ITransactionTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class TransactionTypeListParams implements ITransactionTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

