import { Component, OnInit } from '@angular/core';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';
import { PasswordResetPost } from '../../models/models';


@Component({
  selector: 'app-scholarship-manager',
  templateUrl: './scholarship-manager.component.html'
})
export class ScholarshipManagerComponent extends BaseEditComponent implements OnInit {

  constructor(r: ActivatedRoute) {
    super(r);
  }

 

  ngOnInit(): void {
    super.baseNgOnInit();

  }

  protected getData() {

    console.log(this.id);
  }


}
