import { Component, OnInit, Directive } from '@angular/core';
import {  IFeeDiscountListParams } from '../../models/entities/FeeDiscountModel.response';
import { FeeDiscountModel } from '../../models/entities/FeeDiscountModel';
import { PagingData } from '../../paging/paging.component';
import { FeeDiscountListBaseComponent } from './feeDiscountList.base';

@Component({
	selector: 'app-feeDiscountList',
	templateUrl: './feeDiscountList.component.html',
})
export class FeeDiscountListComponent extends FeeDiscountListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
