import { GuardianReimbursementModel} from '../entities/GuardianReimbursementModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class GuardianReimbursementResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: GuardianReimbursementModel = new GuardianReimbursementModel();
}

export class GuardianReimbursementList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<GuardianReimbursementModel> = <Array<GuardianReimbursementModel >>[]
}

export interface IGuardianReimbursementListParams {
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number;
	amount?:number;
	amount_fromValue?:number;
	amount_toValue?:number;
	fK_GuardianReimbursementStatusType?:number;
	fK_GuardianReimbursementStatusType_display?:string;
	fK_GuardianReimbursementStatusType_like?:string;
	fK_GuardianReimbursementStatusType_fromValue?:number;
	fK_GuardianReimbursementStatusType_toValue?:number;
	pK_Id?:number;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	fK_Login_ApprovedBy?:string;
	fK_Login_ApprovedBy_display?:string;
	fK_Login_ApprovedBy_like?:string;
	dateApproved?:Date;
	dateApproved_fromDate?:string;
	dateApproved_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class GuardianReimbursementListParams implements IGuardianReimbursementListParams, ISearchModel  {
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number=null;
	amount?:number=null;
	amount_fromValue?:number=null;
	amount_toValue?:number=null;
	fK_GuardianReimbursementStatusType?:number=null;
	fK_GuardianReimbursementStatusType_display?:string=null;
	fK_GuardianReimbursementStatusType_like?:string=null;
	fK_GuardianReimbursementStatusType_fromValue?:number=null;
	fK_GuardianReimbursementStatusType_toValue?:number=null;
	pK_Id?:number=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	fK_Login_ApprovedBy?:string=null;
	fK_Login_ApprovedBy_display?:string=null;
	fK_Login_ApprovedBy_like?:string=null;
	dateApproved?:Date=null;
	dateApproved_fromDate?:string=null;
	dateApproved_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

