import { Component, OnInit, Directive } from '@angular/core';
import { ISchoolCommitmentNoteListParams } from '../../models/entities/SchoolCommitmentNoteModel.response';
import { SchoolCommitmentNoteModel } from '../../models/entities/SchoolCommitmentNoteModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolCommitmentNoteListBaseComponent } from './schoolCommitmentNoteList.base';

@Component({
    selector: 'app-schoolCommitmentNoteList',
    templateUrl: './schoolCommitmentNoteList.component.html',
})
export class SchoolCommitmentNoteListComponent extends SchoolCommitmentNoteListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
