import {IListItem } from '../models';
export class StateModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public Name:string = "";
	public SchoolDOECodeRequired?:boolean = null;
	public AccreditationRequired?:boolean = null;
	public Active?:boolean = null;
	public PrepaidCollegeTransfer?:boolean = null;
	public Abbreviation?:string = null;
}
