import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';


export class PagingChanged {
  public PageSize: number = 0;
  public Page: number = 0;

}

export class PagingData {
  public Pages: number = 0;
  public Page: number = 1;
  public RecordsCount: number = 0;
  public PageSize: number =50;


}



@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.css']
})
export class PagingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadData();
  }

  public pagesList: Array<number> = <Array<number>>[1];

  public _data: PagingData = null;

  @Input('data')
  set data(value: PagingData) {
    this._data = value;
    this.loadData();
  }

  @Input()
  public showpagesize: boolean = true;

  @Output() changed: EventEmitter<PagingChanged> = new EventEmitter<PagingChanged>();

  public maxPage: number = 0;

  private loadData() {
    var me = this;
    me.pagesList.length = 0;
    me.maxPage = parseInt((me._data.RecordsCount / me._data.PageSize).toString()) + 1;
    console.log("me.maxPage=" + me.maxPage);
    //var index = 1;
    var cnt = 0;
    var index = this._data.Page - 15;
    index = index >= 1 ? index : 1;
    for (var i = ((index-1) * me._data.PageSize)+1; i <= me._data.RecordsCount; i = i + me._data.PageSize) {
      if (cnt <= 30) {
        me.pagesList.push(index);
        index++;
      }
      cnt++;
    }
  }

  public changePage(value: number) {
    if (value < 1) {
      value = 1;
    }

    if (value > this.maxPage) {
      value = this.maxPage;
    }

    //this._page = value;
    this.changed.emit(<PagingChanged>{ Page: value, PageSize: this._data.PageSize });
    this.loadData();
    
  }

  public changePageSize(value: any) {

    //this._pagesize = parseInt(value);
    this.changed.emit(<PagingChanged>{ Page: 1, PageSize: parseInt(value) });
    this.loadData();
    
  }

  public trackByFn(index, item: number) {

    return item;
  }

}
