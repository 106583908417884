import { Component, OnInit, Directive } from '@angular/core';
import { ITransactionTypeListParams } from '../../models/entities/TransactionTypeModel.response';
import { TransactionTypeModel } from '../../models/entities/TransactionTypeModel';
import { PagingData } from '../../paging/paging.component';
import { TransactionTypeListBaseComponent } from './transactionTypeList.base';

@Component({
    selector: 'app-transactionTypeList',
    templateUrl: './transactionTypeList.component.html',
})
export class TransactionTypeListComponent extends TransactionTypeListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
