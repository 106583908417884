import { Component, OnInit, Directive } from '@angular/core';
import { IVerificationNoteListParams } from '../../models/entities/VerificationNoteModel.response';
import { VerificationNoteModel } from '../../models/entities/VerificationNoteModel';
import { PagingData } from '../../paging/paging.component';
import { VerificationNoteListBaseComponent } from './verificationNoteList.base';

@Component({
    selector: 'app-verificationNoteList',
    templateUrl: './verificationNoteList.component.html',
})
export class VerificationNoteListComponent extends VerificationNoteListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
