import { Component, OnInit, Directive } from '@angular/core';
import {  IGuardianListParams } from '../../models/entities/GuardianModel.response';
import { GuardianModel } from '../../models/entities/GuardianModel';
import { PagingData } from '../../paging/paging.component';
import { GuardianListBaseComponent } from './guardianList.base';

@Component({
	selector: 'app-guardianList',
	templateUrl: './guardianList.component.html',
})
export class GuardianListComponent extends GuardianListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
