<div *ngIf="modalEdit||newModalEdit" class="modal" [style.left]="leftOffset" [style.top]="topOffset">
  <app-scholarshipDisbursementCategoriesLookUpEdit [modalview]="true" [id]="modalEdit" [level]="(level+.5)" (saved)="modalEditSaved($event)" (cancelled)="modalEdit=null;newModalEdit=false"></app-scholarshipDisbursementCategoriesLookUpEdit>
</div>
<div class="card">
  <div class="card-header">
    Scholarship Disbursement Categories Look Up List <a class="right"  (click)="newModalEdit = true" ><i class="fa fa-plus" title="Add Scholarship Disbursement Categories Look Up"></i></a>
    <div><app-search-input [placeholdertext]="'Search'" (changed)="fuzzySearchChange($event)" ></app-search-input></div>

  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div style="grid-template-columns: 100px auto auto auto auto auto auto ;" class="table">
          <div class="table-header" style="text-align:left">
            <i (click)="selectAll()" title="Select All" class="fa" [ngClass]="{ 'fa-check-square-o':selectAllStatus,'fa-square-o':!selectAllStatus}"></i>
            <i title='Delete Many' (click)='deleteMany()' class='fa fa-trash icon-link'></i>
          </div>
          <div class="table-header" (click)="sortChange('FK_Scholarship_Display')">Scholarship <i *ngIf="search._sort=='FK_Scholarship_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_ScholarshipDisbursementCategory_Display')">Scholarship Disbursement Category <i *ngIf="search._sort=='FK_ScholarshipDisbursementCategory_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('LicensedInstructors')">Licensed Instructors <i *ngIf="search._sort=='LicensedInstructors'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('PreAuthorizationRequired')">Pre Authorization Required <i *ngIf="search._sort=='PreAuthorizationRequired'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('DocumentUploadForApproval')">Document Upload For Approval <i *ngIf="search._sort=='DocumentUploadForApproval'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('IsEnabled')">Is Enabled <i *ngIf="search._sort=='IsEnabled'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>


          <div><i title="Filter" (click)="searchSelected()" class="fa fa-filter icon-link"></i><i title="Filter" (click)="clearSearch()" class="fa fa-times icon-link"></i></div>
          <div class="center"><input class="filter-list" placeholder="Search" [(ngModel)]="search.fK_Scholarship_like" /></div>
          <div class="center"><input class="filter-list" placeholder="Search" [(ngModel)]="search.fK_ScholarshipDisbursementCategory_like" /></div>
          <div  class="center">
            <app-checkbox [nullable]="true" [(model)]="search.licensedInstructors_booleanValue" ></app-checkbox>
          </div>
          <div  class="center">
            <app-checkbox [nullable]="true" [(model)]="search.preAuthorizationRequired_booleanValue" ></app-checkbox>
          </div>
          <div  class="center">
            <app-checkbox [nullable]="true" [(model)]="search.documentUploadForApproval_booleanValue" ></app-checkbox>
          </div>
          <div  class="center">
            <app-checkbox [nullable]="true" [(model)]="search.isEnabled_booleanValue" ></app-checkbox>
          </div>


          <ng-container *ngFor="let x of model">
            <div>
              <span class='edit-tools'>
                <i (click)="x.Selected = !x.Selected" class="fa" [ngClass]="{ 'fa-check-square-o':x.Selected,'fa-square-o':!x.Selected}"></i>
                <a (click)="modalEdit = x.PK_Id"><i title='Edit' class='fa fa-pencil icon-link'></i></a> <i title='Delete' (click)='delete(x.PK_Id )' class='fa fa-trash icon-link'></i>
              </span>
            </div>
            <div>
              {{x.FK_Scholarship_Display}}
            </div>
            <div>
              {{x.FK_ScholarshipDisbursementCategory_Display}}
            </div>
            <div>
              <i *ngIf="x.LicensedInstructors" class='fa fa-check' ></i>
            </div>
            <div>
              <i *ngIf="x.PreAuthorizationRequired" class='fa fa-check' ></i>
            </div>
            <div>
              <i *ngIf="x.DocumentUploadForApproval" class='fa fa-check' ></i>
            </div>
            <div>
              <i *ngIf="x.IsEnabled" class='fa fa-check' ></i>
            </div>

          </ng-container>
        </div>

        <app-paging [showpagesize]="true" [data]="pagingData" (changed)="pagingChanged($event)"></app-paging>
      </div>
    </div>
  </div>
</div>

<app-spinner [showspinner]="ShowSpinner"></app-spinner>

