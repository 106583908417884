import { AwardAcceptanceStatusLookUpModel} from '../entities/AwardAcceptanceStatusLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class AwardAcceptanceStatusLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: AwardAcceptanceStatusLookUpModel = new AwardAcceptanceStatusLookUpModel();
}

export class AwardAcceptanceStatusLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<AwardAcceptanceStatusLookUpModel> = <Array<AwardAcceptanceStatusLookUpModel >>[]
}

export interface IAwardAcceptanceStatusLookUpListParams {
	pK_Id?:number;
	fK_AwardDetermination?:number;
	fK_AwardDetermination_display?:string;
	fK_AwardDetermination_like?:string;
	fK_AwardDetermination_fromValue?:number;
	fK_AwardDetermination_toValue?:number;
	fK_AwardAcceptanceStatusType?:number;
	fK_AwardAcceptanceStatusType_display?:string;
	fK_AwardAcceptanceStatusType_like?:string;
	fK_AwardAcceptanceStatusType_fromValue?:number;
	fK_AwardAcceptanceStatusType_toValue?:number;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class AwardAcceptanceStatusLookUpListParams implements IAwardAcceptanceStatusLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_AwardDetermination?:number=null;
	fK_AwardDetermination_display?:string=null;
	fK_AwardDetermination_like?:string=null;
	fK_AwardDetermination_fromValue?:number=null;
	fK_AwardDetermination_toValue?:number=null;
	fK_AwardAcceptanceStatusType?:number=null;
	fK_AwardAcceptanceStatusType_display?:string=null;
	fK_AwardAcceptanceStatusType_like?:string=null;
	fK_AwardAcceptanceStatusType_fromValue?:number=null;
	fK_AwardAcceptanceStatusType_toValue?:number=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

