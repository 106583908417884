import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentEditBaseComponent } from './studentEdit.base';

@Component({
	selector: 'app-studentEdit',
	templateUrl: './studentEdit.component.html',
})
export class StudentEditComponent extends StudentEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
