import { VendorTransactionNoteModel} from '../entities/VendorTransactionNoteModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class VendorTransactionNoteResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: VendorTransactionNoteModel = new VendorTransactionNoteModel();
}

export class VendorTransactionNoteList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<VendorTransactionNoteModel> = <Array<VendorTransactionNoteModel >>[]
}

export interface IVendorTransactionNoteListParams {
	pK_Id?:number;
	fK_VendorTransaction?:number;
	fK_VendorTransaction_display?:string;
	fK_VendorTransaction_like?:string;
	fK_VendorTransaction_fromValue?:number;
	fK_VendorTransaction_toValue?:number;
	fK_NoteType?:number;
	fK_NoteType_display?:string;
	fK_NoteType_like?:string;
	fK_NoteType_fromValue?:number;
	fK_NoteType_toValue?:number;
	note?:string;
	note_like?:string;
	noteActive?:boolean;
	noteActive_booleanValue?:boolean;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class VendorTransactionNoteListParams implements IVendorTransactionNoteListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_VendorTransaction?:number=null;
	fK_VendorTransaction_display?:string=null;
	fK_VendorTransaction_like?:string=null;
	fK_VendorTransaction_fromValue?:number=null;
	fK_VendorTransaction_toValue?:number=null;
	fK_NoteType?:number=null;
	fK_NoteType_display?:string=null;
	fK_NoteType_like?:string=null;
	fK_NoteType_fromValue?:number=null;
	fK_NoteType_toValue?:number=null;
	note?:string=null;
	note_like?:string=null;
	noteActive?:boolean=null;
	noteActive_booleanValue?:boolean=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

