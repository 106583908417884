import {IListItem } from '../models';
export class SchoolTransactionModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public CreatedDate:Date = null;
	public FK_StudentGradeLevelsSchoolYearsLookUp:number = 0;
	public FK_TransactionStatusType:number = 0;
	public FK_TransactionStatusType_Display:string = null;
	public FK_TransactionType:number = 0;
	public FK_TransactionType_Display:string = null;
	public Amount:number = 0;
	public FK_Verification?:number = null;
	public FK_Verification_Display:string = null;
}
