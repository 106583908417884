import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolListParams } from '../../models/entities/SchoolModel.response';
import { SchoolModel } from '../../models/entities/SchoolModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolListBaseComponent } from './schoolList.base';

@Component({
	selector: 'app-schoolList',
	templateUrl: './schoolList.component.html',
})
export class SchoolListComponent extends SchoolListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
