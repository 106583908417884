import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { ScholarshipDisbursementCategoryModel } from '../../../models/entities/ScholarshipDisbursementCategoryModel';
import { BaseComponent } from '../../../BaseComponent';
import { ITableColumnDefinition } from '../../../interfaces/table-column-definition-interface';
import { SchoolCommitmentModel } from '../../../models/entities/SchoolCommitmentModel';
import { SchoolCommitmentStatusTypes } from '../../../models/models';
import { aaaAPIAdminService } from '../../../services/aaa.API.admin.service';
import { FieldTypeDefinition } from '../../../types/field-type-definition-type';
import { Subscription } from 'rxjs';
import { CONSTANTS } from 'src/app/constants/constants';

@Component({
  selector: 'app-admin-dashboard-page',
  templateUrl: './admin-dashboard-page.component.html',
  styleUrls: ['./admin-dashboard-page.component.scss']
})
export class AdminDashboardPageComponent extends BaseComponent implements OnInit {
  scfRequestTableData: any[] = [];
  scfRequestTableColumns: ITableColumnDefinition[] = [];
  verificationReportsTableData: any[] = [];
  verificationReportsTableColumns: ITableColumnDefinition[] = [];
  paymentRequestTableData: any[] = [];
  paymentRequestTableColumns: ITableColumnDefinition[] = [];
  withdrawalRequestTableData: any[] = [];
  withdrawalRequestTableColumns: ITableColumnDefinition[] = [];
  vendorRequestTableData: any[] = [];
  vendorRequestTableColumns: ITableColumnDefinition[] = [];

  schoolCommitmentList: SchoolCommitmentModel[] = [];

  reimbursementSearchText: string = null;
  reimbursementActive: boolean = false;
  categoryOptions: SelectItem[] = [];
  categorySelected: ScholarshipDisbursementCategoryModel = null;

  studentTableData: any[] = [];
  lastLazyLoadEvent: LazyLoadEvent = null;
  lastSearchText: string = null;
  usersTableLoading: boolean = false;
  usersTableTotalRecords: number = null;
  searchSubscription: Subscription;
  studentModels: SchoolCommitmentModel[] = [];

  constructor(
    private adminService: aaaAPIAdminService
  ) {
    super(null, false);
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {

    this.generateVerificationReportsTableData();
    this.generatePaymentRequestTableData();
    this.generateVendorRequestTableData();
    this.loadStudentData(CONSTANTS.FirstLazyLoadEvent);
  }

  /*
    SCF Request Methods
  */
  private generateSCFRequestTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Student Name',
      fieldName: 'StudentName',
      fieldType: FieldTypeDefinition.String,
      sortable: true
    });
    columns.push({
      fieldCaption: 'Grade',
      fieldName: 'GradeLevel',
      fieldType: FieldTypeDefinition.Integer,
    });
    columns.push({
      fieldCaption: 'Guardian Name',
      fieldName: 'GuardianName',
      fieldType: FieldTypeDefinition.String,
      width: '25%'
    });
    columns.push({
      fieldCaption: 'Status Form Type',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.String,
    });

    this.scfRequestTableColumns = [...columns];
  }

  private generateSCFRequestTableData() {
    this.generateSCFRequestTableColumns();
    this.studentTableData = this.studentModels.map(item => {
      return {
        'FK_Student': item.FK_Student,
        'StudentName': item.FK_Student_Display,
        'FK_SchoolYear': item.FK_SchoolYear,
        'FK_SchoolYear_Display': item.FK_SchoolYear_Display,
        'GradeLevel': '',
        'GuardianName': '',
        'Status': item.FK_SchoolCommitmentStatusType_Display,
        'AwardDeterminationData': null,
        'PK_Id': item.PK_Id
      }
    });
    this.studentTableData.forEach(item => {
      // Getting Award Determination Data and Guardian Name
      this.adminService.getAwardDeterminationList({ fK_Student: item.FK_Student, fK_SchoolYear: item.fK_SchoolYear })
        .subscribe(response => {
          if (response.Data.length > 0) {
            const awardDetermination = response.Data[0];
            item.GradeLevel = awardDetermination.FK_GradeLevel_Display;
            item.GuardianName = awardDetermination.FK_Guardian_Display;
            item.AwardDeterminationData = awardDetermination;
          }
        });
    });
  }

  loadStudentData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.usersTableLoading = true;
      this.adminService.getSchoolCommitmentList({ fK_SchoolCommitmentStatusType: SchoolCommitmentStatusTypes.PendingAAAReview })
        .subscribe(responseSCF => {
          this.studentModels = responseSCF.Data;
          this.generateSCFRequestTableData();
          this.usersTableLoading = false;
        });
    }
  }
  /*
    Verification Reports Methods
  */
  private generateVerificationReportsTableColumns() {
    this.verificationReportsTableColumns = [];
    this.verificationReportsTableColumns.push({
      fieldCaption: 'Status',
      fieldName: 'StatusIcon',
      fieldType: FieldTypeDefinition.FontAwesomeIcon
    });
    this.verificationReportsTableColumns.push({
      fieldCaption: 'Student Name',
      fieldName: 'StudentName',
      fieldType: FieldTypeDefinition.String,
      sortable: true,
      width: '20%'
    });
    this.verificationReportsTableColumns.push({
      fieldCaption: 'Grade',
      fieldName: 'FK_GradeLevel_Display',
      fieldType: FieldTypeDefinition.String
    });
    this.verificationReportsTableColumns.push({
      fieldCaption: 'Guardian Name',
      fieldName: 'GuardianName',
      fieldType: FieldTypeDefinition.String,
      width: '20%'
    });
    this.verificationReportsTableColumns.push({
      fieldCaption: 'School Name',
      fieldName: 'SchoolName',
      fieldType: FieldTypeDefinition.String,
      width: '20%'
    });
    this.verificationReportsTableColumns.push({
      fieldCaption: 'State',
      fieldName: 'StateAbbreviation',
      fieldType: FieldTypeDefinition.String,
    });
    this.verificationReportsTableColumns.push({
      fieldCaption: 'Scholarship',
      fieldName: 'FK_ScholarshipType_Display',
      fieldType: FieldTypeDefinition.String,
      width: '20%'
    });
  }

  private generateVerificationReportsTableData() {
    this.generateVerificationReportsTableColumns();
  }

  /*
    Payment Request Methods
  */
  private generatePaymentRequestTableColumns() {
    this.paymentRequestTableColumns = [];
    this.paymentRequestTableColumns.push({
      fieldCaption: 'Requested Date',
      fieldName: 'RequestedDate',
      fieldType: FieldTypeDefinition.Date,
      sortable: true,
      width: '190px'
    });
    this.paymentRequestTableColumns.push({
      fieldCaption: 'Request Type',
      fieldName: 'RequestType',
      fieldType: FieldTypeDefinition.String
    });
    this.paymentRequestTableColumns.push({
      fieldCaption: 'Student',
      fieldName: 'StudentName',
      fieldType: FieldTypeDefinition.String,
      width: '20%'
    });
    this.paymentRequestTableColumns.push({
      fieldCaption: 'Vendor',
      fieldName: 'VendorName',
      fieldType: FieldTypeDefinition.String,
      width: '30%'
    });
    this.paymentRequestTableColumns.push({
      fieldCaption: 'Amount',
      fieldName: 'Amount',
      fieldType: FieldTypeDefinition.Money
    });
    this.paymentRequestTableColumns.push({
      fieldCaption: 'Status',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.String
    });
  }

  private generatePaymentRequestTableData() {
    this.generatePaymentRequestTableColumns();
  }

  /*
    New Vendors Request Methods
  */

  private generateVendorRequestTableColumns() {
    this.vendorRequestTableColumns = [];
    this.vendorRequestTableColumns.push({
      fieldCaption: 'Vendor Status',
      fieldName: 'VendorStatusIcon',
      fieldType: FieldTypeDefinition.FontAwesomeIcon
    });
    this.vendorRequestTableColumns.push({
      fieldCaption: 'Vendor/Service Provider Name',
      fieldName: 'VendorName',
      fieldType: FieldTypeDefinition.String,
      sortable: true,
      width: '20%'
    });
    this.vendorRequestTableColumns.push({
      fieldCaption: 'Address',
      fieldName: 'VendorAddress',
      fieldType: FieldTypeDefinition.String,
      width: '20%'
    });
    this.vendorRequestTableColumns.push({
      fieldCaption: 'Instructor',
      fieldName: 'VendorInstructor',
      fieldType: FieldTypeDefinition.String,
      width: '15%'
    });
    this.vendorRequestTableColumns.push({
      fieldCaption: 'Categories',
      fieldName: 'VendorCategories',
      fieldType: FieldTypeDefinition.String,
      width: '30%'
    });
  }

  private generateVendorRequestTableData() {
    this.generateVendorRequestTableColumns();
  }
}
