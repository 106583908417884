import { SchoolScholarshipsLookUpModel} from '../entities/SchoolScholarshipsLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolScholarshipsLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolScholarshipsLookUpModel = new SchoolScholarshipsLookUpModel();
}

export class SchoolScholarshipsLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolScholarshipsLookUpModel> = <Array<SchoolScholarshipsLookUpModel >>[]
}

export interface ISchoolScholarshipsLookUpListParams {
	pK_Id?:number;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	fK_Scholarship?:number;
	fK_Scholarship_display?:string;
	fK_Scholarship_like?:string;
	fK_Scholarship_fromValue?:number;
	fK_Scholarship_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolScholarshipsLookUpListParams implements ISchoolScholarshipsLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	fK_Scholarship?:number=null;
	fK_Scholarship_display?:string=null;
	fK_Scholarship_like?:string=null;
	fK_Scholarship_fromValue?:number=null;
	fK_Scholarship_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

