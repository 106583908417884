import { Component, OnInit, Directive } from '@angular/core';
import {  IGuardianTypeListParams } from '../../models/entities/GuardianTypeModel.response';
import { GuardianTypeModel } from '../../models/entities/GuardianTypeModel';
import { PagingData } from '../../paging/paging.component';
import { GuardianTypeListBaseComponent } from './guardianTypeList.base';

@Component({
	selector: 'app-guardianTypeList',
	templateUrl: './guardianTypeList.component.html',
})
export class GuardianTypeListComponent extends GuardianTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
