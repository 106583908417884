import { ManagerResponse, ISearchModel, IListItem } from '../models';
import { ContactModel } from './ContactModel';
import { LoginModelBase } from './LoginModel.base'
export class LoginModel extends LoginModelBase {

    public ContactModel: ContactModel = null;
    public Password: string = null;
    public Password2: string = null;

}

