import { AwardDeterminationModel} from '../entities/AwardDeterminationModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class AwardDeterminationResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: AwardDeterminationModel = new AwardDeterminationModel();
}

export class AwardDeterminationList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<AwardDeterminationModel> = <Array<AwardDeterminationModel >>[]
}

export interface IAwardDeterminationListParams {
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	fK_Guardian?:number;
	fK_Guardian_display?:string;
	fK_Guardian_like?:string;
	fK_Guardian_fromValue?:number;
	fK_Guardian_toValue?:number;
	fK_Scholarship?:number;
	fK_Scholarship_display?:string;
	fK_Scholarship_like?:string;
	fK_Scholarship_fromValue?:number;
	fK_Scholarship_toValue?:number;
	fK_Student?:number;
	fK_Student_display?:string;
	fK_Student_like?:string;
	fK_Student_fromValue?:number;
	fK_Student_toValue?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	fK_GradeLevel?:number;
	fK_GradeLevel_display?:string;
	fK_GradeLevel_like?:string;
	fK_GradeLevel_fromValue?:number;
	fK_GradeLevel_toValue?:number;
	pK_Id?:number;
	wasNotificationSent?:boolean;
	wasNotificationSent_booleanValue?:boolean;
	isDeleted?:boolean;
	isDeleted_booleanValue?:boolean;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	awardAmount?:number;
	awardAmount_fromValue?:number;
	awardAmount_toValue?:number;
	determinationReason?:string;
	determinationReason_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class AwardDeterminationListParams implements IAwardDeterminationListParams, ISearchModel  {
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	fK_Guardian?:number=null;
	fK_Guardian_display?:string=null;
	fK_Guardian_like?:string=null;
	fK_Guardian_fromValue?:number=null;
	fK_Guardian_toValue?:number=null;
	fK_Scholarship?:number=null;
	fK_Scholarship_display?:string=null;
	fK_Scholarship_like?:string=null;
	fK_Scholarship_fromValue?:number=null;
	fK_Scholarship_toValue?:number=null;
	fK_Student?:number=null;
	fK_Student_display?:string=null;
	fK_Student_like?:string=null;
	fK_Student_fromValue?:number=null;
	fK_Student_toValue?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	fK_GradeLevel?:number=null;
	fK_GradeLevel_display?:string=null;
	fK_GradeLevel_like?:string=null;
	fK_GradeLevel_fromValue?:number=null;
	fK_GradeLevel_toValue?:number=null;
	pK_Id?:number=null;
	wasNotificationSent?:boolean=null;
	wasNotificationSent_booleanValue?:boolean=null;
	isDeleted?:boolean=null;
	isDeleted_booleanValue?:boolean=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	awardAmount?:number=null;
	awardAmount_fromValue?:number=null;
	awardAmount_toValue?:number=null;
	determinationReason?:string=null;
	determinationReason_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

