import { Component, OnInit, Directive } from '@angular/core';
import {  IZengineLoginListParams } from '../../models/entities/ZengineLoginModel.response';
import { ZengineLoginModel } from '../../models/entities/ZengineLoginModel';
import { PagingData } from '../../paging/paging.component';
import { ZengineLoginListBaseComponent } from './zengineLoginList.base';

@Component({
	selector: 'app-zengineLoginList',
	templateUrl: './zengineLoginList.component.html',
})
export class ZengineLoginListComponent extends ZengineLoginListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
