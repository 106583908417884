<div class="card">
  <div class="card-header">Custom Developed Components</div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div><a routerLink='/userloginlist'>User Logins</a></div>
        <div><a routerLink='/zenginesync'>Zengine Sync Forms</a></div>
        
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-header">Auto Generated Components</div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">

        <!--NavigationLinksStart-->
       
 
<div><a routerLink='/accreditationlist'>Accreditation</a></div>
<div><a routerLink='/accreditationtypelist'>Accreditation Type</a></div>
<div><a routerLink='/awarddeterminationlist'>Award Determination</a></div>
<div><a routerLink='/awarddeterminationstatustypelist'>Award Determination Status Type</a></div>
<div><a routerLink='/contactlist'>Contact</a></div>
<div><a routerLink='/countylist'>County</a></div>
<div><a routerLink='/disabilitylist'>Disability</a></div>
<div><a routerLink='/feediscountlist'>Fee Discount</a></div>
<div><a routerLink='/feetypelist'>Fee Type</a></div>
<div><a routerLink='/gendertypelist'>Gender Type</a></div>
<div><a routerLink='/gradelevellist'>Grade Level</a></div>
<div><a routerLink='/gradingperiodtypelist'>Grading Period Type</a></div>
<div><a routerLink='/guardianlist'>Guardian</a></div>
<div><a routerLink='/guardianreimbursementlist'>Guardian Reimbursement</a></div>
<div><a routerLink='/guardianreimbursementlistitemlist'>Guardian Reimbursement List Item</a></div>
<div><a routerLink='/guardianreimbursementstatustypelist'>Guardian Reimbursement Status Type</a></div>
<div><a routerLink='/guardiantypelist'>Guardian Type</a></div>
<div><a routerLink='/householdlist'>Household</a></div>
<div><a routerLink='/loginlist'>Login</a></div>
<div><a routerLink='/loginroleslookuplist'>Login Roles Look Up</a></div>
<div><a routerLink='/preauthorizationlist'>Pre Authorization</a></div>
<div><a routerLink='/preauthorizationstatustypelist'>Pre Authorization Status Type</a></div>
<div><a routerLink='/prepaidcollegetransferlist'>Prepaid College Transfer</a></div>
<div><a routerLink='/prepaidcollegetransferstatustypelist'>Prepaid College Transfer Status Type</a></div>
<div><a routerLink='/racetypelist'>Race Type</a></div>
<div><a routerLink='/rolelist'>Role</a></div>
<div><a routerLink='/scholarshiplist'>Scholarship</a></div>
<div><a routerLink='/scholarshiptypelist'>Scholarship Type</a></div>
<div><a routerLink='/schoollist'>School</a></div>
<div><a routerLink='/schoolaccreditationslookuplist'>School Accreditations Look Up</a></div>
<div><a routerLink='/schoolcommitmentlist'>School Commitment</a></div>
<div><a routerLink='/schoolcommitmentfeeslookuplist'>School Commitment Fees Look Up</a></div>
<div><a routerLink='/schoolcommitmentstatustypelist'>School Commitment Status Type</a></div>
<div><a routerLink='/schoolcontactslookuplist'>School Contacts Look Up</a></div>
<div><a routerLink='/schooleligibilitiesstatelookuplist'>School Eligibilities State Look Up</a></div>
<div><a routerLink='/schooleligibilitylist'>School Eligibility</a></div>
<div><a routerLink='/schoolfeelist'>School Fee</a></div>
<div><a routerLink='/schoolgradelevelslookuplist'>School Grade Levels Look Up</a></div>
<div><a routerLink='/schoolscholarshipslookuplist'>School Scholarships Look Up</a></div>
<div><a routerLink='/schooltransactionlist'>School Transaction</a></div>
<div><a routerLink='/schooltransactionstatustypelist'>School Transaction Status Type</a></div>
<div><a routerLink='/schoolyearlist'>School Year</a></div>
<div><a routerLink='/statelist'>State</a></div>
<div><a routerLink='/studentlist'>Student</a></div>
<div><a routerLink='/studentgradelevelsschoolyearslookuplist'>Student Grade Levels School Years Look Up</a></div>
<div><a routerLink='/vendorlist'>Vendor</a></div>
<div><a routerLink='/vendoraccreditationslookuplist'>Vendor Accreditations Look Up</a></div>
<div><a routerLink='/vendortransactionlist'>Vendor Transaction</a></div>
<div><a routerLink='/verificationlist'>Verification</a></div>
<div><a routerLink='/withdrawlist'>Withdraw</a></div>
<div><a routerLink='/zenginemappinglookuplist'>Zengine Mapping Look Up</a></div>
<div><a routerLink='/zenginemappingoptionlist'>Zengine Mapping Option</a></div>
<div><a routerLink='/zengineformlist'>Zengine Form</a></div>
<div><a routerLink='/zengineformfieldlist'>Zengine Form Field</a></div>

<div><a routerLink='/zengineformtypelist'>Zengine Form Type</a></div>
<div><a routerLink='/schooltypelist'>School Type</a></div>
<div><a routerLink='/zengineformstatelookuplist'>Zengine Form State Look Up</a></div>
<div><a routerLink='/studentdisabilitylookuplist'>Student Disability Look Up</a></div>
<div><a routerLink='/schoolcalendarlookuplist'>School Calendar Look Up</a></div>
<div><a routerLink='/schooleligibilitiesresponslist'>School Eligibilities Respons</a></div>
<div><a routerLink='/notetypelist'>Note Type</a></div>
<div><a routerLink='/verificationnotelist'>Verification Note</a></div>
<div><a routerLink='/withdrawnotelist'>Withdraw Note</a></div>
<div><a routerLink='/vendorcategorieslookuplist'>Vendor Categories Look Up</a></div>
<div><a routerLink='/vendorcategorylist'>Vendor Category</a></div>
<div><a routerLink='/accreditationslookuplist'>Accreditations Look Up</a></div>
<div><a routerLink='/contactslookuplist'>Contacts Look Up</a></div>
<div><a routerLink='/schoolcommitmentnotelist'>School Commitment Note</a></div>
<div><a routerLink='/schooltransactionnotelist'>School Transaction Note</a></div>
<div><a routerLink='/transactionstatustypelist'>Transaction Status Type</a></div>
<div><a routerLink='/transactiontypelist'>Transaction Type</a></div>
<div><a routerLink='/vendorserviceproviderlist'>Vendor Service Provider</a></div>
<div><a routerLink='/documentlist'>Document</a></div>
<div><a routerLink='/studentnotelist'>Student Note</a></div>
<div><a routerLink='/vendortransactionnotelist'>Vendor Transaction Note</a></div>
<div><a routerLink='/scholarshipdisbursementcategorylist'>Scholarship Disbursement Category</a></div>
<div><a routerLink='/scholarshippaymentsdeadlinelist'>Scholarship Payments Deadline</a></div>
<div><a routerLink='/awardacceptancestatuslookuplist'>Award Acceptance Status Look Up</a></div>
<div><a routerLink='/awardacceptancestatustypelist'>Award Acceptance Status Type</a></div>
<div><a routerLink='/awarddeterminationstatuslookuplist'>Award Determination Status Look Up</a></div>
<div><a routerLink='/scholarshipdisbursementcategorieslookuplist'>Scholarship Disbursement Categories Look Up</a></div>
<div><a routerLink='/scholarshiphandbooklist'>Scholarship Handbook</a></div>
<div><a routerLink='/zengineloginlist'>Zengine Login</a></div>
<div><a routerLink='/zengineformyearlookuplist'>Zengine Form Year Look Up</a></div>
<div><a routerLink='/amazonrequestlist'>Amazon Request</a></div>
<div><a routerLink='/verificationstatustypelist'>Verification Status Type</a></div>
<div><a routerLink='/withdrawreasontypelist'>Withdraw Reason Type</a></div>
<!--NavigationLinksEnd-->
      </div>
    </div>
  </div>
</div>
