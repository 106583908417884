import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalsService } from './globals.service';
import { ErrorMessagesService } from './error-messages.service';
import { aaaAPIServiceBase } from './aaa.API.service.base';
import { Observable } from 'rxjs';
import { GuardianResponse } from '../models/entities/GuardianModel.response';
import { catchError, map } from 'rxjs/operators';
import { AwardDeterminationStatusTypeModel } from '../models/entities/AwardDeterminationStatusTypeModel';
import { AwardDeterminationStatusTypeResponse } from '../models/entities/AwardDeterminationStatusTypeModel.response';

@Injectable({
  providedIn: 'root'
})
export class aaaAPIFamilyService extends aaaAPIServiceBase {

  constructor(_http: HttpClient, _gl: GlobalsService, _errorMessagesService: ErrorMessagesService) {
    super(_http, _gl, _errorMessagesService);
  }

  public getGuardianByLogin(login_id: string): Observable<GuardianResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Guardian/GetByLoginId/?login_id=${login_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <GuardianResponse>res.body;
    }));
  }

  public getCurrentStatusAwardDetermination(pk_id: number): Observable<AwardDeterminationStatusTypeResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/AwardDetermination/GetCurrentStatusAD/${pk_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <AwardDeterminationStatusTypeResponse>res.body;
    }));
  }
}