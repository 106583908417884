import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WithdrawNoteEditBaseComponent } from './withdrawNoteEdit.base';

@Component({
    selector: 'app-withdrawNoteEdit',
    templateUrl: './withdrawNoteEdit.component.html',
})
export class WithdrawNoteEditComponent extends WithdrawNoteEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
