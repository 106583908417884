import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AmazonRequestEditBaseComponent } from './amazonRequestEdit.base';

@Component({
	selector: 'app-amazonRequestEdit',
	templateUrl: './amazonRequestEdit.component.html',
})
export class AmazonRequestEditComponent extends AmazonRequestEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
