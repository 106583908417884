import { StudentModel} from '../entities/StudentModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class StudentResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: StudentModel = new StudentModel();
}

export class StudentList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<StudentModel> = <Array<StudentModel >>[]
}

export interface IStudentListParams {
	pK_Id?:number;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	firstName?:string;
	firstName_like?:string;
	lastName?:string;
	lastName_like?:string;
	fK_GenderType?:number;
	fK_GenderType_display?:string;
	fK_GenderType_like?:string;
	fK_GenderType_fromValue?:number;
	fK_GenderType_toValue?:number;
	fK_RaceType?:number;
	fK_RaceType_display?:string;
	fK_RaceType_like?:string;
	fK_RaceType_fromValue?:number;
	fK_RaceType_toValue?:number;
	birthDate?:Date;
	birthDate_fromDate?:string;
	birthDate_toDate?:string;
	socialSecurityNumber?:string;
	socialSecurityNumber_like?:string;
	middleInitial?:string;
	middleInitial_like?:string;
	isDeleted?:boolean;
	isDeleted_booleanValue?:boolean;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class StudentListParams implements IStudentListParams, ISearchModel  {
	pK_Id?:number=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	firstName?:string=null;
	firstName_like?:string=null;
	lastName?:string=null;
	lastName_like?:string=null;
	fK_GenderType?:number=null;
	fK_GenderType_display?:string=null;
	fK_GenderType_like?:string=null;
	fK_GenderType_fromValue?:number=null;
	fK_GenderType_toValue?:number=null;
	fK_RaceType?:number=null;
	fK_RaceType_display?:string=null;
	fK_RaceType_like?:string=null;
	fK_RaceType_fromValue?:number=null;
	fK_RaceType_toValue?:number=null;
	birthDate?:Date=null;
	birthDate_fromDate?:string=null;
	birthDate_toDate?:string=null;
	socialSecurityNumber?:string=null;
	socialSecurityNumber_like?:string=null;
	middleInitial?:string=null;
	middleInitial_like?:string=null;
	isDeleted?:boolean=null;
	isDeleted_booleanValue?:boolean=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

