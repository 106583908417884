import { Component, OnInit, Directive } from '@angular/core';
import {  IScholarshipListParams } from '../../models/entities/ScholarshipModel.response';
import { ScholarshipModel } from '../../models/entities/ScholarshipModel';
import { PagingData } from '../../paging/paging.component';
import { ScholarshipListBaseComponent } from './scholarshipList.base';

@Component({
	selector: 'app-scholarshipList',
	templateUrl: './scholarshipList.component.html',
})
export class ScholarshipListComponent extends ScholarshipListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
