import { Injectable } from '@angular/core';
import { GlobalsService } from './globals.service';
import { ErrorMessagesService } from './error-messages.service';
import { aaaAPIServiceBase } from './aaa.API.service.base';
import { SchoolFeesResponse } from '../models/entities/custom/SchoolFeesModel.response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class aaaAPIStudentService extends aaaAPIServiceBase {
  constructor(
    _http: HttpClient,
    _gl: GlobalsService,
    _errorMessagesService: ErrorMessagesService
  ) {
    super(_http, _gl, _errorMessagesService);
  }

  public getFeesBySchool(id: number): Observable<SchoolFeesResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/SchoolFee/GetFeeBySchool/?id=' + id;
    console.log('Api Call: GetFeeBySchool: ' + url);
    return this.http
      .get(url, {
        headers: new HttpHeaders({ 'x-token': this.gl.Token }),
        observe: 'response',
      })
      .pipe(
        catchError(this.gl.handleError),
        map((res: any) => {
          console.log(res);
          this.gl.SetValidate(res);
          return <SchoolFeesResponse>res.body;
        })
      );
  }
}
