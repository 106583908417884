import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { RoleType } from './models/models';
import { GlobalsService } from './services/globals.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('popupMenuContainer', { static: true }) popupMenuContainer: ElementRef;
  menuItems: MenuItem[] = [];
  bItems: MenuItem[] = [];
  home: MenuItem = null;

  get getProfileRoute(): string {
    if (this.gl.LoginRoles) {
      if (this.gl.LoginRoles.includes(RoleType.SchoolAdmin) || this.gl.LoginRoles.includes(RoleType.SchoolUser)) {
        return 'schoolprofile';
      } else if (this.gl.LoginRoles.includes(RoleType.ServiceProviderAdmin) || this.gl.LoginRoles.includes(RoleType.ServiceProviderUser)) {
        return 'vendorprofile';
      }
      return 'familyprofile';
    }
    return '';
  }

  get getDashboardRoute(): string {
    if (this.gl.LoginRoles) {
      if (this.gl.LoginRoles.includes(RoleType.SchoolAdmin) || this.gl.LoginRoles.includes(RoleType.SchoolUser)) {
        return 'schooldashboard';
      } else if (this.gl.LoginRoles.includes(RoleType.ServiceProviderAdmin) || this.gl.LoginRoles.includes(RoleType.ServiceProviderUser)) {
        return 'vendordashboard';
      } else if (this.gl.LoginRoles.includes(RoleType.GuardianPrimary) || this.gl.LoginRoles.includes(RoleType.GuardianSecondary)) {
        return 'familydashboard';
      }
      return 'admindashboard';
    }
    return '';
  }

  get showBreadCrumb(): boolean {
    if (this.gl.LoginName) {
      return this.bItems.length > 0;
    }
    return false;
  }

  get someUserLoggedIn(): boolean {
    return (this.gl.LoginId && this.gl.LoginId.trim().length > 0);
  }

  get getUserName(): string {
    return this.gl.LoginFullName;
  }

  get isOnlyAdmin(): boolean {
    return this.gl.LoginRoles && this.gl.LoginRoles.length == 1 && this.gl.LoginRoles.includes(RoleType.Admin);
  }

  get isHiddenRoute(): boolean {
    const currentRoute = this.gl.currentRouter.url;
    const hiddenRoutes = [
      'userlogin',
      'familyawardstatus'
    ];
    const index = hiddenRoutes.findIndex(route => currentRoute.includes(route));

    return (index != -1);
  }

  constructor(private r: ActivatedRoute,
    public gl: GlobalsService) {
    //gl.EnvironmentCheck();
  }

  ngOnInit(): void {
    if (!this.gl.LoginTime) {
      this.logout();
      return;
    } else {
      if (((new Date().getTime() - this.gl.LoginTime.getTime()) / 60000) > 360) {
        this.logout();
        return;
      }
    }
    this.menuItems = this.getMenuItems();
    this.bItems = this.getBreadCrumbsItems();
    this.gl.currentRouter.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.menuItems = this.getMenuItems();
        this.bItems = this.getBreadCrumbsItems();
      }
    });
  }

  private getMenuItems(): MenuItem[] {
    let items: MenuItem[] = [
      {
        label: 'Profile',
        items: [
          {
            label: 'Account Settings',
            icon: 'fas fa-user-cog',
            routerLink: this.getProfileRoute
          }
        ]
      },
      {
        label: 'System',
        items: [
          {
            label: 'Logout',
            icon: 'fas fa-sign-out-alt',
            command: () => {
              this.logout();
            }
          }
        ]
      }
    ];
    if (this.gl.LoginRoles.includes(RoleType.Admin)) {
      items[1].items.splice(0, 0, {
        label: 'Users List',
        icon: 'fas fa-users',
        command: () => {
          this.gl.GoTo('adminallusers');
        }
      });
    }
    return items;
  }

  private getBreadCrumbsItems(): MenuItem[] {
    let items: MenuItem[] = [];

    this.home = {
      label: 'Dashboard',
      routerLink: this.getDashboardRoute,
    };

    const url = this.gl.currentRouter.url;

    if (url.includes('dashboard')) {
      return items;
    }

    if (url.startsWith('/school')) {
      items.push({
        label: 'School Profile'
      });
    } else if (url.startsWith('/family')) {
      items.push({
        label: this.gl.LoginFullName
      });
    } else if (url.startsWith('/vendor')) {
      items.push({
        label: 'Vendor Profile'
      });
    } else if (url.startsWith('/student')) {
      items.push({
        label: this.gl.SelectedStudent
      });
    } else if (url.startsWith('/admin')) {
      if (url.includes('user')) {
        if (url.includes('all')) {
          items.push({
            label: 'Users'
          });
        } else {
          items.push({
            label: 'Users',
            command: () => {
              this.gl.GoTo('adminallusers', null, null);
            },
          });
          items.push({
            label: this.gl.SelectedUser
          });
        }
      }
    }
    console.log('url changed');
    return items;
  }

  title = 'aaa-scholarships-web';

  public logout() {
    this.gl.LoginRoles = null;
    this.gl.LoginName = null;
    this.gl.LoginFullName = null;
    this.gl.LoginTime = null;
    this.gl.LoginId = null;

    this.gl.Token = null;
    console.log('logout');
    this.gl.GoTo("userlogin", null, null);
    this.bItems = [];
  }

  public onAdminMenuItemClick(item: string) {
    console.log(item);
    switch (item) {
      case 'Dashboard':
        this.gl.GoTo('admindashboard', null, null);
        break;
      case 'Families & Students':
        this.gl.GoTo('adminallstudents', null, null);
        break;
      case 'Schools':
        this.gl.GoTo('adminallschools', null, null);
        break;
      case 'Vendors':
        this.gl.GoTo('adminallvendors', null, null);
        break;
      case 'Scholarship Settings':
        this.gl.GoTo('adminscholarshipsettings', null, null);
        break;
      case 'Transactions':
        break;
    }
  }
}