import { AccreditationModel} from '../entities/AccreditationModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class AccreditationResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: AccreditationModel = new AccreditationModel();
}

export class AccreditationList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<AccreditationModel> = <Array<AccreditationModel >>[]
}

export interface IAccreditationListParams {
	pK_Id?:number;
	fK_State?:number;
	fK_State_display?:string;
	fK_State_like?:string;
	fK_State_fromValue?:number;
	fK_State_toValue?:number;
	shortName?:string;
	shortName_like?:string;
	fK_AccreditationType?:number;
	fK_AccreditationType_display?:string;
	fK_AccreditationType_like?:string;
	fK_AccreditationType_fromValue?:number;
	fK_AccreditationType_toValue?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class AccreditationListParams implements IAccreditationListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_State?:number=null;
	fK_State_display?:string=null;
	fK_State_like?:string=null;
	fK_State_fromValue?:number=null;
	fK_State_toValue?:number=null;
	shortName?:string=null;
	shortName_like?:string=null;
	fK_AccreditationType?:number=null;
	fK_AccreditationType_display?:string=null;
	fK_AccreditationType_like?:string=null;
	fK_AccreditationType_fromValue?:number=null;
	fK_AccreditationType_toValue?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

