import { ScholarshipDisbursementCategoriesLookUpModel} from '../entities/ScholarshipDisbursementCategoriesLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ScholarshipDisbursementCategoriesLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ScholarshipDisbursementCategoriesLookUpModel = new ScholarshipDisbursementCategoriesLookUpModel();
}

export class ScholarshipDisbursementCategoriesLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ScholarshipDisbursementCategoriesLookUpModel> = <Array<ScholarshipDisbursementCategoriesLookUpModel >>[]
}

export interface IScholarshipDisbursementCategoriesLookUpListParams {
	pK_Id?:number;
	fK_Scholarship?:number;
	fK_Scholarship_display?:string;
	fK_Scholarship_like?:string;
	fK_Scholarship_fromValue?:number;
	fK_Scholarship_toValue?:number;
	fK_ScholarshipDisbursementCategory?:number;
	fK_ScholarshipDisbursementCategory_display?:string;
	fK_ScholarshipDisbursementCategory_like?:string;
	fK_ScholarshipDisbursementCategory_fromValue?:number;
	fK_ScholarshipDisbursementCategory_toValue?:number;
	isEnabled?:boolean;
	isEnabled_booleanValue?:boolean;
	preAuthorizationRequired?:boolean;
	preAuthorizationRequired_booleanValue?:boolean;
	licensedInstructors?:boolean;
	licensedInstructors_booleanValue?:boolean;
	documentUploadForApproval?:boolean;
	documentUploadForApproval_booleanValue?:boolean;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ScholarshipDisbursementCategoriesLookUpListParams implements IScholarshipDisbursementCategoriesLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_Scholarship?:number=null;
	fK_Scholarship_display?:string=null;
	fK_Scholarship_like?:string=null;
	fK_Scholarship_fromValue?:number=null;
	fK_Scholarship_toValue?:number=null;
	fK_ScholarshipDisbursementCategory?:number=null;
	fK_ScholarshipDisbursementCategory_display?:string=null;
	fK_ScholarshipDisbursementCategory_like?:string=null;
	fK_ScholarshipDisbursementCategory_fromValue?:number=null;
	fK_ScholarshipDisbursementCategory_toValue?:number=null;
	isEnabled?:boolean=null;
	isEnabled_booleanValue?:boolean=null;
	preAuthorizationRequired?:boolean=null;
	preAuthorizationRequired_booleanValue?:boolean=null;
	licensedInstructors?:boolean=null;
	licensedInstructors_booleanValue?:boolean=null;
	documentUploadForApproval?:boolean=null;
	documentUploadForApproval_booleanValue?:boolean=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

