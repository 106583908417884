import { Component, OnInit, Directive } from '@angular/core';
import { IScholarshipPaymentsDeadlineListParams } from '../../models/entities/ScholarshipPaymentsDeadlineModel.response';
import { ScholarshipPaymentsDeadlineModel } from '../../models/entities/ScholarshipPaymentsDeadlineModel';
import { PagingData } from '../../paging/paging.component';
import { ScholarshipPaymentsDeadlineListBaseComponent } from './scholarshipPaymentsDeadlineList.base';

@Component({
    selector: 'app-scholarshipPaymentsDeadlineList',
    templateUrl: './scholarshipPaymentsDeadlineList.component.html',
})
export class ScholarshipPaymentsDeadlineListComponent extends ScholarshipPaymentsDeadlineListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
