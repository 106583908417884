import { Component, OnInit, Directive } from '@angular/core';
import {  IGenderTypeListParams } from '../../models/entities/GenderTypeModel.response';
import { GenderTypeModel } from '../../models/entities/GenderTypeModel';
import { PagingData } from '../../paging/paging.component';
import { GenderTypeListBaseComponent } from './genderTypeList.base';

@Component({
	selector: 'app-genderTypeList',
	templateUrl: './genderTypeList.component.html',
})
export class GenderTypeListComponent extends GenderTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
