import { Component, OnInit, Directive } from '@angular/core';
import { ZengineFormFieldModel } from '../../models/entities/ZengineFormFieldModel';
import { ListItemModel, IListItem, UpdateEvent } from '../../models/models';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class ZengineFormFieldEditBaseComponent extends BaseEditComponent  {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	public model: ZengineFormFieldModel = <ZengineFormFieldModel>{};
	public fK_ZengineForm_options:Array<ListItemModel> = <Array<ListItemModel>>[];

	protected baseNgOnInit() {
		super.baseNgOnInit();
	}


	protected loadOptions() {
				this.getFK_ZengineForm_Options();
	}

	protected getData() {
	this.ShowSpinner = true;
		var sub = this.api.getZengineFormField(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
			}
			this.ShowSpinner = false;
			this.getDataComplete();
			sub.unsubscribe();
		});
	}
	public getFK_ZengineForm_Options(clear?: boolean) {
		if (clear) {  this.model.FK_ZengineForm = null; }
		var sub = this.api.getZengineFormList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_ZengineForm_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateZengineFormField(this.model).subscribe(x=>{
			if (x.Success) {
				this.saved.emit(this.id);
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertZengineFormField(this.model).subscribe(x=>{
			if (x.Success) {
				this._id = x.Data;
				this.saved.emit(x.Data);
			}
			sub.unsubscribe();
		});
		}
	}
}
