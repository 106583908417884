import { SchoolEligibilitiesStateLookUpModel} from '../entities/SchoolEligibilitiesStateLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolEligibilitiesStateLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolEligibilitiesStateLookUpModel = new SchoolEligibilitiesStateLookUpModel();
}

export class SchoolEligibilitiesStateLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolEligibilitiesStateLookUpModel> = <Array<SchoolEligibilitiesStateLookUpModel >>[]
}

export interface ISchoolEligibilitiesStateLookUpListParams {
	pK_Id?:number;
	fK_SchoolEligibility?:number;
	fK_SchoolEligibility_display?:string;
	fK_SchoolEligibility_like?:string;
	fK_SchoolEligibility_fromValue?:number;
	fK_SchoolEligibility_toValue?:number;
	fK_State?:number;
	fK_State_display?:string;
	fK_State_like?:string;
	fK_State_fromValue?:number;
	fK_State_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolEligibilitiesStateLookUpListParams implements ISchoolEligibilitiesStateLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_SchoolEligibility?:number=null;
	fK_SchoolEligibility_display?:string=null;
	fK_SchoolEligibility_like?:string=null;
	fK_SchoolEligibility_fromValue?:number=null;
	fK_SchoolEligibility_toValue?:number=null;
	fK_State?:number=null;
	fK_State_display?:string=null;
	fK_State_like?:string=null;
	fK_State_fromValue?:number=null;
	fK_State_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

