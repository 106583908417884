import {IListItem } from '../models';
export class ContactModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FirstName:string = "";
	public LastName?:string = null;
	public Title?:string = null;
	public Phone?:string = null;
	public Email?:string = null;
	public FK_Login?:string = null;
	public FK_Login_Display:string = null;
	public Extension?:number = null;
}
