import { Component, OnInit, Directive } from '@angular/core';
import { ISchoolCommitmentListParams,SchoolCommitmentListParams } from '../../models/entities/SchoolCommitmentModel.response';
import { SchoolCommitmentModel} from '../../models/entities/SchoolCommitmentModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class SchoolCommitmentListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<SchoolCommitmentModel> = <Array<SchoolCommitmentModel>>[];
	public search: SchoolCommitmentListParams = new SchoolCommitmentListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getSchoolCommitmentList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.pK_Id  = null;
		this.search.fK_School  = null;
		this.search.fK_School_display  = null;
		this.search.fK_School_like  = null;
		this.search.fK_School_fromValue  = null;
		this.search.fK_School_toValue  = null;
		this.search.fK_SchoolCommitmentStatusType  = null;
		this.search.fK_SchoolCommitmentStatusType_display  = null;
		this.search.fK_SchoolCommitmentStatusType_like  = null;
		this.search.fK_SchoolCommitmentStatusType_fromValue  = null;
		this.search.fK_SchoolCommitmentStatusType_toValue  = null;
		this.search.fK_Student  = null;
		this.search.fK_Student_display  = null;
		this.search.fK_Student_like  = null;
		this.search.fK_Student_fromValue  = null;
		this.search.fK_Student_toValue  = null;
		this.search.fK_SchoolYear  = null;
		this.search.fK_SchoolYear_display  = null;
		this.search.fK_SchoolYear_like  = null;
		this.search.fK_SchoolYear_fromValue  = null;
		this.search.fK_SchoolYear_toValue  = null;
		this.search.studentStartDate  = null;
		this.search.studentStartDate_fromDate  = null;
		this.search.studentStartDate_toDate  = null;
		this.search.fK_Withdraw  = null;
		this.search.fK_Withdraw_display  = null;
		this.search.fK_Withdraw_like  = null;
		this.search.fK_Withdraw_fromValue  = null;
		this.search.fK_Withdraw_toValue  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deleteSchoolCommitment(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManySchoolCommitment(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
