import { Component, OnInit, Directive } from '@angular/core';
import {  IZengineFormYearLookUpListParams } from '../../models/entities/ZengineFormYearLookUpModel.response';
import { ZengineFormYearLookUpModel } from '../../models/entities/ZengineFormYearLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { ZengineFormYearLookUpListBaseComponent } from './zengineFormYearLookUpList.base';

@Component({
	selector: 'app-zengineFormYearLookUpList',
	templateUrl: './zengineFormYearLookUpList.component.html',
})
export class ZengineFormYearLookUpListComponent extends ZengineFormYearLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
