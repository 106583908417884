import { Component, OnInit, Directive } from '@angular/core';
import {  IZengineFormTypeListParams } from '../../models/entities/ZengineFormTypeModel.response';
import { ZengineFormTypeModel } from '../../models/entities/ZengineFormTypeModel';
import { PagingData } from '../../paging/paging.component';
import { ZengineFormTypeListBaseComponent } from './zengineFormTypeList.base';

@Component({
	selector: 'app-zengineFormTypeList',
	templateUrl: './zengineFormTypeList.component.html',
})
export class ZengineFormTypeListComponent extends ZengineFormTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
