import { Component, OnInit, Directive } from '@angular/core';
import { AwardAcceptanceStatusTypeModel } from '../../models/entities/AwardAcceptanceStatusTypeModel';
import { ListItemModel, IListItem, UpdateEvent } from '../../models/models';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class AwardAcceptanceStatusTypeEditBaseComponent extends BaseEditComponent  {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	public model: AwardAcceptanceStatusTypeModel = <AwardAcceptanceStatusTypeModel>{};

	protected baseNgOnInit() {
		super.baseNgOnInit();
	}


	protected loadOptions() {
	}

	protected getData() {
	this.ShowSpinner = true;
		var sub = this.api.getAwardAcceptanceStatusType(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
			}
			this.ShowSpinner = false;
			this.getDataComplete();
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateAwardAcceptanceStatusType(this.model).subscribe(x=>{
			if (x.Success) {
				this.saved.emit(this.id);
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertAwardAcceptanceStatusType(this.model).subscribe(x=>{
			if (x.Success) {
				this._id = x.Data;
				this.saved.emit(x.Data);
			}
			sub.unsubscribe();
		});
		}
	}
}
