import { ScholarshipPaymentsDeadlineModel} from '../entities/ScholarshipPaymentsDeadlineModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ScholarshipPaymentsDeadlineResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ScholarshipPaymentsDeadlineModel = new ScholarshipPaymentsDeadlineModel();
}

export class ScholarshipPaymentsDeadlineList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ScholarshipPaymentsDeadlineModel> = <Array<ScholarshipPaymentsDeadlineModel >>[]
}

export interface IScholarshipPaymentsDeadlineListParams {
	pK_Id?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	fK_Scholarship?:number;
	fK_Scholarship_display?:string;
	fK_Scholarship_like?:string;
	fK_Scholarship_fromValue?:number;
	fK_Scholarship_toValue?:number;
	name?:string;
	name_like?:string;
	notifyParentSchools?:Date;
	notifyParentSchools_fromDate?:string;
	notifyParentSchools_toDate?:string;
	verificationReportsDue?:Date;
	verificationReportsDue_fromDate?:string;
	verificationReportsDue_toDate?:string;
	paymentSent?:Date;
	paymentSent_fromDate?:string;
	paymentSent_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ScholarshipPaymentsDeadlineListParams implements IScholarshipPaymentsDeadlineListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	fK_Scholarship?:number=null;
	fK_Scholarship_display?:string=null;
	fK_Scholarship_like?:string=null;
	fK_Scholarship_fromValue?:number=null;
	fK_Scholarship_toValue?:number=null;
	name?:string=null;
	name_like?:string=null;
	notifyParentSchools?:Date=null;
	notifyParentSchools_fromDate?:string=null;
	notifyParentSchools_toDate?:string=null;
	verificationReportsDue?:Date=null;
	verificationReportsDue_fromDate?:string=null;
	verificationReportsDue_toDate?:string=null;
	paymentSent?:Date=null;
	paymentSent_fromDate?:string=null;
	paymentSent_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

