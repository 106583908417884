import { Component, OnInit, Directive } from '@angular/core';
import {  IWithdrawListParams } from '../../models/entities/WithdrawModel.response';
import { WithdrawModel } from '../../models/entities/WithdrawModel';
import { PagingData } from '../../paging/paging.component';
import { WithdrawListBaseComponent } from './withdrawList.base';

@Component({
	selector: 'app-withdrawList',
	templateUrl: './withdrawList.component.html',
})
export class WithdrawListComponent extends WithdrawListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
