import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ConfirmationService } from 'primeng/api';
import { aaaAPIVendorService } from '../../../services/aaa.API.vendor.service';
import { VendorProfilePageComponent } from '../../vendor/vendor-profile-page/vendor-profile-page.component';

@Component({
  selector: 'app-admin-vendor-profile-page',
  templateUrl: '../../vendor/vendor-profile-page/vendor-profile-page.component.html',
  styleUrls: ['../../vendor/vendor-profile-page/vendor-profile-page.component.scss']
})
export class AdminVendorProfilePageComponent extends VendorProfilePageComponent implements OnInit {

  constructor(
    private _vendorService: aaaAPIVendorService,
    private _confirmationService: ConfirmationService,
    private _recaptchaV3Service: ReCaptchaV3Service,
    private _activatedRoute: ActivatedRoute,
  ) {
    super(_vendorService, _confirmationService, _recaptchaV3Service, _activatedRoute);
  }

  ngOnInit(): void {
    this._activatedRoute.paramMap
      .subscribe(param => {
        this.vendorId = parseInt(param.get('id'));
        super.ngOnInit();
      });
  }

}
