import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuardianReimbursementListItemEditBaseComponent } from './guardianReimbursementListItemEdit.base';

@Component({
	selector: 'app-guardianReimbursementListItemEdit',
	templateUrl: './guardianReimbursementListItemEdit.component.html',
})
export class GuardianReimbursementListItemEditComponent extends GuardianReimbursementListItemEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
