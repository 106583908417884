import { ContactsLookUpModel} from '../entities/ContactsLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ContactsLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ContactsLookUpModel = new ContactsLookUpModel();
}

export class ContactsLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ContactsLookUpModel> = <Array<ContactsLookUpModel >>[]
}

export interface IContactsLookUpListParams {
	pK_Id?:number;
	fK_Contact?:number;
	fK_Contact_display?:string;
	fK_Contact_like?:string;
	fK_Contact_fromValue?:number;
	fK_Contact_toValue?:number;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	fK_Vendor?:number;
	fK_Vendor_display?:string;
	fK_Vendor_like?:string;
	fK_Vendor_fromValue?:number;
	fK_Vendor_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ContactsLookUpListParams implements IContactsLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_Contact?:number=null;
	fK_Contact_display?:string=null;
	fK_Contact_like?:string=null;
	fK_Contact_fromValue?:number=null;
	fK_Contact_toValue?:number=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	fK_Vendor?:number=null;
	fK_Vendor_display?:string=null;
	fK_Vendor_like?:string=null;
	fK_Vendor_fromValue?:number=null;
	fK_Vendor_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

