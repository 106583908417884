import { SchoolCommitmentFeesLookUpModel} from '../entities/SchoolCommitmentFeesLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolCommitmentFeesLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolCommitmentFeesLookUpModel = new SchoolCommitmentFeesLookUpModel();
}

export class SchoolCommitmentFeesLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolCommitmentFeesLookUpModel> = <Array<SchoolCommitmentFeesLookUpModel >>[]
}

export interface ISchoolCommitmentFeesLookUpListParams {
	pK_Id?:number;
	fK_SchoolFee?:number;
	fK_SchoolFee_display?:string;
	fK_SchoolFee_like?:string;
	fK_SchoolFee_fromValue?:number;
	fK_SchoolFee_toValue?:number;
	fK_SchoolCommitment?:number;
	fK_SchoolCommitment_fromValue?:number;
	fK_SchoolCommitment_toValue?:number;
	discountAmount?:number;
	discountAmount_fromValue?:number;
	discountAmount_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolCommitmentFeesLookUpListParams implements ISchoolCommitmentFeesLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_SchoolFee?:number=null;
	fK_SchoolFee_display?:string=null;
	fK_SchoolFee_like?:string=null;
	fK_SchoolFee_fromValue?:number=null;
	fK_SchoolFee_toValue?:number=null;
	fK_SchoolCommitment?:number=null;
	fK_SchoolCommitment_fromValue?:number=null;
	fK_SchoolCommitment_toValue?:number=null;
	discountAmount?:number=null;
	discountAmount_fromValue?:number=null;
	discountAmount_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

