import {IListItem } from '../models';
export class PrepaidCollegeTransferModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public CreatedDate:Date = null;
	public FK_StudentGradeLevelsSchoolYearsLookUp:number = 0;
	public Amount:number = 0;
	public FK_PrepaidCollegeTransferStatusType:number = 0;
	public FK_PrepaidCollegeTransferStatusType_Display:string = null;
	public DateApproved?:Date = null;
	public FK_Login_ApprovedBy?:string = null;
	public FK_Login_ApprovedBy_Display:string = null;
	public SavingsAccountNumber?:string = null;
	public PrepaidAccountNumber?:string = null;
}
