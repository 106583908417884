import { Component, OnInit, Directive } from '@angular/core';
import { SchoolTransactionModel } from '../../models/entities/SchoolTransactionModel';
import { ListItemModel, IListItem, UpdateEvent } from '../../models/models';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class SchoolTransactionEditBaseComponent extends BaseEditComponent  {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	public model: SchoolTransactionModel = <SchoolTransactionModel>{};
	public fK_TransactionStatusType_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_TransactionType_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_Verification_options:Array<ListItemModel> = <Array<ListItemModel>>[];

	protected baseNgOnInit() {
		super.baseNgOnInit();
	}


	protected loadOptions() {
				this.getFK_TransactionStatusType_Options();
				this.getFK_TransactionType_Options();
				this.getFK_Verification_Options();
	}

	protected getData() {
	this.ShowSpinner = true;
		var sub = this.api.getSchoolTransaction(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
			}
			this.ShowSpinner = false;
			this.getDataComplete();
			sub.unsubscribe();
		});
	}
	public getFK_TransactionStatusType_Options(clear?: boolean) {
		if (clear) {  this.model.FK_TransactionStatusType = null; }
		var sub = this.api.getTransactionStatusTypeList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_TransactionStatusType_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_TransactionType_Options(clear?: boolean) {
		if (clear) {  this.model.FK_TransactionType = null; }
		var sub = this.api.getTransactionTypeList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_TransactionType_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_Verification_Options(clear?: boolean) {
		if (clear) {  this.model.FK_Verification = null; }
		var sub = this.api.getVerificationList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_Verification_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateSchoolTransaction(this.model).subscribe(x=>{
			if (x.Success) {
				this.saved.emit(this.id);
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertSchoolTransaction(this.model).subscribe(x=>{
			if (x.Success) {
				this._id = x.Data;
				this.saved.emit(x.Data);
			}
			sub.unsubscribe();
		});
		}
	}
}
