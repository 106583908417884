import { Component, OnInit, Directive } from '@angular/core';
import { ZengineMappingLookUpModel } from '../../models/entities/ZengineMappingLookUpModel';
import { ListItemModel, IListItem, UpdateEvent } from '../../models/models';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class ZengineMappingLookUpEditBaseComponent extends BaseEditComponent  {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	public model: ZengineMappingLookUpModel = <ZengineMappingLookUpModel>{};
	public fK_State_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_SchoolYear_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_ZengineMappingOption_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_ZengineFormField_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_ZengineForm_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_Scholarship_options:Array<ListItemModel> = <Array<ListItemModel>>[];

	protected baseNgOnInit() {
		super.baseNgOnInit();
	}


	protected loadOptions() {
				this.getFK_State_Options();
				this.getFK_SchoolYear_Options();
				this.getFK_ZengineMappingOption_Options();
				this.getFK_ZengineForm_Options();
				this.getFK_Scholarship_Options();
	}

	protected getData() {
	this.ShowSpinner = true;
		var sub = this.api.getZengineMappingLookUp(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.getFK_ZengineFormField_Options();
			}
			this.ShowSpinner = false;
			this.getDataComplete();
			sub.unsubscribe();
		});
	}
	public getFK_State_Options(clear?: boolean) {
		if (clear) {  this.model.FK_State = null; }
		var sub = this.api.getStateList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_State_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_SchoolYear_Options(clear?: boolean) {
		if (clear) {  this.model.FK_SchoolYear = null; }
		var sub = this.api.getSchoolYearList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_SchoolYear_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_ZengineMappingOption_Options(clear?: boolean) {
		if (clear) {  this.model.FK_ZengineMappingOption = null; }
		var sub = this.api.getZengineMappingOptionList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_ZengineMappingOption_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_ZengineFormField_Options(clear?: boolean) {
		if(!this.model.FK_ZengineForm){ return;}
		if (clear) {  this.model.FK_ZengineFormField = null; }
		var sub = this.api.getZengineFormFieldList({_pageSize:0,fK_ZengineForm: this.model.FK_ZengineForm}).subscribe(x => {
			if (x.Success) {
				this.fK_ZengineFormField_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_ZengineForm_Options(clear?: boolean) {
		if (clear) {  this.model.FK_ZengineForm = null; }
		var sub = this.api.getZengineFormList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_ZengineForm_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_Scholarship_Options(clear?: boolean) {
		if (clear) {  this.model.FK_Scholarship = null; }
		var sub = this.api.getScholarshipList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_Scholarship_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateZengineMappingLookUp(this.model).subscribe(x=>{
			if (x.Success) {
				this.saved.emit(this.id);
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertZengineMappingLookUp(this.model).subscribe(x=>{
			if (x.Success) {
				this._id = x.Data;
				this.saved.emit(x.Data);
			}
			sub.unsubscribe();
		});
		}
	}
}
