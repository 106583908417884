import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VendorTransactionNoteEditBaseComponent } from './vendorTransactionNoteEdit.base';

@Component({
    selector: 'app-vendorTransactionNoteEdit',
    templateUrl: './vendorTransactionNoteEdit.component.html',
})
export class VendorTransactionNoteEditComponent extends VendorTransactionNoteEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
