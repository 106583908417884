import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolScholarshipsLookUpListParams } from '../../models/entities/SchoolScholarshipsLookUpModel.response';
import { SchoolScholarshipsLookUpModel } from '../../models/entities/SchoolScholarshipsLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolScholarshipsLookUpListBaseComponent } from './schoolScholarshipsLookUpList.base';

@Component({
	selector: 'app-schoolScholarshipsLookUpList',
	templateUrl: './schoolScholarshipsLookUpList.component.html',
})
export class SchoolScholarshipsLookUpListComponent extends SchoolScholarshipsLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
