import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolFeeListParams } from '../../models/entities/SchoolFeeModel.response';
import { SchoolFeeModel } from '../../models/entities/SchoolFeeModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolFeeListBaseComponent } from './schoolFeeList.base';

@Component({
	selector: 'app-schoolFeeList',
	templateUrl: './schoolFeeList.component.html',
})
export class SchoolFeeListComponent extends SchoolFeeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
