import { ZengineFormModel} from '../entities/ZengineFormModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ZengineFormResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ZengineFormModel = new ZengineFormModel();
}

export class ZengineFormList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ZengineFormModel> = <Array<ZengineFormModel >>[]
}

export interface IZengineFormListParams {
	pK_Id?:number;
	zengineFormId?:number;
	zengineFormId_fromValue?:number;
	zengineFormId_toValue?:number;
	zengineFormName?:string;
	zengineFormName_like?:string;
	fK_ZengineFormType?:number;
	fK_ZengineFormType_display?:string;
	fK_ZengineFormType_like?:string;
	fK_ZengineFormType_fromValue?:number;
	fK_ZengineFormType_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ZengineFormListParams implements IZengineFormListParams, ISearchModel  {
	pK_Id?:number=null;
	zengineFormId?:number=null;
	zengineFormId_fromValue?:number=null;
	zengineFormId_toValue?:number=null;
	zengineFormName?:string=null;
	zengineFormName_like?:string=null;
	fK_ZengineFormType?:number=null;
	fK_ZengineFormType_display?:string=null;
	fK_ZengineFormType_like?:string=null;
	fK_ZengineFormType_fromValue?:number=null;
	fK_ZengineFormType_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

