import { Component, OnInit, Directive } from '@angular/core';
import { ScholarshipModel } from '../../models/entities/ScholarshipModel';
import { ListItemModel, IListItem, UpdateEvent } from '../../models/models';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class ScholarshipEditBaseComponent extends BaseEditComponent  {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	public model: ScholarshipModel = <ScholarshipModel>{};
	public fK_State_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_ScholarshipType_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_SchoolYear_options:Array<ListItemModel> = <Array<ListItemModel>>[];

	protected baseNgOnInit() {
		super.baseNgOnInit();
	}


	protected loadOptions() {
				this.getFK_State_Options();
				this.getFK_ScholarshipType_Options();
				this.getFK_SchoolYear_Options();
	}

	protected getData() {
	this.ShowSpinner = true;
		var sub = this.api.getScholarship(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
			}
			this.ShowSpinner = false;
			this.getDataComplete();
			sub.unsubscribe();
		});
	}
	public getFK_State_Options(clear?: boolean) {
		if (clear) {  this.model.FK_State = null; }
		var sub = this.api.getStateList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_State_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_ScholarshipType_Options(clear?: boolean) {
		if (clear) {  this.model.FK_ScholarshipType = null; }
		var sub = this.api.getScholarshipTypeList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_ScholarshipType_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_SchoolYear_Options(clear?: boolean) {
		if (clear) {  this.model.FK_SchoolYear = null; }
		var sub = this.api.getSchoolYearList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_SchoolYear_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateScholarship(this.model).subscribe(x=>{
			if (x.Success) {
				this.saved.emit(this.id);
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertScholarship(this.model).subscribe(x=>{
			if (x.Success) {
				this._id = x.Data;
				this.saved.emit(x.Data);
			}
			sub.unsubscribe();
		});
		}
	}
}
