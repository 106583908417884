import { AccreditationsLookUpModel} from '../entities/AccreditationsLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class AccreditationsLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: AccreditationsLookUpModel = new AccreditationsLookUpModel();
}

export class AccreditationsLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<AccreditationsLookUpModel> = <Array<AccreditationsLookUpModel >>[]
}

export interface IAccreditationsLookUpListParams {
	pK_Id?:number;
	fK_VendorServiceProvider?:number;
	fK_VendorServiceProvider_fromValue?:number;
	fK_VendorServiceProvider_toValue?:number;
	fK_Accreditation?:number;
	fK_Accreditation_display?:string;
	fK_Accreditation_like?:string;
	fK_Accreditation_fromValue?:number;
	fK_Accreditation_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class AccreditationsLookUpListParams implements IAccreditationsLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_VendorServiceProvider?:number=null;
	fK_VendorServiceProvider_fromValue?:number=null;
	fK_VendorServiceProvider_toValue?:number=null;
	fK_Accreditation?:number=null;
	fK_Accreditation_display?:string=null;
	fK_Accreditation_like?:string=null;
	fK_Accreditation_fromValue?:number=null;
	fK_Accreditation_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

