import { Component, OnInit, Directive } from '@angular/core';
import {  IWithdrawReasonTypeListParams } from '../../models/entities/WithdrawReasonTypeModel.response';
import { WithdrawReasonTypeModel } from '../../models/entities/WithdrawReasonTypeModel';
import { PagingData } from '../../paging/paging.component';
import { WithdrawReasonTypeListBaseComponent } from './withdrawReasonTypeList.base';

@Component({
	selector: 'app-withdrawReasonTypeList',
	templateUrl: './withdrawReasonTypeList.component.html',
})
export class WithdrawReasonTypeListComponent extends WithdrawReasonTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
