import { Component, OnInit, Directive } from '@angular/core';
import {  IZengineFormListParams } from '../../models/entities/ZengineFormModel.response';
import { ZengineFormModel } from '../../models/entities/ZengineFormModel';
import { PagingData } from '../../paging/paging.component';
import { ZengineFormListBaseComponent } from './zengineFormList.base';

@Component({
	selector: 'app-zengineFormList',
	templateUrl: './zengineFormList.component.html',
})
export class ZengineFormListComponent extends ZengineFormListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
