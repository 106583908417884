import { Component, OnInit, Directive } from '@angular/core';
import {  IGuardianReimbursementListItemListParams } from '../../models/entities/GuardianReimbursementListItemModel.response';
import { GuardianReimbursementListItemModel } from '../../models/entities/GuardianReimbursementListItemModel';
import { PagingData } from '../../paging/paging.component';
import { GuardianReimbursementListItemListBaseComponent } from './guardianReimbursementListItemList.base';

@Component({
	selector: 'app-guardianReimbursementListItemList',
	templateUrl: './guardianReimbursementListItemList.component.html',
})
export class GuardianReimbursementListItemListComponent extends GuardianReimbursementListItemListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
