import { Component, OnInit, Directive } from '@angular/core';
import { IStudentNoteListParams } from '../../models/entities/StudentNoteModel.response';
import { StudentNoteModel } from '../../models/entities/StudentNoteModel';
import { PagingData } from '../../paging/paging.component';
import { StudentNoteListBaseComponent } from './studentNoteList.base';

@Component({
    selector: 'app-studentNoteList',
    templateUrl: './studentNoteList.component.html',
})
export class StudentNoteListComponent extends StudentNoteListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
