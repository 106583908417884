import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html'
})
export class SearchInputComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
   
  }

  public _placeHolder: string = '';
  public text: string = null;

  @Output('changed') changed: EventEmitter<string> = new EventEmitter<string>();

  @Input('placeholdertext')
  public set placeholdertext(value: string) {
    console.log(value);
    this._placeHolder = value;
    
  }

  private prevValue: string = '';
  textChanged(value: string) {
    var me = this;
    window.setTimeout(function () {
      if (me.prevValue == value) {
        me.changed.emit(value);
      }
    }, 1000);

    me.prevValue = value;

  }

  clearFilter() {
    this.text = '';
    this.changed.emit('');
  }

}
