import { Component, OnInit, Directive } from '@angular/core';
import {  IVendorListParams } from '../../models/entities/VendorModel.response';
import { VendorModel } from '../../models/entities/VendorModel';
import { PagingData } from '../../paging/paging.component';
import { VendorListBaseComponent } from './vendorList.base';

@Component({
	selector: 'app-vendorList',
	templateUrl: './vendorList.component.html',
})
export class VendorListComponent extends VendorListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
