import { Component, OnInit, Directive } from '@angular/core';
import {  IStudentGradeLevelsSchoolYearsLookUpListParams } from '../../models/entities/StudentGradeLevelsSchoolYearsLookUpModel.response';
import { StudentGradeLevelsSchoolYearsLookUpModel } from '../../models/entities/StudentGradeLevelsSchoolYearsLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { StudentGradeLevelsSchoolYearsLookUpListBaseComponent } from './studentGradeLevelsSchoolYearsLookUpList.base';

@Component({
	selector: 'app-studentGradeLevelsSchoolYearsLookUpList',
	templateUrl: './studentGradeLevelsSchoolYearsLookUpList.component.html',
})
export class StudentGradeLevelsSchoolYearsLookUpListComponent extends StudentGradeLevelsSchoolYearsLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
