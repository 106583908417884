import { VendorCategoriesLookUpModel} from '../entities/VendorCategoriesLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class VendorCategoriesLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: VendorCategoriesLookUpModel = new VendorCategoriesLookUpModel();
}

export class VendorCategoriesLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<VendorCategoriesLookUpModel> = <Array<VendorCategoriesLookUpModel >>[]
}

export interface IVendorCategoriesLookUpListParams {
	pK_Id?:number;
	fK_Vendor?:number;
	fK_Vendor_display?:string;
	fK_Vendor_like?:string;
	fK_Vendor_fromValue?:number;
	fK_Vendor_toValue?:number;
	fK_ScholarshipDisbursementCategory?:number;
	fK_ScholarshipDisbursementCategory_display?:string;
	fK_ScholarshipDisbursementCategory_like?:string;
	fK_ScholarshipDisbursementCategory_fromValue?:number;
	fK_ScholarshipDisbursementCategory_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class VendorCategoriesLookUpListParams implements IVendorCategoriesLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_Vendor?:number=null;
	fK_Vendor_display?:string=null;
	fK_Vendor_like?:string=null;
	fK_Vendor_fromValue?:number=null;
	fK_Vendor_toValue?:number=null;
	fK_ScholarshipDisbursementCategory?:number=null;
	fK_ScholarshipDisbursementCategory_display?:string=null;
	fK_ScholarshipDisbursementCategory_like?:string=null;
	fK_ScholarshipDisbursementCategory_fromValue?:number=null;
	fK_ScholarshipDisbursementCategory_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

