import { VendorModel} from '../entities/VendorModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class VendorResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: VendorModel = new VendorModel();
}

export class VendorList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<VendorModel> = <Array<VendorModel >>[]
}

export interface IVendorListParams {
	name?:string;
	name_like?:string;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	fK_State_VendorPhysical?:number;
	fK_State_VendorPhysical_display?:string;
	fK_State_VendorPhysical_like?:string;
	fK_State_VendorPhysical_fromValue?:number;
	fK_State_VendorPhysical_toValue?:number;
	pK_Id?:number;
	isDeleted?:boolean;
	isDeleted_booleanValue?:boolean;
	physical_Zipcode?:string;
	physical_Zipcode_like?:string;
	vendorCode?:string;
	vendorCode_like?:string;
	faxNumber?:string;
	faxNumber_like?:string;
	emailAddress?:string;
	emailAddress_like?:string;
	websiteURL?:string;
	websiteURL_like?:string;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	paymentBankName?:string;
	paymentBankName_like?:string;
	paymentBankRouting?:string;
	paymentBankRouting_like?:string;
	paymentBankAccount?:string;
	paymentBankAccount_like?:string;
	paymentAddress1?:string;
	paymentAddress1_like?:string;
	paymentAddress2?:string;
	paymentAddress2_like?:string;
	paymentCity?:string;
	paymentCity_like?:string;
	fK_State_Payment?:number;
	fK_State_Payment_display?:string;
	fK_State_Payment_like?:string;
	fK_State_Payment_fromValue?:number;
	fK_State_Payment_toValue?:number;
	paymentZipcode?:string;
	paymentZipcode_like?:string;
	paymentPayTo?:string;
	paymentPayTo_like?:string;
	fK_Login?:string;
	fK_Login_display?:string;
	fK_Login_like?:string;
	phone?:string;
	phone_like?:string;
	mailing_StreetAddress1?:string;
	mailing_StreetAddress1_like?:string;
	mailing_StreetAddress2?:string;
	mailing_StreetAddress2_like?:string;
	mailing_City?:string;
	mailing_City_like?:string;
	fK_State_VendorMailing?:number;
	fK_State_VendorMailing_display?:string;
	fK_State_VendorMailing_like?:string;
	fK_State_VendorMailing_fromValue?:number;
	fK_State_VendorMailing_toValue?:number;
	mailing_Zipcode?:string;
	mailing_Zipcode_like?:string;
	physical_StreetAddress1?:string;
	physical_StreetAddress1_like?:string;
	physical_StreetAddress2?:string;
	physical_StreetAddress2_like?:string;
	physical_City?:string;
	physical_City_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class VendorListParams implements IVendorListParams, ISearchModel  {
	name?:string=null;
	name_like?:string=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	fK_State_VendorPhysical?:number=null;
	fK_State_VendorPhysical_display?:string=null;
	fK_State_VendorPhysical_like?:string=null;
	fK_State_VendorPhysical_fromValue?:number=null;
	fK_State_VendorPhysical_toValue?:number=null;
	pK_Id?:number=null;
	isDeleted?:boolean=null;
	isDeleted_booleanValue?:boolean=null;
	physical_Zipcode?:string=null;
	physical_Zipcode_like?:string=null;
	vendorCode?:string=null;
	vendorCode_like?:string=null;
	faxNumber?:string=null;
	faxNumber_like?:string=null;
	emailAddress?:string=null;
	emailAddress_like?:string=null;
	websiteURL?:string=null;
	websiteURL_like?:string=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	paymentBankName?:string=null;
	paymentBankName_like?:string=null;
	paymentBankRouting?:string=null;
	paymentBankRouting_like?:string=null;
	paymentBankAccount?:string=null;
	paymentBankAccount_like?:string=null;
	paymentAddress1?:string=null;
	paymentAddress1_like?:string=null;
	paymentAddress2?:string=null;
	paymentAddress2_like?:string=null;
	paymentCity?:string=null;
	paymentCity_like?:string=null;
	fK_State_Payment?:number=null;
	fK_State_Payment_display?:string=null;
	fK_State_Payment_like?:string=null;
	fK_State_Payment_fromValue?:number=null;
	fK_State_Payment_toValue?:number=null;
	paymentZipcode?:string=null;
	paymentZipcode_like?:string=null;
	paymentPayTo?:string=null;
	paymentPayTo_like?:string=null;
	fK_Login?:string=null;
	fK_Login_display?:string=null;
	fK_Login_like?:string=null;
	phone?:string=null;
	phone_like?:string=null;
	mailing_StreetAddress1?:string=null;
	mailing_StreetAddress1_like?:string=null;
	mailing_StreetAddress2?:string=null;
	mailing_StreetAddress2_like?:string=null;
	mailing_City?:string=null;
	mailing_City_like?:string=null;
	fK_State_VendorMailing?:number=null;
	fK_State_VendorMailing_display?:string=null;
	fK_State_VendorMailing_like?:string=null;
	fK_State_VendorMailing_fromValue?:number=null;
	fK_State_VendorMailing_toValue?:number=null;
	mailing_Zipcode?:string=null;
	mailing_Zipcode_like?:string=null;
	physical_StreetAddress1?:string=null;
	physical_StreetAddress1_like?:string=null;
	physical_StreetAddress2?:string=null;
	physical_StreetAddress2_like?:string=null;
	physical_City?:string=null;
	physical_City_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

