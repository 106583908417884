import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AwardAcceptanceStatusTypeEditBaseComponent } from './awardAcceptanceStatusTypeEdit.base';

@Component({
	selector: 'app-awardAcceptanceStatusTypeEdit',
	templateUrl: './awardAcceptanceStatusTypeEdit.component.html',
})
export class AwardAcceptanceStatusTypeEditComponent extends AwardAcceptanceStatusTypeEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
