import { Component, OnInit, Directive } from '@angular/core';
import { ITransactionStatusTypeListParams } from '../../models/entities/TransactionStatusTypeModel.response';
import { TransactionStatusTypeModel } from '../../models/entities/TransactionStatusTypeModel';
import { PagingData } from '../../paging/paging.component';
import { TransactionStatusTypeListBaseComponent } from './transactionStatusTypeList.base';

@Component({
    selector: 'app-transactionStatusTypeList',
    templateUrl: './transactionStatusTypeList.component.html',
})
export class TransactionStatusTypeListComponent extends TransactionStatusTypeListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
