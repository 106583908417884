import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolFeeEditBaseComponent } from './schoolFeeEdit.base';

@Component({
	selector: 'app-schoolFeeEdit',
	templateUrl: './schoolFeeEdit.component.html',
})
export class SchoolFeeEditComponent extends SchoolFeeEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
