<div class="card">
    <div class="card-header">
        Vendor Transaction Note <span class="right">
            <a title="Cancel" *ngIf="!ModalView" routerLink="/vendortransactionnotelist"><i class="fa fa-undo icon-link"></i></a>
            <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
            <i class="fa fa-save icon-link" (click)="save()"></i>
        </span>
    </div>
    <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
        <div class="form">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Vendor Transaction</label><span title="Required" class="required">*</span>
                        <select [(ngModel)]="model.FK_VendorTransaction" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_VendorTransaction_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Note Type</label><span title="Required" class="required">*</span>
                        <select [(ngModel)]="model.FK_NoteType" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_NoteType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Note</label><span title="Required" class="required">*</span>
                        <input [(ngModel)]="model.Note" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <span title="Required" class="required">*</span>
                        <app-checkbox [(model)]="model.NoteActive" [label]="'Note Active'"></app-checkbox>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Created Date</label><span title="Required" class="required">*</span>
                        <app-date-picker [(model)]="model.CreatedDate"></app-date-picker>
                    </div>
                </div>


            </div>


        </div>
    </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
