<div>
  <div class="row">
    <div class="col-3">
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 1"></i>
          <span (click)="doChangePage(1)" class="btn font-weight-bold">
            Scholarship Information
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 2"></i>
          <span (click)="doChangePage(2)" class="btn font-weight-bold">
            Payments Schedules & Deadlines
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 3"></i>
          <span (click)="doChangePage(3)" class="btn font-weight-bold">
            Disbursement Categories
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 4"></i>
          <span (click)="doChangePage(4)" class="btn font-weight-bold">
            School Accreditation and Eligibility
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 5"></i>
          <span (click)="doChangePage(5)" class="btn font-weight-bold">
            Award Matrices
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 6"></i>
          <span (click)="doChangePage(6)" class="btn font-weight-bold">
            Handbook
          </span>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div *ngIf="pageNumber == 1">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2>{{ scholarship.Name }}</h2>
            <button type="button" class="btn btn-primary" (click)="doScholarshipEdit()">
              <i class="fas fa-pen"></i>
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <h4>Scholarship Information</h4>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <p class="font-weight-bold mb-0 mt-3">State</p>
            <p>{{ stateScholarship.Abbreviation }}</p>
          </div>
          <div class="col-12">
            <p class="font-weight-bold mb-0 mt-3">Non-school Disbursement Allowed</p>
            <p>{{ scholarship.NonSchoolDisbursementAllowed }}</p>
          </div>
          <div class="col-12">
            <p class="font-weight-bold mb-0 mt-3">Length of Scholarship</p>
            <p>{{ scholarship.LengthOfScholarshipYears || '0' }} year</p>
          </div>
          <div class="col-12">
            <p class="font-weight-bold mb-0 mt-3">Rollover Unused Funds</p>
            <p>{{ scholarship.RolloverUnusedFunds }}</p>
          </div>
          <div class="col-12">
            <p class="font-weight-bold mb-0 mt-3">School Participation Approval</p>
            <p>State of {{ stateScholarship.Name }}</p>
          </div>
          <div class="col-12">
            <p class="font-weight-bold mb-0 mt-3">Verify Income on Renewal</p>
            <p>{{ scholarship.VerifyIncomeOnRenewal }}</p>
          </div>
        </div>

        <p-sidebar [(visible)]="editViewDisplay" position="right" [style]="{ width: '60%' }" [showCloseIcon]="false">
          <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="scholarship.Name" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Tuition-only Payments<span
                      class="text-danger">*</span></label>
                  <div class="p-field-radiobutton">
                    <p-radioButton name="question1" value="Yes" [(ngModel)]="question1" inputId="question1Yes">
                    </p-radioButton>
                    <label for="question1Yes">Yes</label>
                  </div>
                  <div class="p-field-radiobutton">
                    <p-radioButton name="question1" value="No" [(ngModel)]="question1" inputId="question1No">
                    </p-radioButton>
                    <label for="question1No">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Length of Scholarship<span
                      class="text-danger">*</span></label>
                  <div class="d-flex">
                    <input type="text" class="form-control col-2"
                      [(ngModel)]="scholarship.LengthOfScholarshipYears" /><span>&nbsp;year</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="d-flex justify-content-left align-items-center">
                  <input type="checkbox" [(ngModel)]="scholarship.NonSchoolDisbursementAllowed" class="mr-1" />
                  <span class="form-control no-border">
                    Non-school Disbursement Allowed
                  </span>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="d-flex justify-content-left align-items-center">
                  <input type="checkbox" [(ngModel)]="scholarship.RolloverUnusedFunds" class="mr-1" />
                  <span class="form-control no-border">
                    Rollover Unused Funds
                  </span>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="d-flex justify-content-left align-items-center">
                  <input type="checkbox" [(ngModel)]="scholarship.VerifyIncomeOnRenewal" class="mr-1" />
                  <span class="form-control no-border">
                    Verify Income on Renewal
                  </span>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" [disabled]="!isValidProfile()"
                  (click)="saveScholarship()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelScholarship()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 2">
        <div class="row p-1">
          <div class="col-12">
            <h2>{{ scholarship.Name }}</h2>
          </div>
        </div>
        <div class="row p-1 mt-4">
          <div class="col-12">
            <h4>Payments, Schedules, & Deadlines</h4>
          </div>
        </div>
        <div class="row p-1 mb-3">
          <div class="col-3 d-flex flex-column">
            <span class="form-label font-weight-bold">School Year</span>
            <p-dropdown [options]="schoolYearOptions" class="form-control no-border" [(ngModel)]="schoolYear"
              optionLabel="name">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-custom-table [data]="paymentsTableData" [columns]="paymentsTableColumns">
            </app-custom-table>
          </div>
        </div>
      </div>
      <div *ngIf="pageNumber == 3">
        <div class="row p-1">
          <div class="col-12">
            <h2>{{ scholarship.Name }}</h2>
          </div>
        </div>

        <div class="row mb-3 d-flex align-items-end">
          <div class="col-3 d-flex flex-column">
            <span class="form-label font-weight-bold">Search Categories</span>
            <input type="text" class="form-control" [(ngModel)]="searchCategoryText"
              (ngModelChange)="onSearchTextChanged()" />
          </div>
          <div class="col-2 d-flex flex-column">
            <span class="form-label font-weight-bold">State</span>
            <p-dropdown [options]="states" class="form-control no-border" [(ngModel)]="states" optionLabel="name"
              (ngModelChange)="changeState()" [disabled]="true">
            </p-dropdown>
          </div>
          <div class="col-3 d-flex flex-column">
            <span class="form-label font-weight-bold">Scholarship</span>
            <p-dropdown [options]="scholarshipOptions" class="form-control no-border" [(ngModel)]="scholarshipOptions"
              [disabled]="true">
            </p-dropdown>
          </div>
          <button class="btn btn-primary" type="button" (click)="addCategory()">
            <span class="px-3 mx-3">Add Category</span>
          </button>
        </div>
        <div class="row">
          <div class="col-12">
            <app-custom-table [data]="disbursementCategoriesTableData" [rows]="10" showPaginator="true"
              [columns]="disbursementCategoriesTableColumns" [showSummary]="true" [useLazyLoad]="true"
              (onLoadData)="loadDisburmentCategoriesData($event)" [isLoading]="disbursementCategoriesTableLoading"
              [totalRecords]="disbursementCategoriesTableTotalRecords" (onRowTableClicked)="doCategoryEdit($event)">
            </app-custom-table>
          </div>
        </div>

        <p-sidebar [(visible)]="editViewDisplayCategory" position="right" [style]="{ width: '60%' }"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Vendor/Service Provider Name<span
                      class="text-danger">*</span></label>
                  <input type="text" class="form-control"
                    [(ngModel)]="disbursementCategory.FK_ScholarshipDisbursementCategory_Display" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Pre Authorization Required
                  </label>
                  <span class="d-flex justify-content-left">
                    <div class="p-field-radiobutton d-flex align-items-start h-100">
                      <p-radioButton name="PreAuth" [value]="true"
                        [(ngModel)]="disbursementCategory.PreAuthorizationRequired" inputId="PreAuthYes">
                      </p-radioButton>
                      <label for="PreAuthYes">Yes</label>
                    </div>
                    <div class="p-field-radiobutton d-flex align-items-start h-100 ml-4">
                      <p-radioButton name="PreAuth" [value]="false"
                        [(ngModel)]="disbursementCategory.PreAuthorizationRequired" inputId="PreAuthNo">
                      </p-radioButton>
                      <label for="PreAuthNo">No</label>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Licensed Instructors
                  </label>
                  <span class="d-flex justify-content-left">
                    <div class="p-field-radiobutton d-flex align-items-start h-100">
                      <p-radioButton name="LicensedInstructors" [value]="true"
                        [(ngModel)]="disbursementCategory.LicensedInstructors" inputId="LicensedYes">
                      </p-radioButton>
                      <label for="LicensedYes">Yes</label>
                    </div>
                    <div class="p-field-radiobutton d-flex align-items-start h-100 ml-4">
                      <p-radioButton name="LicensedInstructors" [value]="false"
                        [(ngModel)]="disbursementCategory.LicensedInstructors" inputId="LicensedNo">
                      </p-radioButton>
                      <label for="LicensedNo">No</label>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Document Upload for Approval
                  </label>
                  <span class="d-flex justify-content-left">
                    <div class="p-field-radiobutton d-flex align-items-start h-100">
                      <p-radioButton name="DocumentApproval" [value]="true"
                        [(ngModel)]="disbursementCategory.DocumentUploadForApproval" inputId="DocumentYes">
                      </p-radioButton>
                      <label for="DocumentYes">Yes</label>
                    </div>
                    <div class="p-field-radiobutton d-flex align-items-start h-100 ml-4">
                      <p-radioButton name="DocumentApproval" [value]="false"
                        [(ngModel)]="disbursementCategory.DocumentUploadForApproval" inputId="DocumentNo">
                      </p-radioButton>
                      <label for="DocumentNo">No</label>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" [disabled]="!isValidProfile()"
                  (click)="saveDisbursementCategory()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelDisbursementCategory()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 4">
        <div class="row p-1">
          <div class="col-12">
            <h2 class="text-header">{{ scholarship.Name }}</h2>
          </div>
        </div>
        <div class="row p-1 mt-3">
          <div class="col-12 d-flex justify-content-between">
            <h3 class="text-subtitle">School Accreditation</h3>
            <span class="text-subtitle">
              School will answer YES/NO to the following when completing their profile:
            </span>
          </div>
        </div>
        <div class="row mb-3 d-flex align-items-end">
          <div class="col-3 d-flex flex-column">
            <span class="form-label font-weight-bold">Search School Accreditation</span>
            <input type="text" class="form-control" [(ngModel)]="searchAccreditationText"
              (ngModelChange)="onSearchAccreditationTextChanged()" />
          </div>
          <button class="btn btn-primary" type="button" (click)="addAccreditation()">
            <span class="px-3 mx-3">Add School Accreditation</span>
          </button>
        </div>
        <div class="row">
          <div class="col-12">
            <app-custom-table [data]="accreditationsTableData" [rows]="5" showPaginator="true"
              [columns]="accreditationsTableColumns" [showSummary]="true" [useLazyLoad]="true"
              (onLoadData)="loadAccreditationsData($event)" [isLoading]="accreditationsTableLoading"
              [totalRecords]="accreditationsTableTotalRecords" (onRowTableClicked)="doAccreditationEdit($event)">
            </app-custom-table>
          </div>
        </div>

        <div class="row p-1 mt-3">
          <div class="col-12 d-flex justify-content-between">
            <h3 class="text-subtitle">School Eligibility</h3>
            <span class="text-subtitle">
              School will answer YES/NO to the following filling out an SCF
            </span>
          </div>
        </div>
        <div class="row mb-3 d-flex align-items-end">
          <div class="col-3 d-flex flex-column">
            <span class="form-label font-weight-bold">Search School Eligibility</span>
            <input type="text" class="form-control" [(ngModel)]="searchEligibilitiesText"
              (ngModelChange)="onSearchEligibilitiesTextChanged()" />
          </div>
          <button class="btn btn-primary" type="button" (click)="addEligibility()">
            <span class="px-3 mx-3">Add School Eligibility</span>
          </button>
        </div>
        <div class="row">
          <div class="col-12">
            <app-custom-table [data]="eligibilitiesTableData" [rows]="5" showPaginator="true"
              [columns]="eligibilitiesTableColumns" [showSummary]="true" [useLazyLoad]="true"
              (onLoadData)="loadEligibilitiesData($event)" [isLoading]="eligibilitiesTableLoading"
              [totalRecords]="eligibilitiesTableTotalRecords" (onRowTableClicked)="doEligibilityEdit($event)">
            </app-custom-table>
          </div>
        </div>

        <p-sidebar [(visible)]="editSchoolAccreditationViewDisplay" position="right" [style]="{width:'60%'}"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class="row p-1 mb-3">
              <div class="col-12">
                <h3 class="text-subtitle font-weight-bold">School Accreditation</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Accreditation Name<span
                      class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="accreditationModel.Name" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Accreditation Abbreviation<span
                      class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="accreditationModel.ShortName" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Accreditation Type<span
                      class="text-danger">*</span></label>
                  <p-dropdown [options]="accreditationTypeOptions" class="form-control no-border"
                    [(ngModel)]="accreditationModel.FK_AccreditationType">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" [disabled]="!isValidAccreditation()"
                  (click)="saveAccreditation()">
                  Submit
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelAccreditation()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>

        <p-sidebar [(visible)]="editSchoolEligibilityViewDisplay" position="right" [style]="{width:'60%'}"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class="row p-1 mb-3">
              <div class="col-12">
                <h3 class="text-subtitle font-weight-bold">School Eligibility</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Eligibility Content<span
                      class="text-danger">*</span></label>
                  <textarea [rows]="5" [cols]="50" pInputTextarea [(ngModel)]="eligibilityModel.HtmlContent"
                    class="form-control">
                  </textarea>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <p-checkbox [(ngModel)]="eligibilityModel.ForStudent" [binary]="true" label="Use for Student"
                  class="form-label font-weight-bold">
                </p-checkbox>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <p-checkbox [(ngModel)]="eligibilityModel.ForSchool" [binary]="true" label="Use for School"
                  class="form-label font-weight-bold">
                </p-checkbox>
              </div>
            </div>
            <div class="row p-1 mt-3">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" [disabled]="!isValidEligibility()"
                  (click)="saveEligibilities()">
                  Submit
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelEligibilities()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 5">

      </div>
      <div *ngIf="pageNumber == 6">
        <div class="row p-2">
          <div class="col-12">
            <div class="d-flex flex-row justify-content-left align-items-center" *ngIf="!scholarshipHandbookModel">
              <label class="form-label font-weight-bold m-0 py-0 px-2">Current:</label>
              <span class="font-weight-normal m-0 py-0 px-2">N/A</span>
              <button class="btn btn-primary" (click)="fileInput.click()">Upload</button>
            </div>
            <div class="d-flex flex-row justify-content-left align-items-center" *ngIf="scholarshipHandbookModel">
              <label class="form-label font-weight-bold m-0 py-0 px-2">Current:</label>
              <span class="font-weight-normal m-0 py-0 px-2 handbook-link" (click)="showHandbook()">
                {{ scholarship.Name }} Handbook
              </span>
              <label class="font-italic m-0 py-0 px-2">
                Updated at {{ scholarshipHandbookModel.CreatedDate | date: "MM/dd/yyyy" }}
              </label>
              <button class="btn btn-primary" (click)="fileInput.click()">Update</button>
            </div>
            <input name="files" type="file" class="d-none" #fileInput accept="application/pdf" [(ngModel)]="fileModel"
              (change)="processFile($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>