import { StudentGradeLevelsSchoolYearsLookUpModel} from '../entities/StudentGradeLevelsSchoolYearsLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class StudentGradeLevelsSchoolYearsLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: StudentGradeLevelsSchoolYearsLookUpModel = new StudentGradeLevelsSchoolYearsLookUpModel();
}

export class StudentGradeLevelsSchoolYearsLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<StudentGradeLevelsSchoolYearsLookUpModel> = <Array<StudentGradeLevelsSchoolYearsLookUpModel >>[]
}

export interface IStudentGradeLevelsSchoolYearsLookUpListParams {
	archived?:boolean;
	archived_booleanValue?:boolean;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	pK_Id?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	fK_GradeLevel?:number;
	fK_GradeLevel_display?:string;
	fK_GradeLevel_like?:string;
	fK_GradeLevel_fromValue?:number;
	fK_GradeLevel_toValue?:number;
	fK_Student?:number;
	fK_Student_display?:string;
	fK_Student_like?:string;
	fK_Student_fromValue?:number;
	fK_Student_toValue?:number;
	fK_State_Application?:number;
	fK_State_Application_display?:string;
	fK_State_Application_like?:string;
	fK_State_Application_fromValue?:number;
	fK_State_Application_toValue?:number;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	zengineStudentId?:number;
	zengineStudentId_fromValue?:number;
	zengineStudentId_toValue?:number;
	zengineHouseholdId?:number;
	zengineHouseholdId_fromValue?:number;
	zengineHouseholdId_toValue?:number;
	previousSchoolDistrict?:string;
	previousSchoolDistrict_like?:string;
	previousSchoolType?:string;
	previousSchoolType_like?:string;
	previousSchoolName?:string;
	previousSchoolName_like?:string;
	nameOfZonedSchool?:string;
	nameOfZonedSchool_like?:string;
	fK_Household?:number;
	fK_Household_fromValue?:number;
	fK_Household_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class StudentGradeLevelsSchoolYearsLookUpListParams implements IStudentGradeLevelsSchoolYearsLookUpListParams, ISearchModel  {
	archived?:boolean=null;
	archived_booleanValue?:boolean=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	pK_Id?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	fK_GradeLevel?:number=null;
	fK_GradeLevel_display?:string=null;
	fK_GradeLevel_like?:string=null;
	fK_GradeLevel_fromValue?:number=null;
	fK_GradeLevel_toValue?:number=null;
	fK_Student?:number=null;
	fK_Student_display?:string=null;
	fK_Student_like?:string=null;
	fK_Student_fromValue?:number=null;
	fK_Student_toValue?:number=null;
	fK_State_Application?:number=null;
	fK_State_Application_display?:string=null;
	fK_State_Application_like?:string=null;
	fK_State_Application_fromValue?:number=null;
	fK_State_Application_toValue?:number=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	zengineStudentId?:number=null;
	zengineStudentId_fromValue?:number=null;
	zengineStudentId_toValue?:number=null;
	zengineHouseholdId?:number=null;
	zengineHouseholdId_fromValue?:number=null;
	zengineHouseholdId_toValue?:number=null;
	previousSchoolDistrict?:string=null;
	previousSchoolDistrict_like?:string=null;
	previousSchoolType?:string=null;
	previousSchoolType_like?:string=null;
	previousSchoolName?:string=null;
	previousSchoolName_like?:string=null;
	nameOfZonedSchool?:string=null;
	nameOfZonedSchool_like?:string=null;
	fK_Household?:number=null;
	fK_Household_fromValue?:number=null;
	fK_Household_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

