

import { BaseComponent } from "./BaseComponent";
import { Input, Output, EventEmitter, Directive } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { debug } from "util";
import { UpdateEvent } from "./models/models";

@Directive()
export class BaseEditComponent extends BaseComponent {
  constructor(r?: ActivatedRoute) {
    super(r);

  }

  protected getDataComplete() {

  }

  protected baseNgOnInit() {
    super.baseNgOnInit();
    this.loadOptions();
    var me = this;
    window.setTimeout(function () {
      console.log('base ngInit');
      if (!me.id && me.route) {
        var v = me.route.snapshot.paramMap.get("id");
        if (v) {
          console.log('id set from url:' + v);
          me.id = v;
        }
      }
    }, 100);

  }


  public _id: any = null;



  @Input('id')
  public set id(value: any) {
    this._id = value;
    if (value) {
      this.getData();
    }
  }

  public get id(): any {
    return this._id;
  }



  public childComponentSaved() {

    //this.getData();
  }

  protected getData() {

  }

  protected loadOptions() {

  }


  public save() {

  }

  public cancel() {
    this.cancelled.emit(null);
  }

  @Input('commit')
  public set commit(value: UpdateEvent) {
    if (value && value.Stamp) {
      this._id = value.Id;
      this.save();
    }
  }

  @Output('saved')
  public saved: EventEmitter<any> = new EventEmitter<any>();

  @Output('cancelled')
  public cancelled: EventEmitter<any> = new EventEmitter<any>();






}
