//import { ManagerResponse, ISearchModel, IListItem } from '../models';
import { AwardDeterminationStatusTypes } from '../models';
import { AwardDeterminationModelBase } from './AwardDeterminationModel.base'
import { AwardDeterminationStatusTypeModel } from './AwardDeterminationStatusTypeModel';
import { GuardianModel } from './GuardianModel';
import { StudentModel } from './StudentModel';
export class AwardDeterminationModel extends AwardDeterminationModelBase {
  FK_ScholarshipType: number;
  Completed: boolean;
  CompletedValue: number;
  SchoolSelected: boolean;
  FK_School: number;
  FK_School_Display: string;
  CurrentAwardDeterminationStatus: AwardDeterminationStatusTypeModel;
  Guardian: GuardianModel;
  StudentDisplay: string;
  ScholarshipType: string;
  FK_State: number;
  FK_State_Display: string;
}