import { SUPER_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';
import { BaseComponent } from 'src/app/BaseComponent';
import { AwardDeterminationModel } from 'src/app/models/entities/AwardDeterminationModel';
import { AwardDeterminationStatusTypeModel } from 'src/app/models/entities/AwardDeterminationStatusTypeModel';
import { GuardianModel } from 'src/app/models/entities/GuardianModel';
import { AwardDeterminationStatusTypes } from 'src/app/models/models';
import { aaaAPIFamilyService } from 'src/app/services/aaa.API.family.service';

@Component({
  selector: 'app-family-award-status-page',
  templateUrl: './family-award-status-page.component.html',
  styleUrls: ['./family-award-status-page.component.scss']
})
export class FamilyAwardStatusPageComponent extends BaseComponent implements OnInit {
  guardianModel: GuardianModel = null;
  pendingAwardStatus: AwardDeterminationModel[] = [];
  eligibleAndWaitListed: AwardDeterminationStatusTypes[] = [
    AwardDeterminationStatusTypes.EligibleAndWaitListed_100,
    AwardDeterminationStatusTypes.EligibleAndWaitListed_75,
    AwardDeterminationStatusTypes.EligibleAndWaitListed_50,
    AwardDeterminationStatusTypes.EligibleAndWaitListed_25
  ];
  eligibleAndFunded: AwardDeterminationStatusTypes[] = [
    AwardDeterminationStatusTypes.EligibleAndFunded_100,
    AwardDeterminationStatusTypes.EligibleAndFunded_75,
    AwardDeterminationStatusTypes.EligibleAndFunded_50,
    AwardDeterminationStatusTypes.EligibleAndFunded_25
  ];
  denied: AwardDeterminationStatusTypes[] = [
    AwardDeterminationStatusTypes.Denied,
    AwardDeterminationStatusTypes.Denied_Explain
  ];
  gardinerPrefunding: AwardDeterminationStatusTypes[] = [
    AwardDeterminationStatusTypes.EligibleAndPendingFunding
  ];
  empowerment: AwardDeterminationStatusTypes[] = [
    AwardDeterminationStatusTypes.Empowerment
  ];
  showAppealReason: boolean = false;
  showForfeitReason: boolean = false;
  notesText: string = null;
  forfeitNotesText: string = null;

  constructor(private familyService: aaaAPIFamilyService) {
    super(null, false);
  }

  ngOnInit(): void {
    this.familyService.getGuardianByLogin(this.gl.LoginId)
      .subscribe(response => {
        if (response.Success) {
          this.guardianModel = response.Data;
          this.familyService.getAwardDeterminationList({ fK_Guardian: this.guardianModel.PK_Id })
            .subscribe(responseADList => {
              this.pendingAwardStatus = [...responseADList.Data];
              this.getCurrentAwardStatuses();
            });
        }
      });
  }

  get hasNotesText(): boolean {
    return (this.notesText && this.notesText.trim().length > 0);
  }

  get hasForfeitNotesText(): boolean {
    return (this.forfeitNotesText && this.forfeitNotesText.trim().length > 0);
  }

  private getCurrentAwardStatuses() {

    forkJoin(this.pendingAwardStatus.map(item => this.familyService.getCurrentStatusAwardDetermination(item.PK_Id)))
      .pipe(defaultIfEmpty([]))
      .subscribe(responses => {
        console.log('responses: ', responses);
        for (let i = 0; i < responses.length; i++) {
          this.pendingAwardStatus[i].CurrentAwardDeterminationStatus = responses[i].Data;
          this.pendingAwardStatus[i].Guardian = this.guardianModel;
        }
        console.log('pendingAwardStatus: ', this.pendingAwardStatus);
        this.checkPendingAwardDetermination();
      });
  }

  private checkPendingAwardDetermination() {
    if (this.pendingAwardStatus.length == 0) {
      this.goToDashboard();
    }
  }

  private moveToNextPendingAwardDetermination() {
    if (this.pendingAwardStatus.length >= 1) {
      this.pendingAwardStatus.splice(0, 1);
      if (this.pendingAwardStatus.length > 0) {
        if (this.pendingAwardStatus[0].CurrentAwardDeterminationStatus == null) {
          this.moveToNextPendingAwardDetermination();
          return;
        }
      }
      this.checkPendingAwardDetermination();
    }
  }

  /*
    Methods
  */

  private clearReasons() {
    this.notesText = null;
    this.forfeitNotesText = null;
    this.showAppealReason = false;
    this.showForfeitReason = false;
  }

  private processAccepted() {
    this.clearReasons();
  }

  private processAppeal() {
    this.clearReasons();
  }

  private processForfeit() {
    this.clearReasons();
  }

  onAcceptClick() {
    console.log("Accepted Clicked");
    this.processAccepted();
    this.moveToNextPendingAwardDetermination();
  }

  onForfeitClick() {
    this.showForfeitReason = true;
    this.forfeitNotesText = null;
    console.log("Forfeit Clicked");
  }

  onAppealClick() {
    this.showAppealReason = true;
    console.log("Appeal Clicked");
  }

  onAcceptDeterminationClick() {
    console.log("Accept Determination Clicked");
    this.processAccepted();
    this.moveToNextPendingAwardDetermination();
  }

  onSubmitClick() {
    console.log("Submit Clicked");
    this.processAppeal();
    this.moveToNextPendingAwardDetermination();
  }

  onCancelClick() {
    this.showAppealReason = false;
    this.notesText = null;
    console.log("Cancel Clicked");
  }

  onForfeitConfirmClick() {
    console.log("Forfeit Confirm Clicked");
    this.processForfeit();
    this.moveToNextPendingAwardDetermination();
  }

  onForfeitCancelClick() {
    this.showForfeitReason = false;
    console.log("Forfeit Cancel Clicked");
  }

  goToDashboard() {
    this.gl.GoTo('familydashboard');
  }
}
