import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolTypeEditBaseComponent } from './schoolTypeEdit.base';

@Component({
	selector: 'app-schoolTypeEdit',
	templateUrl: './schoolTypeEdit.component.html',
})
export class SchoolTypeEditComponent extends SchoolTypeEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
