import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolCalendarLookUpListParams } from '../../models/entities/SchoolCalendarLookUpModel.response';
import { SchoolCalendarLookUpModel } from '../../models/entities/SchoolCalendarLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolCalendarLookUpListBaseComponent } from './schoolCalendarLookUpList.base';

@Component({
	selector: 'app-schoolCalendarLookUpList',
	templateUrl: './schoolCalendarLookUpList.component.html',
})
export class SchoolCalendarLookUpListComponent extends SchoolCalendarLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
