import {IListItem } from '../models';
export class SchoolCommitmentModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_School:number = 0;
	public FK_School_Display:string = null;
	public FK_SchoolCommitmentStatusType:number = 0;
	public FK_SchoolCommitmentStatusType_Display:string = null;
	public FK_Student:number = 0;
	public FK_Student_Display:string = null;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public StudentStartDate:Date = null;
	public FK_Withdraw?:number = null;
	public FK_Withdraw_Display:string = null;
}
