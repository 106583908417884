import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolEligibilitiesResponsListParams } from '../../models/entities/SchoolEligibilitiesResponsModel.response';
import { SchoolEligibilitiesResponsModel } from '../../models/entities/SchoolEligibilitiesResponsModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolEligibilitiesResponsListBaseComponent } from './schoolEligibilitiesResponsList.base';

@Component({
	selector: 'app-schoolEligibilitiesResponsList',
	templateUrl: './schoolEligibilitiesResponsList.component.html',
})
export class SchoolEligibilitiesResponsListComponent extends SchoolEligibilitiesResponsListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
