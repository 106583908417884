import {IListItem } from '../models';
export class ScholarshipHandbookModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_Scholarship:number = 0;
	public FK_Scholarship_Display:string = null;
	public FK_Login_CreatedBy:string = "";
	public FK_Login_CreatedBy_Display:string = null;
	public FK_Document?:string = null;
	public FK_Document_Display:string = null;
	public CreatedDate?:Date = null;
}
