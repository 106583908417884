import { Component, OnInit, Directive } from '@angular/core';
import { ErrorMessagesService } from '../services/error-messages.service';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.css']
})
export class ValidationComponent implements OnInit {

  constructor(public errorMessagesService: ErrorMessagesService) {

  }
  subscription: any;

  public _showSpinner: boolean = false;
  


  ngOnInit() {
    var me = this;
    this.errorMessagesService.spinnerChange.subscribe(x => {
      me._showSpinner = x;
     
    });
    this.subscription = this.errorMessagesService.getSuccessMessageChange()
      .subscribe(item => {

        if (item) {
          var obj = this.errorMessagesService;
          window.setTimeout(function () {
            var fadeTarget = document.getElementById("successMessagePanel");
            if (fadeTarget) {
              var fadeEffect = window.setInterval(function () {
                if (!fadeTarget.style.opacity) {
                  fadeTarget.style.opacity = "1";
                }

                if (parseFloat(fadeTarget.style.opacity) < 0.1) {
                  obj.successMessage = null;
                  fadeTarget.style.opacity = "1";
                  window.clearInterval(fadeEffect);

                } else {
                  fadeTarget.style.opacity = (parseFloat(fadeTarget.style.opacity) - 0.1).toString();
                }
              }, 200);
            }
          }, 2000);
        }
      });

  }

  ngOnDestroy() {

    this.subscription.unsubscribe();
    this.errorMessagesService.clear(null);
  }


}
