import { GuardianReimbursementListItemModel} from '../entities/GuardianReimbursementListItemModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class GuardianReimbursementListItemResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: GuardianReimbursementListItemModel = new GuardianReimbursementListItemModel();
}

export class GuardianReimbursementListItemList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<GuardianReimbursementListItemModel> = <Array<GuardianReimbursementListItemModel >>[]
}

export interface IGuardianReimbursementListItemListParams {
	pK_Id?:number;
	description?:string;
	description_like?:string;
	purpose?:string;
	purpose_like?:string;
	fK_GuardianReimbursement?:number;
	fK_GuardianReimbursement_display?:string;
	fK_GuardianReimbursement_like?:string;
	fK_GuardianReimbursement_fromValue?:number;
	fK_GuardianReimbursement_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class GuardianReimbursementListItemListParams implements IGuardianReimbursementListItemListParams, ISearchModel  {
	pK_Id?:number=null;
	description?:string=null;
	description_like?:string=null;
	purpose?:string=null;
	purpose_like?:string=null;
	fK_GuardianReimbursement?:number=null;
	fK_GuardianReimbursement_display?:string=null;
	fK_GuardianReimbursement_like?:string=null;
	fK_GuardianReimbursement_fromValue?:number=null;
	fK_GuardianReimbursement_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

