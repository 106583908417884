<div class="topbar col-12" *ngIf="someUserLoggedIn && !isHiddenRoute">
  <div class="d-flex">
    <span>
      <img class="topbar-logo" src="assets/aaa-logo.png" />
    </span>
    <div *ngIf="isOnlyAdmin" class="d-flex flex-row justify-content-left">
      <span class="mx-2 topbar-item btn" (click)="onAdminMenuItemClick('Dashboard')">
        Dashboard
      </span>
      <span class="mx-2 topbar-item btn" (click)="onAdminMenuItemClick('Families & Students')">
        Families & Students
      </span>
      <span class="mx-2 topbar-item btn" (click)="onAdminMenuItemClick('Schools')">
        Schools
      </span>
      <span class="mx-2 topbar-item btn" (click)="onAdminMenuItemClick('Vendors')">
        Vendors
      </span>
      <span class="mx-2 topbar-item btn" (click)="onAdminMenuItemClick('Scholarship Settings')">
        Scholarship Settings
      </span>
      <span class="mx-2 topbar-item btn" (click)="onAdminMenuItemClick('Transactions')">
        Transactions
      </span>
    </div>
  </div>
  <div class="topbar-items">
    <span class="topbar-item p-2">
      <i class="fas fa-envelope fa-2x"></i>
    </span>
    <div #popupMenuContainer class="btn d-flex align-items-center" (click)="topMenu.toggle($event)">
      <span class="topbar-item">
        <i class="fas fa-user-circle fa-2x"></i>
      </span>
      <span class="topbar-item p-2">
        {{ getUserName }}
      </span>
    </div>
  </div>
</div>
<!-- breadcrumbs -->
<div class="breadcrumbbar" *ngIf="showBreadCrumb && !isHiddenRoute">
  <p-breadcrumb [model]="bItems" [home]="home"></p-breadcrumb>
</div>

<p-menu #topMenu [popup]="true" [model]="menuItems" styleClass="topbar-menu" [appendTo]="popupMenuContainer"></p-menu>
<app-validation></app-validation>
<router-outlet></router-outlet>