import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolAccreditationsLookUpEditBaseComponent } from './schoolAccreditationsLookUpEdit.base';

@Component({
	selector: 'app-schoolAccreditationsLookUpEdit',
	templateUrl: './schoolAccreditationsLookUpEdit.component.html',
})
export class SchoolAccreditationsLookUpEditComponent extends SchoolAccreditationsLookUpEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
