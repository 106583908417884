<div *ngIf="modalEdit||newModalEdit" class="modal" [style.left]="leftOffset" [style.top]="topOffset">
  <app-schoolTransactionNoteEdit [modalview]="true" [id]="modalEdit" [level]="(level+.5)" (saved)="modalEditSaved($event)" (cancelled)="modalEdit=null;newModalEdit=false"></app-schoolTransactionNoteEdit>
</div>
<div class="card">
  <div class="card-header">
    School Transaction Note List <a class="right"  (click)="newModalEdit = true" ><i class="fa fa-plus" title="Add School Transaction Note"></i></a>
    <div><app-search-input [placeholdertext]="'Search'" (changed)="fuzzySearchChange($event)" ></app-search-input></div>

  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div style="grid-template-columns: 100px ;" class="table">
          <div class="table-header" style="text-align:left">
            <i (click)="selectAll()" title="Select All" class="fa" [ngClass]="{ 'fa-check-square-o':selectAllStatus,'fa-square-o':!selectAllStatus}"></i>
            <i title='Delete Many' (click)='deleteMany()' class='fa fa-trash icon-link'></i>
          </div>


          <div><i title="Filter" (click)="searchSelected()" class="fa fa-filter icon-link"></i><i title="Filter" (click)="clearSearch()" class="fa fa-times icon-link"></i></div>


          <ng-container *ngFor="let x of model">

          </ng-container>
        </div>

        <app-paging [showpagesize]="true" [data]="pagingData" (changed)="pagingChanged($event)"></app-paging>
      </div>
    </div>
  </div>
</div>

<app-spinner [showspinner]="ShowSpinner"></app-spinner>

