import { PrepaidCollegeTransferModel} from '../entities/PrepaidCollegeTransferModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class PrepaidCollegeTransferResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: PrepaidCollegeTransferModel = new PrepaidCollegeTransferModel();
}

export class PrepaidCollegeTransferList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<PrepaidCollegeTransferModel> = <Array<PrepaidCollegeTransferModel >>[]
}

export interface IPrepaidCollegeTransferListParams {
	pK_Id?:number;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number;
	amount?:number;
	amount_fromValue?:number;
	amount_toValue?:number;
	fK_PrepaidCollegeTransferStatusType?:number;
	fK_PrepaidCollegeTransferStatusType_display?:string;
	fK_PrepaidCollegeTransferStatusType_like?:string;
	fK_PrepaidCollegeTransferStatusType_fromValue?:number;
	fK_PrepaidCollegeTransferStatusType_toValue?:number;
	dateApproved?:Date;
	dateApproved_fromDate?:string;
	dateApproved_toDate?:string;
	fK_Login_ApprovedBy?:string;
	fK_Login_ApprovedBy_display?:string;
	fK_Login_ApprovedBy_like?:string;
	savingsAccountNumber?:string;
	savingsAccountNumber_like?:string;
	prepaidAccountNumber?:string;
	prepaidAccountNumber_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class PrepaidCollegeTransferListParams implements IPrepaidCollegeTransferListParams, ISearchModel  {
	pK_Id?:number=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number=null;
	amount?:number=null;
	amount_fromValue?:number=null;
	amount_toValue?:number=null;
	fK_PrepaidCollegeTransferStatusType?:number=null;
	fK_PrepaidCollegeTransferStatusType_display?:string=null;
	fK_PrepaidCollegeTransferStatusType_like?:string=null;
	fK_PrepaidCollegeTransferStatusType_fromValue?:number=null;
	fK_PrepaidCollegeTransferStatusType_toValue?:number=null;
	dateApproved?:Date=null;
	dateApproved_fromDate?:string=null;
	dateApproved_toDate?:string=null;
	fK_Login_ApprovedBy?:string=null;
	fK_Login_ApprovedBy_display?:string=null;
	fK_Login_ApprovedBy_like?:string=null;
	savingsAccountNumber?:string=null;
	savingsAccountNumber_like?:string=null;
	prepaidAccountNumber?:string=null;
	prepaidAccountNumber_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

