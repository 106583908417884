import {IListItem } from '../models';
export class ZengineFormYearLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public FK_ZengineForm:number = 0;
	public FK_ZengineForm_Display:string = null;
}
