<div class="card">
  <div class="card-header">
    Sync Zengine Forms
  </div>
  <div class="card-body">
    <div class="form">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>API Token</label><span title="Required" class="required">*</span>
            <input [(ngModel)]="model" type="text" class="form-control">
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">

            <button (click)="sync()" class="form-control">Sync Forms</button>


          </div>
        </div>

      </div>


      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Form Id</label>
            <input [(ngModel)]="form" type="text" class="form-control">
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">

            <button (click)="showRecords()" class="form-control">Show Records</button>



          </div>
        </div>
        <div class="col-12">
          <div class="form-group">

           

            <textarea [(ngModel)]="recordsJason"></textarea>

          </div>
        </div>

      </div>

    </div>


  </div>
</div>



