import { StudentDisabilityLookUpModel} from '../entities/StudentDisabilityLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class StudentDisabilityLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: StudentDisabilityLookUpModel = new StudentDisabilityLookUpModel();
}

export class StudentDisabilityLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<StudentDisabilityLookUpModel> = <Array<StudentDisabilityLookUpModel >>[]
}

export interface IStudentDisabilityLookUpListParams {
	pK_Id?:number;
	fK_Student?:number;
	fK_Student_display?:string;
	fK_Student_like?:string;
	fK_Student_fromValue?:number;
	fK_Student_toValue?:number;
	fK_Disability?:number;
	fK_Disability_display?:string;
	fK_Disability_like?:string;
	fK_Disability_fromValue?:number;
	fK_Disability_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class StudentDisabilityLookUpListParams implements IStudentDisabilityLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_Student?:number=null;
	fK_Student_display?:string=null;
	fK_Student_like?:string=null;
	fK_Student_fromValue?:number=null;
	fK_Student_toValue?:number=null;
	fK_Disability?:number=null;
	fK_Disability_display?:string=null;
	fK_Disability_like?:string=null;
	fK_Disability_fromValue?:number=null;
	fK_Disability_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

