import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolGradeLevelsLookUpListParams } from '../../models/entities/SchoolGradeLevelsLookUpModel.response';
import { SchoolGradeLevelsLookUpModel } from '../../models/entities/SchoolGradeLevelsLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolGradeLevelsLookUpListBaseComponent } from './schoolGradeLevelsLookUpList.base';

@Component({
	selector: 'app-schoolGradeLevelsLookUpList',
	templateUrl: './schoolGradeLevelsLookUpList.component.html',
})
export class SchoolGradeLevelsLookUpListComponent extends SchoolGradeLevelsLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
