import { Component, OnInit, Directive } from '@angular/core';
import {  IHouseholdListParams } from '../../models/entities/HouseholdModel.response';
import { HouseholdModel } from '../../models/entities/HouseholdModel';
import { PagingData } from '../../paging/paging.component';
import { HouseholdListBaseComponent } from './householdList.base';

@Component({
	selector: 'app-householdList',
	templateUrl: './householdList.component.html',
})
export class HouseholdListComponent extends HouseholdListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
