import { Component, OnInit, Directive } from '@angular/core';
import {  IAccreditationTypeListParams } from '../../models/entities/AccreditationTypeModel.response';
import { AccreditationTypeModel } from '../../models/entities/AccreditationTypeModel';
import { PagingData } from '../../paging/paging.component';
import { AccreditationTypeListBaseComponent } from './accreditationTypeList.base';

@Component({
	selector: 'app-accreditationTypeList',
	templateUrl: './accreditationTypeList.component.html',
})
export class AccreditationTypeListComponent extends AccreditationTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
