<div class="card">
    <div class="card-header">
        School Transaction <span class="right">
            <a title="Cancel" *ngIf="!ModalView" routerLink="/schooltransactionlist"><i class="fa fa-undo icon-link"></i></a>
            <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
            <i class="fa fa-save icon-link" (click)="save()"></i>
        </span>
    </div>
    <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
        <div class="form">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Created Date</label><span title="Required" class="required">*</span>
                        <app-date-picker [(model)]="model.CreatedDate"></app-date-picker>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Student Grade Levels School Years Look Up</label><span title="Required" class="required">*</span>
                        <input [(ngModel)]="model.FK_StudentGradeLevelsSchoolYearsLookUp" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Transaction Status Type</label><span title="Required" class="required">*</span>
                        <select [(ngModel)]="model.FK_TransactionStatusType" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_TransactionStatusType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Transaction Type</label><span title="Required" class="required">*</span>
                        <select [(ngModel)]="model.FK_TransactionType" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_TransactionType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Amount</label><span title="Required" class="required">*</span>
                        <input [(ngModel)]="model.Amount" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Verification</label>
                        <select [(ngModel)]="model.FK_Verification" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_Verification_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>


            </div>


        </div>
    </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
