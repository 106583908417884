import { Component, OnInit, Directive } from '@angular/core';
import {  ILoginListParams } from '../../models/entities/LoginModel.response';
import { LoginModel } from '../../models/entities/LoginModel';
import { PagingData } from '../../paging/paging.component';
import { LoginListBaseComponent } from './loginList.base';

@Component({
	selector: 'app-loginList',
	templateUrl: './loginList.component.html',
})
export class LoginListComponent extends LoginListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
