import { Component, OnInit, Directive } from '@angular/core';
import {  IPreAuthorizationListParams } from '../../models/entities/PreAuthorizationModel.response';
import { PreAuthorizationModel } from '../../models/entities/PreAuthorizationModel';
import { PagingData } from '../../paging/paging.component';
import { PreAuthorizationListBaseComponent } from './preAuthorizationList.base';

@Component({
	selector: 'app-preAuthorizationList',
	templateUrl: './preAuthorizationList.component.html',
})
export class PreAuthorizationListComponent extends PreAuthorizationListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
