import { Component, OnInit, Directive } from '@angular/core';
import {  IVendorTransactionListParams } from '../../models/entities/VendorTransactionModel.response';
import { VendorTransactionModel } from '../../models/entities/VendorTransactionModel';
import { PagingData } from '../../paging/paging.component';
import { VendorTransactionListBaseComponent } from './vendorTransactionList.base';

@Component({
	selector: 'app-vendorTransactionList',
	templateUrl: './vendorTransactionList.component.html',
})
export class VendorTransactionListComponent extends VendorTransactionListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
