import { Component, OnInit, Directive } from '@angular/core';
import {  IContactListParams } from '../../models/entities/ContactModel.response';
import { ContactModel } from '../../models/entities/ContactModel';
import { PagingData } from '../../paging/paging.component';
import { ContactListBaseComponent } from './contactList.base';

@Component({
	selector: 'app-contactList',
	templateUrl: './contactList.component.html',
})
export class ContactListComponent extends ContactListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
