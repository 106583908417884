<div class="card">
  <div class="card-header">
    Login List <a class="right" routerLink="/userloginedit"><i class="fa fa-plus" title="Add Login"></i></a>
    <div>
      <div><app-search-input [placeholdertext]="'Search'" (changed)="fuzzySearchChange($event)"></app-search-input></div>


    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div style="grid-template-columns:repeat(4, 1fr);" class="table">

          <div class="table-header" (click)="sortChange('Email')">Email <i *ngIf="search._sort=='Email'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('IsEnabled')">Is Enabled <i *ngIf="search._sort=='IsEnabled'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('TwoFactorPhoneNumber')">Two Factor Phone Number <i *ngIf="search._sort=='TwoFactorPhoneNumber'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('CreatedDate')">Created Date <i *ngIf="search._sort=='CreatedDate'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>


          <ng-container *ngFor="let x of model">
            <div>
              <span class='edit-tools'>
                <a [routerLink]="'/userloginedit/' + x.PK_Id"><i title='Edit' class='fa fa-pencil icon-link'></i></a>
                <i title='Delete' (click)='delete(x.PK_Id )' class='fa fa-trash icon-link'></i>
                <i title='Reset Password' (click)='resetPassword(x.Email)' class='fa fa-paper-plane icon-link'></i>
              </span>
            {{x.Email}}
            </div>
            <div>{{x.IsEnabled}}</div>
            <div>{{x.TwoFactorPhoneNumber}}</div>
            <div>{{x.CreatedDate}}</div>

          </ng-container>
        </div>

        <app-paging [showpagesize]="true" [data]="pagingData" (changed)="pagingChanged($event)"></app-paging>
      </div>
    </div>
  </div>
</div>
