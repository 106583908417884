import {IListItem } from '../models';
export class VendorTransactionModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public FK_Vendor:number = 0;
	public FK_Vendor_Display:string = null;
	public FK_Student:number = 0;
	public FK_Student_Display:string = null;
	public FK_Guardian:number = 0;
	public FK_Guardian_Display:string = null;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public FK_TransactionType:number = 0;
	public FK_TransactionType_Display:string = null;
	public FK_TransactionStatusType:number = 0;
	public FK_TransactionStatusType_Display:string = null;
	public PK_Id:number = 0;
	public FK_Login_CreatedBy:string = "";
	public FK_Login_CreatedBy_Display:string = null;
	public CreatedDate:Date = null;
	public Amount:number = 0;
	public FK_PreAuthorization?:number = null;
	public FK_PreAuthorization_Display:string = null;
	public Description?:string = null;
}
