import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { Subscription, timer } from 'rxjs';
import { BaseComponent } from '../../../BaseComponent';
import { CONSTANTS } from '../../../constants/constants';
import { ITableColumnDefinition } from '../../../interfaces/table-column-definition-interface';
import { ContactModel } from '../../../models/entities/ContactModel';
import { LoginModel } from '../../../models/entities/LoginModel';
import { LoginRolesLookUpModel } from '../../../models/entities/LoginRolesLookUpModel';
import { RoleModel } from '../../../models/entities/RoleModel';
import { aaaAPIAdminService } from '../../../services/aaa.API.admin.service';
import { FieldTypeDefinition } from '../../../types/field-type-definition-type';

@Component({
  selector: 'app-admin-users-page',
  templateUrl: './admin-users-page.component.html',
  styleUrls: ['./admin-users-page.component.scss']
})
export class AdminUsersPageComponent extends BaseComponent implements OnInit {
  usersSearchText: string = null;
  usersActive: boolean = true;
  usersTableData: any[] = [];
  usersTableColumns: ITableColumnDefinition[] = [];
  usersTableLoading: boolean = false;
  usersTableTotalRecords: number = null;
  usersList: LoginModel[] = [];
  usersTypeList: RoleModel[] = [];
  userTypeOptions: SelectItem[] = [];
  userTypeSelected: RoleModel = null;

  lastLazyLoadEvent: LazyLoadEvent = null;
  lastSearchText: string = null;
  rowSelected: any = null;
  editUserViewDisplay: boolean = false;
  editModel: LoginModel = new LoginModel;
  editContactModel: ContactModel = new ContactModel;

  searchSubscription: Subscription;

  constructor(
    private adminService: aaaAPIAdminService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super(null, false);
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.generateUsersTableColumns();
    this.adminService.getRoleList({})
      .subscribe(responseRoles => {
        this.usersTypeList = responseRoles.Data;
        this.userTypeOptions = this.usersTypeList.map(item => {
          return {
            label: item.Name,
            value: item,
          } as SelectItem;
        });
        this.userTypeSelected = this.userTypeOptions[0].value as RoleModel;
      });
    this.loadUserData(CONSTANTS.FirstLazyLoadEvent);
  }

  private generateUsersTableColumns() {
    this.usersTableColumns = [];
    this.usersTableColumns.push({
      fieldCaption: 'Status',
      fieldName: 'StatusIcon',
      fieldType: FieldTypeDefinition.FontAwesomeIcon,
      showTooltip: true,
      tooltipFieldName: 'StatusDescription'
    });
    this.usersTableColumns.push({
      fieldCaption: 'Name',
      fieldName: 'UserName',
      fieldType: FieldTypeDefinition.String,
      sortable: true,
      width: '20%'
    });
    this.usersTableColumns.push({
      fieldCaption: 'Email',
      fieldName: 'EmailAddress',
      fieldType: FieldTypeDefinition.String,
      width: '40%'
    });
    this.usersTableColumns.push({
      fieldCaption: 'Type',
      fieldName: 'UserType',
      fieldType: FieldTypeDefinition.String
    });
    this.usersTableColumns.push({
      fieldCaption: 'Last Login',
      fieldName: 'UserLastLogin',
      fieldType: FieldTypeDefinition.DateTime,
    });
  }

  private generateUsersTableData() {
    console.log(this.usersList);
    this.usersTableData = this.usersList.map(item => {
      const icon = `fas fa-2x ${(item.IsEnabled) ? 'fa-check-circle icon-green' : 'fa-times-circle icon-red'}`;
      return {
        'PK_Id': item.PK_Id,
        'Status': true,
        'StatusIcon': icon,
        'StatusDescription': item.IsEnabled ? 'Enabled' : 'Disabled',
        'FK_Contact': item.ContactModel,
        'UserName': (item.ContactModel) ? `${item.ContactModel.FirstName} ${item.ContactModel.LastName}` : '',
        'EmailAddress': item.Email,
        'UserType': '',
        'UserRoles': [],
        'UserLastLogin': moment().toDate(),
      };
    });
    this.usersTableData.forEach(item => {
      this.adminService.getLoginRolesLookUpList({ fK_Login: item['PK_Id'] })
        .subscribe(responseLoginRoles => {
          if (responseLoginRoles.Data.length > 0) {
            item['UserRoles'] = responseLoginRoles.Data;
            item['UserType'] = responseLoginRoles.Data.map(lr => lr.FK_Role_Display).join(", ");
          }
        });
    });
    this.usersTableLoading = false;
  }

  loadUserData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.usersTableLoading = true;
      const page = (this.usersSearchText !== this.lastSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.usersSearchText && this.usersSearchText.trim().length > 0) ? this.usersSearchText : null;
      const isEnabled = (this.usersActive) ? true : null;
      this.lastSearchText = this.usersSearchText;

      this.adminService.doSearchUserList(searchText, isEnabled, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseLogins => {
          this.usersTableTotalRecords = responseLogins.RecordsCount;
          this.usersList = responseLogins.Data;
          this.generateUsersTableData();
        });
    }
  }

  addUser() {
    this.editUserViewDisplay = true;
  }

  isValidContact(): boolean {
    const model = this.editContactModel;
    return (
      this.userTypeSelected &&
      model.FirstName && model.FirstName.trim().length > 0 &&
      model.LastName && model.LastName.trim().length > 0 &&
      model.Title && model.Title.trim().length > 0 &&
      model.Email && model.Email.trim().length > 0
    );
  }

  saveUser() {
    this.editModel.Email = this.editContactModel.Email;
    this.editModel.FK_Login_CreatedBy = this.gl.LoginId;
    this.editModel.CreatedDate = moment().toDate();
    this.editModel.IsEnabled = false;
    this.editModel.Password = this.editModel.Email;
    this.editModel.PasswordHash = this.editModel.Email;
    this.adminService.insertLogin(this.editModel)
      .subscribe(loginResponse => {
        if (loginResponse.Success) {
          console.log(loginResponse);
          this.editModel.PK_Id = loginResponse.Data as string;
          this.editContactModel.FK_Login = this.editModel.PK_Id;
          this.adminService.insertContact(this.editContactModel)
            .subscribe(contactResponse => {
              if (contactResponse.Success) {
                let loginRoleLookup: LoginRolesLookUpModel = new LoginRolesLookUpModel;
                loginRoleLookup.FK_Login = this.editModel.PK_Id;
                loginRoleLookup.FK_Role = this.userTypeSelected.PK_Id;
                this.adminService.insertLoginRolesLookUp(loginRoleLookup)
                  .subscribe(loginRoleLookupResponse => {
                    if (loginRoleLookupResponse.Success) {
                      const sub = this.recaptchaV3Service.execute('resetPassword')
                        .subscribe((token) => {
                          this.api.sendInviteEmail(this.editContactModel.Email, `${this.editContactModel.FirstName} ${this.editContactModel.LastName}`, `${this.editContactModel.FirstName} ${this.editContactModel.LastName}`, token)
                            .subscribe((sendMail) => {
                              // TODO: Check Invitation Email
                              this.editUserViewDisplay = false;
                              this.loadUserData(this.lastLazyLoadEvent);
                            });
                          sub.unsubscribe();
                        });
                    }
                  });
              }
            });
        }
      });
  }

  cancelUser() {
    this.editUserViewDisplay = false;
  }

  onUsersActiveChange() {
    this.loadUserData(this.lastLazyLoadEvent);
  }

  onUserTableRowClick(row: any) {
    const contactModel = row['FK_Contact'] as ContactModel;
    if (contactModel) {
      this.gl.SelectedUser = `${contactModel.FirstName} ${contactModel.LastName}`;
    } else {
      this.gl.SelectedUser = 'N/A';
    }
    this.gl.GoTo(`adminuserprofile/${row['PK_Id']}`);
  }

  onSearchTextChanged() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchSubscription = source
      .subscribe(() => {
        this.loadUserData(this.lastLazyLoadEvent);
        this.searchSubscription.unsubscribe();
      })
  }
}
