<div class="card">
  <div class="card-header">
    School <span class="right">
  <a title="Cancel" *ngIf="!ModalView" routerLink="/schoollist"><i class="fa fa-undo icon-link"></i></a>
  <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
  <i class="fa fa-save icon-link" (click)="save()"></i>
</span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        								<div class="col-6">
									<div class="form-group">
										<label>County Physical</label><span title="Required" class="required">*</span>
										<select [(ngModel)]="model.FK_County_Physical" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_County_Physical_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Name</label><span title="Required" class="required">*</span>
										<textarea rows="4" [(ngModel)]="model.Name" class="form-control"></textarea>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>State Physical</label><span title="Required" class="required">*</span>
										<select [(ngModel)]="model.FK_State_Physical" (ngModelChange)="getFK_County_Physical_Options(true)"  class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_State_Physical_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Physical Zipcode</label>
										<input [(ngModel)]="model.Physical_Zipcode" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Phone</label>
										<input [(ngModel)]="model.Phone" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>District</label>
										<input [(ngModel)]="model.District" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Mailing Street Address</label>
										<input [(ngModel)]="model.Mailing_StreetAddress1" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Mailing Street Address</label>
										<input [(ngModel)]="model.Mailing_StreetAddress2" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Mailing City</label>
										<input [(ngModel)]="model.Mailing_City" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>State Mailing Address</label>
										<select [(ngModel)]="model.FK_State_MailingAddress" (ngModelChange)="getFK_County_Mailing_Options(true)"  class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_State_MailingAddress_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Mailing Zipcode</label>
										<input [(ngModel)]="model.Mailing_Zipcode" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>County Mailing</label>
										<select [(ngModel)]="model.FK_County_Mailing" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_County_Mailing_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Physical Street Address</label>
										<input [(ngModel)]="model.Physical_StreetAddress1" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Physical Street Address</label>
										<input [(ngModel)]="model.Physical_StreetAddress2" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Physical City</label>
										<input [(ngModel)]="model.Physical_City" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Code</label>
										<input [(ngModel)]="model.DOECode" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Fax Number</label>
										<input [(ngModel)]="model.FaxNumber" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">

										<app-checkbox [(model)]="model.NonProfit" [label]="'Non Profit'"></app-checkbox>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">

										<app-checkbox [(model)]="model.Religious" [label]="'Religious'"></app-checkbox>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Religious Affiliation</label>
										<input [(ngModel)]="model.ReligiousAffiliation" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Gender Served</label>
										<input [(ngModel)]="model.GenderServed" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Email Address</label>
										<input [(ngModel)]="model.EmailAddress" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Website</label>
										<input [(ngModel)]="model.WebsiteURL" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Created Date</label>
										<div>{{gl.FormatDateShort(model.CreatedDate)}}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Bank Name</label>
										<input [(ngModel)]="model.PaymentBankName" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Bank Routing</label>
										<input [(ngModel)]="model.PaymentBankRouting" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Bank Account</label>
										<input [(ngModel)]="model.PaymentBankAccount" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Address</label>
										<input [(ngModel)]="model.PaymentAddress1" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Address</label>
										<input [(ngModel)]="model.PaymentAddress2" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment City</label>
										<input [(ngModel)]="model.PaymentCity" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>State Payment</label>
										<select [(ngModel)]="model.FK_State_Payment" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_State_Payment_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Zipcode</label>
										<input [(ngModel)]="model.PaymentZipcode" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Pay To</label>
										<input [(ngModel)]="model.PaymentPayTo" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>School Type</label>
										<select [(ngModel)]="model.FK_SchoolType" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_SchoolType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>


      </div>
      

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
