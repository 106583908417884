import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable, Subscription, timer } from 'rxjs';
import { SelectItem, ConfirmationService, LazyLoadEvent } from 'primeng/api';
import * as uuid from 'uuid';
import * as moment from 'moment';

import { aaaAPISchoolService } from '../../../services/aaa.API.school.service';
import { BaseComponent } from '../../../BaseComponent';
import { ContactModel } from '../../../models/entities/ContactModel';
import { SchoolModel } from '../../../models/entities/SchoolModel';
import { SchoolFeeModel } from '../../../models/entities/SchoolFeeModel';
import { SchoolFeeListModel } from '../../../models/entities/SchoolFeeListModel';
import { InsertResponse, ManagerResponse, RoleType, ScholarshipTypes } from '../../../models/models';
import { FeeTypeModel } from '../../../models/entities/FeeTypeModel';
import { SchoolCalendarLookUpModel } from '../../../models/entities/SchoolCalendarLookUpModel';
import { ITableColumnDefinition } from '../../../interfaces/table-column-definition-interface';
import { FieldTypeDefinition } from '../../../types/field-type-definition-type';
import { SchoolEligibilitiesResponsModel } from '../../../models/entities/SchoolEligibilitiesResponsModel';
import { SchoolYearModel } from '../../../models/entities/SchoolYearModel';
import { SchoolEligibilitiesStateLookUpModel } from '../../../models/entities/SchoolEligibilitiesStateLookUpModel';
import { LoginRolesLookUpModel } from '../../../models/entities/LoginRolesLookUpModel';
import { LoginModel } from '../../../models/entities/LoginModel';
import { ContactsLookUpModel } from '../../../models/entities/ContactsLookUpModel';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { SchoolResponse } from '../../../models/entities/SchoolModel.response';
import { ContactResponse } from '../../../models/entities/ContactModel.response';
import { ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '../../../constants/constants';
import { UTILS } from '../../../utils/utils';

@Component({
  selector: 'app-school-profile-page',
  templateUrl: './school-profile-page.component.html',
  styleUrls: ['./school-profile-page.component.scss'],
})
export class SchoolProfilePageComponent extends BaseComponent implements OnInit {
  isAdminContact: boolean = false;
  isNewContact: boolean = false;
  isContactEnabled: boolean = false;
  pageNumber: number = 1;
  model: SchoolModel = null;
  editModel: SchoolModel = new SchoolModel();
  contactModel: ContactModel = new ContactModel();
  loginModel: LoginModel = new LoginModel();
  editContactModel: ContactModel = new ContactModel();
  schoolCalendarList: SchoolCalendarLookUpModel[] = [];
  schoolCalendar: SchoolCalendarLookUpModel = null;
  schoolFeeList: SchoolFeeModel[] = [];
  schoolFeeTableData: any = {};
  schoolFeeTableColumns: ITableColumnDefinition[] = [];
  selectedSchoolCalendar: SchoolCalendarLookUpModel = null;
  editSchoolYearModel: SchoolCalendarLookUpModel = new SchoolCalendarLookUpModel();
  editFeeModel: SchoolFeeListModel = new SchoolFeeListModel();
  isNewFee: boolean = false;
  schoolCertificationTableData: any[] = [];
  schoolCertificationTableColumns: ITableColumnDefinition[] = [];
  selectedSchoolYear: SchoolYearModel = null;
  schoolEligibilitiesResponses: any = {};
  contactsList: ContactModel[] = [];
  contactsTableData: any[] = [];
  contactsTableColumns: ITableColumnDefinition[] = [];
  contactsTableLoading: boolean = false;
  contactsTableTotalRecords: number = null;
  lastLazyLoadEvent: LazyLoadEvent = null;
  contactsSearchText: string = null;
  contactsActive: boolean = true;
  searchSubscription: Subscription = null;
  lastSearchText: string = null;

  editViewDisplay: boolean = false;
  editBankAccountViewDisplay: boolean = false;
  editContactViewDisplay: boolean = false;
  editSchoolYearViewDisplay: boolean = false;
  editFeeViewDisplay: boolean = false;
  editAAACertificationViewDisplay: boolean = false;
  activeAccounts: boolean = true;

  stateOptions: SelectItem[] = [];
  gradeOptions: SelectItem[] = [];
  schoolYearOptions: SelectItem[] = [];
  feeTypesList: FeeTypeModel[] = [];
  schoolYearList: SchoolYearModel[] = [];
  schoolEligibilitiesList: SchoolEligibilitiesStateLookUpModel[] = [];
  schoolCertificationList: SchoolEligibilitiesResponsModel[] = [];
  isSamePhysicalAddress: boolean = false;
  isSchoolWithGardinerStudents: boolean = false;
  yearCalendarRange: string = '';
  protected schoolId: number = null;

  constructor(
    private schoolService: aaaAPISchoolService,
    private confirmationService: ConfirmationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute, false);
  }

  protected isSchoolAdmin(): boolean {
    return this.gl.LoginRoles.includes(RoleType.SchoolAdmin) || this.gl.LoginRoles.includes(RoleType.Admin);
  }

  protected isSchoolUser(): boolean {
    return this.gl.LoginRoles.includes(RoleType.SchoolUser);
  }

  protected hasSchoolId(): boolean {
    return this.schoolId && this.schoolId > 0;
  }

  protected getContactData(loginId?: string): Observable<ContactResponse> {
    if (this.hasSchoolId()) {
      return this.schoolService.getContactBySchoolId(this.schoolId);
    }
    return this.schoolService.getContactByLogin(loginId);
  }

  protected getSchoolData(contactId?: number): Observable<SchoolResponse> {
    if (this.hasSchoolId()) {
      return this.schoolService.getSchool(this.schoolId);
    }
    return this.schoolService.getSchoolByContactId(contactId);
  }

  ngOnInit(): void {
    this.getContactData(this.gl.LoginId)
      .subscribe((res) => {
        if (res.Success) {
          this.contactModel = res.Data;
          this.getSchoolData(this.contactModel ? this.contactModel.PK_Id : null)
            .subscribe((resSchool) => {
              this.model = resSchool.Data;
              this.loadContactsData(CONSTANTS.FirstLazyLoadEvent);
              this.loadSchoolCalendarList();
              this.schoolService
                .getSchoolYearList({ _sort: 'PK_Id', _sortDesc: true })
                .subscribe((resSchoolYear) => {
                  this.schoolYearList = resSchoolYear.Data;
                  this.loadSchoolYearCertificationList();
                });
              this.schoolService
                .getSchoolEligibilitiesStateLookUpList({
                  fK_State: this.model.FK_State_Physical,
                  _sort: 'PK_Id',
                })
                .subscribe((resSchoolEligibilitiesState) => {
                  this.schoolService
                    .getSchoolEligibilityList({})
                    .subscribe((resSchoolEligibilities) => {
                      const eligibilitiesForSchool = resSchoolEligibilities.Data.filter(
                        (item) =>
                          item.ForSchool &&
                          item.FK_SchoolEligibility_Parent == null
                      ).map((item) => item.PK_Id);
                      this.schoolEligibilitiesList = resSchoolEligibilitiesState.Data.filter(
                        (item) =>
                          eligibilitiesForSchool.includes(
                            item.FK_SchoolEligibility
                          )
                      );
                    });
                });
              this.generateContactsTableData();
              this.schoolService
                .getStudentsWithScholarshipTypeIdOnSchoolId(
                  ScholarshipTypes.Disability,
                  this.model.PK_Id
                )
                .subscribe((resStudents) => {
                  this.isSchoolWithGardinerStudents = resStudents.Data.length > 0 && false;
                  console.log(resStudents);
                });
            });
        }
      });
    this.schoolService.getStateList({ active: true }).subscribe((res) => {
      this.stateOptions = res.Data.map((item) => {
        return {
          label: item.ListDisplay,
          value: item.PK_Id,
        } as SelectItem;
      });
    });
    this.schoolService
      .getGradeLevelList({ _sort: 'PK_Id' })
      .subscribe((res) => {
        this.gradeOptions = res.Data.map((item) => {
          return {
            label: item.ListDisplay,
            value: item.PK_Id,
          } as SelectItem;
        });
      });
    this.schoolService.getFeeTypeList({ _sort: 'PK_Id' }).subscribe((res) => {
      this.feeTypesList = res.Data;
    });
    const currentYear = moment().year();
    this.yearCalendarRange = `${currentYear - 10}:${currentYear + 10}`;
  }

  doChangePage(pageNumber: number) {
    if (pageNumber != this.pageNumber) {
      this.pageNumber = pageNumber;
    }
  }

  /*
    School Methods
  */

  get isMailingAddressSamePhysicalAddressView() {
    return this.isMailingAddressSamePhysicalAddress(this.model);
  }

  get isMailingAddressSamePhysicalAddressEdit() {
    return this.isMailingAddressSamePhysicalAddress(this.editModel);
  }

  get hasBankAccount() {
    return (this.model?.PaymentBankAccount && this.model?.PaymentBankAccount.trim().length > 0);
  }

  get getFilteredBankAccount() {
    let s = this.model.PaymentBankAccount.trim();
    let filteredText = s.substring(s.length - 4, s.length);
    filteredText = filteredText.padStart(s.length, "*");
    return filteredText;
  }

  private isMailingAddressSamePhysicalAddress(model: SchoolModel): boolean {
    if (model) {
      return (
        model.Mailing_StreetAddress1 == model.Physical_StreetAddress1 &&
        model.Mailing_StreetAddress2 == model.Physical_StreetAddress2 &&
        model.Mailing_City == model.Physical_City &&
        model.FK_State_MailingAddress == model.FK_State_Physical &&
        model.Mailing_Zipcode == model.Physical_Zipcode
      );
    }
    return false;
  }

  doSchoolEdit() {
    this.editModel = { ...this.model };
    this.isSamePhysicalAddress = this.isMailingAddressSamePhysicalAddressEdit;
    this.onSamePhysicalAddressChange();
    this.editViewDisplay = true;
  }

  saveProfile() {
    this.schoolService.updateSchool(this.editModel).subscribe((res) => {
      if (res.Success) {
        this.model = { ...this.editModel };
        this.editViewDisplay = false;
      }
    });
  }

  cancelProfile() {
    this.editViewDisplay = false;
  }

  private copyPhysicalAddressToMailingAddress() {
    this.editModel.Mailing_StreetAddress1 = this.editModel.Physical_StreetAddress1;
    this.editModel.Mailing_StreetAddress2 = this.editModel.Physical_StreetAddress2;
    this.editModel.Mailing_City = this.editModel.Physical_City;
    this.editModel.FK_State_MailingAddress = this.editModel.FK_State_Physical;
    this.editModel.FK_State_MailingAddress_Display = this.editModel.FK_State_Physical_Display;
    this.editModel.Mailing_Zipcode = this.editModel.Physical_Zipcode;
  }

  onSamePhysicalAddressChange() {
    if (this.isSamePhysicalAddress) {
      this.copyPhysicalAddressToMailingAddress();
    } else {
      this.editModel.Mailing_StreetAddress1 = this.model.Mailing_StreetAddress1;
      this.editModel.Mailing_StreetAddress2 = this.model.Mailing_StreetAddress2;
      this.editModel.Mailing_City = this.model.Mailing_City;
      this.editModel.FK_State_MailingAddress = this.model.FK_State_MailingAddress;
      this.editModel.FK_State_MailingAddress_Display = this.model.FK_State_MailingAddress_Display;
      this.editModel.Mailing_Zipcode = this.model.Mailing_Zipcode;
    }
  }

  onPhysicalAddressChange() {
    if (this.isSamePhysicalAddress) {
      this.editModel.Mailing_StreetAddress1 = this.editModel.Physical_StreetAddress1;
      this.editModel.Mailing_StreetAddress2 = this.editModel.Physical_StreetAddress2;
      this.editModel.Mailing_City = this.editModel.Physical_City;
      this.editModel.FK_State_MailingAddress = this.editModel.FK_State_Physical;
      this.editModel.FK_State_MailingAddress_Display = this.editModel.FK_State_Physical_Display;
      this.editModel.Mailing_Zipcode = this.editModel.Physical_Zipcode;
    }
  }

  isValidProfile(): boolean {
    const model = this.editModel;
    return (
      model.Name &&
      model.Name.trim().length > 0 &&
      model.Phone &&
      model.Phone.trim().length > 0 &&
      model.DOECode &&
      model.DOECode.trim().length > 0 &&
      model.WebsiteURL &&
      model.WebsiteURL.trim().length > 0 &&
      model.Physical_StreetAddress1 &&
      model.Physical_StreetAddress1.trim().length > 0 &&
      model.Physical_City &&
      model.Physical_City.trim().length > 0 &&
      model.FK_State_Physical &&
      model.FK_State_Physical > 0 &&
      model.Physical_Zipcode &&
      model.Physical_Zipcode.trim().length > 0 &&
      model.Mailing_StreetAddress1 &&
      model.Mailing_StreetAddress1.trim().length > 0 &&
      model.Mailing_City &&
      model.Mailing_City.trim().length > 0 &&
      model.FK_State_MailingAddress &&
      model.FK_State_MailingAddress > 0 &&
      model.Mailing_Zipcode &&
      model.Mailing_Zipcode.trim().length > 0
    );
  }

  doAddBankAccount() {
    this.editModel = { ...this.model };
    this.editBankAccountViewDisplay = true;
  }

  saveBankAccount() {
    this.schoolService.updateSchool(this.editModel).subscribe((res) => {
      if (res.Success) {
        this.model = { ...this.editModel };
        this.editBankAccountViewDisplay = false;
      }
    });
  }

  cancelBankAccount() {
    this.editBankAccountViewDisplay = false;
  }

  isValidBankAccount(): boolean {
    const model = this.editModel;
    return (
      model.PaymentBankName &&
      model.PaymentBankName.trim().length > 0 &&
      model.PaymentBankRouting &&
      model.PaymentBankRouting.trim().length > 0 &&
      model.PaymentBankAccount &&
      model.PaymentBankAccount.trim().length > 0
    );
  }

  /*
    Contact Methods
  */

  private generateContactsTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Status',
      fieldName: 'StatusIcon',
      fieldType: FieldTypeDefinition.FontAwesomeIcon,
      showTooltip: true,
      tooltipFieldName: 'StatusDescription'
    });
    columns.push({
      fieldCaption: 'Name',
      fieldName: 'ContactName',
      fieldType: FieldTypeDefinition.String,
      sortable: true
    });
    columns.push({
      fieldCaption: 'Title',
      fieldName: 'Title',
      fieldType: FieldTypeDefinition.String
    });
    columns.push({
      fieldCaption: 'Email',
      fieldName: 'EmailAddress',
      fieldType: FieldTypeDefinition.String
    });
    columns.push({
      fieldCaption: 'Account Type',
      fieldName: 'UserType',
      fieldType: FieldTypeDefinition.String
    });
    this.contactsTableColumns = [...columns];
  }

  private generateContactsTableData() {
    this.generateContactsTableColumns();
    this.contactsTableData = [];

    this.contactsTableData = this.contactsList.map(item => {
      const icon = `fas fa-2x ${(item.IsEnabled) ? 'fa-check-circle icon-green' : 'fa-times-circle icon-red'}`;
      return {
        'Model': item,
        'PK_Id': item.PK_Id,
        'Status': true,
        'StatusIcon': icon,
        'StatusDescription': item.IsEnabled ? 'Enabled' : 'Disabled',
        'FK_Login': item.FK_Login,
        'ContactName': `${item.FirstName} ${item.LastName}`,
        'Title': item.Title,
        'EmailAddress': item.Email,
        'UserType': '',
        'UserRoles': [],
        'UserLastLogin': moment().toDate(),
      };
    });
    this.contactsTableData.forEach(item => {
      this.schoolService.getLoginRolesLookUpList({ fK_Login: item['FK_Login'] })
        .subscribe(responseLoginRoles => {
          if (responseLoginRoles.Data.length > 0) {
            item['UserRoles'] = responseLoginRoles.Data;
            item['UserType'] = responseLoginRoles.Data.map(lr => lr.FK_Role_Display).join(", ");
          }
        });
      this.schoolService.getLogin(item['FK_Login'])
        .subscribe(responseLogin => {
          if (responseLogin.Success) {
            item['LoginModel'] = responseLogin.Data;
          }
        })
    });
    this.contactsTableLoading = false;
  }

  loadContactsData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.contactsTableLoading = true;
      const page = (this.contactsSearchText !== this.lastSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.contactsSearchText && this.contactsSearchText.trim().length > 0) ? this.contactsSearchText : null;
      const isEnabled = (this.contactsActive) ? true : null;
      this.lastSearchText = this.contactsSearchText;

      this.schoolService.doSearchContactList(this.model.PK_Id, searchText, isEnabled, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseLogins => {
          this.contactsTableTotalRecords = responseLogins.RecordsCount;
          this.contactsList = responseLogins.Data;
          this.generateContactsTableData();
        });
    }
  }

  doAAAContactEdit() {
    this.isAdminContact = true;
    this.isNewContact = false;
    this.editContactModel = { ...this.contactModel };
    this.editContactViewDisplay = true;
  }

  addNewContact() {
    this.isAdminContact = true;
    this.isNewContact = true;
    this.editContactModel = new ContactModel();
    this.editContactViewDisplay = true;
  }

  saveAndInviteContact() {
    this.editContactModel = { ...this.editContactModel };
    let loginModel = new LoginModel();
    loginModel.Email = this.editContactModel.Email;
    loginModel.Password = this.editContactModel.Email;
    loginModel.Password2 = this.editContactModel.Email;
    loginModel.IsEnabled = this.editContactModel.IsEnabled;
    this.api.insertUserLogin(loginModel)
      .subscribe(responseLogin => {
        if (responseLogin.Success) {
          this.editContactModel.FK_Login = responseLogin.Data;
          this.schoolService.insertContact(this.editContactModel)
            .subscribe(responseContact => {
              if (responseContact.Success) {
                let loginRoles = new LoginRolesLookUpModel();
                loginRoles.FK_Login = this.editContactModel.FK_Login;
                loginRoles.FK_Role = RoleType.SchoolUser;
                this.schoolService.insertLoginRolesLookUp(loginRoles)
                  .subscribe(responseLoginRoles => {
                    if (responseLoginRoles.Success) {
                      let schoolContact = new ContactsLookUpModel();
                      schoolContact.FK_Contact = responseContact.Data;
                      schoolContact.FK_School = this.model.PK_Id;
                      schoolContact.FK_Vendor = null;
                      this.schoolService.insertContactsLookUp(schoolContact)
                        .subscribe(responseContactsLookUps => {
                          if (responseContactsLookUps.Success) {
                            const sub = this.recaptchaV3Service.execute('resetPassword')
                              .subscribe((token) => {
                                this.api.sendInviteEmail(this.editContactModel.Email, `${this.editContactModel.FirstName} ${this.editContactModel.LastName}`, `${this.contactModel.FirstName} ${this.contactModel.LastName}`, token)
                                  .subscribe((sendMail) => {
                                    // TODO: Check Invitation Email
                                    this.loadContactsData(this.lastLazyLoadEvent);
                                    this.editContactViewDisplay = false;
                                  });
                                sub.unsubscribe();
                              });
                          }
                        });
                    }
                  });
              }
            });
        }
      });
  }

  doContactRowClicked(row: any) {
    if (!this.isSchoolAdmin()) {
      return;
    }
    const roles = row['UserRoles'] as LoginRolesLookUpModel[];
    if (roles.findIndex(item => item.FK_Role == RoleType.Admin || item.FK_Role == RoleType.SchoolAdmin)) {
      this.isAdminContact = true;
    } else {
      this.isAdminContact = false;
    }
    this.isNewContact = false;
    this.editContactModel = { ...row["Model"] as ContactModel };
    this.loginModel = { ...row["LoginModel"] as LoginModel };
    this.isContactEnabled = this.editContactModel.IsEnabled;
    this.editContactViewDisplay = true;
  }

  saveContact() {
    this.schoolService.updateContact(this.editContactModel)
      .subscribe(responseContact => {
        if (responseContact.Success) {
          this.loginModel.IsEnabled = this.editContactModel.IsEnabled;
          this.schoolService.updateLogin(this.loginModel)
            .subscribe(responseLogin => {
              if (responseLogin) {
                this.contactModel = { ...this.editContactModel };
                this.editContactViewDisplay = false;
                this.loadContactsData(this.lastLazyLoadEvent);
              }
            });
        }
      });
  }

  cancelContact() {
    this.editContactViewDisplay = false;
  }

  isValidContact() {
    const model = this.editContactModel;
    return (
      model.FirstName && model.FirstName.trim().length > 0 &&
      model.LastName && model.LastName.trim().length > 0 &&
      model.Title && model.Title.trim().length > 0 &&
      model.Phone && model.Phone.trim().length > 0 &&
      model.Email && model.Email.trim().length > 0
    );
  }

  onContactsActiveChange() {
    this.loadContactsData(this.lastLazyLoadEvent);
  }

  onSearchTextChanged() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchSubscription = source
      .subscribe(() => {
        this.loadContactsData(this.lastLazyLoadEvent);
        this.searchSubscription.unsubscribe();
      });
  }

  /*
    Fee Methods
  */

  private loadSchoolCalendarList() {
    this.schoolService
      .getSchoolCalendarLookUpList({
        fK_School: this.model.PK_Id,
        _sort: 'FK_SchoolYear',
        _sortDesc: true,
      })
      .subscribe((resCalendar) => {
        this.schoolCalendarList = resCalendar.Data;
        this.schoolYearOptions = resCalendar.Data.map((item) => {
          return {
            label: UTILS.getSchoolYearDisplay(item.SchoolYearStart, item.SchoolYearEnd),
            value: item,
          } as SelectItem;
        });
        if (!this.schoolCalendar) {
          this.schoolCalendar =
            this.schoolYearOptions.length > 0
              ? this.schoolYearOptions.find(item => item.label.includes('Current Year')).value
              : null;
        }
        this.loadSchoolFeeList();
      });
  }

  private loadSchoolFeeList() {
    this.schoolService
      .getSchoolFeeList({ fK_School: this.model.PK_Id, _sort: 'FK_GradeLevel', _pageSize: 10000 })
      .subscribe((resFee) => {
        this.schoolFeeList = resFee.Data;
        this.generateSchoolFeeTableData();
      });
  }

  private generateSchoolFeeTableData() {
    this.generateSchoolFeeTableColumns();
    this.schoolFeeTableData = {};
    this.schoolCalendarList.forEach((schoolCalendar) => {
      let gradeData = [];
      const feeListData = this.schoolFeeList.filter(
        (item) => item.FK_SchoolYear == schoolCalendar.FK_SchoolYear
      );
      const grades = feeListData
        .map((item) => `${item.FK_GradeLevel}-${item.FK_GradeLevel_To}`)
        .filter((elem, index, self) => index === self.indexOf(elem));
      grades.forEach((gradeItem) => {
        const gradeRange = gradeItem.split('-');
        const gradeLevel = parseInt(gradeRange[0]);
        const gradeLevelTo = parseInt(gradeRange[1]);
        const fees = feeListData.filter(
          (item) =>
            item.FK_GradeLevel == gradeLevel &&
            item.FK_GradeLevel_To == gradeLevelTo
        );
        let rowData = {};
        let editFeeModel = new SchoolFeeListModel();
        editFeeModel.Id = uuid.v4();
        editFeeModel.FK_GradeLevel = gradeLevel;
        editFeeModel.FK_GradeLevel_Display = fees[0].FK_GradeLevel_Display;
        editFeeModel.FK_GradeLevel_To = gradeLevelTo;
        editFeeModel.FK_GradeLevel_To_Display = fees[0].FK_GradeLevel_To_Display;
        editFeeModel.List = [];
        rowData['Grade'] =
          editFeeModel.FK_GradeLevel_Display ==
            editFeeModel.FK_GradeLevel_To_Display
            ? editFeeModel.FK_GradeLevel_Display
            : `${editFeeModel.FK_GradeLevel_Display}-${editFeeModel.FK_GradeLevel_To_Display}`;
        fees.forEach((feeItem) => {
          rowData[feeItem.FK_FeeType_Display] = feeItem.Amount;
          editFeeModel.List.push(feeItem);
        });
        rowData['Total'] = editFeeModel.Total;
        rowData['RawData'] = Object.assign(
          Object.create(editFeeModel),
          editFeeModel
        );
        gradeData.push(rowData);
      });
      this.schoolFeeTableData[schoolCalendar.PK_Id] = gradeData;
    });
  }

  private generateSchoolFeeTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Grade',
      fieldName: 'Grade',
      fieldType: FieldTypeDefinition.Integer,
    });
    this.feeTypesList.forEach((feeType) => {
      columns.push({
        fieldCaption: feeType.Name,
        fieldName: feeType.Name,
        fieldType: FieldTypeDefinition.Money,
      });
    });
    columns.push({
      fieldCaption: 'Total',
      fieldName: 'Total',
      fieldType: FieldTypeDefinition.Money,
    });
    this.schoolFeeTableColumns = [...columns];
  }

  doSchoolYearEdit() {
    let schoolYearItem = this.schoolCalendarList.find(
      (item) => item.PK_Id == this.schoolCalendar.PK_Id
    );
    this.editSchoolYearModel = new SchoolCalendarLookUpModel();
    this.editSchoolYearModel.PK_Id = schoolYearItem.PK_Id;
    this.editSchoolYearModel.FK_School = schoolYearItem.FK_School;
    this.editSchoolYearModel.FK_School_Display =
      schoolYearItem.FK_School_Display;
    this.editSchoolYearModel.FK_SchoolYear = schoolYearItem.FK_SchoolYear;
    this.editSchoolYearModel.FK_SchoolYear_Display =
      schoolYearItem.FK_SchoolYear_Display;
    this.editSchoolYearModel.SchoolYearStart = moment(
      schoolYearItem.SchoolYearStart
    ).toDate();
    this.editSchoolYearModel.SchoolYearEnd = moment(
      schoolYearItem.SchoolYearEnd
    ).toDate();
    this.editSchoolYearViewDisplay = true;
  }

  isValidSchoolYear(): boolean {
    if (
      this.editSchoolYearModel.SchoolYearStart >=
      this.editSchoolYearModel.SchoolYearEnd
    ) {
      return false;
    }
    const yearsRange = this.editSchoolYearModel.FK_SchoolYear_Display.split('-');
    const minSchoolYearStart = moment(`${yearsRange[0]}-01-01`, 'YYYY-MM-DD').toDate();
    const maxSchoolYeartEnd = moment(`${yearsRange[1]}-12-31`, 'YYYY-MM-DD').toDate();
    if (
      !(
        this.editSchoolYearModel.SchoolYearStart >= minSchoolYearStart &&
        this.editSchoolYearModel.SchoolYearStart <= maxSchoolYeartEnd &&
        this.editSchoolYearModel.SchoolYearEnd >= minSchoolYearStart &&
        this.editSchoolYearModel.SchoolYearEnd <= maxSchoolYeartEnd
      )
    ) {
      return false;
    }
    return true;
  }

  saveSchoolYear() {
    this.schoolService
      .updateSchoolCalendarLookUp(this.editSchoolYearModel)
      .subscribe((response) => {
        if (response.Success) {
          this.loadSchoolCalendarList();
          this.editSchoolYearViewDisplay = false;
        }
      });
  }

  cancelSchoolYear() {
    this.editSchoolYearViewDisplay = false;
  }

  addNewFee() {
    this.isNewFee = true;
    this.selectedSchoolCalendar = this.schoolCalendar;
    this.editFeeModel = new SchoolFeeListModel();
    this.editFeeModel.Id = uuid.v4();
    this.editFeeModel.FK_GradeLevel = this.editFeeModel.FK_GradeLevel_To = this.gradeOptions[0].value;
    this.editFeeModel.List = this.feeTypesList.map((feeType) => {
      let schoolFee = new SchoolFeeModel();
      schoolFee.FK_School = this.selectedSchoolCalendar.FK_School;
      schoolFee.FK_SchoolYear = this.selectedSchoolCalendar.FK_SchoolYear;
      schoolFee.FK_FeeType = feeType.PK_Id;
      schoolFee.FK_FeeType_Display = feeType.Name;
      schoolFee.FK_GradeLevel = this.editFeeModel.FK_GradeLevel;
      schoolFee.FK_GradeLevel_To = this.editFeeModel.FK_GradeLevel_To;
      schoolFee.CreatedDate = new Date();
      schoolFee.IsDeleted = null;
      schoolFee.FK_Login_CreatedBy = this.gl.LoginId;
      return schoolFee;
    });
    this.editFeeModel.FK_GradeLevel = this.editFeeModel.FK_GradeLevel_To = this.gradeOptions[0].value;
    this.editFeeViewDisplay = true;
  }

  doFeeEdit(schoolFeeRow: SchoolFeeListModel) {
    this.isNewFee = false;
    this.selectedSchoolCalendar = this.schoolCalendar;
    this.editFeeModel = new SchoolFeeListModel();
    this.editFeeModel.Id = schoolFeeRow.Id;
    this.editFeeModel.List = schoolFeeRow.List;
    this.editFeeModel.FK_GradeLevel = schoolFeeRow.FK_GradeLevel;
    this.editFeeModel.FK_GradeLevel_Display =
      schoolFeeRow.FK_GradeLevel_Display;
    this.editFeeModel.FK_GradeLevel_To = schoolFeeRow.FK_GradeLevel_To;
    this.editFeeModel.FK_GradeLevel_To_Display =
      schoolFeeRow.FK_GradeLevel_To_Display;
    this.editFeeViewDisplay = true;
  }

  onFeeGradeChange(event) {
    this.editFeeModel.FK_GradeLevel_To = this.editFeeModel.FK_GradeLevel;
    this.editFeeModel.FK_GradeLevel_Display = this.gradeOptions.find(
      (item) => item.value == event
    ).label;
  }

  onFeeGradeToChange(event) {
    this.editFeeModel.FK_GradeLevel_To_Display = this.gradeOptions.find(
      (item) => item.value == event
    ).label;
  }

  saveFee() {
    let subscriptions = [];
    if (this.isNewFee) {
      subscriptions = this.editFeeModel.List.map((feeModel) =>
        this.schoolService.insertSchoolFee(feeModel)
      );
    } else {
      subscriptions = this.editFeeModel.List.map((feeModel) =>
        this.schoolService.updateSchoolFee(feeModel)
      );
    }
    forkJoin(subscriptions).subscribe((res) => {
      const response = this.isNewFee
        ? (res as InsertResponse[])
        : (res as ManagerResponse[]);
      if (response.length == this.editFeeModel.List.length) {
        if (this.isNewFee) {
          this.loadSchoolFeeList();
        } else {
          this.updateRowIntoTable(this.editFeeModel);
        }
        this.editFeeViewDisplay = false;
      }
    });
  }

  cancelFee() {
    this.editFeeViewDisplay = false;
  }

  deleteFee() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'fas fa-exclamation-triangle',
      message: 'Confirm Delete?',
      key: 'confirmDialog',
      accept: () => {
        const schoolFeeToDelete = this.editFeeModel;
        const subscriptions = schoolFeeToDelete.List.map((feeModel) =>
          this.schoolService.deleteSchoolFee(feeModel.PK_Id)
        );
        forkJoin(subscriptions).subscribe((response) => {
          if (response.length == schoolFeeToDelete.List.length) {
            this.loadSchoolFeeList();
            this.editFeeViewDisplay = false;
          }
        });
      },
    });
  }

  doFeeTableRowClicked(event: any) {
    if (!this.isSchoolAdmin()) {
      return;
    }
    this.doFeeEdit(event.RawData);
  }

  private getRowTable(row: SchoolFeeListModel): any {
    let rowData = {};
    rowData['Grade'] =
      row.FK_GradeLevel_Display == row.FK_GradeLevel_To_Display
        ? row.FK_GradeLevel_Display
        : `${row.FK_GradeLevel_Display}-${row.FK_GradeLevel_To_Display}`;
    row.List.forEach((feeItem) => {
      rowData[feeItem.FK_FeeType_Display] = feeItem.Amount;
    });
    rowData['Total'] = row.Total;
    rowData['RawData'] = Object.assign(Object.create(row), row);
    return rowData;
  }

  doDeleteButtonFeeTable(event: any) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'fas fa-exclamation-triangle',
      message: 'Confirm Delete?',
      key: 'confirmDialog',
      accept: () => {
        const schoolFeeToDelete = event.RawData as SchoolFeeListModel;
        const subscriptions = schoolFeeToDelete.List.map((feeModel) =>
          this.schoolService.deleteSchoolFee(feeModel.PK_Id)
        );
        forkJoin(subscriptions).subscribe((response) => {
          if (response.length == schoolFeeToDelete.List.length) {
            this.loadSchoolFeeList();
            this.editFeeViewDisplay = false;
          }
        });
      },
    });
  }

  updateRowIntoTable(row: SchoolFeeListModel) {
    const rowData = this.getRowTable(row);
    let currentRow = this.schoolFeeTableData[this.schoolCalendar.PK_Id].find(
      (item) => item.RawData.Id == row.Id
    );
    currentRow['Grade'] = rowData['Grade'];
    row.List.forEach((feeItem) => {
      currentRow[feeItem.FK_FeeType_Display] =
        rowData[feeItem.FK_FeeType_Display];
    });
    currentRow['Total'] = rowData['Total'];
    currentRow['RawData'] = rowData['RawData'];
  }

  isValidGrade(): boolean {
    if (this.schoolFeeTableData.length == 0) {
      return true;
    }
    if (this.editFeeModel.FK_GradeLevel > this.editFeeModel.FK_GradeLevel_To) {
      return false;
    }
    if (
      this.schoolCalendar &&
      this.schoolFeeTableData &&
      this.schoolFeeTableData[this.schoolCalendar.PK_Id]
    ) {
      let existingRow = this.schoolFeeTableData[this.schoolCalendar.PK_Id].find(
        (item) => {
          return (
            (this.editFeeModel.FK_GradeLevel >= item.RawData.FK_GradeLevel &&
              this.editFeeModel.FK_GradeLevel <= item.RawData.FK_GradeLevel_To) ||
            (this.editFeeModel.FK_GradeLevel_To >= item.RawData.FK_GradeLevel &&
              this.editFeeModel.FK_GradeLevel_To <= item.RawData.FK_GradeLevel_To)
          );
        }
      );
      return (
        (existingRow &&
          !this.isNewFee &&
          existingRow.RawData.Id == this.editFeeModel.Id) ||
        !existingRow
      );
    }
    return false;
  }

  /*
    AAA Certification Methods
  */

  get showCompleteCertification(): boolean {
    if (this.schoolCertificationTableData.length > 0) {
      const index = this.schoolCertificationTableData.findIndex(item => !item.Status);
      return (index != -1);
    }
    return false;
  }

  private loadSchoolYearCertificationList() {
    this.schoolService
      .getSchoolEligibilitiesResponsList({ fK_School: this.model.PK_Id })
      .subscribe((resCertification) => {
        this.schoolCertificationList = resCertification.Data;
        this.generateSchoolYearCertificationTableData();
      });
  }

  private generateSchoolYearCertificationTableData() {
    this.generateSchoolYearCertificationTableColumns();
    this.schoolCertificationTableData = [];
    this.schoolYearList.forEach((schoolYear) => {
      let rowData = {};
      const status = this.schoolCertificationList.findIndex((item) => item.FK_SchoolYear == schoolYear.PK_Id) !== -1;
      // filling row data
      rowData['PK_Id'] = schoolYear.PK_Id;
      rowData['FK_SchoolYear_Display'] = schoolYear.Name;
      rowData['Status'] = status;
      rowData['Icon'] = `fas fa-2x ${(status) ? 'fa-check-circle icon-green' : 'fa-exclamation-triangle icon-red'}`;
      this.schoolCertificationTableData.push(rowData);
    });
  }

  private generateSchoolYearCertificationTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Status',
      fieldName: 'Icon',
      fieldType: FieldTypeDefinition.FontAwesomeIcon,
      width: '100px'
    });
    columns.push({
      fieldCaption: 'School Year',
      fieldName: 'FK_SchoolYear_Display',
      fieldType: FieldTypeDefinition.String,
    });
    this.schoolCertificationTableColumns = [...columns];
  }

  doCertification(rowData: any) {
    this.selectedSchoolYear = new SchoolYearModel();
    this.selectedSchoolYear.PK_Id = rowData.PK_Id;
    this.selectedSchoolYear.Name = rowData.FK_SchoolYear_Display;
    this.schoolEligibilitiesResponses = {};
    this.editAAACertificationViewDisplay = true;
  }

  saveCertification() {
    let schoolEligibilitiesResponses: SchoolEligibilitiesResponsModel[] = this.schoolEligibilitiesList.map(
      (schoolEligibilityModel) => {
        return {
          FK_Guardian: null,
          FK_School: this.model.PK_Id,
          FK_SchoolYear: this.selectedSchoolYear.PK_Id,
          FK_SchoolEligibility: schoolEligibilityModel.FK_SchoolEligibility,
          ValueBoolean: this.schoolEligibilitiesResponses[
            schoolEligibilityModel.FK_SchoolEligibility
          ],
          FK_Login_AcceptedBy: null,
        } as SchoolEligibilitiesResponsModel;
      }
    );
    let subscriptions = schoolEligibilitiesResponses.map(
      (schoolEligibilityModel) =>
        this.schoolService.insertSchoolEligibilitiesRespons(
          schoolEligibilityModel
        )
    );
    forkJoin(subscriptions).subscribe((response) => {
      if (response.length == schoolEligibilitiesResponses.length) {
        this.confirmationService.confirm({
          header: 'Information',
          icon: 'fas fa-info-circle',
          key: 'messageDialog',
          acceptLabel: 'Ok',
          rejectVisible: false,
          message:
            'You will receive an email notification once your information has been reviewed by AAA Scholarships. You will be able to log in at that time to review the decision regarding your eligibility.',
          accept: () => {
            this.loadSchoolYearCertificationList();
            this.editAAACertificationViewDisplay = false;
          },
        });
      }
    });
  }

  cancelCertification() {
    this.editAAACertificationViewDisplay = false;
  }

  doCompleteCertification() {
    const arr = this.schoolCertificationTableData.slice().reverse();
    const row = arr.find(item => !item.Status);
    this.doCertification(row);
  }

  isValidCertification(): boolean {
    let result = true;
    this.schoolEligibilitiesList.forEach((item) => {
      result =
        result && this.schoolEligibilitiesResponses[item.PK_Id] !== undefined;
    });
    return result;
  }
}
