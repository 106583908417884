<div class="p-2">
    <div class="row mb-5">
        <div class="col-12">
            <h2>All Students</h2>
        </div>
    </div>
    <div class="row mb-3 d-flex align-items-end">
        <div class="col-3 d-flex flex-column">
            <span class="form-label font-weight-bold">Search Students / Guardians</span>
            <input type="text" class="form-control" [(ngModel)]="searchScholarshipText"
                (ngModelChange)="searchScholarship($event)" />
        </div>
        <div class="col-2 d-flex flex-column">
            <span class="form-label font-weight-bold">State</span>
            <p-dropdown [options]="statesOptions" class="form-control no-border" [(ngModel)]="state" optionLabel="name"
                (ngModelChange)="changeState($event)">
            </p-dropdown>
        </div>
        <div class="col-3 d-flex flex-column">
            <span class="form-label font-weight-bold">School Year</span>
            <p-dropdown [options]="schoolYearOptions2" class="form-control no-border" [(ngModel)]="schoolCalendar"
                (ngModelChange)="changeState($event)">
            </p-dropdown>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-custom-table [data]="schoolTableData" [rows]="10" showPaginator="true" [columns]="schoolTableColumns"
                [showSummary]="true" [useLazyLoad]="true" (onLoadData)="loadSchoolData($event)"
                [isLoading]="usersTableLoading" [totalRecords]="usersTableTotalRecords"
                (onRowTableClicked)="openStudent($event)">
            </app-custom-table>
        </div>
    </div>
</div>