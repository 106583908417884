import { Component, OnInit, Directive } from '@angular/core';
import { ZengineFormStateLookUpCheckBoxListByZengineFormBase } from './zengineFormStateLookUpCheckBoxListByZengineForm.base';
@Component({
  selector: 'app-zengineFormStateLookUpCheckBoxListByZengineForm',
  templateUrl: './zengineFormStateLookUpCheckBoxListByZengineForm.component.html',
})
export class ZengineFormStateLookUpCheckBoxListByZengineFormComponent extends ZengineFormStateLookUpCheckBoxListByZengineFormBase implements OnInit {
  constructor() { super(); }
  ngOnInit() { super.baseNgOnInit(); }
  
}
