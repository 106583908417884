import { Component, OnInit } from '@angular/core';
import { SelectItem, ConfirmationService } from 'primeng/api';
import * as moment from 'moment';

import { aaaAPIFamilyService } from '../../../services/aaa.API.family.service';
import { BaseComponent } from '../../../BaseComponent';
import { SchoolModel } from '../../../models/entities/SchoolModel';
import { GuardianModel } from 'src/app/models/entities/GuardianModel';
import { HouseholdModel } from 'src/app/models/entities/HouseholdModel';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-family-profile-page',
  templateUrl: './family-profile-page.component.html',
  styleUrls: ['./family-profile-page.component.scss']
})
export class FamilyProfilePageComponent extends BaseComponent implements OnInit {
  pageNumber: number = 1;
  emailAddress: string = null;
  model: GuardianModel = null;
  houseHoldModel: HouseholdModel = null;
  editModel: GuardianModel = new GuardianModel();
  editHouseHoldModel: HouseholdModel = new HouseholdModel();

  editViewDisplay: boolean = false;
  editBankAccountViewDisplay: boolean = false;

  stateOptions: SelectItem[] = [];
  isSamePhysicalAddress: boolean = false;
  haveGardinerStudents: boolean = true;

  constructor(
    private familyService: aaaAPIFamilyService,
    private confirmationService: ConfirmationService) {
    super(null, false);
  }

  ngOnInit(): void {
    this.familyService.getGuardianByLogin(this.gl.LoginId)
      .subscribe(res => {
        this.model = res.Data;
        this.familyService.getHousehold(this.model.FK_Household)
          .subscribe(resHouseHold => {
            this.houseHoldModel = resHouseHold.Data;
          });
      });
    this.familyService.getLogin(this.gl.LoginId)
      .subscribe(res => {
        this.emailAddress = res.Data.Email;
      })
    this.familyService.getStateList({ active: true })
      .subscribe(res => {
        this.stateOptions = res.Data.map(item => {
          return {
            label: item.ListDisplay,
            value: item.PK_Id
          } as SelectItem;
        });
      });
  }

  doChangePage(pageNumber: number) {
    if (pageNumber != this.pageNumber) {
      this.pageNumber = pageNumber;
    }
  }

  /*
    Family Methods
  */

  doFamilyProfileEdit() {
    this.editModel = { ...this.model };
    this.editHouseHoldModel = { ...this.houseHoldModel }
    this.editViewDisplay = true;
  }

  saveFamilyProfile() {
    forkJoin([
      this.familyService.updateGuardian(this.editModel),
      this.familyService.updateHousehold(this.editHouseHoldModel)
    ])
      .subscribe(res => {
        if (res.length == 2) {
          this.model = { ...this.editModel };
          this.houseHoldModel = { ...this.editHouseHoldModel };
          this.editViewDisplay = false;
        }
      });
  }

  cancelFamilyProfile() {
    this.editViewDisplay = false;
  }

  isValidFamilyProfile(): boolean {
    const model = this.editModel;
    const modelH = this.editHouseHoldModel;
    return (model.FirstName && model.FirstName.trim().length > 0 &&
      model.LastName && model.LastName.trim().length > 0 &&
      model.CellPhone && model.CellPhone.trim().length > 0 &&
      modelH.Address1 && modelH.Address1.trim().length > 0 &&
      modelH.City && modelH.City.trim().length > 0 &&
      modelH.FK_State && modelH.FK_State > 0 &&
      modelH.Zipcode && modelH.Zipcode.trim().length > 0);
  }

  /*
    Bank Account Methods
  */

  get hasBankAccount() {
    return (this.model?.PaymentBankAccount && this.model?.PaymentBankAccount.trim().length > 0);
  }

  get getFilteredBankAccount() {
    let s = this.model.PaymentBankAccount.trim();
    let filteredText = s.substring(s.length - 4, s.length);
    filteredText = filteredText.padStart(s.length, "*");
    return filteredText;
  }

  doAddBankAccount() {
    this.editModel = { ...this.model };
    this.editBankAccountViewDisplay = true;
  }

  saveBankAccount() {
    this.familyService.updateGuardian(this.editModel)
      .subscribe((res) => {
        if (res.Success) {
          this.model = { ...this.editModel };
          this.editBankAccountViewDisplay = false;
        }
      });
  }

  cancelBankAccount() {
    this.editBankAccountViewDisplay = false;
  }

  isValidBankAccount(): boolean {
    const model = this.editModel;
    return (
      model.PaymentBankName && model.PaymentBankName.trim().length > 0 &&
      model.PaymentBankRouting && model.PaymentBankRouting.trim().length > 0 &&
      model.PaymentBankAccount && model.PaymentBankAccount.trim().length > 0
    );
  }
}
