import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RaceTypeEditBaseComponent } from './raceTypeEdit.base';

@Component({
	selector: 'app-raceTypeEdit',
	templateUrl: './raceTypeEdit.component.html',
})
export class RaceTypeEditComponent extends RaceTypeEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
