import { SchoolFeeModel} from '../entities/SchoolFeeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolFeeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolFeeModel = new SchoolFeeModel();
}

export class SchoolFeeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolFeeModel> = <Array<SchoolFeeModel >>[]
}

export interface ISchoolFeeListParams {
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	fK_GradeLevel?:number;
	fK_GradeLevel_display?:string;
	fK_GradeLevel_like?:string;
	fK_GradeLevel_fromValue?:number;
	fK_GradeLevel_toValue?:number;
	pK_Id?:number;
	fK_FeeType?:number;
	fK_FeeType_display?:string;
	fK_FeeType_like?:string;
	fK_FeeType_fromValue?:number;
	fK_FeeType_toValue?:number;
	amount?:number;
	amount_fromValue?:number;
	amount_toValue?:number;
	fK_GradeLevel_To?:number;
	fK_GradeLevel_To_display?:string;
	fK_GradeLevel_To_like?:string;
	fK_GradeLevel_To_fromValue?:number;
	fK_GradeLevel_To_toValue?:number;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	isDeleted?:boolean;
	isDeleted_booleanValue?:boolean;
	description?:string;
	description_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolFeeListParams implements ISchoolFeeListParams, ISearchModel  {
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	fK_GradeLevel?:number=null;
	fK_GradeLevel_display?:string=null;
	fK_GradeLevel_like?:string=null;
	fK_GradeLevel_fromValue?:number=null;
	fK_GradeLevel_toValue?:number=null;
	pK_Id?:number=null;
	fK_FeeType?:number=null;
	fK_FeeType_display?:string=null;
	fK_FeeType_like?:string=null;
	fK_FeeType_fromValue?:number=null;
	fK_FeeType_toValue?:number=null;
	amount?:number=null;
	amount_fromValue?:number=null;
	amount_toValue?:number=null;
	fK_GradeLevel_To?:number=null;
	fK_GradeLevel_To_display?:string=null;
	fK_GradeLevel_To_like?:string=null;
	fK_GradeLevel_To_fromValue?:number=null;
	fK_GradeLevel_To_toValue?:number=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	isDeleted?:boolean=null;
	isDeleted_booleanValue?:boolean=null;
	description?:string=null;
	description_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

