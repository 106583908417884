import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuardianEditBaseComponent } from './guardianEdit.base';

@Component({
	selector: 'app-guardianEdit',
	templateUrl: './guardianEdit.component.html',
})
export class GuardianEditComponent extends GuardianEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
