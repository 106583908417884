import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolCommitmentFeesLookUpListParams } from '../../models/entities/SchoolCommitmentFeesLookUpModel.response';
import { SchoolCommitmentFeesLookUpModel } from '../../models/entities/SchoolCommitmentFeesLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolCommitmentFeesLookUpListBaseComponent } from './schoolCommitmentFeesLookUpList.base';

@Component({
	selector: 'app-schoolCommitmentFeesLookUpList',
	templateUrl: './schoolCommitmentFeesLookUpList.component.html',
})
export class SchoolCommitmentFeesLookUpListComponent extends SchoolCommitmentFeesLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
