import {IListItem } from '../models';
export class AwardAcceptanceStatusLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_AwardDetermination:number = 0;
	public FK_AwardDetermination_Display:string = null;
	public FK_AwardAcceptanceStatusType:number = 0;
	public FK_AwardAcceptanceStatusType_Display:string = null;
	public CreatedDate:Date = null;
}
