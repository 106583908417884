import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalsService } from 'src/app/services/globals.service';
import { ErrorMessagesService } from './error-messages.service';
import { ManagerResponse, InsertResponse, ListItemModelResponse, ListItemModel, CheckboxListSave, PasswordResetPost } from '../models/models';
import { aaaAPIServiceBase } from './aaa.API.service.base';
import { UserLoginPost, UserLoginResponse } from '../models/componentModels';
import { LoginModel } from '../models/entities/LoginModel';
import { UserNameResponse } from '../models/entities/custom/UserNameModel.response';
import { LoginRolesLookUpList } from '../models/entities/LoginRolesLookUpModel.response';
import { IZengineLoginListParams, ZengineLoginList, ZengineLoginResponse } from '../models/entities/ZengineLoginModel.response';
import { ZengineLoginModel } from '../models/entities/ZengineLoginModel';

@Injectable({
	providedIn: 'root'
})
export class aaaAPIService extends aaaAPIServiceBase {

  constructor(_http: HttpClient, _gl: GlobalsService, _errorMessagesService: ErrorMessagesService) {
    super(_http, _gl, _errorMessagesService);
  }

  public doPasswordReset(model: PasswordResetPost, captcha: string): Observable<UserLoginResponse> {
    this.errorMessagesService.showSpinner = true;
    var clientUrl = window.location.protocol + "//" + window.location.host;
    const url = this.gl.BaseUrl + '/api/Login/doPasswordReset';
    console.log(url);
    return this.http.post(url, model, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token, 'x-captcha': captcha }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <UserLoginResponse>res.body;
    }));
  }

  public sendPasswordResetEmail(email: string, captcha: string): Observable<ManagerResponse> {
    this.errorMessagesService.showSpinner = true;
    var clientUrl = window.location.protocol + "//" + window.location.host;
    const url = this.gl.BaseUrl + '/api/Login/SendPasswordResetEmail/?email=' + encodeURI(email?email:'').replace('+', '%2B') + "&url=" + encodeURI(clientUrl).replace('+', '%2B');
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token, 'x-captcha': captcha }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ManagerResponse>res.body;
    }));
  }

 public sendInviteEmail(email: string, username: string, primaryUsername: string, captcha: string): Observable<ManagerResponse> {
    this.errorMessagesService.showSpinner = true;
    var clientUrl = window.location.protocol + "//" + window.location.host;
    const url = this.gl.BaseUrl + '/api/Login/SendInvitation?email=' + encodeURI(email ? email : '').replace('+', '%2B') + "&url=" + encodeURI(clientUrl).replace('+', '%2B') + "&username=" + encodeURI(username).replace('+', '%2B') + "&primaryUsername=" + encodeURI(primaryUsername).replace('+', '%2B');
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token, 'x-captcha': captcha }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ManagerResponse>res.body;
    }));
  }


  public doUserLogin(model: UserLoginPost, captcha:string): Observable<UserLoginResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/Login/DoUserLogin/';
    return this.http.post(url, model, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token, 'x-captcha': captcha }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <UserLoginResponse>res.body;
    }));
  }

  public insertUserLogin(model: LoginModel): Observable<InsertResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/Login/PostUserLogin/';
    return this.http.post(url, model, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <InsertResponse>res.body;
    }));
  }

  public updateUserLogin(model: LoginModel): Observable<ManagerResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/Login/PutUserLogin/';
    return this.http.post(url, model, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ManagerResponse>res.body;
    }));
  }

  public syncZengineForms(token: string): Observable<ManagerResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ZengineForm/SyncForms/?token=' + encodeURI(token ? token : '').replace('+', '%2B');
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ManagerResponse>res.body;
    }));
  }

  public syncZengineRecords(token: string, form: number): Observable<ManagerResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ZengineForm/SyncRecords/?token=' + encodeURI(token ? token : '').replace('+', '%2B') + "&form=" + form.toString();
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ManagerResponse>res.body;
    }));
  }

 public getUserByToken(token: string): Observable<UserNameResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/Login/GetUserByToken/?token=' + token;
    console.log('Api Call: GetUserByToken: ' + url);
    return this.http
      .get(url, {
        headers: new HttpHeaders({ 'x-token': this.gl.Token }),
        observe: 'response',
      })
      .pipe(
        catchError(this.gl.handleError),
        map((res: any) => {
          console.log(res);
          this.gl.SetValidate(res);
          return <UserNameResponse>res.body;
        })
      );
  }

  public getRolesByToken(token: string): Observable<LoginRolesLookUpList> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/Login/GetRolesByToken?token=' + token;
    console.log('Api Call: GetRolesByToken: ' + url);
    return this.http
      .get(url, {
        headers: new HttpHeaders({ 'x-token': this.gl.Token }),
        observe: 'response',
      })
      .pipe(
        catchError(this.gl.handleError),
        map((res: any) => {
          console.log(res);
          this.gl.SetValidate(res);
          return <LoginRolesLookUpList>res.body;
        })
      );
  }

  public getZengineLogin(id: number): Observable<ZengineLoginResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ZengineLogin/Get/?id=' + id;
    console.log('Api Call: getZengineLogin: ' + url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ZengineLoginResponse>res.body;
    }));
  }

  public updateZengineLogin(m: ZengineLoginModel): Observable<ManagerResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ZengineLogin/Put';
    return this.http.put(url, m, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ManagerResponse>res.body;
    }));
  }

  public insertZengineLogin(m: ZengineLoginModel): Observable<InsertResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ZengineLogin/Post';
    return this.http.post(url, m, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <InsertResponse>res.body;
    }));
  }

  public getZengineLoginList({ pK_Id, userName, userName_like, password, password_like, apiToken, apiToken_like, dateUpdated, dateUpdated_fromDate, dateUpdated_toDate, _fuzzySearch, _sort, _sortDesc, _page, _pageSize }: IZengineLoginListParams): Observable<ZengineLoginList> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ZengineLogin/Search/?pK_Id=' + (pK_Id != null ? encodeURI(pK_Id.toString()) : '') + '&userName=' + (userName != null ? encodeURI(userName.toString()) : '') + '&userName_like=' + (userName_like != null ? encodeURI(userName_like.toString()) : '') + '&password=' + (password != null ? encodeURI(password.toString()) : '') + '&password_like=' + (password_like != null ? encodeURI(password_like.toString()) : '') + '&apiToken=' + (apiToken != null ? encodeURI(apiToken.toString()) : '') + '&apiToken_like=' + (apiToken_like != null ? encodeURI(apiToken_like.toString()) : '') + '&dateUpdated=' + (dateUpdated != null ? encodeURI(dateUpdated.toString()) : '') + '&dateUpdated_fromDate=' + (dateUpdated_fromDate != null ? encodeURI(dateUpdated_fromDate.toString()) : '') + '&dateUpdated_toDate=' + (dateUpdated_toDate != null ? encodeURI(dateUpdated_toDate.toString()) : '') + '&_fuzzySearch=' + (_fuzzySearch != null ? encodeURI(_fuzzySearch.toString()) : '') + '&_sort=' + (_sort != null ? encodeURI(_sort.toString()) : '') + '&_sortDesc=' + (_sortDesc != null ? encodeURI(_sortDesc.toString()) : '') + '&_page=' + (_page != null ? encodeURI(_page.toString()) : '') + '&_pageSize=' + (_pageSize != null ? encodeURI(_pageSize.toString()) : '');
    console.log('Api Call: getZengineLoginList: ' + url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ZengineLoginList>res.body;
    }));
  }

  public deleteZengineLogin(id: number): Observable<ManagerResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ZengineLogin/Delete/?id=' + id;
    return this.http.delete(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ManagerResponse>res.body;
    }));
  }

  public deleteManyZengineLogin(ids: Array<number>): Observable<ManagerResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ZengineLogin/DeleteMany';
    var model = { IDs: ids };
    return this.http.post(url, model, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ManagerResponse>res.body;
    }));
  }

  public zengineLoginsListBox(search?: string, size?: number, id?: any): Observable<Array<ListItemModel>> {
    const url = this.gl.BaseUrl + '/api/ZengineLogin/ListBox/?search=' + (search ? encodeURI(search.toString()) : '') + '&size=' + (size ? size.toString() : '') + '&id=' + (id ? id.toString() : '');
    console.log('Api Call: getZengineLoginListBox: ' + url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      return <Array<ListItemModel>>res.body;
    }));
  }

 
 

}
