import { Component, OnInit, Directive } from '@angular/core';
import {  IPreAuthorizationStatusTypeListParams } from '../../models/entities/PreAuthorizationStatusTypeModel.response';
import { PreAuthorizationStatusTypeModel } from '../../models/entities/PreAuthorizationStatusTypeModel';
import { PagingData } from '../../paging/paging.component';
import { PreAuthorizationStatusTypeListBaseComponent } from './preAuthorizationStatusTypeList.base';

@Component({
	selector: 'app-preAuthorizationStatusTypeList',
	templateUrl: './preAuthorizationStatusTypeList.component.html',
})
export class PreAuthorizationStatusTypeListComponent extends PreAuthorizationStatusTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
