import { Component, OnInit, Directive } from '@angular/core';
import { IVendorTransactionNoteListParams } from '../../models/entities/VendorTransactionNoteModel.response';
import { VendorTransactionNoteModel } from '../../models/entities/VendorTransactionNoteModel';
import { PagingData } from '../../paging/paging.component';
import { VendorTransactionNoteListBaseComponent } from './vendorTransactionNoteList.base';

@Component({
    selector: 'app-vendorTransactionNoteList',
    templateUrl: './vendorTransactionNoteList.component.html',
})
export class VendorTransactionNoteListComponent extends VendorTransactionNoteListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
