import { Component, OnInit, Directive } from '@angular/core';
import {  IVerificationStatusTypeListParams } from '../../models/entities/VerificationStatusTypeModel.response';
import { VerificationStatusTypeModel } from '../../models/entities/VerificationStatusTypeModel';
import { PagingData } from '../../paging/paging.component';
import { VerificationStatusTypeListBaseComponent } from './verificationStatusTypeList.base';

@Component({
	selector: 'app-verificationStatusTypeList',
	templateUrl: './verificationStatusTypeList.component.html',
})
export class VerificationStatusTypeListComponent extends VerificationStatusTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
