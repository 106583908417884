import { Component, OnInit, Directive } from '@angular/core';
import {  IGradingPeriodTypeListParams } from '../../models/entities/GradingPeriodTypeModel.response';
import { GradingPeriodTypeModel } from '../../models/entities/GradingPeriodTypeModel';
import { PagingData } from '../../paging/paging.component';
import { GradingPeriodTypeListBaseComponent } from './gradingPeriodTypeList.base';

@Component({
	selector: 'app-gradingPeriodTypeList',
	templateUrl: './gradingPeriodTypeList.component.html',
})
export class GradingPeriodTypeListComponent extends GradingPeriodTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
