import { Component, OnInit, Directive } from '@angular/core';
import {  IRaceTypeListParams } from '../../models/entities/RaceTypeModel.response';
import { RaceTypeModel } from '../../models/entities/RaceTypeModel';
import { PagingData } from '../../paging/paging.component';
import { RaceTypeListBaseComponent } from './raceTypeList.base';

@Component({
	selector: 'app-raceTypeList',
	templateUrl: './raceTypeList.component.html',
})
export class RaceTypeListComponent extends RaceTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
