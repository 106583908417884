import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentDisabilityLookUpEditBaseComponent } from './studentDisabilityLookUpEdit.base';

@Component({
	selector: 'app-studentDisabilityLookUpEdit',
	templateUrl: './studentDisabilityLookUpEdit.component.html',
})
export class StudentDisabilityLookUpEditComponent extends StudentDisabilityLookUpEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
