import { Component, OnInit, Directive } from '@angular/core';
import {  IStudentListParams } from '../../models/entities/StudentModel.response';
import { StudentModel } from '../../models/entities/StudentModel';
import { PagingData } from '../../paging/paging.component';
import { StudentListBaseComponent } from './studentList.base';

@Component({
	selector: 'app-studentList',
	templateUrl: './studentList.component.html',
})
export class StudentListComponent extends StudentListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
