import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { ActivatedRoute } from '@angular/router';
import { UserLoginPost, UserLoginResponse } from '../models/componentModels';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RoleType } from '../models/models';

@Component({
  selector: 'app-do-user-login',
  templateUrl: './do-user-login.component.html'
})
export class DoUserLoginComponent extends BaseComponent implements OnInit {
  public model: UserLoginPost = <UserLoginPost>{};
  forgotPassword: boolean = false;

  constructor(r: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service) {
    super(r, true);
    this.resetSession();
  }

  ngOnInit(): void {
    super.baseNgOnInit();
  }

  protected loadOptions() { }

  public validateEmail(): boolean {
    return (this.model.Email && this.model.Email.trim().length > 0);
  }

  public validateData(): boolean {
    return (this.validateEmail() && this.model.Password && this.model.Password.trim().length > 0);
  }

  public login(): void {
    var sub = this.recaptchaV3Service.execute('login')
      .subscribe((token) => {
        this.login2(token);
        sub.unsubscribe();
      });
  }

  private login2(token: string) {
    var me = this;

    var sub = this.api.doUserLogin(this.model, token)
      .subscribe(x => {
        if (x.Success) {
          if (x.Data.Token) {
            me.gl.LoginRoles = x.Data.Roles;
            me.gl.LoginName = x.Data.Email;
            me.gl.LoginFullName = x.Data.FullName;
            me.gl.LoginId = x.Data.Id;
            me.gl.LoginTime = new Date();

            me.gl.Token = x.Data.Token;
            if (x.Data.Roles.includes(RoleType.SchoolAdmin) || x.Data.Roles.includes(RoleType.SchoolUser)) {
              me.gl.GoTo("schooldashboard", null, me.zone);
            } else if (x.Data.Roles.includes(RoleType.ServiceProviderAdmin) || (x.Data.Roles.includes(RoleType.ServiceProviderUser))) {
              me.gl.GoTo("vendordashboard");
            } else if (x.Data.Roles.includes(RoleType.GuardianPrimary) || (x.Data.Roles.includes(RoleType.GuardianSecondary))) {
              me.gl.GoTo("familyawardstatus", null, me.zone);
            } else if (x.Data.Roles.length == 1 && x.Data.Roles.includes(RoleType.Admin)) {
              me.gl.GoTo("admindashboard", null, me.zone);
            } else {
              me.gl.GoTo("userlogin", null, me.zone);
            }
          }
        } else {
          this.model.Password = null;
        }
        sub.unsubscribe();
      });
  }

  public resetPassword(): void {
    var sub = this.recaptchaV3Service.execute('resetPassword')
      .subscribe((token) => {
        this.resetPassword2(token, this.model.Email);
        sub.unsubscribe();
      });
  }

  private resetPassword2(token: string, email: string) {
    var me = this;
    var sub = this.api.sendPasswordResetEmail(email, token).subscribe(x => {
      this.model.Email = null;
      this.model.Password = null;
      this.forgotPassword = false;
      sub.unsubscribe();
    });
  }

  public goToForgotPassword() {
    this.forgotPassword = true;
  }

  public backToLogin() {
    this.forgotPassword = false;
  }
}
