import { ZengineMappingLookUpModel} from '../entities/ZengineMappingLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ZengineMappingLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ZengineMappingLookUpModel = new ZengineMappingLookUpModel();
}

export class ZengineMappingLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ZengineMappingLookUpModel> = <Array<ZengineMappingLookUpModel >>[]
}

export interface IZengineMappingLookUpListParams {
	fK_State?:number;
	fK_State_display?:string;
	fK_State_like?:string;
	fK_State_fromValue?:number;
	fK_State_toValue?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	fK_ZengineMappingOption?:number;
	fK_ZengineMappingOption_display?:string;
	fK_ZengineMappingOption_like?:string;
	fK_ZengineMappingOption_fromValue?:number;
	fK_ZengineMappingOption_toValue?:number;
	fK_ZengineFormField?:number;
	fK_ZengineFormField_display?:string;
	fK_ZengineFormField_like?:string;
	fK_ZengineFormField_fromValue?:number;
	fK_ZengineFormField_toValue?:number;
	pK_Id?:number;
	fK_ZengineForm?:number;
	fK_ZengineForm_display?:string;
	fK_ZengineForm_like?:string;
	fK_ZengineForm_fromValue?:number;
	fK_ZengineForm_toValue?:number;
	fK_Scholarship?:number;
	fK_Scholarship_display?:string;
	fK_Scholarship_like?:string;
	fK_Scholarship_fromValue?:number;
	fK_Scholarship_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ZengineMappingLookUpListParams implements IZengineMappingLookUpListParams, ISearchModel  {
	fK_State?:number=null;
	fK_State_display?:string=null;
	fK_State_like?:string=null;
	fK_State_fromValue?:number=null;
	fK_State_toValue?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	fK_ZengineMappingOption?:number=null;
	fK_ZengineMappingOption_display?:string=null;
	fK_ZengineMappingOption_like?:string=null;
	fK_ZengineMappingOption_fromValue?:number=null;
	fK_ZengineMappingOption_toValue?:number=null;
	fK_ZengineFormField?:number=null;
	fK_ZengineFormField_display?:string=null;
	fK_ZengineFormField_like?:string=null;
	fK_ZengineFormField_fromValue?:number=null;
	fK_ZengineFormField_toValue?:number=null;
	pK_Id?:number=null;
	fK_ZengineForm?:number=null;
	fK_ZengineForm_display?:string=null;
	fK_ZengineForm_like?:string=null;
	fK_ZengineForm_fromValue?:number=null;
	fK_ZengineForm_toValue?:number=null;
	fK_Scholarship?:number=null;
	fK_Scholarship_display?:string=null;
	fK_Scholarship_like?:string=null;
	fK_Scholarship_fromValue?:number=null;
	fK_Scholarship_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

