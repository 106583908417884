import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VendorCategoriesLookUpEditBaseComponent } from './vendorCategoriesLookUpEdit.base';

@Component({
    selector: 'app-vendorCategoriesLookUpEdit',
    templateUrl: './vendorCategoriesLookUpEdit.component.html',
})
export class VendorCategoriesLookUpEditComponent extends VendorCategoriesLookUpEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
