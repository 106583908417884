<div *ngIf="modalEdit||newModalEdit" class="modal" [style.left]="leftOffset" [style.top]="topOffset">
  <app-vendorEdit [modalview]="true" [id]="modalEdit" [level]="(level+.5)" (saved)="modalEditSaved($event)" (cancelled)="modalEdit=null;newModalEdit=false"></app-vendorEdit>
</div>
<div class="card">
  <div class="card-header">
    Vendor List <a class="right"  (click)="newModalEdit = true" ><i class="fa fa-plus" title="Add Vendor"></i></a>
    <div><app-search-input [placeholdertext]="'Search'" (changed)="fuzzySearchChange($event)" ></app-search-input></div>

  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div style="grid-template-columns: 100px auto auto auto auto auto auto auto auto ;" class="table">
          <div class="table-header" style="text-align:left">
            <i (click)="selectAll()" title="Select All" class="fa" [ngClass]="{ 'fa-check-square-o':selectAllStatus,'fa-square-o':!selectAllStatus}"></i>
            <i title='Delete Many' (click)='deleteMany()' class='fa fa-trash icon-link'></i>
          </div>
          <div class="table-header" (click)="sortChange('Name')">Name <i *ngIf="search._sort=='Name'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('VendorCode')">Vendor Code <i *ngIf="search._sort=='VendorCode'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('EmailAddress')">Email Address <i *ngIf="search._sort=='EmailAddress'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_State_VendorPhysical_Display')">State Vendor Physical <i *ngIf="search._sort=='FK_State_VendorPhysical_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('CreatedDate')">Created Date <i *ngIf="search._sort=='CreatedDate'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('Physical_StreetAddress1')">Physical Street Address <i *ngIf="search._sort=='Physical_StreetAddress1'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_Login_Display')">Login <i *ngIf="search._sort=='FK_Login_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('Physical_City')">Physical City <i *ngIf="search._sort=='Physical_City'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>





          <ng-container *ngFor="let x of model">
            <div>
              <span class='edit-tools'>
                <i (click)="x.Selected = !x.Selected" class="fa" [ngClass]="{ 'fa-check-square-o':x.Selected,'fa-square-o':!x.Selected}"></i>
                <a (click)="modalEdit = x.PK_Id"><i title='Edit' class='fa fa-pencil icon-link'></i></a> <i title='Delete' (click)='delete(x.PK_Id )' class='fa fa-trash icon-link'></i>
              </span>
            </div>
            <div>
              {{x.Name}}
            </div>
            <div>
              {{x.VendorCode}}
            </div>
            <div>
              {{x.EmailAddress}}
            </div>
            <div>
              {{x.FK_State_VendorPhysical_Display}}
            </div>
            <div>
              {{gl.FormatDateShort(x.CreatedDate)}}
            </div>
            <div>
              {{x.Physical_StreetAddress1}}
            </div>
            <div>
              {{x.FK_Login_Display}}
            </div>
            <div>
              {{x.Physical_City}}
            </div>

          </ng-container>
        </div>

        <app-paging [showpagesize]="true" [data]="pagingData" (changed)="pagingChanged($event)"></app-paging>
      </div>
    </div>
  </div>
</div>

<app-spinner [showspinner]="ShowSpinner"></app-spinner>

