import { LoginModel} from '../entities/LoginModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class LoginResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: LoginModel = new LoginModel();
}

export class LoginList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<LoginModel> = <Array<LoginModel >>[]
}

export interface ILoginListParams {
	email?:string;
	email_like?:string;
	passwordHash?:string;
	passwordHash_like?:string;
	isEnabled?:boolean;
	isEnabled_booleanValue?:boolean;
	pK_Id?:string;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	salt?:string;
	salt_like?:string;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	twoFactorPhoneNumber?:string;
	twoFactorPhoneNumber_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class LoginListParams implements ILoginListParams, ISearchModel  {
	email?:string=null;
	email_like?:string=null;
	passwordHash?:string=null;
	passwordHash_like?:string=null;
	isEnabled?:boolean=null;
	isEnabled_booleanValue?:boolean=null;
	pK_Id?:string=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	salt?:string=null;
	salt_like?:string=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	twoFactorPhoneNumber?:string=null;
	twoFactorPhoneNumber_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

