import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/BaseComponent';
import { ContactModel } from 'src/app/models/entities/ContactModel';
import { StudentGradeLevelsSchoolYearsLookUpModel } from 'src/app/models/entities/StudentGradeLevelsSchoolYearsLookUpModel';
import { VendorModel } from 'src/app/models/entities/VendorModel';
import { VendorTransactionModel } from 'src/app/models/entities/VendorTransactionModel';
import { aaaAPIVendorService } from 'src/app/services/aaa.API.vendor.service';
import { FieldTypeDefinition } from 'src/app/types/field-type-definition-type';
import { ITableColumnDefinition } from '../../../interfaces/table-column-definition-interface';

interface ServiceCategory {
    name: string,
    code: string
}

@Component({
  selector: 'app-vendor-dashboard-page',
  templateUrl: './vendor-dashboard-page.component.html',
  styleUrls: ['./vendor-dashboard-page.component.scss']
})
export class VendorDashboardPageComponent extends BaseComponent implements OnInit {
  searchInstructorsText: string = '';
  searchPaymentsText: string = '';
  vendorId = 0;
  serviceCategoriesOptions: ServiceCategory[];
  serviceCategory: ServiceCategory;
  activeInstructors: boolean = true;

  instructorsTableData: any[] = [];
  instructorsTableColumns: ITableColumnDefinition[] = [];
  originalInstructorsTableData: any[] = [];
  paymentsTableData: any[] = [];
  paymentsTableColumns: ITableColumnDefinition[] = [];
  
  vendorModel: VendorModel;
  vendorPayments: VendorTransactionModel[] = [];
  instructorsModels: any = []; 

  paymentDetailsDisplay: boolean = false;
  addInstructorDisplay: boolean = false;

  constructor(
    private vendorService: aaaAPIVendorService
  ) {
    super(null, false);
  }

  ngOnInit(): void {
    this.serviceCategoriesOptions = [
        {name: 'All Service Categories', code: 'All'},
        {name: 'Full-time tutoring', code: 'Full'},
        {name: 'Part-time tutoring', code: 'Part'},
    ];
    
    this.vendorService.getContactByLogin(this.gl.LoginId)
      .subscribe(response => {
        if (response.Success) {
          this.vendorService.getContactsLookUpList({ fK_Contact: response.Data.PK_Id }).subscribe((contactLookUp) => {
            if (contactLookUp.Success) {
              this.vendorId = contactLookUp.Data[0].FK_Vendor;
              this.vendorService.getVendorTransactionList({ fK_Vendor: contactLookUp.Data[0].FK_Vendor })
                .subscribe((vendorTransactions) => {
                  if (vendorTransactions.Success){
                    this.vendorPayments = vendorTransactions.Data;
                    this.generatePaymentsTableData();
                  }
                });
                this.generateInstructorsTableData(contactLookUp.Data[0].FK_Vendor);
            }
          });
        }
      });
  }

  private generatePaymentsTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Approval Date',
      fieldName: 'Approval Date',
      fieldType: FieldTypeDefinition.Date,
      sortable: true,
    });
    columns.push({
      fieldCaption: 'Student Name',
      fieldName: 'Student Name',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Grade',
      fieldName: 'Grade',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Guardian Name',
      fieldName: 'Guardian Name',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Amount',
      fieldName: 'Amount',
      fieldType: FieldTypeDefinition.Money,
    });
    columns.push({
      fieldCaption: 'Item/Service Purchased',
      fieldName: 'ItemServicePurchased',
      fieldType: FieldTypeDefinition.String,
    });
    this.paymentsTableColumns = [...columns];
  }

  private generatePaymentsTableData() {
    this.generatePaymentsTableColumns();
    this.paymentsTableData = [];
    this.vendorPayments.forEach((item) => {
      let rowData = {};
      rowData['Approval Date'] = item.CreatedDate;
      rowData['Student Name'] = item.FK_Student_Display;
      this.vendorService.getStudentGradeLevelsSchoolYearsLookUpList({ fK_Student: item.FK_Student, fK_SchoolYear: item.FK_SchoolYear })
        .subscribe((data) =>{
          rowData['Grade'] = data.Data[0].FK_GradeLevel_Display;
        });
      rowData['Guardian Name'] = item.FK_Guardian_Display;
      rowData['Amount'] = item.Amount;
      rowData['ItemServicePurchased'] = item.Description;
      this.paymentsTableData.push(rowData);
    });
    
    console.log(this.paymentsTableData);
  }

  private generateInstructorsTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Status',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.HTML,
    });
    columns.push({
      fieldCaption: 'Name',
      fieldName: 'Name',
      fieldType: FieldTypeDefinition.String,
      sortable: true
    });
    columns.push({
      fieldCaption: 'Email',
      fieldName: 'Email',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Categories',
      fieldName: 'Categories',
      fieldType: FieldTypeDefinition.String,
    });
    this.instructorsTableColumns = [...columns];
  }

  private generateInstructorsTableData(fk_vendor) {
    this.generateInstructorsTableColumns();
    this.instructorsTableData = [];
    this.vendorService.getContactsLookUpList({ fK_Vendor: fk_vendor }).subscribe((instructors) => {
        if (instructors.Success){
          instructors.Data.forEach((item) => {
            this.vendorService.getContact(item.FK_Contact).subscribe((contact) => {
              console.log('contact', contact.Data);
              let rowData = {};
              rowData['Name'] = `${contact.Data.FirstName} ${contact.Data.LastName}`;
              rowData['Email'] = contact.Data.Email;
              rowData['Categories'] = 'Full-time Tutoring, Part-time Tutoring'
              this.vendorService.getLogin(contact.Data.FK_Login).subscribe((login) => {
                let statusTable = '';
                if (login.Data.IsEnabled) {
                  statusTable = "<i class='fas fa-check-circle text-success h3'></i>";
                } else {
                  statusTable = "<i class='far fa-clock text-secondary h3'></i>";
                }
                rowData['Status'] = statusTable;
              });
              this.instructorsTableData.push(rowData);
            });
          });
          
        }
      });
    this.originalInstructorsTableData = this.instructorsTableData;
  }

  searchPayments(e) {
    console.log(e);
    this.generatePaymentsTableData();
    let filtered = this.paymentsTableData.filter((item) => 
      item.ItemServicePurchased.includes(e)
    );
    this.paymentsTableData = [ ...filtered ];
  }

  paymentsRowClicked(row: any) {
    this.paymentDetailsDisplay = true;
  }

  searchInstructors(e){
    console.log('1',this.originalInstructorsTableData);
    let filtered = this.originalInstructorsTableData.filter((item) => 
      item.Name.toLowerCase().includes(e.toLowerCase())
    );
    this.instructorsTableData = [ ...filtered ];
  }

  openAddInstructor(){
    this.addInstructorDisplay = true;
  }
  handleShowActiveInstructorsOnly(e){
    if (!e.checked){
      let filtered = this.originalInstructorsTableData.filter((item) => 
        item.Status.toLowerCase().includes('fa-clock')
      );
      this.instructorsTableData = [ ...filtered ];
    } else {
      this.instructorsTableData = [ ...this.originalInstructorsTableData ];
    }
  }
  saveInstructor(){}

  cancelInstructor(){
    this.addInstructorDisplay = false;
  }
}
