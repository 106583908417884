<div class="card">
  <div class="card-header">
    Guardian <span class="right">
  <a title="Cancel" *ngIf="!ModalView" routerLink="/guardianlist"><i class="fa fa-undo icon-link"></i></a>
  <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
  <i class="fa fa-save icon-link" (click)="save()"></i>
</span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        								<div class="col-6">
									<div class="form-group">
										<label>Guardian Type</label><span title="Required" class="required">*</span>
										<select [(ngModel)]="model.FK_GuardianType" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_GuardianType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Login</label><span title="Required" class="required">*</span>
										<app-typeahead-basic [(id)]="model.FK_Login" [searchapi]="'loginsListBox'"></app-typeahead-basic>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Household</label><span title="Required" class="required">*</span>
										<input [(ngModel)]="model.FK_Household" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>First Name</label><span title="Required" class="required">*</span>
										<input [(ngModel)]="model.FirstName" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Middle Initial</label>
										<input [(ngModel)]="model.MiddleInitial" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Last Name</label>
										<input [(ngModel)]="model.LastName" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Home Phone</label>
										<input [(ngModel)]="model.HomePhone" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Cell Phone</label>
										<input [(ngModel)]="model.CellPhone" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Bank Name</label>
										<input [(ngModel)]="model.PaymentBankName" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Bank Routing</label>
										<input [(ngModel)]="model.PaymentBankRouting" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Payment Bank Account</label>
										<input [(ngModel)]="model.PaymentBankAccount" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Employer</label>
										<textarea rows="4" [(ngModel)]="model.Employer" class="form-control"></textarea>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Work Phone</label>
										<input [(ngModel)]="model.WorkPhone" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Invite Email Sent</label>
<app-date-picker [(model)]="model.InviteEmailSent"></app-date-picker>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Invite Email Accepted</label>
<app-date-picker [(model)]="model.InviteEmailAccepted"></app-date-picker>
									</div>
								</div>


      </div>
      

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
