import { Component, OnInit, Directive } from '@angular/core';
import { ScholarshipPaymentsDeadlineModel } from '../../models/entities/ScholarshipPaymentsDeadlineModel';
import { ListItemModel, IListItem, UpdateEvent } from '../../models/models';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class ScholarshipPaymentsDeadlineEditBaseComponent extends BaseEditComponent  {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	public model: ScholarshipPaymentsDeadlineModel = <ScholarshipPaymentsDeadlineModel>{};
	public fK_SchoolYear_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_Scholarship_options:Array<ListItemModel> = <Array<ListItemModel>>[];

	protected baseNgOnInit() {
		super.baseNgOnInit();
	}


	protected loadOptions() {
				this.getFK_SchoolYear_Options();
				this.getFK_Scholarship_Options();
	}

	protected getData() {
	this.ShowSpinner = true;
		var sub = this.api.getScholarshipPaymentsDeadline(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
			}
			this.ShowSpinner = false;
			this.getDataComplete();
			sub.unsubscribe();
		});
	}
	public getFK_SchoolYear_Options(clear?: boolean) {
		if (clear) {  this.model.FK_SchoolYear = null; }
		var sub = this.api.getSchoolYearList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_SchoolYear_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_Scholarship_Options(clear?: boolean) {
		if (clear) {  this.model.FK_Scholarship = null; }
		var sub = this.api.getScholarshipList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_Scholarship_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateScholarshipPaymentsDeadline(this.model).subscribe(x=>{
			if (x.Success) {
				this.saved.emit(this.id);
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertScholarshipPaymentsDeadline(this.model).subscribe(x=>{
			if (x.Success) {
				this._id = x.Data;
				this.saved.emit(x.Data);
			}
			sub.unsubscribe();
		});
		}
	}
}
