import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WithdrawReasonTypeEditBaseComponent } from './withdrawReasonTypeEdit.base';

@Component({
	selector: 'app-withdrawReasonTypeEdit',
	templateUrl: './withdrawReasonTypeEdit.component.html',
})
export class WithdrawReasonTypeEditComponent extends WithdrawReasonTypeEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
