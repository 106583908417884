import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NoteTypeEditBaseComponent } from './noteTypeEdit.base';

@Component({
    selector: 'app-noteTypeEdit',
    templateUrl: './noteTypeEdit.component.html',
})
export class NoteTypeEditComponent extends NoteTypeEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
