import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolYearListParams } from '../../models/entities/SchoolYearModel.response';
import { SchoolYearModel } from '../../models/entities/SchoolYearModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolYearListBaseComponent } from './schoolYearList.base';

@Component({
	selector: 'app-schoolYearList',
	templateUrl: './schoolYearList.component.html',
})
export class SchoolYearListComponent extends SchoolYearListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
