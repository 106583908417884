import { VerificationNoteModel} from '../entities/VerificationNoteModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class VerificationNoteResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: VerificationNoteModel = new VerificationNoteModel();
}

export class VerificationNoteList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<VerificationNoteModel> = <Array<VerificationNoteModel >>[]
}

export interface IVerificationNoteListParams {
	pK_Id?:number;
	fK_Verification?:number;
	fK_Verification_display?:string;
	fK_Verification_like?:string;
	fK_Verification_fromValue?:number;
	fK_Verification_toValue?:number;
	fK_NoteType?:number;
	fK_NoteType_display?:string;
	fK_NoteType_like?:string;
	fK_NoteType_fromValue?:number;
	fK_NoteType_toValue?:number;
	note?:string;
	note_like?:string;
	noteActive?:boolean;
	noteActive_booleanValue?:boolean;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class VerificationNoteListParams implements IVerificationNoteListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_Verification?:number=null;
	fK_Verification_display?:string=null;
	fK_Verification_like?:string=null;
	fK_Verification_fromValue?:number=null;
	fK_Verification_toValue?:number=null;
	fK_NoteType?:number=null;
	fK_NoteType_display?:string=null;
	fK_NoteType_like?:string=null;
	fK_NoteType_fromValue?:number=null;
	fK_NoteType_toValue?:number=null;
	note?:string=null;
	note_like?:string=null;
	noteActive?:boolean=null;
	noteActive_booleanValue?:boolean=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

