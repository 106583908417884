<div>
  <div class="row p-3">
    <div class="col-12">
      <div class="form-group">
        <label class="form-label font-weight-bold">Search Users</label>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div class="d-flex flex-row align-items-center w-75">
            <input type="text" class="form-control w-25" [(ngModel)]="usersSearchText"
              (ngModelChange)="onSearchTextChanged()" />
            <p-inputSwitch [(ngModel)]="usersActive" class="px-2" (ngModelChange)="onUsersActiveChange()">
            </p-inputSwitch>
            <span class="text-subtitle font-weight-bold">Show Active Users Only</span>
          </div>
          <button class="btn btn-primary" type="button" (click)="addUser()">
            <span class="px-3 mx-3">Add User</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row p-3">
    <div class="col-12">
      <app-custom-table [data]="usersTableData" [columns]="usersTableColumns" [rows]="10" [showPaginator]="true"
        [showSummary]="true" [useLazyLoad]="true" (onLoadData)="loadUserData($event)" [isLoading]="usersTableLoading"
        [totalRecords]="usersTableTotalRecords" (onRowTableClicked)="onUserTableRowClick($event)">
      </app-custom-table>
    </div>
  </div>

  <p-sidebar [(visible)]="editUserViewDisplay" position="right" [style]="{ width: '60%' }" [showCloseIcon]="false">
    <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
      <div class="row p-1">
        <div class="col-12 d-flex justify-content-between">
          <h2 class="text-header">New User</h2>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label font-weight-bold">User Type<span class="text-danger">*</span></label>
            <p-dropdown [options]="userTypeOptions" class="form-control no-border" [(ngModel)]="userTypeSelected">
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-6">
          <div class="form-group">
            <label class="form-label font-weight-bold">First Name<span class="text-danger">*</span></label>
            <input type="text" class="form-control" [(ngModel)]="editContactModel.FirstName" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="form-label font-weight-bold">Last Name<span class="text-danger">*</span></label>
            <input type="text" class="form-control" [(ngModel)]="editContactModel.LastName" />
          </div>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label font-weight-bold">Title<span class="text-danger">*</span></label>
            <input type="text" class="form-control" [(ngModel)]="editContactModel.Title" />
          </div>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label font-weight-bold">Email Address<span class="text-danger">*</span></label>
            <input type="text" class="form-control" [(ngModel)]="editContactModel.Email" />
          </div>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12 d-flex justify-content-start buttons-contact">
          <button class="btn btn-primary mr-2" type="button" (click)="saveUser()" [disabled]="!isValidContact()">
            Save User
          </button>
          <button class="btn btn-outline-secondary mx-2" type="button" (click)="cancelUser()">
            Cancel
          </button>
        </div>
      </div>
    </p-scrollPanel>
  </p-sidebar>
</div>