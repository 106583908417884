
import { ListItemModel } from "./models/models";
import { BaseEditComponent } from "./BaseEditComponent";
import { Directive } from "@angular/core";

@Directive()
export class BaseCheckBoxListComponent extends BaseEditComponent {
  constructor() {
    super();

  }

  protected baseNgOnInit() {
    super.baseNgOnInit();
  }

  public model: Array<ListItemModel> = <Array<ListItemModel>>[];

  protected getDataComplete() {

  }

  public loadOptions() {
    console.log('loadOptions');
    var me = this;
    window.setTimeout(function () {
      console.log(!me.id);
      if (!me.id) {
        me.getData();

      }

    }, 100);

  }

}
