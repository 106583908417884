import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


//BeginWriteOnceImports
import { AccreditationListComponent } from './entities/accreditation/accreditationList.component';
import { AccreditationEditComponent } from './entities/accreditation/accreditationEdit.component';
import { PreAuthorizationStatusTypeListComponent } from './entities/preAuthorizationStatusType/preAuthorizationStatusTypeList.component';
import { PreAuthorizationStatusTypeEditComponent } from './entities/preAuthorizationStatusType/preAuthorizationStatusTypeEdit.component';
import { SchoolEligibilitiesStateLookUpListComponent } from './entities/schoolEligibilitiesStateLookUp/schoolEligibilitiesStateLookUpList.component';
import { SchoolEligibilitiesStateLookUpEditComponent } from './entities/schoolEligibilitiesStateLookUp/schoolEligibilitiesStateLookUpEdit.component';
import { PrepaidCollegeTransferStatusTypeListComponent } from './entities/prepaidCollegeTransferStatusType/prepaidCollegeTransferStatusTypeList.component';
import { PrepaidCollegeTransferStatusTypeEditComponent } from './entities/prepaidCollegeTransferStatusType/prepaidCollegeTransferStatusTypeEdit.component';
import { ScholarshipPaymentsDeadlineListComponent } from './entities/scholarshipPaymentsDeadline/scholarshipPaymentsDeadlineList.component';
import { ScholarshipPaymentsDeadlineEditComponent } from './entities/scholarshipPaymentsDeadline/scholarshipPaymentsDeadlineEdit.component';
import { ScholarshipTypeListComponent } from './entities/scholarshipType/scholarshipTypeList.component';
import { ScholarshipTypeEditComponent } from './entities/scholarshipType/scholarshipTypeEdit.component';
import { SchoolCommitmentStatusTypeListComponent } from './entities/schoolCommitmentStatusType/schoolCommitmentStatusTypeList.component';
import { SchoolCommitmentStatusTypeEditComponent } from './entities/schoolCommitmentStatusType/schoolCommitmentStatusTypeEdit.component';
import { FeeTypeListComponent } from './entities/feeType/feeTypeList.component';
import { FeeTypeEditComponent } from './entities/feeType/feeTypeEdit.component';
import { TransactionTypeListComponent } from './entities/transactionType/transactionTypeList.component';
import { TransactionTypeEditComponent } from './entities/transactionType/transactionTypeEdit.component';
import { WithdrawReasonTypeListComponent } from './entities/withdrawReasonType/withdrawReasonTypeList.component';
import { WithdrawReasonTypeEditComponent } from './entities/withdrawReasonType/withdrawReasonTypeEdit.component';
import { TransactionStatusTypeListComponent } from './entities/transactionStatusType/transactionStatusTypeList.component';
import { TransactionStatusTypeEditComponent } from './entities/transactionStatusType/transactionStatusTypeEdit.component';
import { VerificationStatusTypeListComponent } from './entities/verificationStatusType/verificationStatusTypeList.component';
import { VerificationStatusTypeEditComponent } from './entities/verificationStatusType/verificationStatusTypeEdit.component';
import { GenderTypeListComponent } from './entities/genderType/genderTypeList.component';
import { GenderTypeEditComponent } from './entities/genderType/genderTypeEdit.component';
import { LoginListComponent } from './entities/login/loginList.component';
import { LoginEditComponent } from './entities/login/loginEdit.component';
import { AmazonRequestListComponent } from './entities/amazonRequest/amazonRequestList.component';
import { AmazonRequestEditComponent } from './entities/amazonRequest/amazonRequestEdit.component';
import { SchoolTransactionListComponent } from './entities/schoolTransaction/schoolTransactionList.component';
import { SchoolTransactionEditComponent } from './entities/schoolTransaction/schoolTransactionEdit.component';
import { HouseholdListComponent } from './entities/household/householdList.component';
import { HouseholdEditComponent } from './entities/household/householdEdit.component';
import { RaceTypeListComponent } from './entities/raceType/raceTypeList.component';
import { RaceTypeEditComponent } from './entities/raceType/raceTypeEdit.component';
import { GradingPeriodTypeListComponent } from './entities/gradingPeriodType/gradingPeriodTypeList.component';
import { GradingPeriodTypeEditComponent } from './entities/gradingPeriodType/gradingPeriodTypeEdit.component';
import { VendorTransactionListComponent } from './entities/vendorTransaction/vendorTransactionList.component';
import { VendorTransactionEditComponent } from './entities/vendorTransaction/vendorTransactionEdit.component';
import { SchoolCommitmentListComponent } from './entities/schoolCommitment/schoolCommitmentList.component';
import { SchoolCommitmentEditComponent } from './entities/schoolCommitment/schoolCommitmentEdit.component';
import { GuardianListComponent } from './entities/guardian/guardianList.component';
import { GuardianEditComponent } from './entities/guardian/guardianEdit.component';
import { AwardDeterminationStatusTypeListComponent } from './entities/awardDeterminationStatusType/awardDeterminationStatusTypeList.component';
import { AwardDeterminationStatusTypeEditComponent } from './entities/awardDeterminationStatusType/awardDeterminationStatusTypeEdit.component';
import { SchoolCommitmentFeesLookUpListComponent } from './entities/schoolCommitmentFeesLookUp/schoolCommitmentFeesLookUpList.component';
import { SchoolCommitmentFeesLookUpEditComponent } from './entities/schoolCommitmentFeesLookUp/schoolCommitmentFeesLookUpEdit.component';
import { AwardDeterminationListComponent } from './entities/awardDetermination/awardDeterminationList.component';
import { AwardDeterminationEditComponent } from './entities/awardDetermination/awardDeterminationEdit.component';
import { RoleListComponent } from './entities/role/roleList.component';
import { RoleEditComponent } from './entities/role/roleEdit.component';
import { FeeDiscountListComponent } from './entities/feeDiscount/feeDiscountList.component';
import { FeeDiscountEditComponent } from './entities/feeDiscount/feeDiscountEdit.component';
import { StudentListComponent } from './entities/student/studentList.component';
import { StudentEditComponent } from './entities/student/studentEdit.component';
import { VendorServiceProviderListComponent } from './entities/vendorServiceProvider/vendorServiceProviderList.component';
import { VendorServiceProviderEditComponent } from './entities/vendorServiceProvider/vendorServiceProviderEdit.component';
import { ZengineMappingOptionListComponent } from './entities/zengineMappingOption/zengineMappingOptionList.component';
import { ZengineMappingOptionEditComponent } from './entities/zengineMappingOption/zengineMappingOptionEdit.component';
import { VerificationListComponent } from './entities/verification/verificationList.component';
import { VerificationEditComponent } from './entities/verification/verificationEdit.component';
import { StudentGradeLevelsSchoolYearsLookUpListComponent } from './entities/studentGradeLevelsSchoolYearsLookUp/studentGradeLevelsSchoolYearsLookUpList.component';
import { StudentGradeLevelsSchoolYearsLookUpEditComponent } from './entities/studentGradeLevelsSchoolYearsLookUp/studentGradeLevelsSchoolYearsLookUpEdit.component';
import { ZengineMappingLookUpListComponent } from './entities/zengineMappingLookUp/zengineMappingLookUpList.component';
import { ZengineMappingLookUpEditComponent } from './entities/zengineMappingLookUp/zengineMappingLookUpEdit.component';
import { LoginRolesLookUpListComponent } from './entities/loginRolesLookUp/loginRolesLookUpList.component';
import { LoginRolesLookUpEditComponent } from './entities/loginRolesLookUp/loginRolesLookUpEdit.component';
import { AwardAcceptanceStatusLookUpListComponent } from './entities/awardAcceptanceStatusLookUp/awardAcceptanceStatusLookUpList.component';
import { AwardAcceptanceStatusLookUpEditComponent } from './entities/awardAcceptanceStatusLookUp/awardAcceptanceStatusLookUpEdit.component';
import { WithdrawListComponent } from './entities/withdraw/withdrawList.component';
import { WithdrawEditComponent } from './entities/withdraw/withdrawEdit.component';
import { ZengineFormListComponent } from './entities/zengineForm/zengineFormList.component';
import { ZengineFormEditComponent } from './entities/zengineForm/zengineFormEdit.component';
import { SchoolCalendarLookUpListComponent } from './entities/schoolCalendarLookUp/schoolCalendarLookUpList.component';
import { SchoolCalendarLookUpEditComponent } from './entities/schoolCalendarLookUp/schoolCalendarLookUpEdit.component';
import { VendorCategoriesLookUpListComponent } from './entities/vendorCategoriesLookUp/vendorCategoriesLookUpList.component';
import { VendorCategoriesLookUpEditComponent } from './entities/vendorCategoriesLookUp/vendorCategoriesLookUpEdit.component';
import { PreAuthorizationListComponent } from './entities/preAuthorization/preAuthorizationList.component';
import { PreAuthorizationEditComponent } from './entities/preAuthorization/preAuthorizationEdit.component';
import { AwardAcceptanceStatusTypeListComponent } from './entities/awardAcceptanceStatusType/awardAcceptanceStatusTypeList.component';
import { AwardAcceptanceStatusTypeEditComponent } from './entities/awardAcceptanceStatusType/awardAcceptanceStatusTypeEdit.component';
import { ZengineFormStateLookUpListComponent } from './entities/zengineFormStateLookUp/zengineFormStateLookUpList.component';
import { ZengineFormStateLookUpEditComponent } from './entities/zengineFormStateLookUp/zengineFormStateLookUpEdit.component';
import { ZengineFormTypeListComponent } from './entities/zengineFormType/zengineFormTypeList.component';
import { ZengineFormTypeEditComponent } from './entities/zengineFormType/zengineFormTypeEdit.component';
import { ScholarshipDisbursementCategoriesLookUpListComponent } from './entities/scholarshipDisbursementCategoriesLookUp/scholarshipDisbursementCategoriesLookUpList.component';
import { ScholarshipDisbursementCategoriesLookUpEditComponent } from './entities/scholarshipDisbursementCategoriesLookUp/scholarshipDisbursementCategoriesLookUpEdit.component';
import { ContactsLookUpListComponent } from './entities/contactsLookUp/contactsLookUpList.component';
import { ContactsLookUpEditComponent } from './entities/contactsLookUp/contactsLookUpEdit.component';
import { StateListComponent } from './entities/state/stateList.component';
import { StateEditComponent } from './entities/state/stateEdit.component';
import { GuardianReimbursementListItemListComponent } from './entities/guardianReimbursementListItem/guardianReimbursementListItemList.component';
import { GuardianReimbursementListItemEditComponent } from './entities/guardianReimbursementListItem/guardianReimbursementListItemEdit.component';
import { SchoolEligibilitiesResponsListComponent } from './entities/schoolEligibilitiesRespons/schoolEligibilitiesResponsList.component';
import { SchoolEligibilitiesResponsEditComponent } from './entities/schoolEligibilitiesRespons/schoolEligibilitiesResponsEdit.component';
import { SchoolTypeListComponent } from './entities/schoolType/schoolTypeList.component';
import { SchoolTypeEditComponent } from './entities/schoolType/schoolTypeEdit.component';
import { SchoolFeeListComponent } from './entities/schoolFee/schoolFeeList.component';
import { SchoolFeeEditComponent } from './entities/schoolFee/schoolFeeEdit.component';
import { SchoolAccreditationsLookUpListComponent } from './entities/schoolAccreditationsLookUp/schoolAccreditationsLookUpList.component';
import { SchoolAccreditationsLookUpEditComponent } from './entities/schoolAccreditationsLookUp/schoolAccreditationsLookUpEdit.component';
import { AccreditationsLookUpListComponent } from './entities/accreditationsLookUp/accreditationsLookUpList.component';
import { AccreditationsLookUpEditComponent } from './entities/accreditationsLookUp/accreditationsLookUpEdit.component';
import { AwardDeterminationStatusLookUpListComponent } from './entities/awardDeterminationStatusLookUp/awardDeterminationStatusLookUpList.component';
import { AwardDeterminationStatusLookUpEditComponent } from './entities/awardDeterminationStatusLookUp/awardDeterminationStatusLookUpEdit.component';
import { GuardianReimbursementListComponent } from './entities/guardianReimbursement/guardianReimbursementList.component';
import { GuardianReimbursementEditComponent } from './entities/guardianReimbursement/guardianReimbursementEdit.component';
import { SchoolListComponent } from './entities/school/schoolList.component';
import { SchoolEditComponent } from './entities/school/schoolEdit.component';
import { SchoolCommitmentNoteListComponent } from './entities/schoolCommitmentNote/schoolCommitmentNoteList.component';
import { SchoolCommitmentNoteEditComponent } from './entities/schoolCommitmentNote/schoolCommitmentNoteEdit.component';
import { PrepaidCollegeTransferListComponent } from './entities/prepaidCollegeTransfer/prepaidCollegeTransferList.component';
import { PrepaidCollegeTransferEditComponent } from './entities/prepaidCollegeTransfer/prepaidCollegeTransferEdit.component';
import { SchoolEligibilityListComponent } from './entities/schoolEligibility/schoolEligibilityList.component';
import { SchoolEligibilityEditComponent } from './entities/schoolEligibility/schoolEligibilityEdit.component';
import { StudentNoteListComponent } from './entities/studentNote/studentNoteList.component';
import { StudentNoteEditComponent } from './entities/studentNote/studentNoteEdit.component';
import { ZengineFormFieldListComponent } from './entities/zengineFormField/zengineFormFieldList.component';
import { ZengineFormFieldEditComponent } from './entities/zengineFormField/zengineFormFieldEdit.component';
import { SchoolScholarshipsLookUpListComponent } from './entities/schoolScholarshipsLookUp/schoolScholarshipsLookUpList.component';
import { SchoolScholarshipsLookUpEditComponent } from './entities/schoolScholarshipsLookUp/schoolScholarshipsLookUpEdit.component';
import { SchoolTransactionNoteListComponent } from './entities/schoolTransactionNote/schoolTransactionNoteList.component';
import { SchoolTransactionNoteEditComponent } from './entities/schoolTransactionNote/schoolTransactionNoteEdit.component';
import { GradeLevelListComponent } from './entities/gradeLevel/gradeLevelList.component';
import { GradeLevelEditComponent } from './entities/gradeLevel/gradeLevelEdit.component';
import { VendorListComponent } from './entities/vendor/vendorList.component';
import { VendorEditComponent } from './entities/vendor/vendorEdit.component';
import { StudentDisabilityLookUpListComponent } from './entities/studentDisabilityLookUp/studentDisabilityLookUpList.component';
import { StudentDisabilityLookUpEditComponent } from './entities/studentDisabilityLookUp/studentDisabilityLookUpEdit.component';
import { ScholarshipHandbookListComponent } from './entities/scholarshipHandbook/scholarshipHandbookList.component';
import { VerificationNoteListComponent } from './entities/verificationNote/verificationNoteList.component';
import { VerificationNoteEditComponent } from './entities/verificationNote/verificationNoteEdit.component';
import { VendorTransactionNoteListComponent } from './entities/vendorTransactionNote/vendorTransactionNoteList.component';
import { VendorTransactionNoteEditComponent } from './entities/vendorTransactionNote/vendorTransactionNoteEdit.component';
import { NoteTypeListComponent } from './entities/noteType/noteTypeList.component';
import { NoteTypeEditComponent } from './entities/noteType/noteTypeEdit.component';
import { SchoolGradeLevelsLookUpListComponent } from './entities/schoolGradeLevelsLookUp/schoolGradeLevelsLookUpList.component';
import { SchoolGradeLevelsLookUpEditComponent } from './entities/schoolGradeLevelsLookUp/schoolGradeLevelsLookUpEdit.component';
import { WithdrawNoteListComponent } from './entities/withdrawNote/withdrawNoteList.component';
import { WithdrawNoteEditComponent } from './entities/withdrawNote/withdrawNoteEdit.component';
import { DocumentListComponent } from './entities/document/documentList.component';
import { DocumentEditComponent } from './entities/document/documentEdit.component';
import { ZengineFormYearLookUpListComponent } from './entities/zengineFormYearLookUp/zengineFormYearLookUpList.component';
import { ZengineFormYearLookUpEditComponent } from './entities/zengineFormYearLookUp/zengineFormYearLookUpEdit.component';
import { DisabilityListComponent } from './entities/disability/disabilityList.component';
import { DisabilityEditComponent } from './entities/disability/disabilityEdit.component';
import { CountyListComponent } from './entities/county/countyList.component';
import { CountyEditComponent } from './entities/county/countyEdit.component';
import { AccreditationTypeListComponent } from './entities/accreditationType/accreditationTypeList.component';
import { AccreditationTypeEditComponent } from './entities/accreditationType/accreditationTypeEdit.component';
import { ScholarshipListComponent } from './entities/scholarship/scholarshipList.component';
import { ScholarshipEditComponent } from './entities/scholarship/scholarshipEdit.component';
import { ScholarshipDisbursementCategoryListComponent } from './entities/scholarshipDisbursementCategory/scholarshipDisbursementCategoryList.component';
import { ScholarshipDisbursementCategoryEditComponent } from './entities/scholarshipDisbursementCategory/scholarshipDisbursementCategoryEdit.component';
import { SchoolYearListComponent } from './entities/schoolYear/schoolYearList.component';
import { SchoolYearEditComponent } from './entities/schoolYear/schoolYearEdit.component';
import { GuardianReimbursementStatusTypeListComponent } from './entities/guardianReimbursementStatusType/guardianReimbursementStatusTypeList.component';
import { GuardianReimbursementStatusTypeEditComponent } from './entities/guardianReimbursementStatusType/guardianReimbursementStatusTypeEdit.component';
import { GuardianTypeListComponent } from './entities/guardianType/guardianTypeList.component';
import { GuardianTypeEditComponent } from './entities/guardianType/guardianTypeEdit.component';
//EndWriteOnceImports

import { ZengineLoginListComponent } from './entities/zengineLogin/zengineLoginList.component';
import { ZengineLoginEditComponent } from './entities/zengineLogin/zengineLoginEdit.component';
import { AdminNavComponent } from './admin-nav/admin-nav.component';
import { HomeComponent } from './home/home.component';
import { DoUserLoginComponent } from './user-login/do-user-login.component';
import { UserLoginEditComponent } from './user-login/user-login-Edit.component';
import { UserLoginListComponent } from './user-login/user-login-List.component';
import { DoResetPasswordComponent } from './reset-password/do-reset-password.component';
import { FirstTimeLoginComponent } from './user-login/first-time-login.component';
import { ScholarshipManagerComponent } from './student/scholarship-manager/scholarship-manager.component';
import { SyncComponent } from './zengine/sync/sync.component';

import { SchoolProfilePageComponent } from './pages/school/school-profile-page/school-profile-page.component';
import { SchoolDashboardPageComponent } from './pages/school/school-dashboard-page/school-dashboard-page.component';
import { FamilyProfilePageComponent } from './pages/family/family-profile-page/family-profile-page.component';
import { FamilyDashboardPageComponent } from './pages/family/family-dashboard-page/family-dashboard-page.component';
import { FamilyAwardStatusPageComponent } from './pages/family/family-award-status-page/family-award-status-page.component';
import { StudentProfilePageComponent } from './pages/family/student-profile/student-profile-page.component';
import { VendorDashboardPageComponent } from './pages/vendor/vendor-dashboard-page/vendor-dashboard-page.component';
import { VendorProfilePageComponent } from './pages/vendor/vendor-profile-page/vendor-profile-page.component';
import { AdminDashboardPageComponent } from './pages/admin/admin-dashboard-page/admin-dashboard-page.component';
import { AdminScholarshipSettingsPageComponent } from './pages/admin/admin-scholarship-settings/admin-scholarship-settings-page.component';
import { AdminScholarshipPageComponent } from './pages/admin/admin-scholarship/admin-scholarship-page.component';
import { AdminAllSchoolsComponent } from './pages/admin/admin-allschools/admin-allschools.component';
import { AdminSchoolProfilePageComponent } from './pages/admin/admin-school-profile-page/admin-school-profile-page.component';
import { AdminAllstudentsComponent } from './pages/admin/admin-allstudents/admin-allstudents.component';
import { AdminUsersPageComponent } from './pages/admin/admin-users-page/admin-users-page.component';
import { AdminUserProfilePageComponent } from './pages/admin/admin-user-profile-page/admin-user-profile-page.component';
import { AdminVendorsPageComponent } from './pages/admin/admin-vendors-page/admin-vendors-page.component';
import { AdminVendorProfilePageComponent } from './pages/admin/admin-vendor-profile-page/admin-vendor-profile-page.component';

const routes: Routes = [

	{ path: 'angular/index', redirectTo: 'userlogin', pathMatch: 'full' },
	{ path: '', redirectTo: 'userlogin', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'admin', component: AdminNavComponent },
  { path: 'userlogin', component: DoUserLoginComponent },
  { path: 'userloginedit', component: UserLoginEditComponent },
  { path: 'userloginedit/:id', component: UserLoginEditComponent },
  { path: 'userloginlist', component: UserLoginListComponent },
  { path: 'doresetpassword/:id', component: DoResetPasswordComponent },
  { path: 'firsttimelogin/:id', component: FirstTimeLoginComponent },
  { path: 'studentscholarshipmanager', component: ScholarshipManagerComponent },
  { path: 'studentscholarshipmanager/:id', component: ScholarshipManagerComponent },
	{ path: 'schoolprofile', component: SchoolProfilePageComponent },
	{ path: 'schooldashboard', component: SchoolDashboardPageComponent },
	{ path: 'familyprofile', component: FamilyProfilePageComponent },
	{ path: 'familydashboard', component: FamilyDashboardPageComponent },
	{ path: 'familyawardstatus', component: FamilyAwardStatusPageComponent },
	{ path: 'studentprofile/:id', component: StudentProfilePageComponent },
	{ path: 'vendordashboard', component: VendorDashboardPageComponent },
	{ path: 'vendorprofile', component: VendorProfilePageComponent },
	{ path: 'admindashboard', component: AdminDashboardPageComponent },
	{ path: 'adminscholarshipsettings', component: AdminScholarshipSettingsPageComponent },
	{ path: 'scholarship/:id', component: AdminScholarshipPageComponent },
	{ path: 'adminallschools', component: AdminAllSchoolsComponent },
	{ path: 'adminallstudents', component: AdminAllstudentsComponent },
	{ path: 'adminschoolprofile/:id', component: AdminSchoolProfilePageComponent },
	{ path: 'adminallusers', component: AdminUsersPageComponent },
	{ path: 'adminuserprofile/:id', component: AdminUserProfilePageComponent },
	{ path: 'adminallvendors', component: AdminVendorsPageComponent },
	{ path: 'adminvendorprofile/:id', component: AdminVendorProfilePageComponent },
  { path: 'zenginesync', component: SyncComponent },
  { path: 'zengineloginlist', component: ZengineLoginListComponent },
  { path: 'zengineloginedit/:id', component: ZengineLoginEditComponent },
  { path: 'zengineloginedit', component: ZengineLoginEditComponent },

  //BeginWriteOnceRoutes
		{ path: 'accreditationlist', component: AccreditationListComponent },
		{ path: 'accreditationedit/:id', component: AccreditationEditComponent },
		{ path: 'accreditationedit', component: AccreditationEditComponent },
		{ path: 'preauthorizationstatustypelist', component: PreAuthorizationStatusTypeListComponent },
		{ path: 'preauthorizationstatustypeedit/:id', component: PreAuthorizationStatusTypeEditComponent },
		{ path: 'preauthorizationstatustypeedit', component: PreAuthorizationStatusTypeEditComponent },
		{ path: 'schooleligibilitiesstatelookuplist', component: SchoolEligibilitiesStateLookUpListComponent },
		{ path: 'schooleligibilitiesstatelookupedit/:id', component: SchoolEligibilitiesStateLookUpEditComponent },
		{ path: 'schooleligibilitiesstatelookupedit', component: SchoolEligibilitiesStateLookUpEditComponent },
		{ path: 'prepaidcollegetransferstatustypelist', component: PrepaidCollegeTransferStatusTypeListComponent },
		{ path: 'prepaidcollegetransferstatustypeedit/:id', component: PrepaidCollegeTransferStatusTypeEditComponent },
		{ path: 'prepaidcollegetransferstatustypeedit', component: PrepaidCollegeTransferStatusTypeEditComponent },
		{ path: 'scholarshippaymentsdeadlinelist', component: ScholarshipPaymentsDeadlineListComponent },
		{ path: 'scholarshippaymentsdeadlineedit/:id', component: ScholarshipPaymentsDeadlineEditComponent },
		{ path: 'scholarshippaymentsdeadlineedit', component: ScholarshipPaymentsDeadlineEditComponent },
		{ path: 'scholarshiptypelist', component: ScholarshipTypeListComponent },
		{ path: 'scholarshiptypeedit/:id', component: ScholarshipTypeEditComponent },
		{ path: 'scholarshiptypeedit', component: ScholarshipTypeEditComponent },
		{ path: 'schoolcommitmentstatustypelist', component: SchoolCommitmentStatusTypeListComponent },
		{ path: 'schoolcommitmentstatustypeedit/:id', component: SchoolCommitmentStatusTypeEditComponent },
		{ path: 'schoolcommitmentstatustypeedit', component: SchoolCommitmentStatusTypeEditComponent },
		{ path: 'feetypelist', component: FeeTypeListComponent },
		{ path: 'feetypeedit/:id', component: FeeTypeEditComponent },
		{ path: 'feetypeedit', component: FeeTypeEditComponent },
		{ path: 'transactiontypelist', component: TransactionTypeListComponent },
		{ path: 'transactiontypeedit/:id', component: TransactionTypeEditComponent },
		{ path: 'transactiontypeedit', component: TransactionTypeEditComponent },
		{ path: 'withdrawreasontypelist', component: WithdrawReasonTypeListComponent },
		{ path: 'withdrawreasontypeedit/:id', component: WithdrawReasonTypeEditComponent },
		{ path: 'withdrawreasontypeedit', component: WithdrawReasonTypeEditComponent },
		{ path: 'transactionstatustypelist', component: TransactionStatusTypeListComponent },
		{ path: 'transactionstatustypeedit/:id', component: TransactionStatusTypeEditComponent },
		{ path: 'transactionstatustypeedit', component: TransactionStatusTypeEditComponent },
		{ path: 'verificationstatustypelist', component: VerificationStatusTypeListComponent },
		{ path: 'verificationstatustypeedit/:id', component: VerificationStatusTypeEditComponent },
		{ path: 'verificationstatustypeedit', component: VerificationStatusTypeEditComponent },
		{ path: 'gendertypelist', component: GenderTypeListComponent },
		{ path: 'gendertypeedit/:id', component: GenderTypeEditComponent },
		{ path: 'gendertypeedit', component: GenderTypeEditComponent },
		{ path: 'loginlist', component: LoginListComponent },
		{ path: 'loginedit/:id', component: LoginEditComponent },
		{ path: 'loginedit', component: LoginEditComponent },
		{ path: 'amazonrequestlist', component: AmazonRequestListComponent },
		{ path: 'amazonrequestedit/:id', component: AmazonRequestEditComponent },
		{ path: 'amazonrequestedit', component: AmazonRequestEditComponent },
		{ path: 'schooltransactionlist', component: SchoolTransactionListComponent },
		{ path: 'schooltransactionedit/:id', component: SchoolTransactionEditComponent },
		{ path: 'schooltransactionedit', component: SchoolTransactionEditComponent },
		{ path: 'householdlist', component: HouseholdListComponent },
		{ path: 'householdedit/:id', component: HouseholdEditComponent },
		{ path: 'householdedit', component: HouseholdEditComponent },
		{ path: 'racetypelist', component: RaceTypeListComponent },
		{ path: 'racetypeedit/:id', component: RaceTypeEditComponent },
		{ path: 'racetypeedit', component: RaceTypeEditComponent },
		{ path: 'gradingperiodtypelist', component: GradingPeriodTypeListComponent },
		{ path: 'gradingperiodtypeedit/:id', component: GradingPeriodTypeEditComponent },
		{ path: 'gradingperiodtypeedit', component: GradingPeriodTypeEditComponent },
		{ path: 'vendortransactionlist', component: VendorTransactionListComponent },
		{ path: 'vendortransactionedit/:id', component: VendorTransactionEditComponent },
		{ path: 'vendortransactionedit', component: VendorTransactionEditComponent },
		{ path: 'schoolcommitmentlist', component: SchoolCommitmentListComponent },
		{ path: 'schoolcommitmentedit/:id', component: SchoolCommitmentEditComponent },
		{ path: 'schoolcommitmentedit', component: SchoolCommitmentEditComponent },
		{ path: 'guardianlist', component: GuardianListComponent },
		{ path: 'guardianedit/:id', component: GuardianEditComponent },
		{ path: 'guardianedit', component: GuardianEditComponent },
		{ path: 'awarddeterminationstatustypelist', component: AwardDeterminationStatusTypeListComponent },
		{ path: 'awarddeterminationstatustypeedit/:id', component: AwardDeterminationStatusTypeEditComponent },
		{ path: 'awarddeterminationstatustypeedit', component: AwardDeterminationStatusTypeEditComponent },
		{ path: 'schoolcommitmentfeeslookuplist', component: SchoolCommitmentFeesLookUpListComponent },
		{ path: 'schoolcommitmentfeeslookupedit/:id', component: SchoolCommitmentFeesLookUpEditComponent },
		{ path: 'schoolcommitmentfeeslookupedit', component: SchoolCommitmentFeesLookUpEditComponent },
		{ path: 'awarddeterminationlist', component: AwardDeterminationListComponent },
		{ path: 'awarddeterminationedit/:id', component: AwardDeterminationEditComponent },
		{ path: 'awarddeterminationedit', component: AwardDeterminationEditComponent },
		{ path: 'rolelist', component: RoleListComponent },
		{ path: 'roleedit/:id', component: RoleEditComponent },
		{ path: 'roleedit', component: RoleEditComponent },
		{ path: 'feediscountlist', component: FeeDiscountListComponent },
		{ path: 'feediscountedit/:id', component: FeeDiscountEditComponent },
		{ path: 'feediscountedit', component: FeeDiscountEditComponent },
		{ path: 'studentlist', component: StudentListComponent },
		{ path: 'studentedit/:id', component: StudentEditComponent },
		{ path: 'studentedit', component: StudentEditComponent },
		{ path: 'vendorserviceproviderlist', component: VendorServiceProviderListComponent },
		{ path: 'vendorserviceprovideredit/:id', component: VendorServiceProviderEditComponent },
		{ path: 'vendorserviceprovideredit', component: VendorServiceProviderEditComponent },
		{ path: 'zenginemappingoptionlist', component: ZengineMappingOptionListComponent },
		{ path: 'zenginemappingoptionedit/:id', component: ZengineMappingOptionEditComponent },
		{ path: 'zenginemappingoptionedit', component: ZengineMappingOptionEditComponent },
		{ path: 'verificationlist', component: VerificationListComponent },
		{ path: 'verificationedit/:id', component: VerificationEditComponent },
		{ path: 'verificationedit', component: VerificationEditComponent },
		{ path: 'studentgradelevelsschoolyearslookuplist', component: StudentGradeLevelsSchoolYearsLookUpListComponent },
		{ path: 'studentgradelevelsschoolyearslookupedit/:id', component: StudentGradeLevelsSchoolYearsLookUpEditComponent },
		{ path: 'studentgradelevelsschoolyearslookupedit', component: StudentGradeLevelsSchoolYearsLookUpEditComponent },
		{ path: 'zenginemappinglookuplist', component: ZengineMappingLookUpListComponent },
		{ path: 'zenginemappinglookupedit/:id', component: ZengineMappingLookUpEditComponent },
		{ path: 'zenginemappinglookupedit', component: ZengineMappingLookUpEditComponent },
		{ path: 'loginroleslookuplist', component: LoginRolesLookUpListComponent },
		{ path: 'loginroleslookupedit/:id', component: LoginRolesLookUpEditComponent },
		{ path: 'loginroleslookupedit', component: LoginRolesLookUpEditComponent },
		{ path: 'awardacceptancestatuslookuplist', component: AwardAcceptanceStatusLookUpListComponent },
		{ path: 'awardacceptancestatuslookupedit/:id', component: AwardAcceptanceStatusLookUpEditComponent },
		{ path: 'awardacceptancestatuslookupedit', component: AwardAcceptanceStatusLookUpEditComponent },
		{ path: 'withdrawlist', component: WithdrawListComponent },
		{ path: 'withdrawedit/:id', component: WithdrawEditComponent },
		{ path: 'withdrawedit', component: WithdrawEditComponent },
		{ path: 'zengineformlist', component: ZengineFormListComponent },
		{ path: 'zengineformedit/:id', component: ZengineFormEditComponent },
		{ path: 'zengineformedit', component: ZengineFormEditComponent },
		{ path: 'schoolcalendarlookuplist', component: SchoolCalendarLookUpListComponent },
		{ path: 'schoolcalendarlookupedit/:id', component: SchoolCalendarLookUpEditComponent },
		{ path: 'schoolcalendarlookupedit', component: SchoolCalendarLookUpEditComponent },
		{ path: 'vendorcategorieslookuplist', component: VendorCategoriesLookUpListComponent },
		{ path: 'vendorcategorieslookupedit/:id', component: VendorCategoriesLookUpEditComponent },
		{ path: 'vendorcategorieslookupedit', component: VendorCategoriesLookUpEditComponent },
		{ path: 'preauthorizationlist', component: PreAuthorizationListComponent },
		{ path: 'preauthorizationedit/:id', component: PreAuthorizationEditComponent },
		{ path: 'preauthorizationedit', component: PreAuthorizationEditComponent },
		{ path: 'awardacceptancestatustypelist', component: AwardAcceptanceStatusTypeListComponent },
		{ path: 'awardacceptancestatustypeedit/:id', component: AwardAcceptanceStatusTypeEditComponent },
		{ path: 'awardacceptancestatustypeedit', component: AwardAcceptanceStatusTypeEditComponent },
		{ path: 'zengineformstatelookuplist', component: ZengineFormStateLookUpListComponent },
		{ path: 'zengineformstatelookupedit/:id', component: ZengineFormStateLookUpEditComponent },
		{ path: 'zengineformstatelookupedit', component: ZengineFormStateLookUpEditComponent },
		{ path: 'zengineformtypelist', component: ZengineFormTypeListComponent },
		{ path: 'zengineformtypeedit/:id', component: ZengineFormTypeEditComponent },
		{ path: 'zengineformtypeedit', component: ZengineFormTypeEditComponent },
		{ path: 'scholarshipdisbursementcategorieslookuplist', component: ScholarshipDisbursementCategoriesLookUpListComponent },
		{ path: 'scholarshipdisbursementcategorieslookupedit/:id', component: ScholarshipDisbursementCategoriesLookUpEditComponent },
		{ path: 'scholarshipdisbursementcategorieslookupedit', component: ScholarshipDisbursementCategoriesLookUpEditComponent },
		{ path: 'contactslookuplist', component: ContactsLookUpListComponent },
		{ path: 'contactslookupedit/:id', component: ContactsLookUpEditComponent },
		{ path: 'contactslookupedit', component: ContactsLookUpEditComponent },
		{ path: 'statelist', component: StateListComponent },
		{ path: 'stateedit/:id', component: StateEditComponent },
		{ path: 'stateedit', component: StateEditComponent },
		{ path: 'guardianreimbursementlistitemlist', component: GuardianReimbursementListItemListComponent },
		{ path: 'guardianreimbursementlistitemedit/:id', component: GuardianReimbursementListItemEditComponent },
		{ path: 'guardianreimbursementlistitemedit', component: GuardianReimbursementListItemEditComponent },
		{ path: 'schooleligibilitiesresponslist', component: SchoolEligibilitiesResponsListComponent },
		{ path: 'schooleligibilitiesresponsedit/:id', component: SchoolEligibilitiesResponsEditComponent },
		{ path: 'schooleligibilitiesresponsedit', component: SchoolEligibilitiesResponsEditComponent },
		{ path: 'schooltypelist', component: SchoolTypeListComponent },
		{ path: 'schooltypeedit/:id', component: SchoolTypeEditComponent },
		{ path: 'schooltypeedit', component: SchoolTypeEditComponent },
		{ path: 'schoolfeelist', component: SchoolFeeListComponent },
		{ path: 'schoolfeeedit/:id', component: SchoolFeeEditComponent },
		{ path: 'schoolfeeedit', component: SchoolFeeEditComponent },
		{ path: 'schoolaccreditationslookuplist', component: SchoolAccreditationsLookUpListComponent },
		{ path: 'schoolaccreditationslookupedit/:id', component: SchoolAccreditationsLookUpEditComponent },
		{ path: 'schoolaccreditationslookupedit', component: SchoolAccreditationsLookUpEditComponent },
		{ path: 'accreditationslookuplist', component: AccreditationsLookUpListComponent },
		{ path: 'accreditationslookupedit/:id', component: AccreditationsLookUpEditComponent },
		{ path: 'accreditationslookupedit', component: AccreditationsLookUpEditComponent },
		{ path: 'awarddeterminationstatuslookuplist', component: AwardDeterminationStatusLookUpListComponent },
		{ path: 'awarddeterminationstatuslookupedit/:id', component: AwardDeterminationStatusLookUpEditComponent },
		{ path: 'awarddeterminationstatuslookupedit', component: AwardDeterminationStatusLookUpEditComponent },
		{ path: 'guardianreimbursementlist', component: GuardianReimbursementListComponent },
		{ path: 'guardianreimbursementedit/:id', component: GuardianReimbursementEditComponent },
		{ path: 'guardianreimbursementedit', component: GuardianReimbursementEditComponent },
		{ path: 'schoollist', component: SchoolListComponent },
		{ path: 'schooledit/:id', component: SchoolEditComponent },
		{ path: 'schooledit', component: SchoolEditComponent },
		{ path: 'schoolcommitmentnotelist', component: SchoolCommitmentNoteListComponent },
		{ path: 'schoolcommitmentnoteedit/:id', component: SchoolCommitmentNoteEditComponent },
		{ path: 'schoolcommitmentnoteedit', component: SchoolCommitmentNoteEditComponent },
		{ path: 'prepaidcollegetransferlist', component: PrepaidCollegeTransferListComponent },
		{ path: 'prepaidcollegetransferedit/:id', component: PrepaidCollegeTransferEditComponent },
		{ path: 'prepaidcollegetransferedit', component: PrepaidCollegeTransferEditComponent },
		{ path: 'schooleligibilitylist', component: SchoolEligibilityListComponent },
		{ path: 'schooleligibilityedit/:id', component: SchoolEligibilityEditComponent },
		{ path: 'schooleligibilityedit', component: SchoolEligibilityEditComponent },
		{ path: 'studentnotelist', component: StudentNoteListComponent },
		{ path: 'studentnoteedit/:id', component: StudentNoteEditComponent },
		{ path: 'studentnoteedit', component: StudentNoteEditComponent },
		{ path: 'zengineformfieldlist', component: ZengineFormFieldListComponent },
		{ path: 'zengineformfieldedit/:id', component: ZengineFormFieldEditComponent },
		{ path: 'zengineformfieldedit', component: ZengineFormFieldEditComponent },
		{ path: 'schoolscholarshipslookuplist', component: SchoolScholarshipsLookUpListComponent },
		{ path: 'schoolscholarshipslookupedit/:id', component: SchoolScholarshipsLookUpEditComponent },
		{ path: 'schoolscholarshipslookupedit', component: SchoolScholarshipsLookUpEditComponent },
		{ path: 'schooltransactionnotelist', component: SchoolTransactionNoteListComponent },
		{ path: 'schooltransactionnoteedit/:id', component: SchoolTransactionNoteEditComponent },
		{ path: 'schooltransactionnoteedit', component: SchoolTransactionNoteEditComponent },
		{ path: 'gradelevellist', component: GradeLevelListComponent },
		{ path: 'gradeleveledit/:id', component: GradeLevelEditComponent },
		{ path: 'gradeleveledit', component: GradeLevelEditComponent },
		{ path: 'vendorlist', component: VendorListComponent },
		{ path: 'vendoredit/:id', component: VendorEditComponent },
		{ path: 'vendoredit', component: VendorEditComponent },
		{ path: 'studentdisabilitylookuplist', component: StudentDisabilityLookUpListComponent },
		{ path: 'studentdisabilitylookupedit/:id', component: StudentDisabilityLookUpEditComponent },
		{ path: 'studentdisabilitylookupedit', component: StudentDisabilityLookUpEditComponent },
		{ path: 'scholarshiphandbooklist', component: ScholarshipHandbookListComponent },
		{ path: 'verificationnotelist', component: VerificationNoteListComponent },
		{ path: 'verificationnoteedit/:id', component: VerificationNoteEditComponent },
		{ path: 'verificationnoteedit', component: VerificationNoteEditComponent },
		{ path: 'vendortransactionnotelist', component: VendorTransactionNoteListComponent },
		{ path: 'vendortransactionnoteedit/:id', component: VendorTransactionNoteEditComponent },
		{ path: 'vendortransactionnoteedit', component: VendorTransactionNoteEditComponent },
		{ path: 'notetypelist', component: NoteTypeListComponent },
		{ path: 'notetypeedit/:id', component: NoteTypeEditComponent },
		{ path: 'notetypeedit', component: NoteTypeEditComponent },
		{ path: 'schoolgradelevelslookuplist', component: SchoolGradeLevelsLookUpListComponent },
		{ path: 'schoolgradelevelslookupedit/:id', component: SchoolGradeLevelsLookUpEditComponent },
		{ path: 'schoolgradelevelslookupedit', component: SchoolGradeLevelsLookUpEditComponent },
		{ path: 'withdrawnotelist', component: WithdrawNoteListComponent },
		{ path: 'withdrawnoteedit/:id', component: WithdrawNoteEditComponent },
		{ path: 'withdrawnoteedit', component: WithdrawNoteEditComponent },
		{ path: 'documentlist', component: DocumentListComponent },
		{ path: 'documentedit/:id', component: DocumentEditComponent },
		{ path: 'documentedit', component: DocumentEditComponent },
		{ path: 'zengineformyearlookuplist', component: ZengineFormYearLookUpListComponent },
		{ path: 'zengineformyearlookupedit/:id', component: ZengineFormYearLookUpEditComponent },
		{ path: 'zengineformyearlookupedit', component: ZengineFormYearLookUpEditComponent },
		{ path: 'disabilitylist', component: DisabilityListComponent },
		{ path: 'disabilityedit/:id', component: DisabilityEditComponent },
		{ path: 'disabilityedit', component: DisabilityEditComponent },
		{ path: 'countylist', component: CountyListComponent },
		{ path: 'countyedit/:id', component: CountyEditComponent },
		{ path: 'countyedit', component: CountyEditComponent },
		{ path: 'accreditationtypelist', component: AccreditationTypeListComponent },
		{ path: 'accreditationtypeedit/:id', component: AccreditationTypeEditComponent },
		{ path: 'accreditationtypeedit', component: AccreditationTypeEditComponent },
		{ path: 'scholarshiplist', component: ScholarshipListComponent },
		{ path: 'scholarshipedit/:id', component: ScholarshipEditComponent },
		{ path: 'scholarshipedit', component: ScholarshipEditComponent },
		{ path: 'scholarshipdisbursementcategorylist', component: ScholarshipDisbursementCategoryListComponent },
		{ path: 'scholarshipdisbursementcategoryedit/:id', component: ScholarshipDisbursementCategoryEditComponent },
		{ path: 'scholarshipdisbursementcategoryedit', component: ScholarshipDisbursementCategoryEditComponent },
		{ path: 'schoolyearlist', component: SchoolYearListComponent },
		{ path: 'schoolyearedit/:id', component: SchoolYearEditComponent },
		{ path: 'schoolyearedit', component: SchoolYearEditComponent },
		{ path: 'guardianreimbursementstatustypelist', component: GuardianReimbursementStatusTypeListComponent },
		{ path: 'guardianreimbursementstatustypeedit/:id', component: GuardianReimbursementStatusTypeEditComponent },
		{ path: 'guardianreimbursementstatustypeedit', component: GuardianReimbursementStatusTypeEditComponent },
		{ path: 'guardiantypelist', component: GuardianTypeListComponent },
		{ path: 'guardiantypeedit/:id', component: GuardianTypeEditComponent },
		{ path: 'guardiantypeedit', component: GuardianTypeEditComponent },
//EndWriteOnceRoutes
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
