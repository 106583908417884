<div>
  <div class="row">
    <div class="col-3">
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 1"></i>
          <span (click)="doChangePage(1)" class="btn font-weight-bold"> Vendor Profile</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 2"></i>
          <span (click)="doChangePage(2)" class="btn font-weight-bold"> Primary Contact</span>
        </div>
      </div>
      <div class="row" *ngIf="isVendorWithGardinerStudents">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 3"></i>
          <span (click)="doChangePage(3)" class="btn font-weight-bold"> Bank Account</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 4"></i>
          <span (click)="doChangePage(4)" class="btn font-weight-bold"> Approved Categories</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 5 && isVendorAdmin()"></i>
          <span (click)="doChangePage(5)" class="btn font-weight-bold"> Providers</span>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div *ngIf="pageNumber == 1" class="form">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2 class="text-header">Vendor Profile</h2>
            <button type="button" class="btn btn-primary" (click)="doVendorEdit()">
              <i class="fas fa-pen"></i>
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <h3 class="text-subtitle">Vendor Information</h3>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <div class="form-group">
              <span class="form-control no-border">{{ model?.Name }}</span>
              <div class="no-padding">
                <span class="form-control no-border">{{ model?.Physical_StreetAddress1 }}</span>
                <span class="form-control no-border" *ngIf="model?.Physical_StreetAddress2">
                  {{ model?.Physical_StreetAddress2 }}
                </span>
                <span class=" form-control no-border">
                  {{ model?.Physical_City }}, {{ model?.FK_State_VendorPhysical_Display }} {{ model?.Physical_Zipcode }}
                </span>
              </div>
              <span class="form-control no-border" *ngIf="model?.Phone">
                {{ model?.Phone }}
              </span>
              <span class="form-control no-border" *ngIf="model?.EmailAddress">
                {{ model?.EmailAddress }}
              </span>
              <span class="form-control no-border" *ngIf="model?.WebsiteURL">
                {{ model?.WebsiteURL }}
              </span>
            </div>
          </div>
        </div>
        <div class="row p-1 mt-3">
          <div class="col-12">
            <h2 class="text-subtitle">Mailing Address</h2>
          </div>
        </div>
        <div class="row p-1" *ngIf="isMailingAddressSamePhysicalAddressView">
          <div class="col-12">
            <div class="form-group">
              <span class="form-control no-border">
                Mailing Address is the same as my Physical Address
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="!isMailingAddressSamePhysicalAddressView">
          <div class="row p-1">
            <div class="col-12">
              <div class="form-group">
                <div class="no-padding">
                  <span class="form-control no-border">{{ model?.Mailing_StreetAddress1 }}</span>
                  <span class="form-control no-border" *ngIf="model?.Mailing_StreetAddress2">
                    {{ model?.Mailing_StreetAddress2 }}
                  </span>
                  <span class="form-control no-border">
                    {{ model?.Mailing_City }}, {{ model?.FK_State_VendorMailing_Display }} {{ model?.Mailing_Zipcode }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row p-1 mt-3">
          <div class="col-12">
            <h3 class="text-subtitle">Vendor ID</h3>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <div class="form-group">
              <span class="form-control no-border">
                {{ model?.VendorCode }}
              </span>
            </div>
          </div>
        </div>
        <!--
        <div class="row p-1">
          <div class="col-12">
            <h3 class="text-subtitle">W-9</h3>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <div class="form-group">
              <span class="form-control no-border">
                Link to W-9 file
              </span>
            </div>
          </div>
        </div>
        -->

        <p-sidebar [(visible)]="editViewDisplay" position="right" [style]="{width:'60%'}" [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class=" row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h2 class="text-header">Vendor Profile</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <h3 class="text-subtitle">Vendor Information</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Vendor Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Name" />
                </div>
              </div>
            </div>
            <div class="row p-1 mt-3">
              <div class="col-12">
                <h2 class="text-subtitle">Physical Address</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Address Line 1<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Physical_StreetAddress1"
                    (ngModelChange)="onPhysicalAddressChange()" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Address Line 2</label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Physical_StreetAddress2"
                    (ngModelChange)="onPhysicalAddressChange()" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">City<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Physical_City"
                    (ngModelChange)="onPhysicalAddressChange()" />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label class="form-label font-weight-bold">State<span class="text-danger">*</span></label>
                  <p-dropdown [options]="stateOptions" class="form-control no-border"
                    [(ngModel)]="editModel.FK_State_VendorPhysical" (ngModelChange)="onPhysicalAddressChange()">
                  </p-dropdown>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label class="form-label font-weight-bold">ZIP Code<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Physical_Zipcode"
                    (ngModelChange)="onPhysicalAddressChange()" />
                </div>
              </div>
            </div>
            <div class="row p-1 mt-3">
              <div class="col-12">
                <h2 class="text-subtitle">Mailing Address</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="d-flex justify-content-left align-items-center">
                  <input type="checkbox" [(ngModel)]="isSamePhysicalAddress"
                    (ngModelChange)="onSamePhysicalAddressChange()" class="mr-1" />
                  <span class="form-control no-border">
                    Mailing Address is the same as my Physical Address
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="!isSamePhysicalAddress">
              <div class="row p-1">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">Address Line 1<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="editModel.Mailing_StreetAddress1" />
                  </div>
                </div>
              </div>
              <div class="row p-1">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">Address Line 2</label>
                    <input type="text" class="form-control" [(ngModel)]="editModel.Mailing_StreetAddress2" />
                  </div>
                </div>
              </div>
              <div class="row p-1">
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">City<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="editModel.Mailing_City" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">State<span class="text-danger">*</span></label>
                    <p-dropdown [options]="stateOptions" class="form-control no-border"
                      [(ngModel)]="editModel.FK_State_VendorMailing">
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">Zip Code<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="editModel.Mailing_Zipcode" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveProfile()"
                  [disabled]="!isValidProfile()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelProfile()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 2">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2 class="text-header">Vendor Profile</h2>
            <button type="button" class="btn btn-primary" (click)="doPrimaryContactEdit()">
              <i class="fas fa-pen"></i>
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <h3 class="text-subtitle">Contact Information</h3>
          </div>
        </div>
        <div class="row py-1">
          <div class="col-6">
            <label class="text-subtitle font-weight-bold">First Name</label>
          </div>
          <div class="col-6">
            <label class="text-subtitle font-weight-bold">Last Name</label>
          </div>
        </div>
        <div class="row p-0">
          <div class="col-6">
            <span class="text-subtitle">{{ contactModel?.FirstName }}</span>
          </div>
          <div class="col-6">
            <span class="text-subtitle">{{ contactModel?.LastName }}</span>
          </div>
        </div>
        <div class="row py-1 mt-3">
          <div class="col-12">
            <label class="text-subtitle font-weight-bold">Email Address</label>
          </div>
        </div>
        <div class="row p-0">
          <div class="col-12">
            <span class="text-subtitle">{{ contactModel?.Email }}</span>
          </div>
        </div>
        <div class="row py-1 mt-3">
          <div class="col-12">
            <label class="text-subtitle font-weight-bold">Primary Phone Number</label>
          </div>
        </div>
        <div class="row p-0">
          <div class="col-12">
            <span class="text-subtitle">{{ contactModel?.Phone }}</span>
          </div>
        </div>

        <p-sidebar [(visible)]="editContactViewDisplay" position="right" [style]="{ width: '60%' }"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
            <div class="row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h2 class="text-header">Vendor Profile</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <h3 class="text-subtitle">Contact Information</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">First Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.FirstName" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Last Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.LastName" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Title<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.Title" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Email Address<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.Email" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Phone Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.Phone" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Ext.</label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.Extension" />
                </div>
              </div>
            </div>

            <div class="row p-1">
              <div class="col-12 d-flex justify-content-start buttons-contact">
                <button class="btn btn-primary mr-2" type="button" (click)="saveContact()"
                  [disabled]="!isValidContact()">
                  Save
                </button>
                <button class="btn btn-outline-secondary mx-2" type="button" (click)="cancelContact()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 3">
        <div class="row p-1">
          <div class="col-12">
            <h2 class="text-header">Vendor Profile</h2>
          </div>
        </div>
        <div class="row p-1 my-3">
          <div class="col-12">
            <h3 class="text-subtitle">Current Account Information</h3>
          </div>
        </div>
        <div class="row p-1 mt-3" *ngIf="!hasBankAccount">
          <div class="col-12">
            <button type="button" class="btn btn-outline-secondary" (click)="doAddBankAccount()">
              Add Account Information
            </button>
          </div>
        </div>
        <div *ngIf="hasBankAccount">
          <div class="row p-1">
            <div class="col-4">
              <label class="text-subtitle font-weight-bold">
                Account Name/Bank
              </label>
            </div>
            <div class="col-8">
              <span class="text-subtitle">{{ model?.PaymentBankName }}</span>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-4">
              <label class="text-subtitle font-weight-bold">
                Routing Number
              </label>
            </div>
            <div class="col-8">
              <span class="text-subtitle">{{ model?.PaymentBankRouting }}</span>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-4">
              <label class="text-subtitle font-weight-bold">
                Account Number
              </label>
            </div>
            <div class="col-8">
              <span class="text-subtitle">{{ getFilteredBankAccount }}</span>
            </div>
          </div>
          <div class="row p-1 mt-3">
            <div class="col-12">
              <button type="button" class="btn btn-outline-secondary" (click)="doAddBankAccount()">
                Edit Account Information
              </button>
            </div>
          </div>
        </div>

        <p-sidebar [(visible)]="editBankAccountViewDisplay" position="right" [style]="{width:'60%'}"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class=" row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h2 class="text-header">Bank Account Information</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Account Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.PaymentBankName" />
                  <label class="form-label font-italic mt-1">
                    Name your account for easy selection later. (e.g. "Checking Account")
                  </label>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Routing Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.PaymentBankRouting" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Account Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.PaymentBankAccount" maxlength="9" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveBankAccount()"
                  [disabled]="!isValidBankAccount()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelBankAccount()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 4">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2 class="text-header">Vendor Profile</h2>
            <button type="button" class="btn btn-primary" (click)="doApplyForAdditionalCategories()">
              <i class="fas fa-pen"></i>
            </button>
          </div>
        </div>
        <div class="row p-1 mt-3">
          <div class="col-12 d-flex justify-content-between">
            <h3 class="text-subtitle">Approved Categories</h3>
          </div>
        </div>
        <div class="row p-1 mt-3">
          <div class="col-12">
            <div *ngFor="let category of categoryList">
              <label class="form-label no-padding ml-3 mb-0" style="display: list-item;">
                {{ category.FK_ScholarshipDisbursementCategory_Display }}
              </label>
            </div>
          </div>
        </div>

        <p-sidebar [(visible)]="editCategoryViewDisplay" position="right" [style]="{width:'60%'}"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class="row p-1 mb-3">
              <div class="col-12">
                <h3 class="text-subtitle font-weight-bold">Approved Vendor Categories</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <label class="form-label font-weight-bold">Categories<span class="text-danger">*</span></label>
              </div>
            </div>
            <div class="row p-1" *ngFor="let category of categoryCheckList; let i = index">
              <div class="col-12">
                <p-checkbox [(ngModel)]="categoryCheckList[i].value" [binary]="true" label="{{ category.title }}"
                  class="form-label">
                </p-checkbox>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveCategory()">
                  Submit
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelCategory()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 5" class="form">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2 class="text-header">Vendor Profile</h2>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <h3 class="text-subtitle">Providers</h3>
          </div>
        </div>
        <div class="row p-3">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label font-weight-bold">Search Providers</label>
              <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="d-flex flex-row align-items-center w-75">
                  <input type="text" class="form-control w-25" [(ngModel)]="providersSearchText"
                    (ngModelChange)="onSearchTextChanged()" />
                  <p-inputSwitch [(ngModel)]="providersActive" class="px-2" (ngModelChange)="onProvidersActiveChange()">
                  </p-inputSwitch>
                  <span class="text-subtitle font-weight-bold">Show Active/Pending Providers Only</span>
                </div>
                <button class="btn btn-primary" type="button" (click)="addProvider()">
                  <span class="px-3 mx-3">Add Provider</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row p-3">
          <div class="col-12">
            <app-custom-table [data]="providersTableData" [columns]="providersTableColumns" [rows]="10"
              [showPaginator]="true" [showSummary]="true" [useLazyLoad]="true" (onLoadData)="loadProvidersData($event)"
              [isLoading]="providersTableLoading" [totalRecords]="providersTableTotalRecords"
              (onRowTableClicked)="onProviderTableRowClick($event)">
            </app-custom-table>
          </div>
        </div>

        <p-sidebar [(visible)]="editProviderViewDisplay" position="right" [style]="{width:'60%'}"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class="row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h2 class="text-header">Vendor Profile</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <h3 class="text-subtitle">Provider Information</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">First Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editProviderModel.FirstName" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Last Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editProviderModel.LastName" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Title<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editProviderModel.Title" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Email Address<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editProviderModel.Email" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Phone Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editProviderModel.Phone" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Ext.</label>
                  <input type="text" class="form-control" [(ngModel)]="editProviderModel.Extension" />
                </div>
              </div>
            </div>

            <div class="row p-1">
              <div class="col-12 d-flex justify-content-start buttons-contact">
                <button class="btn btn-primary mr-2" type="button" (click)="saveProvider()"
                  [disabled]="!isValidProvider()">
                  Save
                </button>
                <button class="btn btn-outline-secondary mx-2" type="button" (click)="cancelProvider()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog key="messageDialog"></p-confirmDialog>
<p-confirmDialog key="confirmDialog" styleClass="custom-confirm-dialog"></p-confirmDialog>