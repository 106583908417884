import { ScholarshipHandbookModel} from '../entities/ScholarshipHandbookModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ScholarshipHandbookResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ScholarshipHandbookModel = new ScholarshipHandbookModel();
}

export class ScholarshipHandbookList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ScholarshipHandbookModel> = <Array<ScholarshipHandbookModel >>[]
}

export interface IScholarshipHandbookListParams {
	pK_Id?:number;
	fK_Scholarship?:number;
	fK_Scholarship_display?:string;
	fK_Scholarship_like?:string;
	fK_Scholarship_fromValue?:number;
	fK_Scholarship_toValue?:number;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	fK_Document?:string;
	fK_Document_display?:string;
	fK_Document_like?:string;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ScholarshipHandbookListParams implements IScholarshipHandbookListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_Scholarship?:number=null;
	fK_Scholarship_display?:string=null;
	fK_Scholarship_like?:string=null;
	fK_Scholarship_fromValue?:number=null;
	fK_Scholarship_toValue?:number=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	fK_Document?:string=null;
	fK_Document_display?:string=null;
	fK_Document_like?:string=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

