import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuardianReimbursementStatusTypeEditBaseComponent } from './guardianReimbursementStatusTypeEdit.base';

@Component({
	selector: 'app-guardianReimbursementStatusTypeEdit',
	templateUrl: './guardianReimbursementStatusTypeEdit.component.html',
})
export class GuardianReimbursementStatusTypeEditComponent extends GuardianReimbursementStatusTypeEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
