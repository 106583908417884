import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScholarshipPaymentsDeadlineEditBaseComponent } from './scholarshipPaymentsDeadlineEdit.base';

@Component({
    selector: 'app-scholarshipPaymentsDeadlineEdit',
    templateUrl: './scholarshipPaymentsDeadlineEdit.component.html',
})
export class ScholarshipPaymentsDeadlineEditComponent extends ScholarshipPaymentsDeadlineEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
