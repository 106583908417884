import {IListItem } from '../models';
export class GuardianReimbursementListItemModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public Description:string = "";
	public Purpose:string = "";
	public FK_GuardianReimbursement:number = 0;
	public FK_GuardianReimbursement_Display:string = null;
}
