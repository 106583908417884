import { Component, OnInit, Directive } from '@angular/core';
import { aaaAPIService } from '../services/aaa.API.service';
import { SchoolModel } from '../models/entities/SchoolModel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private sv: aaaAPIService) { }

  public exampleId: number = 994;
  ngOnInit() {
   
  }


}
