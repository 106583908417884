import { Component, OnInit, Directive } from '@angular/core';
import { ILoginListParams, LoginListParams } from '../models/entities/LoginModel.response';
import { LoginModel} from '../models/entities/LoginModel';
import { BaseListComponent } from '../BaseListComponent';
import { PagingData } from '../paging/paging.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-user-login-List',
  templateUrl: './user-login-List.component.html',
})
export class UserLoginListComponent extends BaseListComponent implements OnInit {
  constructor(private recaptchaV3Service: ReCaptchaV3Service) {
		super();
  }

	public model: Array<LoginModel> = <Array<LoginModel>>[];
  public search: LoginListParams = new LoginListParams();
	

  ngOnInit() {
    
    this.search._sort = '';
    this.getData();

    super.baseNgOnInit();
  }


	protected getData() {
		var sub = this.api.getLoginList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
			sub.unsubscribe();}
		});
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
			var sub = src.api.deleteLogin(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					sub.unsubscribe()
				}
			});
		});
  }

  public resetPassword(email:string): void {
    var sub = this.recaptchaV3Service.execute('resetPassword')
      .subscribe((token) => {
        this.resetPassword2(token, email);
        sub.unsubscribe();
      });
  }


  private resetPassword2(token: string, email: string) {
    var me = this;

    var sub = this.api.sendPasswordResetEmail(email, token).subscribe(x => {

      if (x.Success) {
        
      }
      sub.unsubscribe();
    });
  }

}
