<div class="card">
  <div class="card-header">
    Login <span class="right">
  <a title="Cancel" *ngIf="!ModalView" routerLink="/loginlist"><i class="fa fa-undo icon-link"></i></a>
  <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
  <i class="fa fa-save icon-link" (click)="save()"></i>
</span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        								<div class="col-6">
									<div class="form-group">
										<label>Email</label><span title="Required" class="required">*</span>
										<input [(ngModel)]="model.Email" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
<span title="Required" class="required">*</span>
										<app-checkbox [(model)]="model.IsEnabled" [label]="'Is Enabled'"></app-checkbox>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Created Date</label>
										<div>{{gl.FormatDateShort(model.CreatedDate)}}</div>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label></label>
									<app-loginRolesLookUpCheckBoxListByLogin (saved)="childComponentSaved()"  [commit]="commit_LoginRolesLookUp" [id]="model.PK_Id"></app-loginRolesLookUpCheckBoxListByLogin>
									</div>
								</div>


      </div>
      

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
