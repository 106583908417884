import { Component, OnInit, Directive } from '@angular/core';
import { IPrepaidCollegeTransferListParams,PrepaidCollegeTransferListParams } from '../../models/entities/PrepaidCollegeTransferModel.response';
import { PrepaidCollegeTransferModel} from '../../models/entities/PrepaidCollegeTransferModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class PrepaidCollegeTransferListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<PrepaidCollegeTransferModel> = <Array<PrepaidCollegeTransferModel>>[];
	public search: PrepaidCollegeTransferListParams = new PrepaidCollegeTransferListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getPrepaidCollegeTransferList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.pK_Id  = null;
		this.search.createdDate  = null;
		this.search.createdDate_fromDate  = null;
		this.search.createdDate_toDate  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp_fromValue  = null;
		this.search.fK_StudentGradeLevelsSchoolYearsLookUp_toValue  = null;
		this.search.amount  = null;
		this.search.amount_fromValue  = null;
		this.search.amount_toValue  = null;
		this.search.fK_PrepaidCollegeTransferStatusType  = null;
		this.search.fK_PrepaidCollegeTransferStatusType_display  = null;
		this.search.fK_PrepaidCollegeTransferStatusType_like  = null;
		this.search.fK_PrepaidCollegeTransferStatusType_fromValue  = null;
		this.search.fK_PrepaidCollegeTransferStatusType_toValue  = null;
		this.search.dateApproved  = null;
		this.search.dateApproved_fromDate  = null;
		this.search.dateApproved_toDate  = null;
		this.search.fK_Login_ApprovedBy  = null;
		this.search.fK_Login_ApprovedBy_display  = null;
		this.search.fK_Login_ApprovedBy_like  = null;
		this.search.savingsAccountNumber  = null;
		this.search.savingsAccountNumber_like  = null;
		this.search.prepaidAccountNumber  = null;
		this.search.prepaidAccountNumber_like  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deletePrepaidCollegeTransfer(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManyPrepaidCollegeTransfer(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
