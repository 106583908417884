import { ZengineFormYearLookUpModel} from '../entities/ZengineFormYearLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ZengineFormYearLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ZengineFormYearLookUpModel = new ZengineFormYearLookUpModel();
}

export class ZengineFormYearLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ZengineFormYearLookUpModel> = <Array<ZengineFormYearLookUpModel >>[]
}

export interface IZengineFormYearLookUpListParams {
	pK_Id?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	fK_ZengineForm?:number;
	fK_ZengineForm_display?:string;
	fK_ZengineForm_like?:string;
	fK_ZengineForm_fromValue?:number;
	fK_ZengineForm_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ZengineFormYearLookUpListParams implements IZengineFormYearLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	fK_ZengineForm?:number=null;
	fK_ZengineForm_display?:string=null;
	fK_ZengineForm_like?:string=null;
	fK_ZengineForm_fromValue?:number=null;
	fK_ZengineForm_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

