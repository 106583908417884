import {IListItem } from '../models';
export class SchoolEligibilitiesResponsModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public FK_SchoolEligibility:number = 0;
	public FK_SchoolEligibility_Display:string = null;
	public PK_Id:number = 0;
	public FK_School?:number = null;
	public FK_School_Display:string = null;
	public FK_Guardian?:number = null;
	public FK_Guardian_Display:string = null;
	public ValueBoolean?:boolean = null;
	public FK_Login_AcceptedBy?:string = null;
	public FK_Login_AcceptedBy_Display:string = null;
	public FK_SchoolYear?:number = null;
	public FK_SchoolYear_Display:string = null;
}
