import {IListItem } from '../models';
export class ScholarshipModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_State:number = 0;
	public FK_State_Display:string = null;
	public Name:string = "";
	public FK_ScholarshipType:number = 0;
	public FK_ScholarshipType_Display:string = null;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public NonSchoolDisbursementAllowed?:boolean = null;
	public LengthOfScholarshipYears?:number = null;
	public RolloverUnusedFunds?:boolean = null;
	public VerifyIncomeOnRenewal?:boolean = null;
}
