import { AwardDeterminationStatusLookUpModel} from '../entities/AwardDeterminationStatusLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class AwardDeterminationStatusLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: AwardDeterminationStatusLookUpModel = new AwardDeterminationStatusLookUpModel();
}

export class AwardDeterminationStatusLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<AwardDeterminationStatusLookUpModel> = <Array<AwardDeterminationStatusLookUpModel >>[]
}

export interface IAwardDeterminationStatusLookUpListParams {
	pK_Id?:number;
	fK_AwardDetermination?:number;
	fK_AwardDetermination_display?:string;
	fK_AwardDetermination_like?:string;
	fK_AwardDetermination_fromValue?:number;
	fK_AwardDetermination_toValue?:number;
	fK_AwardDeterminationStatusType?:number;
	fK_AwardDeterminationStatusType_display?:string;
	fK_AwardDeterminationStatusType_like?:string;
	fK_AwardDeterminationStatusType_fromValue?:number;
	fK_AwardDeterminationStatusType_toValue?:number;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class AwardDeterminationStatusLookUpListParams implements IAwardDeterminationStatusLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_AwardDetermination?:number=null;
	fK_AwardDetermination_display?:string=null;
	fK_AwardDetermination_like?:string=null;
	fK_AwardDetermination_fromValue?:number=null;
	fK_AwardDetermination_toValue?:number=null;
	fK_AwardDeterminationStatusType?:number=null;
	fK_AwardDeterminationStatusType_display?:string=null;
	fK_AwardDeterminationStatusType_like?:string=null;
	fK_AwardDeterminationStatusType_fromValue?:number=null;
	fK_AwardDeterminationStatusType_toValue?:number=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

