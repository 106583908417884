export enum FieldTypeDefinition {
  String,
  Integer,
  Money,
  Decimal,
  Boolean,
  DateTime,
  Date,
  Time,
  Custom,
  CustomButton,
  FontAwesomeIcon,
  HTML,
}
