import {IListItem } from '../models';
export class SchoolFeeModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public CreatedDate:Date = null;
	public FK_School:number = 0;
	public FK_School_Display:string = null;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public FK_GradeLevel:number = 0;
	public FK_GradeLevel_Display:string = null;
	public PK_Id:number = 0;
	public FK_FeeType:number = 0;
	public FK_FeeType_Display:string = null;
	public Amount:number = 0;
	public FK_GradeLevel_To?:number = null;
	public FK_GradeLevel_To_Display:string = null;
	public FK_Login_CreatedBy?:string = null;
	public FK_Login_CreatedBy_Display:string = null;
	public IsDeleted?:boolean = null;
	public Description?:string = null;
}
