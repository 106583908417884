import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalsService } from './globals.service';
import { ErrorMessagesService } from './error-messages.service';
import { aaaAPIServiceBase } from './aaa.API.service.base';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginList } from '../models/entities/LoginModel.response';
import { SchoolList } from '../models/entities/SchoolModel.response';
import { VendorList } from '../models/entities/VendorModel.response';
import { ScholarshipDisbursementCategoriesLookUpList } from '../models/entities/ScholarshipDisbursementCategoriesLookUpModel.response';
import { ScholarshipHandbookModel } from '../models/entities/ScholarshipHandbookModel';
import { InsertResponse, ManagerResponse } from '../models/models';
import { AwardDeterminationList } from '../models/entities/AwardDeterminationModel.response';
import { AwardDeterminationModel } from '../models/entities/AwardDeterminationModel';
import { AccreditationList } from '../models/entities/AccreditationModel.response';
import { SchoolEligibilityList } from '../models/entities/SchoolEligibilityModel.response';

@Injectable({
  providedIn: 'root'
})
export class aaaAPIAdminService extends aaaAPIServiceBase {

  constructor(_http: HttpClient, _gl: GlobalsService, _errorMessagesService: ErrorMessagesService) {
    super(_http, _gl, _errorMessagesService);
  }

  public doSearchUserList(searchText: string, _isEnabled?: boolean, _sort?: string, _sortDesc?: boolean, _page?: number, _pageSize?: number): Observable<LoginList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Login/SearchUserList/?searchText=${(searchText != null ? encodeURI(searchText.toString()) : '')}&_isEnabled=${(_isEnabled != null ? encodeURI(_isEnabled.toString()) : '')}&_sort=${(_sort != null ? encodeURI(_sort.toString()) : '')}&_sortDesc=${(_sortDesc != null ? encodeURI(_sortDesc.toString()) : '')}&_page=${(_page != null ? encodeURI(_page.toString()) : '')}&_pageSize=${(_pageSize != null ? encodeURI(_pageSize.toString()) : '')}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <LoginList>res.body;
    }));
  }

  public doSearchSchoolList(searchText: string, state: string, _sort?: string, _sortDesc?: boolean, _page?: number, _pageSize?: number): Observable<SchoolList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/School/SearchSchoolList/?searchText=${(searchText != null ? encodeURI(searchText.toString()) : '')}&state=${(state != null ? encodeURI(state.toString()) : '')}&_sort=${(_sort != null ? encodeURI(_sort.toString()) : '')}&_sortDesc=${(_sortDesc != null ? encodeURI(_sortDesc.toString()) : '')}&_page=${(_page != null ? encodeURI(_page.toString()) : '')}&_pageSize=${(_pageSize != null ? encodeURI(_pageSize.toString()) : '')}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <SchoolList>res.body;
    }));
  }

  public doSearchAwardDeterminationList(searchText: string, state: string, schoolYear: string, guardian: string, _sort?: string, _sortDesc?: boolean, _page?: number, _pageSize?: number): Observable<AwardDeterminationList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/AwardDetermination/SearchList/?searchText=${(searchText != null ? encodeURI(searchText.toString()) : '')}&state=${(state != null ? encodeURI(state.toString()) : '')}&schoolYear=${(schoolYear != null ? encodeURI(schoolYear.toString()) : '')}&guardian=${(guardian != null ? encodeURI(guardian.toString()) : '')}&_sort=${(_sort != null ? encodeURI(_sort.toString()) : '')}&_sortDesc=${(_sortDesc != null ? encodeURI(_sortDesc.toString()) : '')}&_page=${(_page != null ? encodeURI(_page.toString()) : '')}&_pageSize=${(_pageSize != null ? encodeURI(_pageSize.toString()) : '')}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <AwardDeterminationList>res.body;
    }));
  }

  public doSearchVendorList(searchText: string, state: number, _sort?: string, _sortDesc?: boolean, _page?: number, _pageSize?: number): Observable<VendorList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Vendor/SearchVendorList/?searchText=${(searchText != null ? encodeURI(searchText.toString()) : '')}&state=${(state != null ? encodeURI(state.toString()) : '')}&_sort=${(_sort != null ? encodeURI(_sort.toString()) : '')}&_sortDesc=${(_sortDesc != null ? encodeURI(_sortDesc.toString()) : '')}&_page=${(_page != null ? encodeURI(_page.toString()) : '')}&_pageSize=${(_pageSize != null ? encodeURI(_pageSize.toString()) : '')}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <VendorList>res.body;
    }));
  }

  public doSearchDisbursementCategories(searchText: string, state: string, scholarship: string, _sort?: string, _sortDesc?: boolean, _page?: number, _pageSize?: number): Observable<ScholarshipDisbursementCategoriesLookUpList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/DisbursementCategories/SearchCategoryList/?searchText=${(searchText != null ? encodeURI(searchText.toString()) : '')}&state=${(state != null ? encodeURI(state.toString()) : '')}&scholarship=${(scholarship != null ? encodeURI(scholarship.toString()) : '')}&_sort=${(_sort != null ? encodeURI(_sort.toString()) : '')}&_sortDesc=${(_sortDesc != null ? encodeURI(_sortDesc.toString()) : '')}&_page=${(_page != null ? encodeURI(_page.toString()) : '')}&_pageSize=${(_pageSize != null ? encodeURI(_pageSize.toString()) : '')}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ScholarshipDisbursementCategoriesLookUpList>res.body;
    }));
  }

  public insertScholarshipHandbook(m: ScholarshipHandbookModel): Observable<InsertResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ScholarshipHandbook/Post';
    return this.http.post(url, m, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <InsertResponse>res.body;
    }));
  }

  public updateScholarshipHandbook(m: ScholarshipHandbookModel): Observable<ManagerResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = this.gl.BaseUrl + '/api/ScholarshipHandbook/Put';
    return this.http.put(url, m, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ManagerResponse>res.body;
    }));
  }

  public doSearchAccreditations(searchText: string, state: string, _sort?: string, _sortDesc?: boolean, _page?: number, _pageSize?: number): Observable<AccreditationList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Accreditation/SearchAccreditationList/?searchText=${(searchText != null ? encodeURI(searchText.toString()) : '')}&state=${(state != null ? encodeURI(state.toString()) : '')}&_sort=${(_sort != null ? encodeURI(_sort.toString()) : '')}&_sortDesc=${(_sortDesc != null ? encodeURI(_sortDesc.toString()) : '')}&_page=${(_page != null ? encodeURI(_page.toString()) : '')}&_pageSize=${(_pageSize != null ? encodeURI(_pageSize.toString()) : '')}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <AccreditationList>res.body;
    }));
  }

  public doSearchEligibilities(searchText: string, state: string, _sort?: string, _sortDesc?: boolean, _page?: number, _pageSize?: number): Observable<SchoolEligibilityList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/SchoolEligibility/SearchSchoolEligibilityList/?searchText=${(searchText != null ? encodeURI(searchText.toString()) : '')}&state=${(state != null ? encodeURI(state.toString()) : '')}&_sort=${(_sort != null ? encodeURI(_sort.toString()) : '')}&_sortDesc=${(_sortDesc != null ? encodeURI(_sortDesc.toString()) : '')}&_page=${(_page != null ? encodeURI(_page.toString()) : '')}&_pageSize=${(_pageSize != null ? encodeURI(_pageSize.toString()) : '')}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <SchoolEligibilityList>res.body;
    }));
  }
}
