import { Component, OnInit, Directive } from '@angular/core';
import {  IAwardAcceptanceStatusLookUpListParams } from '../../models/entities/AwardAcceptanceStatusLookUpModel.response';
import { AwardAcceptanceStatusLookUpModel } from '../../models/entities/AwardAcceptanceStatusLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { AwardAcceptanceStatusLookUpListBaseComponent } from './awardAcceptanceStatusLookUpList.base';

@Component({
	selector: 'app-awardAcceptanceStatusLookUpList',
	templateUrl: './awardAcceptanceStatusLookUpList.component.html',
})
export class AwardAcceptanceStatusLookUpListComponent extends AwardAcceptanceStatusLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
