import {IListItem } from '../models';
export class AwardDeterminationModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public CreatedDate:Date = null;
	public FK_Guardian:number = 0;
	public FK_Guardian_Display:string = null;
	public FK_Scholarship:number = 0;
	public FK_Scholarship_Display:string = null;
	public FK_Student:number = 0;
	public FK_Student_Display:string = null;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public FK_GradeLevel:number = 0;
	public FK_GradeLevel_Display:string = null;
	public PK_Id:number = 0;
	public WasNotificationSent:boolean = false;
	public IsDeleted?:boolean = null;
	public FK_Login_CreatedBy?:string = null;
	public FK_Login_CreatedBy_Display:string = null;
	public AwardAmount?:number = null;
	public DeterminationReason?:string = null;
}
