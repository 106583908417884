import { GradingPeriodTypeModel} from '../entities/GradingPeriodTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class GradingPeriodTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: GradingPeriodTypeModel = new GradingPeriodTypeModel();
}

export class GradingPeriodTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<GradingPeriodTypeModel> = <Array<GradingPeriodTypeModel >>[]
}

export interface IGradingPeriodTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class GradingPeriodTypeListParams implements IGradingPeriodTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

