
<div *ngIf="errorMessagesService.messages.length">
  <div class="loading-background-alt"></div>
  <div class="card alert-warning validation-message" (click)="errorMessagesService.clear($event)">

    <div class="card-body">
      <a href="#" class="close" (click)="errorMessagesService.clear($event)" data-dismiss="alert" aria-label="close">&times;</a>
      <ul>
        <li *ngFor="let item of errorMessagesService.messages">
          {{item}}
        </li>
      </ul>
    </div>
  </div>
</div>



<div id="successMessagePanel" (click)="errorMessagesService.clear($event)" class="card alert-success validation-message" *ngIf="errorMessagesService.successMessage">

  <div class="card-body">
    <a href="#" class="close" (click)="errorMessagesService.clear($event)" data-dismiss="alert" aria-label="close">&times;</a>
    <ul>
      <li>
        {{errorMessagesService.successMessage}}
      </li>
    </ul>
  </div>
</div>

<div (click)="_showSpinner=false;updateUI();" *ngIf="_showSpinner" id="loading-spinner">
  <div class="loading-background"></div>
  <div (click)="_showSpinner=false;updateUI();" *ngIf="_showSpinner" id="loading-spinner" class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

</div>


<div id="confirmMessagePanel" class="card alert-warning validation-message" *ngIf="errorMessagesService.confirmMessage">

  <div class="card-body">

    <ul>
      <li>
        {{errorMessagesService.confirmMessage}}
      </li>
    </ul>

    <button style="margin-right:10px" type="button" (click)="errorMessagesService.yes($event)" class="btn btn-success">Yes</button>

    <button type="button" (click)="errorMessagesService.no($event)" class="btn btn-danger">No</button>
  </div>
</div>
