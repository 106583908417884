import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerificationEditBaseComponent } from './verificationEdit.base';

@Component({
	selector: 'app-verificationEdit',
	templateUrl: './verificationEdit.component.html',
})
export class VerificationEditComponent extends VerificationEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
