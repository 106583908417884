import { Component, OnInit, Directive } from '@angular/core';
import {  IFeeTypeListParams } from '../../models/entities/FeeTypeModel.response';
import { FeeTypeModel } from '../../models/entities/FeeTypeModel';
import { PagingData } from '../../paging/paging.component';
import { FeeTypeListBaseComponent } from './feeTypeList.base';

@Component({
	selector: 'app-feeTypeList',
	templateUrl: './feeTypeList.component.html',
})
export class FeeTypeListComponent extends FeeTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
