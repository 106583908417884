import { Component, OnInit, Directive } from '@angular/core';
import {  IGuardianReimbursementListParams } from '../../models/entities/GuardianReimbursementModel.response';
import { GuardianReimbursementModel } from '../../models/entities/GuardianReimbursementModel';
import { PagingData } from '../../paging/paging.component';
import { GuardianReimbursementListBaseComponent } from './guardianReimbursementList.base';

@Component({
	selector: 'app-guardianReimbursementList',
	templateUrl: './guardianReimbursementList.component.html',
})
export class GuardianReimbursementListComponent extends GuardianReimbursementListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
