import { FeeTypeModel} from '../entities/FeeTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class FeeTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: FeeTypeModel = new FeeTypeModel();
}

export class FeeTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<FeeTypeModel> = <Array<FeeTypeModel >>[]
}

export interface IFeeTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class FeeTypeListParams implements IFeeTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

