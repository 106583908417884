import {IListItem } from '../models';
export class StudentDisabilityLookUpModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_Student:number = 0;
	public FK_Student_Display:string = null;
	public FK_Disability:number = 0;
	public FK_Disability_Display:string = null;
}
