export interface IManagerResponse {
  Errors: string[];
  ErrorObjects: ErrorObject[];
  Success: boolean;
  SuccessMessage: string;
  Token: string;
  Unauthorized: boolean;
  RecordsCount: number;
  Pages: number;
  Page: number;

}

export class UpdateEvent {
  public Id: any = null;
  public Stamp: Date = null;
  public Name: string = null;
}

export class PasswordResetPost {
  public Token: string = null;
  public Password: string = null;
  public Password2: string = null;
}

export interface ISearchModel {
  _sort?: string;
  _sortDesc?: boolean;
  _page?: number;
  _pageSize?: number;
  _fuzzySearch?: string;
}

export class ManagerResponse implements IManagerResponse {
  constructor() {

  }
  Errors: string[] = <string[]>[];
  ErrorObjects: ErrorObject[] = <ErrorObject[]>[];
  Success: boolean = false;
  SuccessMessage: string = null;
  Token: string = null;
  Unauthorized: boolean = false;
  RecordsCount: number;
  Pages: number;
  Page: number;

}


export class ListItemModelResponse extends ManagerResponse {
  constructor() {
    super();
  }
  public Data: Array<ListItemModel> = null;
}

export class InsertResponse extends ManagerResponse {
  constructor() {
    super();
  }
  public Data: any = null;
}

export interface IListItem {
  PK_Id?: any;
  ListDisplay: any;
  Selected?: boolean;
}

export class CheckboxListSave {
  public Items: Array<ListItemModel> = null;
  public Id: any = null;
}

export class ListItemModel {
  public PK_Id?: any = null;
  public ListDisplay: any = null;
  public Selected?: boolean = null;
}

export class ErrorObject {
  Text: string = null;
  Code: ResponseErrorCodes = 0;
}



export interface IDataGrid {
  Sort: string;
  SortDesc: boolean;
  Page: number;
  PageSize: number;
  RecordsCount: number;
}

export enum RoleType {

  //System Admin
  Admin = 1, //Table maint

  //Accounting Department
  AccountingManager = 2, //View edit in a workflow, override
  AccountingUser = 3, //View edit in a workflow


  //Customer Service & Operations
  Manager = 4, //View edit in a workflow, override
  Support = 5, //View edit in a workflow
  Executive = 6,//Can view and edit all. Not table maint

  //Family Household
  GuardianPrimary = 7,
  GuardianSecondary = 8,

  //Vendor
  ServiceProviderAdmin = 9,
  ServiceProviderUser = 10,
  SchoolAdmin = 11,
  SchoolUser = 12

}

export enum ResponseErrorCodes {
  InvalidAccess = 100,
  EmailNotUnique = 101,
  EmailRequired = 102,
  EmailFormatNotCorrect = 103,
  PasswordFormatNotCorrect = 104,
  ErrorCreatingCounselor = 105,
  CounselorEmailNotUnique = 106,
  ClientEmailNotUnique = 107,
  FirstNameRequired = 108,
  LastNameRequired = 109,
  StateRequired = 110,
  ZipRequired = 111,
  AnnualIncomeRequired = 112,
  FamilySizeRequired = 113,
  TaxFilingStatusRequired = 114,
  SpouseAnnualIncomeRequired = 115,
  ClientNotFound = 116,
  UserNameNotUnique = 117,
  PasswordIsRequired = 118,
  UserNameIsRequired = 119,
  ExpiredAccess = 120,
  InCompleteClientInfo = 121,
  MobileCouponError = 122,
  AccountNotInDisbursements = 123,
  FairShareNotSet = 124,
  FinalPaymentError = 125,
  PaymentError = 126,
  DuplicateAccountNumber = 127,
  NetPaymentWasChangedToNotFairshare = 128,
  NetPaymentWasChangedToFairshare = 129,
}

export enum ScholarshipTypes {
  Unknown = 0,
  Income = 1, // Income Based Scholarship
  Disability = 2 // Gardiner Based Scholarship
}

export enum SchoolCommitmentStatusTypes {
  Unknown = 0,
  PendingSchoolReview = 1,
  PendingParentReview = 2,
  PendingAAAReview = 3,
  Approved = 4
}

export enum AwardDeterminationStatusTypes {
  Unknown = 0,
  Denied = 3,
  Denied_Explain = 8,
  Empowerment = 9,
  EligibleAndFunded_100 = 10,
  EligibleAndFunded_75 = 11,
  EligibleAndFunded_50 = 12,
  EligibleAndFunded_25 = 13,
  EligibleAndWaitListed_100 = 14,
  EligibleAndWaitListed_75 = 15,
  EligibleAndWaitListed_50 = 16,
  EligibleAndWaitListed_25 = 17,
  EligibleAndPendingFunding = 18
}

export enum AwardAcceptanceStatusTypes {
  Accepted = 1,
  Forfeited = 2,
  WithdrawnFundsReturningToScholarship = 3,
  WithdrawnFundsReturningToDepartment = 4,
  AppealRequested = 5,
  AppealDenied = 6,
  Revoked = 7
}

export enum NoteTypes {
  School = 0,
  Guardian = 1,
  AAA = 2,
  Vendor = 3,
  Scholarship = 4
}

export enum FeeTypes {
  Tuition = 1,
  Registration = 2,
  Books = 3,
  Transportation = 4,
  Uniforms = 5,
  Testing = 6,
  Other = 7
}