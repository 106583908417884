import {IListItem } from '../models';
export class VerificationModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_StudentGradeLevelsSchoolYearsLookUp:number = 0;
	public FK_AwardDetermination:number = 0;
	public FK_AwardDetermination_Display:string = null;
	public FK_GradingPeriodType:number = 0;
	public FK_GradingPeriodType_Display:string = null;
	public NumberOfAbsences:number = 0;
	public HouseholdCurrent:boolean = false;
	public StillEnrolled:boolean = false;
	public FK_VerificationStatusType?:number = null;
	public FK_VerificationStatusType_Display:string = null;
}
