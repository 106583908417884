import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ZengineMappingOptionEditBaseComponent } from './zengineMappingOptionEdit.base';

@Component({
	selector: 'app-zengineMappingOptionEdit',
	templateUrl: './zengineMappingOptionEdit.component.html',
})
export class ZengineMappingOptionEditComponent extends ZengineMappingOptionEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
