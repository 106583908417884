import { Component, OnInit } from '@angular/core';
import { ITableColumnDefinition } from 'src/app/interfaces/table-column-definition-interface';
import { FieldTypeDefinition } from 'src/app/types/field-type-definition-type';
import { aaaAPIAdminService } from 'src/app/services/aaa.API.admin.service';
import { SchoolModel } from 'src/app/models/entities/SchoolModel';
import { BaseComponent } from 'src/app/BaseComponent';
import { CONSTANTS } from '../../../constants/constants';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription, timer } from 'rxjs';

interface Option {
  name: string,
  code: string
}

@Component({
  selector: 'app-admin-allschools',
  templateUrl: './admin-allschools.component.html',
  styleUrls: ['./admin-allschools.component.scss']
})
export class AdminAllSchoolsComponent extends BaseComponent implements OnInit {
  searchScholarshipText: string = '';
  statesOptions: Option[];
  state: Option;
  schoolYearOptions: Option[];
  schoolYear: Option;
  schoolModels: SchoolModel[] = [];

  schoolTableData: any[] = [];
  originalschoolTableData: any[] = [];
  schoolTableColumns: ITableColumnDefinition[] = [];

  lastLazyLoadEvent: LazyLoadEvent = null;
  lastSearchText: string = null;
  usersTableLoading: boolean = false;
  usersTableTotalRecords: number = null;

  searchSubscription: Subscription;

  constructor(private adminServices: aaaAPIAdminService) {
    super(null, false);
  }

  ngOnInit(): void {
    this.adminServices.getStateList({ _sort: 'Name', active: true })
      .subscribe((states) => {
        this.statesOptions = [
          { name: 'All States', code: 'All' },
        ];
        states.Data.forEach((state) => {
          let stateObj = { name: state.Name, code: state.Abbreviation };
          this.statesOptions.push(stateObj);
        });
      });
    this.adminServices.getSchoolYearList({ _sort: 'Name' })
      .subscribe((schoolYear) => {
        this.schoolYearOptions = [
          { name: 'All School Years', code: 'All' },
        ];
        schoolYear.Data.forEach((state) => {
          let schoolYearObj = { name: state.Name, code: state.PK_Id.toString() };
          this.schoolYearOptions.push(schoolYearObj);
        });
      });
    this.loadSchoolData(CONSTANTS.FirstLazyLoadEvent);
  }

  onSearchTextChanged() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const source = timer(CONSTANTS.SearchTimerTime);
    this.searchSubscription = source
      .subscribe(() => {
        this.loadSchoolData(this.lastLazyLoadEvent);
        this.searchSubscription.unsubscribe();
      })
  }

  changeState() {
    this.loadSchoolData(this.lastLazyLoadEvent);
  }

  openScholarship(e) {
    this.gl.GoTo(`adminschoolprofile/${e.SchoolId}`, null, null);
  }

  loadSchoolData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.usersTableLoading = true;
      const page = (this.searchScholarshipText !== this.lastSearchText) ? 1 : (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const statefilter = this.state;
      const sortFieldDesc = event.sortOrder < 0;
      const searchText = (this.searchScholarshipText && this.searchScholarshipText.trim().length > 0) ? this.searchScholarshipText : null;
      this.lastSearchText = this.searchScholarshipText;
      console.log('page: ', page, '  pageSize: ', pageSize);

      this.adminServices.doSearchSchoolList(searchText, statefilter?.name, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseLogins => {
          console.log(responseLogins);
          this.usersTableTotalRecords = responseLogins.RecordsCount;
          this.schoolModels = responseLogins.Data;
          this.generateSchoolsTableData();
          this.usersTableLoading = false;
        });
    }
  }

  private generateSchoolsTableColumn() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Status',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.Boolean,
    });
    columns.push({
      fieldCaption: 'School Name',
      fieldName: 'Name',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Grades',
      fieldName: 'Grades',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Students',
      fieldName: 'Students',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Address',
      fieldName: 'Physical_StreetAddress1',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'City',
      fieldName: 'Physical_City',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'State',
      fieldName: 'State',
      fieldType: FieldTypeDefinition.String,
      width: '10%',
    });
    columns.push({
      fieldCaption: 'Phone Number',
      fieldName: 'Phone',
      fieldType: FieldTypeDefinition.String,
    });

    this.schoolTableColumns = [...columns];
  }

  private generateSchoolsTableData() {
    this.generateSchoolsTableColumn();
    this.schoolTableData = this.schoolModels.map(i => {
      return {
        'SchoolId': i.PK_Id,
        'State': i.FK_State_Physical_Display,
        'Name': i.Name,
        'Phone': i.Phone,
        'Physical_City': i.Physical_City,
        'Physical_StreetAddress1': i.Physical_StreetAddress1,
        'Grades': null,
        'Students': 0
      }
    });

    this.schoolTableData.forEach((s) => {
      this.adminServices.getSchoolGradeLevelsLookUpList({ fK_School: s.SchoolId })
        .subscribe((grade) => {
          if (grade.Data && grade.Data.length > 0) {
            s.Grades = grade.Data[0].FK_GradeLevel_Display + ' - ' + grade.Data[grade.Data.length - 1].FK_GradeLevel_Display;
          }
        })
    });
    this.originalschoolTableData = this.schoolTableData;
  }
}
