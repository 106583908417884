import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, SelectItem } from 'primeng/api';

import { BaseComponent } from '../../../BaseComponent';
import { aaaAPIStudentService } from '../../../services/aaa.API.student.service';
import { RoleType } from '../../../models/models';
import { ContactModel } from '../../../models/entities/ContactModel';
import { StudentModel } from '../../../models/entities/StudentModel';
import { VerificationNoteModel } from '../../../models/entities/VerificationNoteModel';
import { SchoolModel } from '../../../models/entities/SchoolModel';
import { SchoolTransactionModel } from '../../../models/entities/SchoolTransactionModel';
import { SchoolFeeModel } from '../../../models/entities/SchoolFeeModel';
import { SchoolFeeListModel } from '../../../models/entities/SchoolFeeListModel';
import { FeeTypeModel } from '../../../models/entities/FeeTypeModel';
import { SchoolCalendarLookUpModel } from '../../../models/entities/SchoolCalendarLookUpModel';
import { ITableColumnDefinition } from '../../../interfaces/table-column-definition-interface';
import { FieldTypeDefinition } from '../../../types/field-type-definition-type';
import { SchoolEligibilitiesResponsModel } from '../../../models/entities/SchoolEligibilitiesResponsModel';
import { SchoolYearModel } from '../../../models/entities/SchoolYearModel';
import { SchoolEligibilitiesStateLookUpModel } from '../../../models/entities/SchoolEligibilitiesStateLookUpModel';
import { StudentGradeLevelsSchoolYearsLookUpModel } from '../../../models/entities/StudentGradeLevelsSchoolYearsLookUpModel';
import { Input } from '@angular/core';
import { StateModel } from '../../../models/entities/StateModel';
import { AwardDeterminationModel } from '../../../models/entities/AwardDeterminationModel';
import { ScholarshipModel } from '../../../models/entities/ScholarshipModel';
import { VendorTransactionModel } from '../../../models/entities/VendorTransactionModel';
import { VerificationModel } from '../../../models/entities/VerificationModel';
import { GuardianModel } from '../../../models/entities/GuardianModel';
import { HouseholdModel } from '../../../models/entities/HouseholdModel';
import * as moment from 'moment';
import { aaaAPISchoolService } from 'src/app/services/aaa.API.school.service';
import { Subscription } from 'rxjs';
import { CONSTANTS } from 'src/app/constants/constants';
import { aaaAPIAdminService } from 'src/app/services/aaa.API.admin.service';
import { UTILS } from '../../../utils/utils';
@Component({
  selector: 'app-student-profile-page',
  templateUrl: './student-profile-page.component.html',
  styleUrls: ['./student-profile-page.component.scss'],
})
export class StudentProfilePageComponent extends BaseComponent implements OnInit {
  @Input('id') id: number;
  pageNumber: number = 1;
  question1: string;
  question2: string;
  explainText: string = '';
  model: StudentModel = null;
  editModel: StudentModel = new StudentModel();
  studentGradeLevelsSchoolYearsModel: StudentGradeLevelsSchoolYearsLookUpModel = new StudentGradeLevelsSchoolYearsLookUpModel();
  schoolModel: SchoolModel = new SchoolModel();
  stateModel: StateModel = new StateModel();
  actualAwardModel: AwardDeterminationModel = new AwardDeterminationModel();
  scholarshipModel: ScholarshipModel = new ScholarshipModel();
  awardModels: AwardDeterminationModel[] = [];
  newVendorTransactionModel: VendorTransactionModel = new VendorTransactionModel();
  schoolTransactionModels: SchoolTransactionModel[] = [];
  schoolSearchList: SchoolModel[] = [];
  schoolToCommitment: SchoolModel = new SchoolModel();
  editContactModel: ContactModel = new ContactModel();
  guardianModel: GuardianModel = new GuardianModel();
  guardianEmail: string = '';
  houseHoldModel: HouseholdModel = new HouseholdModel();
  guardianStateAbrevation: string = '';
  schoolCalendarList: SchoolCalendarLookUpModel[] = [];
  schoolFeeList: SchoolFeeModel[] = [];
  loginEditRole: boolean = false;
  schoolTableData: any[][] = [];
  schoolTableColumns: ITableColumnDefinition[] = [];

  studentTableColumns: ITableColumnDefinition[] = [];

  awardTableData: any[][] = [];
  awardTableColumns: ITableColumnDefinition[] = [];
  paymentsTableData: any[][] = [];
  paymentsTableColumns: ITableColumnDefinition[] = [];
  vendorsTableData: any[][] = [];
  vendorsTableColumns: ITableColumnDefinition[] = [];
  schoolFeesTableData: any[][] = [];
  schoolFeesTableColumns: ITableColumnDefinition[] = [];
  schoolFeesData: any[] = [];
  verificationNote: VerificationNoteModel = new VerificationNoteModel();
  loginRoleNumber: number = null;
  schoolYearOptions2: SelectItem[] = [];
  schoolCalendar: SchoolCalendarLookUpModel = null;
  selectedSchoolCalendar: SchoolCalendarLookUpModel = null;
  selectedTableIndex: number = null;
  editFeeModel: SchoolFeeListModel = new SchoolFeeListModel();
  isNewFee: boolean = false;
  schoolCertificationTableData: any[] = [];
  schoolCertificationTableColumns: ITableColumnDefinition[] = [];
  selectedSchoolYear: SchoolYearModel = null;
  schoolEligibilitiesResponses: any = {};
  enrollmentVerificationData: any = {};
  studentTableData: any[] = [];
  findSchoolDisplay: boolean = false;
  createNewPaymentVendorDisplay: boolean = false;
  schoolCommitmentStudentDisplay: boolean = false;
  editFeeViewDisplay: boolean = false;
  editAAACertificationViewDisplay: boolean = false;
  editPaymentDetailsViewDisplay: boolean = false;
  enrollmentVerificationDisplay: boolean = false;
  studentsModels: AwardDeterminationModel[] = [];
  stateOptions: SelectItem[] = [];
  vendorTransactionOptions: SelectItem[] = [];
  feeTypesList: FeeTypeModel[] = [];
  schoolYearList: SchoolYearModel[] = [];
  schoolEligibilitiesList: SchoolEligibilitiesStateLookUpModel[] = [];
  schoolCertificationList: SchoolEligibilitiesResponsModel[] = [];
  isSamePhysicalAddress: boolean = false;
  schoolNameOrAddress: string = '';
  descriptionVendor: string = '';
  lastLazyLoadEvent: LazyLoadEvent = null;
  lastSearchText: string = null;
  usersTableLoading: boolean = false;
  usersTableTotalRecords: number = null;
  searchSubscription: Subscription;

  constructor(
    private studentService: aaaAPIStudentService,
    private rt: ActivatedRoute,
    private schoolService: aaaAPISchoolService,
    private adminServices: aaaAPIAdminService
  ) {
    super(rt, false);
  }

  ngOnInit(): void {
    const checkRole = this.gl.LoginRoles.filter((item) => item === RoleType.GuardianPrimary);
    if (checkRole !== undefined && checkRole.length > 0) {
      this.loginRoleNumber = checkRole[0];
      this.loginEditRole = true;
    }
    this.route.params
      .subscribe((params) => {
        this.id = +params['id'];

        this.pageLoad();
      });
  }

  pageLoad() {
    this.studentService.getStudent(this.id)
      .subscribe((res) => {
        if (res.Success) {
          this.model = res.Data;
          this.studentService.getStudentGradeLevelsSchoolYearsLookUpList({ fK_Student: this.id, fK_SchoolYear: this.schoolCalendar ? this.schoolCalendar.FK_SchoolYear : null })
            .subscribe((res) => {
              this.studentGradeLevelsSchoolYearsModel = res.Data[0];
              if (res.Data[0]) {
                this.studentService.getAwardDeterminationList({ fK_Student: this.id })
                  .subscribe((resAward) => {
                    this.awardModels = resAward.Data;
                    this.actualAwardModel = resAward.Data.filter(item => item.FK_SchoolYear == res.Data[0].FK_SchoolYear)[0];
                    if (resAward.Data.length > 0) {
                      const sid = resAward.Data.filter(item => item.FK_SchoolYear == res.Data[0].FK_SchoolYear)[0].FK_Scholarship;
                      this.studentService.getScholarshipList({ pK_Id: sid })
                        .subscribe((data) => {
                          this.scholarshipModel = data.Data[0];
                        });
                      this.loadSchoolCalendarList();
                      this.loadStudentData(CONSTANTS.FirstLazyLoadEvent);
                    }
                  });
                this.studentService.getSchoolTransactionList({ fK_StudentGradeLevelsSchoolYearsLookUp: res.Data[0].PK_Id })
                  .subscribe((res) => {
                    this.schoolTransactionModels = res.Data;
                  });
              }
              if (res.Data[0].FK_School) {
                this.studentService.getSchool(res.Data[0].FK_School)
                  .subscribe((res) => {
                    this.schoolModel = res.Data;
                    if (res.Data.FK_State_Physical) {
                      this.studentService.getState(res.Data.FK_State_Physical)
                        .subscribe((res) => {
                          this.stateModel = res.Data;
                        });
                    }
                  });
              }

            });
        }
      });
    this.studentService.getAwardDeterminationList({ fK_Student: this.id })
      .subscribe((res) => {
        this.awardModels = res.Data;
        if (res.Data[0]) {
          this.studentService.getGuardian(res.Data[0].FK_Guardian)
            .subscribe((data) => {
              this.guardianModel = data.Data;
              this.studentService.getLogin(data.Data.FK_Login)
                .subscribe((login) => {
                  this.guardianEmail = login.Data.Email;
                });
              this.studentService.getHousehold(data.Data.FK_Household)
                .subscribe((house) => {
                  this.houseHoldModel = house.Data;
                  console.log('HouseHold Model: ', this.houseHoldModel);
                  this.studentService.getState(house.Data.FK_State)
                    .subscribe((state) => {
                      this.guardianStateAbrevation = state.Data.Abbreviation;
                    });
                });
            });
        }
      });
    this.generateAwardTableData();
    this.generatePaymentsTableData();
    this.generateVendorsTableData();
    this.studentService.getVendorList({ _sort: 'PK_Id' }).subscribe((res) => {
      this.vendorTransactionOptions = res.Data.map((item) => {
        return {
          label: item.Name,
          value: item.PK_Id,
        } as SelectItem;
      });
    });
  }

  doChangePage(pageNumber: number) {
    if (pageNumber != this.pageNumber) {
      this.pageNumber = pageNumber;
    }
  }

  onVendorChange(event) {
    this.newVendorTransactionModel.FK_Vendor_Display = this.vendorTransactionOptions.find(
      (item) => item.value == event
    ).label;
  }

  private loadSchoolCalendarList() {
    this.schoolService
      .getSchoolCalendarLookUpList({
        fK_School: this.studentGradeLevelsSchoolYearsModel.FK_School,
        _sort: 'FK_SchoolYear',
        _sortDesc: true,
      })
      .subscribe((resCalendar) => {
        this.schoolYearOptions2 = resCalendar.Data.map((item) => {
          return {
            label: UTILS.getSchoolYearDisplay(item.SchoolYearStart, item.SchoolYearEnd),
            value: item,
          } as SelectItem;
        });
        if (!this.schoolCalendar) {
          this.schoolCalendar =
            this.schoolYearOptions2.length > 0
              ? this.schoolYearOptions2.find(item => item.label.includes('Current Year')).value
              : null;
        }
      });

  }

  openFindSchool() {
    this.actualAwardModel = this.awardModels.filter(
      (item) =>
        item.FK_SchoolYear ==
        this.studentGradeLevelsSchoolYearsModel.FK_SchoolYear
    )[0];
    this.findSchoolDisplay = true;
  }

  openCreateNewPaymentVendor() {
    this.createNewPaymentVendorDisplay = true;
  }

  openEnrollmentVerificationModal(e) {
    console.log(e);
    if (this.loginEditRole === false) {
      return;
    }
    this.studentService
      .getSchoolCalendarLookUpList({
        fK_SchoolYear: e.award.FK_SchoolYear,
        fK_School: e.SchoolId,
      })
      .subscribe((sy) => {
        this.enrollmentVerificationData['startDate'] =
          sy.Data[0].SchoolYearStart;
        this.enrollmentVerificationData['endDate'] = sy.Data[0].SchoolYearEnd;
      });
    this.enrollmentVerificationData['studentName'] = e.award.FK_Student_Display;
    this.enrollmentVerificationData['schoolName'] = e.School;
    this.enrollmentVerificationData['award'] = e.award;
    this.enrollmentVerificationData['verification'] = e.verification;
    this.enrollmentVerificationDisplay = true;
  }

  doViewButtonSchoolCommitment(e) {
    this.schoolToCommitment = e.data;
    this.studentService.getFeesBySchool(e.data.PK_Id).subscribe((x) => {
      if (x.Success) {
        this.generateSchoolFeesTableData(<Array<object>>x.Data);
      }
    });
    console.log(this.schoolToCommitment);
    this.schoolCommitmentStudentDisplay = true;
  }

  sendMessage() { }

  saveVendorTransaction() {
    this.newVendorTransactionModel.FK_Student = this.id;
    this.newVendorTransactionModel.FK_Guardian = this.actualAwardModel.FK_Guardian;
    this.newVendorTransactionModel.FK_SchoolYear = this.actualAwardModel.FK_SchoolYear;
    this.newVendorTransactionModel.FK_PreAuthorization = 5;
    this.newVendorTransactionModel.FK_Login_CreatedBy = this.model.FK_Login_CreatedBy;
    this.studentService.insertVendorTransaction(this.newVendorTransactionModel)
      .subscribe((res) => {
        if (res.Success) {
          this.generateVendorsTableData();
          this.createNewPaymentVendorDisplay = false;
        }
      });
  }

  saveEnrollmentVerification() {
    this.verificationNote.FK_Verification = this.enrollmentVerificationData.verification.PK_Id;
    this.verificationNote.FK_NoteType =
      this.loginRoleNumber === RoleType.Admin
        ? 2
        : this.loginRoleNumber === RoleType.SchoolAdmin
          ? 0
          : this.loginRoleNumber === RoleType.GuardianPrimary
            ? 1
            : 3;
    this.verificationNote.Note = this.explainText;
    this.verificationNote.NoteActive = true;
    this.studentService.insertVerificationNote(this.verificationNote)
      .subscribe((res) => {
        if (res.Success) {
          this.enrollmentVerificationDisplay = false;
        }
      });
  }

  isValidEnrollmentVerification() {
    if (
      (this.question1 !== undefined &&
        this.question2 !== undefined &&
        this.explainText !== '') ||
      (this.question1 === 'Yes' && this.question2 === 'Yes')
    ) {
      return true;
    }
    return false;
  }

  withdrawSchool() {
    this.studentGradeLevelsSchoolYearsModel.FK_School = null;
    this.studentService.updateStudentGradeLevelsSchoolYearsLookUp(this.studentGradeLevelsSchoolYearsModel)
      .subscribe((res) => {
        if (res.Success) {
          this.studentGradeLevelsSchoolYearsModel = { ...this.studentGradeLevelsSchoolYearsModel };
          window.location.reload(); //TODO: Why?
        }
      });
  }

  searchSchool(e) {
    this.schoolNameOrAddress = e.target.value;
    this.generateSchoolTableData();
  }

  searchVendorTransaction(e) {
    this.descriptionVendor = e.target.value;
    this.generateVendorsTableData();
  }

  submitSchoolCommitment() {
    this.studentGradeLevelsSchoolYearsModel.FK_School = this.schoolToCommitment.PK_Id;
    let awd = new AwardDeterminationModel();
    awd.FK_Guardian = this.guardianModel.PK_Id;
    this.studentService.getSchoolScholarshipsLookUpList({ fK_School: this.schoolToCommitment.PK_Id })
      .subscribe((r) => {
        awd.FK_Scholarship = r.Data[0].FK_Scholarship;
      });
    awd.FK_Student = this.id;
    awd.FK_GradeLevel = this.studentGradeLevelsSchoolYearsModel.FK_GradeLevel;
    awd.AwardAmount = 7500; //TODO: verificar de donde obtener amount award
    awd.DeterminationReason = 'test';
    awd.WasNotificationSent = false;
    awd.FK_SchoolYear = this.studentGradeLevelsSchoolYearsModel.FK_SchoolYear;
    this.studentService.updateStudentGradeLevelsSchoolYearsLookUp(this.studentGradeLevelsSchoolYearsModel)
      .subscribe((res) => {
        if (res.Success) {
          this.studentService
            .insertAwardDetermination(awd)
            .subscribe((resAW) => {
              if (resAW.Success) {
                let verification = new VerificationModel();
                verification.FK_StudentGradeLevelsSchoolYearsLookUp = this.studentGradeLevelsSchoolYearsModel.PK_Id;
                verification.FK_AwardDetermination = resAW.Data;
                verification.FK_GradingPeriodType = 0;
                verification.NumberOfAbsences = 0;
                verification.HouseholdCurrent = true;
                verification.StillEnrolled = true;
                this.studentService.insertVerification(verification)
                  .subscribe((resAW) => {
                    if (resAW.Success) {
                      window.location.reload(); //TODO: Why?
                    }
                  });
              }
            });
        }
      });
  }

  cancelProfile() {
    this.findSchoolDisplay = false;
  }

  cancelCreateVendorTransaction() {
    this.createNewPaymentVendorDisplay = false;
  }

  cancelSchoolCommitment() {
    this.schoolCommitmentStudentDisplay = false;
  }

  cancelEnrollmentVerification() {
    this.enrollmentVerificationDisplay = false;
  }

  private generateSchoolFeesTableData(dataSchoolFees: Array<object>) {
    this.schoolFeesTableData = [];
    this.schoolFeesData = [];
    this.generateSchoolFeesTableColumns(dataSchoolFees);

    if (dataSchoolFees !== undefined || dataSchoolFees.length != 0) {
      for (let index = 0; index < dataSchoolFees.length; index++) {
        let data = dataSchoolFees[index]['Data'];
        this.schoolFeesData.push(
          `${dataSchoolFees[index]['SchoolYear']} Fee Schedule`
        );
        this.schoolFeesTableData.push(data);
      }
    }
  }

  private generateSchoolFeesTableColumns(dataSchoolFees: Array<object>) {
    let columns: ITableColumnDefinition[] = [];
    let cols = dataSchoolFees[0];
    if (cols !== null && cols !== undefined) {
      let columnsSchoolFees = cols['Columns'];
      columns.push({
        fieldCaption: columnsSchoolFees[0],
        fieldName: columnsSchoolFees[0],
        fieldType: FieldTypeDefinition.String,
      });
      for (let index = 1; index < columnsSchoolFees.length; index++) {
        columns.push({
          fieldCaption: columnsSchoolFees[index],
          fieldName: columnsSchoolFees[index],
          fieldType: FieldTypeDefinition.Money,
        });
      }
    } else {
      columns.push({
        fieldCaption: 'Data',
        fieldName: 'Data',
        fieldType: FieldTypeDefinition.String,
      });
    }
    this.schoolFeesTableColumns = [...columns];
  }

  private generateVendorsTableData() {
    this.generateVendorsTableColumns();
    this.vendorsTableData = [];
    let data = [];
    this.studentService.getVendorTransactionList({ fK_Student: this.id, description_like: this.descriptionVendor })
      .subscribe((res) => {
        if (res.Success) {
          res.Data.forEach((vendor) => {
            let rowData = {};
            this.studentService.getPreAuthorization(vendor.FK_PreAuthorization)
              .subscribe((preauth) => {
                let statusTable = '';
                if (preauth.Data.FK_PreAuthorizationStatusType === 3) {
                  statusTable =
                    "<i class='fas fa-check-circle text-success h3'></i>";
                } else if (preauth.Data.FK_PreAuthorizationStatusType === 2) {
                  statusTable =
                    "<i class='far fa-check-circle text-success h3'></i>";
                } else if (preauth.Data.FK_PreAuthorizationStatusType === 1) {
                  statusTable =
                    "<i class='fas fa-arrow-circle-right text-muted h3'></i>";
                } else if (preauth.Data.FK_PreAuthorizationStatusType === 0) {
                  statusTable =
                    "<i class='far fa-times-circle text-danger h3'></i>";
                } else if (preauth.Data.FK_PreAuthorizationStatusType === 4) {
                  statusTable =
                    "<i class='fas fa-times-circle text-danger h3'></i>";
                }
                rowData['Status'] = statusTable;
                rowData['Type'] = preauth.Data.ServiceDescription;
              });
            rowData['Request Date'] = vendor.CreatedDate;
            rowData['Vendor'] = vendor.FK_Vendor_Display;
            rowData['Description'] = vendor.Description;
            rowData['Amount'] = vendor.Amount;
            data.push(rowData);
          });
        }
      });
    this.vendorsTableData.push(data);
  }

  private generateVendorsTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Status',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.HTML,
    });
    columns.push({
      fieldCaption: 'Request Date',
      fieldName: 'Request Date',
      fieldType: FieldTypeDefinition.Date,
    });
    columns.push({
      fieldCaption: 'Type',
      fieldName: 'Type',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Vendor',
      fieldName: 'Vendor',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Description',
      fieldName: 'Description',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Amount',
      fieldName: 'Amount',
      fieldType: FieldTypeDefinition.Money,
    });
    this.vendorsTableColumns = [...columns];
  }

  private generatePaymentsTableData() {
    this.generatePaymentsTableColumns();
    this.paymentsTableData = [];
    let data = [];
    this.studentService.getStudent(this.id)
      .subscribe((res) => {
        if (res.Success) {
          this.studentService.getStudentGradeLevelsSchoolYearsLookUpList({ fK_Student: this.id })
            .subscribe((res) => {
              if (res.Data[0]) {
                this.studentService.getSchoolTransactionList({ fK_StudentGradeLevelsSchoolYearsLookUp: res.Data[0].PK_Id })
                  .subscribe((res) => {
                    res.Data.forEach((transaction) => {
                      let rowData = {};
                      rowData['Disbursement Date'] = transaction.CreatedDate;
                      rowData['Description'] = transaction.FK_StudentGradeLevelsSchoolYearsLookUp;
                      rowData['Amount'] = transaction.Amount;
                      rowData['Status'] = transaction.FK_TransactionStatusType_Display;
                      data.push(rowData);
                    });
                  });
              }
            });
        }
      });

    this.paymentsTableData.push(data);
  }

  private generatePaymentsTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Disbursement Date',
      fieldName: 'Disbursement Date',
      fieldType: FieldTypeDefinition.Date,
    });
    columns.push({
      fieldCaption: 'Description',
      fieldName: 'Description',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'Amount',
      fieldName: 'Amount',
      fieldType: FieldTypeDefinition.Money,
    });
    columns.push({
      fieldCaption: 'Status',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.String,
    });
    this.paymentsTableColumns = [...columns];
  }

  private generateAwardTableData() {
    this.generateAwardTableColumns();
    this.awardTableData = [];
    let data = [];
    this.studentService
      .getAwardDeterminationList({ fK_Student: this.id })
      .subscribe((res) => {
        console.log(res.Data);
        res.Data.forEach((award) => {
          let rowData = {};
          let statusTable = '';
          /*
          if (
            award.FK_AwardDeterminationStatusType_Display.includes('Eligible')
          ) {
            statusTable = "<i class='fas fa-check-circle text-success h3'></i>";
          }
          */
          rowData['Status'] = statusTable;
          rowData['Due Date'] = award.FK_SchoolYear_Display;
          rowData['Report Date'] = award.FK_SchoolYear_Display;
          rowData['award'] = award;
          this.studentService.getVerificationList({ fK_AwardDetermination: award.PK_Id })
            .subscribe((res) => {
              if (res.Data[0]) {
                rowData['verification'] = res.Data[0];
                this.studentService.getStudentGradeLevelsSchoolYearsLookUpList({ pK_Id: res.Data[0].FK_StudentGradeLevelsSchoolYearsLookUp })
                  .subscribe((res) => {
                    rowData['SchoolId'] = res.Data[0].FK_School;
                    rowData['School'] = res.Data[0].FK_School_Display;
                  });
              }
            });

          data.push(rowData);
        });
      });

    this.awardTableData.push(data);
  }

  private generateAwardTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    if (this.loginEditRole === true) {
      columns.push({
        fieldCaption: 'Status',
        fieldName: 'Status',
        fieldType: FieldTypeDefinition.HTML,
      });
      columns.push({
        fieldCaption: 'Due Date',
        fieldName: 'Due Date',
        fieldType: FieldTypeDefinition.String,
      });
      columns.push({
        fieldCaption: 'School',
        fieldName: 'School',
        fieldType: FieldTypeDefinition.String,
      });
    }
    else {
      if (this.gl.LoginRoles.includes(RoleType.SchoolAdmin) || this.gl.LoginRoles.includes(RoleType.SchoolUser)) {
        columns.push({
          fieldCaption: 'Report Date',
          fieldName: 'Report Date',
          fieldType: FieldTypeDefinition.String,
        });
      }
      columns.push({
        fieldCaption: 'Due Date',
        fieldName: 'Due Date',
        fieldType: FieldTypeDefinition.String,
      });
      columns.push({
        fieldCaption: 'Status',
        fieldName: 'Status',
        fieldType: FieldTypeDefinition.HTML,
      });
      columns.push({
        fieldCaption: 'School',
        fieldName: 'School',
        fieldType: FieldTypeDefinition.String,
      });
    }

    this.awardTableColumns = [...columns];
  }

  private generateStudentsTableColumn() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'Status',
      fieldName: 'Status',
      fieldType: FieldTypeDefinition.Boolean,
      width: '10%'
    });
    columns.push({
      fieldCaption: 'Student Name',
      fieldName: 'Name',
      fieldType: FieldTypeDefinition.String,
      sortable: true,
    });
    columns.push({
      fieldCaption: 'Grade',
      fieldName: 'Grade',
      fieldType: FieldTypeDefinition.String,
      width: '10%'
    });
    columns.push({
      fieldCaption: 'Guardian Name',
      fieldName: 'GuardianName',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'State',
      fieldName: 'State',
      fieldType: FieldTypeDefinition.String,
      width: '10%'
    });
    columns.push({
      fieldCaption: 'Scholarship Type',
      fieldName: 'ScholarshipType',
      fieldType: FieldTypeDefinition.String,
    });

    this.studentTableColumns = [...columns];
  }

  changeState(e) {
    this.loadStudentData(this.lastLazyLoadEvent);
  }

  loadStudentData(event: LazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
      this.usersTableLoading = true;
      const page = (event.first / event.rows) + 1;
      const pageSize = event.rows;
      const sortField = event.sortField;
      const statefilter = null;
      const schoolYearFilter = !this.schoolCalendar ? null : this.schoolCalendar.FK_SchoolYear;
      console.log('schoolYearFilter: ', schoolYearFilter);
      const sortFieldDesc = event.sortOrder < 0;
      const guardianID = this.guardianModel.PK_Id.toString();
      console.log('guardianID: ', guardianID);
      console.log('page: ', page, '  pageSize: ', pageSize);

      this.adminServices.doSearchAwardDeterminationList(null, null, schoolYearFilter?.toString(), guardianID, sortField, sortFieldDesc, page, pageSize)
        .subscribe(responseLogins => {
          console.log(responseLogins);
          this.usersTableTotalRecords = responseLogins.RecordsCount;
          this.studentsModels = responseLogins.Data;
          this.generateStudentsTableData();
          this.usersTableLoading = false;
        });

    }
  }

  private generateStudentsTableData() {
    this.generateStudentsTableColumn();
    this.studentTableData = this.studentsModels.map(i => {
      return {
        'PK_Id': i.PK_Id,
        'Name': i.FK_Student_Display,
        'Grade': i.FK_GradeLevel_Display,
        'GuardianName': i.FK_Guardian_Display,
        'State': i.FK_State_Display,
        'ScholarshipType': i.FK_Scholarship_Display,
        'FK_Student': i.FK_Student
      }
    });
    this.studentTableData.forEach((s) => {
    });
  }

  private generateSchoolTableData() {
    this.generateSchoolTableColumns();
    this.schoolTableData = [];
    this.studentService
      .getSchoolList({
        name_like: this.schoolNameOrAddress,
      })
      .subscribe((res) => {
        let gradeData = [];
        this.schoolSearchList = res.Data;
        console.log(res.Data);
        res.Data.forEach((school) => {
          let rowData = {};
          rowData['School Name'] = school.Name;
          rowData['Street Address'] = school.Physical_StreetAddress1;
          rowData['City'] = school.Physical_City;
          rowData['County'] = school.FK_County_Physical_Display;
          rowData['State'] = school.FK_State_Physical_Display;
          rowData['data'] = school;
          gradeData.push(rowData);
        });
        this.schoolTableData.push(gradeData);
      });
  }

  private generateSchoolTableColumns() {
    let columns: ITableColumnDefinition[] = [];
    columns.push({
      fieldCaption: 'School Name',
      fieldName: 'School Name',
      sortable: true,
      fieldType: FieldTypeDefinition.String,
      width: '350px',
    });
    columns.push({
      fieldCaption: 'Street Address',
      fieldName: 'Street Address',
      fieldType: FieldTypeDefinition.String,
      width: '250px',
    });
    columns.push({
      fieldCaption: 'City',
      fieldName: 'City',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'County',
      fieldName: 'County',
      fieldType: FieldTypeDefinition.String,
    });
    columns.push({
      fieldCaption: 'State',
      fieldName: 'State',
      fieldType: FieldTypeDefinition.String,
    });
    this.schoolTableColumns = [...columns];
  }

  /*
    Payment Methods
  */
  onPaymentViewDetails() {
    this.editPaymentDetailsViewDisplay = true;
  }

  onClosePaymentDetails() {
    this.editPaymentDetailsViewDisplay = false;
  }
}
