import { Component, OnInit, Directive } from '@angular/core';
import { IContactsLookUpListParams } from '../../models/entities/ContactsLookUpModel.response';
import { ContactsLookUpModel } from '../../models/entities/ContactsLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { ContactsLookUpListBaseComponent } from './contactsLookUpList.base';

@Component({
    selector: 'app-contactsLookUpList',
    templateUrl: './contactsLookUpList.component.html',
})
export class ContactsLookUpListComponent extends ContactsLookUpListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
