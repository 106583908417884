import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrepaidCollegeTransferStatusTypeEditBaseComponent } from './prepaidCollegeTransferStatusTypeEdit.base';

@Component({
	selector: 'app-prepaidCollegeTransferStatusTypeEdit',
	templateUrl: './prepaidCollegeTransferStatusTypeEdit.component.html',
})
export class PrepaidCollegeTransferStatusTypeEditComponent extends PrepaidCollegeTransferStatusTypeEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
