import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolTransactionNoteEditBaseComponent } from './schoolTransactionNoteEdit.base';

@Component({
    selector: 'app-schoolTransactionNoteEdit',
    templateUrl: './schoolTransactionNoteEdit.component.html',
})
export class SchoolTransactionNoteEditComponent extends SchoolTransactionNoteEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
