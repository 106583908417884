<div class="p-2">
  <p-tabView>
    <p-tabPanel header="Incoming Commitments & Withdrawals">
      <div class="row">
        <div class="col-12">
          <app-custom-table [data]="pendingStudentsTableData" [columns]="pendingStudentsTableColumns" [rows]="10"
            (onRowTableClicked)="doPendingStudentsRowClicked($event)">
          </app-custom-table>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="All Students">
      <div class="row mb-3">
        <div class="col-3 d-flex flex-column">
          <span class="form-label font-weight-bold">Search Students</span>
          <input type="text" class="form" [(ngModel)]="searchText" (ngModelChange)="doSearch()" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 px-3">
          <app-custom-table [data]="allStudentsTableData" [columns]="allStudentsTableColumns" [rows]="10"
            (onRowTableClicked)="doAllStudentsRowClicked($event)">
          </app-custom-table>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <p-sidebar [(visible)]="editViewDisplay" position="right" [style]="{width:'80%'}" [showCloseIcon]="false">
    <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
      <div class="row p-1">
        <div class="col-12">
          <h3 class="text-subtitle font-weight-bold">School Commitment Form</h3>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <h2 class="text-header">{{ selectedRow?.StudentName }}</h2>
          <h4>Grade {{ selectedRow?.GradeLevel }}</h4>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-3">
          <div class="form-group">
            <div class="no-padding">
              <label class="form-label font-weight-bold mb-0">
                Scholarship Up To Amount
              </label>
              <span class="form-control no-border p-0"><i>Amount subject to change.</i></span>
            </div>
          </div>
        </div>
        <div class="col-9">
          <span class="form-control no-border">
            {{ selectedAwardDetermination?.AwardAmount | currency:'USD':'symbol':'1.0' }}
          </span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-3">
          <label class="form-label font-weight-bold">
            Parent/Guardian
          </label>
        </div>
        <div class="col-9">
          <span class="form-control no-border">
            {{ selectedGuardian?.FirstName }} {{ selectedGuardian?.LastName }}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-3">
          <label class="form-label font-weight-bold">
            Address
          </label>
        </div>
        <div class="col-9">
          <div class="form-group">
            <div class="no-padding">
              <span class="form-control no-border">{{ selectedHousehold?.Address1 }}</span>
              <span class="form-control no-border" *ngIf="selectedHousehold?.Address2">
                {{ selectedHousehold?.Address2 }}
              </span>
              <span class="form-control no-border">
                {{ selectedHousehold?.City }}, {{ selectedHousehold?.FK_State_Display }}
                {{ selectedHousehold?.Zipcode }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-3">
          <label class="form-label font-weight-bold">
            Employer
          </label>
        </div>
        <div class="col-9">
          <span class="form-control no-border">The Employer Name</span>
        </div>
      </div>
      <div class="row p-1 my-3">
        <div class="col-6">
          <label class="form-label font-weight-bold">
            Student's First Day in this School for
            {{ selectedRow?.FK_SchoolYear_Display }}<span class="text-danger">*</span>
          </label>
          <p-calendar [(ngModel)]="startDate" class="form-control no-border" dateFormat="mm/dd/yy" [showIcon]="true"
            appendTo="body" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearCalendarRange"
            icon="fas fa-calendar">
          </p-calendar>
        </div>
      </div>
      <div class="row p-1 my-3">
        <div class="col-12">
          <h4 class="text-subtitle">School Conditions of Eligibility</h4>
          <span><i>All </i>questions must be answered “Yes” for the student to qualify for funding.</span>
        </div>
      </div>
      <div *ngFor="let schoolEligibility of schoolEligibilitiesList; let i = index">
        <div class="row p-1">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label font-weight-bold" [innerHTML]="schoolEligibility.FK_SchoolEligibility_Display">
              </label>
              <span class="d-flex justify-content-left">
                <div class="p-field-radiobutton d-flex align-items-start h-100">
                  <p-radioButton name="{{ schoolEligibility.PK_Id }}" [value]="true"
                    [(ngModel)]="schoolEligibilitiesResponses[schoolEligibility.PK_Id]"
                    inputId="{{ schoolEligibility.PK_Id }}Yes">
                  </p-radioButton>
                  <label for="{{ schoolEligibility.PK_Id }}Yes">Yes</label>
                </div>
                <div class="p-field-radiobutton d-flex align-items-start h-100 ml-4">
                  <p-radioButton name="{{ schoolEligibility.PK_Id }}" [value]="false"
                    [(ngModel)]="schoolEligibilitiesResponses[schoolEligibility.PK_Id]"
                    inputId="{{ schoolEligibility.PK_Id }}No">
                  </p-radioButton>
                  <label for="{{ schoolEligibility.PK_Id }}No">No</label>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row p-1 my-3">
        <div class="col-12">
          <h4 class="text-subtitle">Annual Published Fees & Applicable Discounts</h4>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <app-custom-table [data]="schoolFeeTableData" [columns]="schoolFeeTableColumns" [rows]="10">
          </app-custom-table>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <button type="button" class="btn btn-primary" (click)="addCustomFee()" *ngIf="customSchoolFeeList.length < 3">
            Add Fee
          </button>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-9">
          <div class="flex-grid">
            <div *ngFor="let feeModel of customSchoolFeeList; let i = index" class="flex-grid-col">
              <div class="d-flex flex-column">
                <div class="form-group">
                  <label class="form-label font-weight-bold">
                    Description <span class="text-danger">*</span>
                  </label>
                  <input type="text" maxlength="30" class="form-control p-0"
                    [(ngModel)]="customSchoolFeeList[i].Description" (ngModelChange)="updateFeeTable()" />
                </div>
                <div class="fom-group">
                  <label class="form-label font-weight-bold">
                    Amount <span class="text-danger">*</span>
                  </label>
                  <p-inputNumber class="form-control no-border p-0" [(ngModel)]="customSchoolFeeList[i].Amount"
                    mode="currency" currency="USD" locale="en-US" (ngModelChange)="updateFeeTable()">
                  </p-inputNumber>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row p-1 mt-3">
        <div class="col-9">
          <div class="flex-grid">
            <div *ngFor="let feeModel of feeTypesListForDiscount; let i = index" class="form-group flex-grid-col">
              <label class="form-label font-weight-bold">
                {{ feeModel.Name }} Discount<span class="text-danger">*</span>
              </label>
              <p-inputNumber class="form-control no-border p-0" [(ngModel)]="discountFeeModel[feeModel.PK_Id]"
                mode="currency" currency="USD" locale="en-US" (ngModelChange)="calculateTotal()">
              </p-inputNumber>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="form-label font-weight-bold">
              Calculated Total<span class="text-danger">*</span>
            </label>
            <p-inputNumber class="form-control no-border p-0" [(ngModel)]="calculatedTotal" mode="currency"
              currency="USD" locale="en-US" [disabled]="true">
            </p-inputNumber>
          </div>
        </div>
      </div>
      <div class="row p-1 mt-3">
        <div class="col-12">
          <input type="checkbox" [(ngModel)]="scfConfirmed">
          I acknowledge that payments by AAA are strictly contingent upon the school and family meeting and
          maintaining
          the above conditions of eligibility, upon the family remaining current on all fees and additional tuition
          payments, and the student meeting attendance requirements (no more than 18 days absence per school year).
          I
          agree to monitor this situation and report to AAA if there is a default in the parent’s portion of their
          required payments for this student, or if anything occurs that would disqualify the family from continuing
          to
          participate in the AAA Scholarship Program. I agree to provide access to such documentation, if any, for
          audit
          purposes, by AAA or its representatives. Finally, I certify the above information is correct and agree to
          the
          terms outlined in this form.
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <button class="btn btn-primary mx-2 w-25" type="button" (click)="submitSCF()" [disabled]="!isValidSCF()">
            Submit
          </button>
          <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelSCF()">
            Cancel
          </button>
        </div>
      </div>
    </p-scrollPanel>
  </p-sidebar>
</div>