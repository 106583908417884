<div class="body-login">
  <div class="d-flex flex-column align-items-center">
    <img src="assets/aaa-logo.png" class="p-5 mt-5" />
    <div class="card p-3">
      <div class="card-header py-3">
        <span class="text-header">{{ (!forgotPassword) ? 'Sign In' : 'Forgot Password' }}</span>
      </div>
      <div class="card-body">

        <div class="form" *ngIf="!forgotPassword">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold">Email Address <span class="text-danger">*</span></label>
                <input [(ngModel)]="model.Email" type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold">Password <span class="text-danger">*</span></label>
                <input [(ngModel)]="model.Password" type="password" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <button class="btn btn-primary form-control" type="button" (click)="login()"
                  [disabled]="!validateData()">
                  Sign In
                </button>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <button class="btn btn-outline-secondary form-control" type="button" (click)="goToForgotPassword()">
                  Forgot Password
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form" *ngIf="forgotPassword">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold">Email Address <span class="text-danger">*</span></label>
                <input [(ngModel)]="model.Email" type="text" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <button class="btn btn-primary form-control" type="button" (click)="resetPassword()"
                  [disabled]="!validateEmail()">
                  Reset Password
                </button>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <button class="btn btn-outline-danger form-control" type="button" (click)="backToLogin()">
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>