import { Component, OnInit, Directive } from '@angular/core';
import {  IPrepaidCollegeTransferStatusTypeListParams } from '../../models/entities/PrepaidCollegeTransferStatusTypeModel.response';
import { PrepaidCollegeTransferStatusTypeModel } from '../../models/entities/PrepaidCollegeTransferStatusTypeModel';
import { PagingData } from '../../paging/paging.component';
import { PrepaidCollegeTransferStatusTypeListBaseComponent } from './prepaidCollegeTransferStatusTypeList.base';

@Component({
	selector: 'app-prepaidCollegeTransferStatusTypeList',
	templateUrl: './prepaidCollegeTransferStatusTypeList.component.html',
})
export class PrepaidCollegeTransferStatusTypeListComponent extends PrepaidCollegeTransferStatusTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
