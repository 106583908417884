import { ScholarshipDisbursementCategoryModel} from '../entities/ScholarshipDisbursementCategoryModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ScholarshipDisbursementCategoryResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ScholarshipDisbursementCategoryModel = new ScholarshipDisbursementCategoryModel();
}

export class ScholarshipDisbursementCategoryList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ScholarshipDisbursementCategoryModel> = <Array<ScholarshipDisbursementCategoryModel >>[]
}

export interface IScholarshipDisbursementCategoryListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ScholarshipDisbursementCategoryListParams implements IScholarshipDisbursementCategoryListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

