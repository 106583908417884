import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScholarshipEditBaseComponent } from './scholarshipEdit.base';

@Component({
	selector: 'app-scholarshipEdit',
	templateUrl: './scholarshipEdit.component.html',
})
export class ScholarshipEditComponent extends ScholarshipEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
