import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolCalendarLookUpEditBaseComponent } from './schoolCalendarLookUpEdit.base';

@Component({
	selector: 'app-schoolCalendarLookUpEdit',
	templateUrl: './schoolCalendarLookUpEdit.component.html',
})
export class SchoolCalendarLookUpEditComponent extends SchoolCalendarLookUpEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
