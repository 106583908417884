import { Component, OnInit, Directive } from '@angular/core';
import { IDocumentListParams } from '../../models/entities/DocumentModel.response';
import { DocumentModel } from '../../models/entities/DocumentModel';
import { PagingData } from '../../paging/paging.component';
import { DocumentListBaseComponent } from './documentList.base';

@Component({
    selector: 'app-documentList',
    templateUrl: './documentList.component.html',
})
export class DocumentListComponent extends DocumentListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
