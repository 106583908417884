import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public caption: string = null;

  @Input('label')
  public set label(value: string) {
    this.caption = value;
  }

  @Output('modelChange')
  public modelChange: EventEmitter<any> = new EventEmitter<any>();

  @Input('model')
  public set model(value: any) {
    this._checked = value;
  }


  public _nullable: boolean = false;

  @Input('nullable')
  public set nullable(value: boolean) {
    this._nullable = value;
  }

  private _checked?: boolean = false;
  public set checked(value: any) {
    this._checked = value;
    this.modelChange.emit(value);

  }

  public get checked(): any {
    return this._checked;

  }

  public toggle() {
    this.checked == null ? this.checked = true : (this.checked ? this.checked = false : this.checked = null)
  }

}
