import { VerificationStatusTypeModel} from '../entities/VerificationStatusTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class VerificationStatusTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: VerificationStatusTypeModel = new VerificationStatusTypeModel();
}

export class VerificationStatusTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<VerificationStatusTypeModel> = <Array<VerificationStatusTypeModel >>[]
}

export interface IVerificationStatusTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class VerificationStatusTypeListParams implements IVerificationStatusTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

