import {IListItem } from '../models';
export class SchoolCommitmentFeesLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_SchoolFee:number = 0;
	public FK_SchoolFee_Display:string = null;
	public FK_SchoolCommitment:number = 0;
	public DiscountAmount?:number = null;
}
