<div class="card">
  <div class="card-header">
    Scholarship Disbursement Categories Look Up <span class="right">
      <a title="Cancel" *ngIf="!ModalView" routerLink="/scholarshipdisbursementcategorieslookuplist"><i class="fa fa-undo icon-link"></i></a>
      <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
      <i class="fa fa-save icon-link" (click)="save()"></i>
    </span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Scholarship</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_Scholarship" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_Scholarship_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Scholarship Disbursement Category</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_ScholarshipDisbursementCategory" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_ScholarshipDisbursementCategory_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
<span title="Required" class="required">*</span>
            <app-checkbox [(model)]="model.IsEnabled" [label]="'Is Enabled'"></app-checkbox>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">

            <app-checkbox [(model)]="model.PreAuthorizationRequired" [label]="'Pre Authorization Required'"></app-checkbox>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">

            <app-checkbox [(model)]="model.LicensedInstructors" [label]="'Licensed Instructors'"></app-checkbox>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">

            <app-checkbox [(model)]="model.DocumentUploadForApproval" [label]="'Document Upload For Approval'"></app-checkbox>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
