<div class="card">
  <div class="card-header">
    School Commitment Fees Look Up <span class="right">
  <a title="Cancel" *ngIf="!ModalView" routerLink="/schoolcommitmentfeeslookuplist"><i class="fa fa-undo icon-link"></i></a>
  <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
  <i class="fa fa-save icon-link" (click)="save()"></i>
</span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        								<div class="col-6">
									<div class="form-group">
										<label>School Fee</label><span title="Required" class="required">*</span>
										<select [(ngModel)]="model.FK_SchoolFee" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_SchoolFee_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>School Commitment</label><span title="Required" class="required">*</span>
										<input [(ngModel)]="model.FK_SchoolCommitment" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Discount Amount</label>
										<input [(ngModel)]="model.DiscountAmount" type="text" class="form-control">
									</div>
								</div>


      </div>
      

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
