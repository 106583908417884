import {IListItem } from '../models';
export class LoginRolesLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_Login:string = "";
	public FK_Login_Display:string = null;
	public FK_Role:number = 0;
	public FK_Role_Display:string = null;
}
