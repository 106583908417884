import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentEditBaseComponent } from './documentEdit.base';

@Component({
    selector: 'app-documentEdit',
    templateUrl: './documentEdit.component.html',
})
export class DocumentEditComponent extends DocumentEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
