import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolCommitmentStatusTypeEditBaseComponent } from './schoolCommitmentStatusTypeEdit.base';

@Component({
	selector: 'app-schoolCommitmentStatusTypeEdit',
	templateUrl: './schoolCommitmentStatusTypeEdit.component.html',
})
export class SchoolCommitmentStatusTypeEditComponent extends SchoolCommitmentStatusTypeEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
