import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError } from 'rxjs/operators';
import { ListItemModel } from '../models/models';


@Component({
  selector: 'app-typeahead-basic',
  templateUrl: './typeahead-basic.component.html',
  styleUrls: ['./typeahead-basic.component.css']
})
export class TypeaheadBasicComponent extends BaseComponent implements  OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    
  }

  model: ListItemModel = null;

  private _id: any = null;

  @Input('searchapi')
  public searchapi = null;

  @Input('id')
  public set id(value: any) {
    this._id = value;
    var me = this;
    window.setTimeout(function () { me.getListItem(); }, 200);
  }

  @Output('idChange')
  public idChange: EventEmitter<any> = new EventEmitter<any>();

  private getListItem() {
    if (!this._id) {
      this.model = null;
    } else {
      var sub = (<Observable<Array<ListItemModel>>>this.api[this.searchapi](null, 1, this._id)).subscribe(x => {
        this.model = x[0];
        sub.unsubscribe();
      });
    }
   
  }

  resultFormat(value: ListItemModel) {
    console.log('resultFormat');
    console.log(value);
    return value && value.ListDisplay ? value.ListDisplay : '';
  }


  inputFormat(value: ListItemModel) {
    console.log('inputFormat');
    console.log(value);
    if (value.ListDisplay) {
      return value.ListDisplay
    } else {
      return '';
    }
      
  }

  selected(value: any) {
    console.log('selected');
    console.log(value);
    this.idChange.emit(value.item.PK_Id);
    this.model = value.item;
  }

  search = (text$: Observable<string>) => {
    console.log('search invoked');
    console.log(JSON.stringify(text$));
    return text$.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      // switchMap allows returning an observable rather than maps array
      switchMap((searchText) => <Observable<Array<ListItemModel>>>this.api[this.searchapi](searchText, 20, null))
    );
  }

  public clear() {
    this.model = null;
    this.idChange.emit(null);
  }
}
