<div class="card">
  <div class="card-header">
    Household <span class="right">
  <a title="Cancel" *ngIf="!ModalView" routerLink="/householdlist"><i class="fa fa-undo icon-link"></i></a>
  <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
  <i class="fa fa-save icon-link" (click)="save()"></i>
</span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        								<div class="col-6">
									<div class="form-group">
										<label>Created Date</label><span title="Required" class="required">*</span>
<app-date-picker [(model)]="model.CreatedDate"></app-date-picker>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Number Of Guardians Living In Home</label>
										<input [(ngModel)]="model.NumberOfGuardiansLivingInHome" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Number Of Children Living In Home</label>
										<input [(ngModel)]="model.NumberOfChildrenLivingInHome" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Number Of Other People Living In Home</label>
										<input [(ngModel)]="model.NumberOfOtherPeopleLivingInHome" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Total Household Size</label>
										<input [(ngModel)]="model.TotalHouseholdSize" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Primary Spoken Language</label>
										<input [(ngModel)]="model.PrimarySpokenLanguage" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Household Income</label>
										<input [(ngModel)]="model.HouseholdIncome" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Address</label>
										<input [(ngModel)]="model.Address1" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Address</label>
										<input [(ngModel)]="model.Address2" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>City</label>
										<input [(ngModel)]="model.City" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>State</label>
										<select [(ngModel)]="model.FK_State" (ngModelChange)="getFK_County_Options(true)"  class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_State_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>Zipcode</label>
										<input [(ngModel)]="model.Zipcode" type="text" class="form-control">
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>County</label>
										<select [(ngModel)]="model.FK_County" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_County_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>


      </div>
      

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
