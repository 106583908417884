import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalsService } from './globals.service';
import { ErrorMessagesService } from './error-messages.service';
import { ManagerResponse, InsertResponse, ListItemModelResponse, ListItemModel, CheckboxListSave, PasswordResetPost } from '../models/models';
import { aaaAPIServiceBase } from './aaa.API.service.base';
import { UserLoginPost, UserLoginResponse } from '../models/componentModels';
import { LoginModel } from '../models/entities/LoginModel';
import { SchoolResponse } from '../models/entities/SchoolModel.response';
import { ContactList, ContactResponse } from '../models/entities/ContactModel.response';
import { StudentList } from '../models/entities/StudentModel.response';

@Injectable({
  providedIn: 'root'
})
export class aaaAPISchoolService extends aaaAPIServiceBase {

  constructor(_http: HttpClient, _gl: GlobalsService, _errorMessagesService: ErrorMessagesService) {
    super(_http, _gl, _errorMessagesService);
  }

  public getSchoolByContactId(contact_id: number): Observable<SchoolResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/School/?contact_id=${contact_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <SchoolResponse>res.body;
    }));
  }

  public getContactByLogin(login_id: string): Observable<ContactResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Contact/?fk_login=${login_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ContactResponse>res.body;
    }));
  }

  public getFeesBySchool(school_id: number): Observable<any> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/SchoolFee/GetFeeBySchool/?id=${school_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <any>res.body;
    }));
  }

  public getStudentsWithScholarshipTypeIdOnSchoolId(scholarship_type_id: number, school_id: number): Observable<StudentList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/School/GetStudentsByScholarshipType/${scholarship_type_id}/${school_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <StudentList>res.body;
    }));
  }

  public getContactBySchoolId(school_id: number): Observable<ContactResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Contact/GetContactBySchoolId/${school_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ContactResponse>res.body;
    }));
  }

  public doSearchContactList(schoolId: number, searchText: string, isEnabled: boolean, _sort?: string, _sortDesc?: boolean, _page?: number, _pageSize?: number): Observable<ContactList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Contact/SearchContactList/?school_id=${(schoolId != null ? encodeURI(schoolId.toString()) : '')}&searchText=${(searchText != null ? encodeURI(searchText.toString()) : '')}&_isEnabled=${(isEnabled != null ? encodeURI(isEnabled.toString()) : '')}&_sort=${(_sort != null ? encodeURI(_sort.toString()) : '')}&_sortDesc=${(_sortDesc != null ? encodeURI(_sortDesc.toString()) : '')}&_page=${(_page != null ? encodeURI(_page.toString()) : '')}&_pageSize=${(_pageSize != null ? encodeURI(_pageSize.toString()) : '')}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ContactList>res.body;
    }));
  }
}