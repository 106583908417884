import { CountyModel} from '../entities/CountyModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class CountyResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: CountyModel = new CountyModel();
}

export class CountyList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<CountyModel> = <Array<CountyModel >>[]
}

export interface ICountyListParams {
	pK_Id?:number;
	fK_State?:number;
	fK_State_display?:string;
	fK_State_like?:string;
	fK_State_fromValue?:number;
	fK_State_toValue?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class CountyListParams implements ICountyListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_State?:number=null;
	fK_State_display?:string=null;
	fK_State_like?:string=null;
	fK_State_fromValue?:number=null;
	fK_State_toValue?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

