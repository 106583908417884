<div class="card">
  <div class="card-header">
    Scholarship <span class="right">
      <a title="Cancel" *ngIf="!ModalView" routerLink="/scholarshiplist"><i class="fa fa-undo icon-link"></i></a>
      <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
      <i class="fa fa-save icon-link" (click)="save()"></i>
    </span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>State</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_State" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_State_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Name</label><span title="Required" class="required">*</span>
            <input [(ngModel)]="model.Name" type="text" class="form-control">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Scholarship Type</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_ScholarshipType" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_ScholarshipType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>School Year</label><span title="Required" class="required">*</span>
            <select [(ngModel)]="model.FK_SchoolYear" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_SchoolYear_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">

            <app-checkbox [(model)]="model.NonSchoolDisbursementAllowed" [label]="'Non School Disbursement Allowed'"></app-checkbox>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Length Of Scholarship Years</label>
            <input [(ngModel)]="model.LengthOfScholarshipYears" type="text" class="form-control">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">

            <app-checkbox [(model)]="model.RolloverUnusedFunds" [label]="'Rollover Unused Funds'"></app-checkbox>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">

            <app-checkbox [(model)]="model.VerifyIncomeOnRenewal" [label]="'Verify Income On Renewal'"></app-checkbox>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
