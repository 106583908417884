import { SchoolYearModel} from '../entities/SchoolYearModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolYearResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolYearModel = new SchoolYearModel();
}

export class SchoolYearList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolYearModel> = <Array<SchoolYearModel >>[]
}

export interface ISchoolYearListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolYearListParams implements ISchoolYearListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

