import { Injectable, NgZone } from '@angular/core';
import { ManagerResponse, RoleType } from '../models/models';
import { ErrorMessagesService } from './error-messages.service';
import { Router } from '@angular/router';
import { Location, DatePipe, CurrencyPipe, PercentPipe } from '@angular/common';
import {
  HttpErrorResponse,
  HttpClient,
  HttpResponse,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class GlobalsService {
  constructor(
    private errorMessagesService: ErrorMessagesService,
    private location: Location,
    private router: Router,
    private http: HttpClient
  ) { }

  public IsLocal: boolean = false;
  private datePipe = new DatePipe(navigator.language);
  private currencyPipe = new CurrencyPipe(navigator.language);
  private percentPipe = new PercentPipe(navigator.language);

  FormatDateShort(date: Date): string {
    if (date) {
      return this.datePipe.transform(moment.utc(date).toDate(), 'MM/dd/yyyy');
    } else {
      return null;
    }
  }

  FormatDateTime(date: Date): string {
    if (date) {
      return this.datePipe.transform(
        moment.utc(date).toDate(),
        'MM/dd/yyyy h:mm:ss a'
      );
    } else {
      return null;
    }
  }

  public get currentRouter(): Router {
    return this.router;
  }

  private _token: string = '';
  public set Token(value: string) {
    this._token = value;
    //console.log('set Token:' + value)
    this.setLocalValue('token_', value, 180);
  }
  public get Token(): string {
    if (!this._token) {
      this._token = this.getLocalValue('token_');
    }
    if (this._token == null) {
      this._token = '';
    }
    //console.log('get Token' + this._token);
    return this._token;
  }
  private baseUrl: string = null;

  private _loginname: string = '';
  public set LoginName(value: string) {
    this._loginname = value;
    this.setLocalValue('loginname_', value, 180);
  }
  public get LoginName(): string {
    if (!this._loginname) {
      this._loginname = this.getLocalValue('loginname_');
    }
    if (this._loginname == null) {
      this._loginname = '';
    }
    //console.log('get LoginName' + this._loginname);
    return this._loginname;
  }

  private _loginid: string = '';
  public set LoginId(value: string) {
    this._loginid = value;
    this.setLocalValue('loginid_', value, 180);
  }
  public get LoginId(): string {
    if (!this._loginid) {
      this._loginid = this.getLocalValue('loginid_');
    }
    if (this._loginid == null) {
      this._loginid = '';
    }
    //console.log('get LoginName' + this._loginname);
    return this._loginid;
  }

  private _loginfullname: string = '';
  public set LoginFullName(value: string) {
    this._loginfullname = value;
    this.setLocalValue('loginfullname_', value, 180);
  }
  public get LoginFullName(): string {
    if (!this._loginfullname) {
      this._loginfullname = this.getLocalValue('loginfullname_');
    }
    if (this._loginfullname == null) {
      this._loginfullname = '';
    }
    //console.log('get LoginName' + this._loginname);
    return this._loginfullname;
  }

  private _loginroles: Array<RoleType> = <Array<RoleType>>[];
  public set LoginRoles(value: Array<RoleType>) {
    this._loginroles = value;
    this.setLocalValue('loginroles_', JSON.stringify(value), 180);
  }
  public get LoginRoles(): Array<RoleType> {
    if (!this._loginroles) {
      var v = this.getLocalValue('loginroles_');
      if (v) {
        this._loginroles = <Array<RoleType>>JSON.parse(v);
      } else {
        this._loginroles = <Array<RoleType>>[];
      }
    }
    //console.log('get LoginRoles' + JSON.stringify(this._loginroles));
    return this._loginroles;
  }

  private _logintime: Date = null;
  public set LoginTime(value: Date) {
    this._logintime = value;
    this.setLocalValue('logintime_', JSON.stringify(value), 180);
  }
  public get LoginTime(): Date {
    if (!this._logintime) {
      var v = this.getLocalValue('logintime_');
      if (v) {
        this._logintime = new Date(v);
      } else {
        this._logintime = null;
      }
    }

    //console.log('get LoginTime' + JSON.stringify(this._logintime));
    return this._logintime;
  }

  private _selectedstudent: string = null;
  public set SelectedStudent(value: string) {
    this._selectedstudent = value;
    this.setLocalValue('selectedstudent_', JSON.stringify(value), 180);
  }
  public get SelectedStudent(): string {
    if (!this._selectedstudent) {
      var v = this.getLocalValue('selectedstudent_');
      if (v) {
        this._selectedstudent = v;
      } else {
        this._selectedstudent = null;
      }
    }

    return this._selectedstudent;
  }

  private _selecteduser: string = null;
  public set SelectedUser(value: string) {
    this._selecteduser = value;
    this.setLocalValue('selecteduser_', JSON.stringify(value), 180);
  }
  public get SelectedUser(): string {
    if (!this._selecteduser) {
      var v = this.getLocalValue('selecteduser_');
      if (v) {
        this._selecteduser = v;
      } else {
        this._selecteduser = null;
      }
    }

    return this._selecteduser;
  }

  public GoTo(page: string, id?: string, zone?: NgZone) {
    //this.location.path
    this.location.replaceState('/');
    var me = this;

    if (zone) {
      if (id != null) {
        zone.run(() => {
          this.router.navigateByUrl(page + '/' + id);
        });
      } else {
        zone.run(() => {
          this.router.navigateByUrl(page);
        });
      }
    } else {
      if (id != null) {
        this.router.navigateByUrl(page + '/' + id);
      } else {
        this.router.navigateByUrl(page);
      }
    }
  }

  //Test
  private ApiUrls: Array<any> = [
    {
      name: 'Local',
      host: 'http://localhost:4201',
      url: 'https://localhost:44384',
    },
    {
      name: 'Dev',
      host: 'http://localhost:4200',
      url: 'https://web-api.aaascholarships.solutions',
    },
    {
      name: 'Devsu Staging',
      host: 'https://staging.aaascholarships.solutions',
      url: 'https://web-api.aaascholarships.solutions',
    },
    {
      name: 'Amplify Staging',
      host: 'https://qa.d1wayrx36plsjz.amplifyapp.com',
      url: 'https://aaa-web-api-devsu.us-east-1.elasticbeanstalk.com',
    },
    {
      name: 'DevCloud',
      host: 'https://aaascholarships.solutions',
      url: 'https://web-api.aaascholarships.solutions',
    },
    //{ name: 'DevCloud', host: 'https://aaascholarships.solutions', url: 'https://web-api.aaascholarships.solutions' },
  ];

  public get BaseUrl(): string {
    if (!this.baseUrl) {
      this.ApiUrls.some((x) => {
        if (
          window.location
            .toString()
            .toLowerCase()
            .indexOf(x.host.toLowerCase()) > -1
        ) {
          this.baseUrl = x.url;
          console.log('Environment:' + x.name);
          return;
        }
      });
    }
    return this.baseUrl;
  }

  public setLocalValue(name, value, minutes) {
    var dt = null;
    if (minutes) {
      dt = new Date();
      dt.setMinutes(dt.getMinutes() + minutes);
    }

    var obj = { value: value, expire: dt };
    window.localStorage.setItem(name, JSON.stringify(obj));
  }

  public getLocalValue(name) {
    var v = window.localStorage.getItem(name);
    if (!v) {
      return null;
    }
    var obj = JSON.parse(v);
    if (obj.expire != null && obj.expire < new Date()) {
      window.localStorage.removeItem(name);
      return null;
    }
    return obj.value;
  }

  SetValidate(res: HttpResponse<any>, hideSuccessMessage?:boolean): boolean {
    var data = <ManagerResponse>res.body;
    this.errorMessagesService.showSpinner = false;

    var tk = res.headers.get("x-token");
    if (tk) {
      this.Token = tk;
    }

    if (!data.Success) {
      if (data.Unauthorized) {
        window.location.href = this.BaseUrl + "/login";
        return false;
      } else {
        this.errorMessagesService.addRange(data.Errors);
      }

      return false;
    } else if (data.SuccessMessage && !hideSuccessMessage) {
      this.errorMessagesService.successMessage = data.SuccessMessage;
      return true;
    }
    return true;
  }

  handleError(error: HttpErrorResponse) {
    if (error.status == 401) {
      window.location.href = '/userlogin';
      return;
    }
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
