import { ZengineLoginModel} from '../entities/ZengineLoginModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ZengineLoginResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ZengineLoginModel = new ZengineLoginModel();
}

export class ZengineLoginList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ZengineLoginModel> = <Array<ZengineLoginModel >>[]
}

export interface IZengineLoginListParams {
	pK_Id?:number;
	userName?:string;
	userName_like?:string;
	password?:string;
	password_like?:string;
	apiToken?:string;
	apiToken_like?:string;
	dateUpdated?:Date;
	dateUpdated_fromDate?:string;
	dateUpdated_toDate?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ZengineLoginListParams implements IZengineLoginListParams, ISearchModel  {
	pK_Id?:number=null;
	userName?:string=null;
	userName_like?:string=null;
	password?:string=null;
	password_like?:string=null;
	apiToken?:string=null;
	apiToken_like?:string=null;
	dateUpdated?:Date=null;
	dateUpdated_fromDate?:string=null;
	dateUpdated_toDate?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

