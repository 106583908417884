import { LoginRolesLookUpModel} from '../entities/LoginRolesLookUpModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class LoginRolesLookUpResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: LoginRolesLookUpModel = new LoginRolesLookUpModel();
}

export class LoginRolesLookUpList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<LoginRolesLookUpModel> = <Array<LoginRolesLookUpModel >>[]
}

export interface ILoginRolesLookUpListParams {
	pK_Id?:number;
	fK_Login?:string;
	fK_Login_display?:string;
	fK_Login_like?:string;
	fK_Role?:number;
	fK_Role_display?:string;
	fK_Role_like?:string;
	fK_Role_fromValue?:number;
	fK_Role_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class LoginRolesLookUpListParams implements ILoginRolesLookUpListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_Login?:string=null;
	fK_Login_display?:string=null;
	fK_Login_like?:string=null;
	fK_Role?:number=null;
	fK_Role_display?:string=null;
	fK_Role_like?:string=null;
	fK_Role_fromValue?:number=null;
	fK_Role_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

