import { Component, OnInit, Directive } from '@angular/core';
import { ZengineFormYearLookUpCheckBoxListByZengineFormBase } from './zengineFormYearLookUpCheckBoxListByZengineForm.base';
@Component({
  selector: 'app-zengineFormYearLookUpCheckBoxListByZengineForm',
  templateUrl: './zengineFormYearLookUpCheckBoxListByZengineForm.component.html',
})
export class ZengineFormYearLookUpCheckBoxListByZengineFormComponent extends ZengineFormYearLookUpCheckBoxListByZengineFormBase implements OnInit {
  constructor() { super(); }
  ngOnInit() { super.baseNgOnInit(); }
  
}
