import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolAccreditationsLookUpListParams } from '../../models/entities/SchoolAccreditationsLookUpModel.response';
import { SchoolAccreditationsLookUpModel } from '../../models/entities/SchoolAccreditationsLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolAccreditationsLookUpListBaseComponent } from './schoolAccreditationsLookUpList.base';

@Component({
	selector: 'app-schoolAccreditationsLookUpList',
	templateUrl: './schoolAccreditationsLookUpList.component.html',
})
export class SchoolAccreditationsLookUpListComponent extends SchoolAccreditationsLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
