import { SchoolEligibilitiesResponsModel} from '../entities/SchoolEligibilitiesResponsModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolEligibilitiesResponsResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolEligibilitiesResponsModel = new SchoolEligibilitiesResponsModel();
}

export class SchoolEligibilitiesResponsList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolEligibilitiesResponsModel> = <Array<SchoolEligibilitiesResponsModel >>[]
}

export interface ISchoolEligibilitiesResponsListParams {
	fK_SchoolEligibility?:number;
	fK_SchoolEligibility_display?:string;
	fK_SchoolEligibility_like?:string;
	fK_SchoolEligibility_fromValue?:number;
	fK_SchoolEligibility_toValue?:number;
	pK_Id?:number;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	fK_Guardian?:number;
	fK_Guardian_display?:string;
	fK_Guardian_like?:string;
	fK_Guardian_fromValue?:number;
	fK_Guardian_toValue?:number;
	valueBoolean?:boolean;
	valueBoolean_booleanValue?:boolean;
	fK_Login_AcceptedBy?:string;
	fK_Login_AcceptedBy_display?:string;
	fK_Login_AcceptedBy_like?:string;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolEligibilitiesResponsListParams implements ISchoolEligibilitiesResponsListParams, ISearchModel  {
	fK_SchoolEligibility?:number=null;
	fK_SchoolEligibility_display?:string=null;
	fK_SchoolEligibility_like?:string=null;
	fK_SchoolEligibility_fromValue?:number=null;
	fK_SchoolEligibility_toValue?:number=null;
	pK_Id?:number=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	fK_Guardian?:number=null;
	fK_Guardian_display?:string=null;
	fK_Guardian_like?:string=null;
	fK_Guardian_fromValue?:number=null;
	fK_Guardian_toValue?:number=null;
	valueBoolean?:boolean=null;
	valueBoolean_booleanValue?:boolean=null;
	fK_Login_AcceptedBy?:string=null;
	fK_Login_AcceptedBy_display?:string=null;
	fK_Login_AcceptedBy_like?:string=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

