import {IListItem } from '../models';
export class FeeDiscountModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public Name:string = "";
	public FK_SchoolFee:number = 0;
	public FK_SchoolFee_Display:string = null;
	public CustomAmount:boolean = false;
	public Amount?:number = null;
	public Description?:string = null;
}
