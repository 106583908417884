import { Component, OnInit, Directive } from '@angular/core';
import {  ISchoolEligibilityListParams } from '../../models/entities/SchoolEligibilityModel.response';
import { SchoolEligibilityModel } from '../../models/entities/SchoolEligibilityModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolEligibilityListBaseComponent } from './schoolEligibilityList.base';

@Component({
	selector: 'app-schoolEligibilityList',
	templateUrl: './schoolEligibilityList.component.html',
})
export class SchoolEligibilityListComponent extends SchoolEligibilityListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
