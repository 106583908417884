import { Component, OnInit } from '@angular/core';
import { BaseEditComponent } from '../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';
import { PasswordResetPost } from '../models/models';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-do-reset-password',
  templateUrl: './do-reset-password.component.html',
})
export class DoResetPasswordComponent
  extends BaseEditComponent
  implements OnInit {
  username: String = null;
  constructor(
    r: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super(r);
  }

  public model: PasswordResetPost = new PasswordResetPost();

  ngOnInit(): void {
    super.baseNgOnInit();
  }

  protected getData() {
    console.log(this.id);
    this.api.getUserByToken(this.id).subscribe((x) => {
      if (x.Success) {
        this.username = x.Data;
      } else {
        this.gl.GoTo('userlogin', null);
      }
    });
  }

  validatePasswords(): boolean {
    return (
      this.model.Password &&
      this.model.Password.length > 0 &&
      this.model.Password2 &&
      this.model.Password2.length > 0 &&
      this.model.Password == this.model.Password2
    );
  }

  public submit() {
    var sub = this.recaptchaV3Service
      .execute('doResetPassword')
      .subscribe((token) => {
        this.submit2(token);
        sub.unsubscribe();
      });

    this.api.sendPasswordResetEmail;
  }

  private submit2(token: string) {
    var me = this;
    this.model.Token = this.id;
    var sub = this.api.doPasswordReset(this.model, token).subscribe((x) => {
      if (x.Success) {
        if (x.Data.Token) {
          me.gl.LoginRoles = x.Data.Roles;
          me.gl.LoginName = x.Data.Email;
          me.gl.LoginFullName = x.Data.FullName;
          me.gl.LoginTime = new Date();

          me.gl.Token = x.Data.Token;
          me.gl.GoTo('home', null, me.zone);
        }
      }
      sub.unsubscribe();
    });
  }
}
