import { Component, OnInit, Directive } from '@angular/core';
import {  IZengineMappingOptionListParams } from '../../models/entities/ZengineMappingOptionModel.response';
import { ZengineMappingOptionModel } from '../../models/entities/ZengineMappingOptionModel';
import { PagingData } from '../../paging/paging.component';
import { ZengineMappingOptionListBaseComponent } from './zengineMappingOptionList.base';

@Component({
	selector: 'app-zengineMappingOptionList',
	templateUrl: './zengineMappingOptionList.component.html',
})
export class ZengineMappingOptionListComponent extends ZengineMappingOptionListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
