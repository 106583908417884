import { Component, OnInit, Directive } from '@angular/core';
import { LoginModel } from '../models/entities/LoginModel';
import { ListItemModel, IListItem, UpdateEvent } from '../models/models';
import { BaseEditComponent } from '../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-login-Edit',
  templateUrl: './user-login-Edit.component.html',
})
export class UserLoginEditComponent extends BaseEditComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}public model: LoginModel = <LoginModel>{};

  ngOnInit() {
    super.baseNgOnInit();
  }

	

	public commit_LoginRolesLookUp: UpdateEvent = null;

  protected loadOptions() {

	}

	protected getData() {
		var sub = this.api.getLogin(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
			}
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateUserLogin(this.model).subscribe(x=>{
			if (x.Success) {
				this.commit_LoginRolesLookUp = <UpdateEvent>{ Id: this.id, Stamp: new Date() }
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertUserLogin(this.model).subscribe(x=>{
			if (x.Success) {
				this.commit_LoginRolesLookUp = <UpdateEvent>{ Id: x.Data, Stamp: new Date() }
				this._id = x.Data;
			}
			sub.unsubscribe();
		});
		}
	}
}
