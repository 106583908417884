import { Component, OnInit, Directive } from '@angular/core';
import {  IDisabilityListParams } from '../../models/entities/DisabilityModel.response';
import { DisabilityModel } from '../../models/entities/DisabilityModel';
import { PagingData } from '../../paging/paging.component';
import { DisabilityListBaseComponent } from './disabilityList.base';

@Component({
	selector: 'app-disabilityList',
	templateUrl: './disabilityList.component.html',
})
export class DisabilityListComponent extends DisabilityListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
