import { Component, OnInit, Directive } from '@angular/core';
import { SchoolModel } from '../../models/entities/SchoolModel';
import { ListItemModel, IListItem, UpdateEvent } from '../../models/models';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class SchoolEditBaseComponent extends BaseEditComponent  {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	public model: SchoolModel = <SchoolModel>{};
	public fK_County_Physical_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_State_Physical_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_State_MailingAddress_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_County_Mailing_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_State_Payment_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_SchoolType_options:Array<ListItemModel> = <Array<ListItemModel>>[];

	protected baseNgOnInit() {
		super.baseNgOnInit();
	}


	protected loadOptions() {
				this.getFK_State_Physical_Options();
				this.getFK_State_MailingAddress_Options();
				this.getFK_State_Payment_Options();
				this.getFK_SchoolType_Options();
	}

	protected getData() {
	this.ShowSpinner = true;
		var sub = this.api.getSchool(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.getFK_County_Physical_Options();
				this.getFK_County_Mailing_Options();
			}
			this.ShowSpinner = false;
			this.getDataComplete();
			sub.unsubscribe();
		});
	}
	public getFK_County_Physical_Options(clear?: boolean) {
		if(!this.model.FK_State_Physical){ return;}
		if (clear) {  this.model.FK_County_Physical = null; }
		var sub = this.api.getCountyList({_pageSize:0,fK_State: this.model.FK_State_Physical}).subscribe(x => {
			if (x.Success) {
				this.fK_County_Physical_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_State_Physical_Options(clear?: boolean) {
		if (clear) {  this.model.FK_State_Physical = null; }
		var sub = this.api.getStateList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_State_Physical_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_State_MailingAddress_Options(clear?: boolean) {
		if (clear) {  this.model.FK_State_MailingAddress = null; }
		var sub = this.api.getStateList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_State_MailingAddress_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_County_Mailing_Options(clear?: boolean) {
		if(!this.model.FK_State_MailingAddress){ return;}
		if (clear) {  this.model.FK_County_Mailing = null; }
		var sub = this.api.getCountyList({_pageSize:0,fK_State: this.model.FK_State_MailingAddress}).subscribe(x => {
			if (x.Success) {
				this.fK_County_Mailing_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_State_Payment_Options(clear?: boolean) {
		if (clear) {  this.model.FK_State_Payment = null; }
		var sub = this.api.getStateList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_State_Payment_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_SchoolType_Options(clear?: boolean) {
		if (clear) {  this.model.FK_SchoolType = null; }
		var sub = this.api.getSchoolTypeList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_SchoolType_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateSchool(this.model).subscribe(x=>{
			if (x.Success) {
				this.saved.emit(this.id);
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertSchool(this.model).subscribe(x=>{
			if (x.Success) {
				this._id = x.Data;
				this.saved.emit(x.Data);
			}
			sub.unsubscribe();
		});
		}
	}
}
