import {IListItem } from '../models';
export class LoginModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public Email:string = "";
	public PasswordHash:string = "";
	public IsEnabled:boolean = false;
	public PK_Id:string = "";
	public CreatedDate:Date = null;
	public Salt?:string = null;
	public FK_Login_CreatedBy?:string = null;
	public FK_Login_CreatedBy_Display:string = null;
	public TwoFactorPhoneNumber?:string = null;
}
