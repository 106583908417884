import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerificationNoteEditBaseComponent } from './verificationNoteEdit.base';

@Component({
    selector: 'app-verificationNoteEdit',
    templateUrl: './verificationNoteEdit.component.html',
})
export class VerificationNoteEditComponent extends VerificationNoteEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
