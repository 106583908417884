import { ScholarshipModel} from '../entities/ScholarshipModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class ScholarshipResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: ScholarshipModel = new ScholarshipModel();
}

export class ScholarshipList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<ScholarshipModel> = <Array<ScholarshipModel >>[]
}

export interface IScholarshipListParams {
	pK_Id?:number;
	fK_State?:number;
	fK_State_display?:string;
	fK_State_like?:string;
	fK_State_fromValue?:number;
	fK_State_toValue?:number;
	name?:string;
	name_like?:string;
	fK_ScholarshipType?:number;
	fK_ScholarshipType_display?:string;
	fK_ScholarshipType_like?:string;
	fK_ScholarshipType_fromValue?:number;
	fK_ScholarshipType_toValue?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	nonSchoolDisbursementAllowed?:boolean;
	nonSchoolDisbursementAllowed_booleanValue?:boolean;
	lengthOfScholarshipYears?:number;
	lengthOfScholarshipYears_fromValue?:number;
	lengthOfScholarshipYears_toValue?:number;
	rolloverUnusedFunds?:boolean;
	rolloverUnusedFunds_booleanValue?:boolean;
	verifyIncomeOnRenewal?:boolean;
	verifyIncomeOnRenewal_booleanValue?:boolean;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class ScholarshipListParams implements IScholarshipListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_State?:number=null;
	fK_State_display?:string=null;
	fK_State_like?:string=null;
	fK_State_fromValue?:number=null;
	fK_State_toValue?:number=null;
	name?:string=null;
	name_like?:string=null;
	fK_ScholarshipType?:number=null;
	fK_ScholarshipType_display?:string=null;
	fK_ScholarshipType_like?:string=null;
	fK_ScholarshipType_fromValue?:number=null;
	fK_ScholarshipType_toValue?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	nonSchoolDisbursementAllowed?:boolean=null;
	nonSchoolDisbursementAllowed_booleanValue?:boolean=null;
	lengthOfScholarshipYears?:number=null;
	lengthOfScholarshipYears_fromValue?:number=null;
	lengthOfScholarshipYears_toValue?:number=null;
	rolloverUnusedFunds?:boolean=null;
	rolloverUnusedFunds_booleanValue?:boolean=null;
	verifyIncomeOnRenewal?:boolean=null;
	verifyIncomeOnRenewal_booleanValue?:boolean=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

