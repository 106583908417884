<div class="card">
    <div class="card-header">
        School Fee <span class="right">
            <a title="Cancel" *ngIf="!ModalView" routerLink="/schoolfeelist"><i class="fa fa-undo icon-link"></i></a>
            <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
            <i class="fa fa-save icon-link" (click)="save()"></i>
        </span>
    </div>
    <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
        <div class="form">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Created Date</label><span title="Required" class="required">*</span>
                        <app-date-picker [(model)]="model.CreatedDate"></app-date-picker>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>School</label><span title="Required" class="required">*</span>
                        <app-typeahead-basic [(id)]="model.FK_School" [searchapi]="'schoolsListBox'"></app-typeahead-basic>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>School Year</label><span title="Required" class="required">*</span>
                        <select [(ngModel)]="model.FK_SchoolYear" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_SchoolYear_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Grade Level</label><span title="Required" class="required">*</span>
                        <select [(ngModel)]="model.FK_GradeLevel" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_GradeLevel_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Fee Type</label><span title="Required" class="required">*</span>
                        <select [(ngModel)]="model.FK_FeeType" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_FeeType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Amount</label><span title="Required" class="required">*</span>
                        <input [(ngModel)]="model.Amount" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Grade Level To</label>
                        <select [(ngModel)]="model.FK_GradeLevel_To" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let n of fK_GradeLevel_To_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Description</label>
                        <textarea rows="4" [(ngModel)]="model.Description" class="form-control"></textarea>
                    </div>
                </div>


            </div>


        </div>
    </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
