import {IListItem } from '../models';
export class SchoolCalendarLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_School:number = 0;
	public FK_School_Display:string = null;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public SchoolYearStart:Date = null;
	public SchoolYearEnd:Date = null;
}
