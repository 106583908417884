import { SchoolModel} from '../entities/SchoolModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolModel = new SchoolModel();
}

export class SchoolList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolModel> = <Array<SchoolModel >>[]
}

export interface ISchoolListParams {
	fK_County_Physical?:number;
	fK_County_Physical_display?:string;
	fK_County_Physical_like?:string;
	fK_County_Physical_fromValue?:number;
	fK_County_Physical_toValue?:number;
	name?:string;
	name_like?:string;
	pK_Id?:number;
	fK_State_Physical?:number;
	fK_State_Physical_display?:string;
	fK_State_Physical_like?:string;
	fK_State_Physical_fromValue?:number;
	fK_State_Physical_toValue?:number;
	physical_Zipcode?:string;
	physical_Zipcode_like?:string;
	isDeleted?:boolean;
	isDeleted_booleanValue?:boolean;
	phone?:string;
	phone_like?:string;
	district?:string;
	district_like?:string;
	mailing_StreetAddress1?:string;
	mailing_StreetAddress1_like?:string;
	mailing_StreetAddress2?:string;
	mailing_StreetAddress2_like?:string;
	mailing_City?:string;
	mailing_City_like?:string;
	fK_State_MailingAddress?:number;
	fK_State_MailingAddress_display?:string;
	fK_State_MailingAddress_like?:string;
	fK_State_MailingAddress_fromValue?:number;
	fK_State_MailingAddress_toValue?:number;
	mailing_Zipcode?:string;
	mailing_Zipcode_like?:string;
	fK_County_Mailing?:number;
	fK_County_Mailing_display?:string;
	fK_County_Mailing_like?:string;
	fK_County_Mailing_fromValue?:number;
	fK_County_Mailing_toValue?:number;
	physical_StreetAddress1?:string;
	physical_StreetAddress1_like?:string;
	physical_StreetAddress2?:string;
	physical_StreetAddress2_like?:string;
	physical_City?:string;
	physical_City_like?:string;
	dOECode?:string;
	dOECode_like?:string;
	faxNumber?:string;
	faxNumber_like?:string;
	nonProfit?:boolean;
	nonProfit_booleanValue?:boolean;
	religious?:boolean;
	religious_booleanValue?:boolean;
	religiousAffiliation?:string;
	religiousAffiliation_like?:string;
	genderServed?:string;
	genderServed_like?:string;
	emailAddress?:string;
	emailAddress_like?:string;
	websiteURL?:string;
	websiteURL_like?:string;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	paymentBankName?:string;
	paymentBankName_like?:string;
	paymentBankRouting?:string;
	paymentBankRouting_like?:string;
	paymentBankAccount?:string;
	paymentBankAccount_like?:string;
	paymentAddress1?:string;
	paymentAddress1_like?:string;
	paymentAddress2?:string;
	paymentAddress2_like?:string;
	paymentCity?:string;
	paymentCity_like?:string;
	fK_State_Payment?:number;
	fK_State_Payment_display?:string;
	fK_State_Payment_like?:string;
	fK_State_Payment_fromValue?:number;
	fK_State_Payment_toValue?:number;
	paymentZipcode?:string;
	paymentZipcode_like?:string;
	paymentPayTo?:string;
	paymentPayTo_like?:string;
	fK_SchoolType?:number;
	fK_SchoolType_display?:string;
	fK_SchoolType_like?:string;
	fK_SchoolType_fromValue?:number;
	fK_SchoolType_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolListParams implements ISchoolListParams, ISearchModel  {
	fK_County_Physical?:number=null;
	fK_County_Physical_display?:string=null;
	fK_County_Physical_like?:string=null;
	fK_County_Physical_fromValue?:number=null;
	fK_County_Physical_toValue?:number=null;
	name?:string=null;
	name_like?:string=null;
	pK_Id?:number=null;
	fK_State_Physical?:number=null;
	fK_State_Physical_display?:string=null;
	fK_State_Physical_like?:string=null;
	fK_State_Physical_fromValue?:number=null;
	fK_State_Physical_toValue?:number=null;
	physical_Zipcode?:string=null;
	physical_Zipcode_like?:string=null;
	isDeleted?:boolean=null;
	isDeleted_booleanValue?:boolean=null;
	phone?:string=null;
	phone_like?:string=null;
	district?:string=null;
	district_like?:string=null;
	mailing_StreetAddress1?:string=null;
	mailing_StreetAddress1_like?:string=null;
	mailing_StreetAddress2?:string=null;
	mailing_StreetAddress2_like?:string=null;
	mailing_City?:string=null;
	mailing_City_like?:string=null;
	fK_State_MailingAddress?:number=null;
	fK_State_MailingAddress_display?:string=null;
	fK_State_MailingAddress_like?:string=null;
	fK_State_MailingAddress_fromValue?:number=null;
	fK_State_MailingAddress_toValue?:number=null;
	mailing_Zipcode?:string=null;
	mailing_Zipcode_like?:string=null;
	fK_County_Mailing?:number=null;
	fK_County_Mailing_display?:string=null;
	fK_County_Mailing_like?:string=null;
	fK_County_Mailing_fromValue?:number=null;
	fK_County_Mailing_toValue?:number=null;
	physical_StreetAddress1?:string=null;
	physical_StreetAddress1_like?:string=null;
	physical_StreetAddress2?:string=null;
	physical_StreetAddress2_like?:string=null;
	physical_City?:string=null;
	physical_City_like?:string=null;
	dOECode?:string=null;
	dOECode_like?:string=null;
	faxNumber?:string=null;
	faxNumber_like?:string=null;
	nonProfit?:boolean=null;
	nonProfit_booleanValue?:boolean=null;
	religious?:boolean=null;
	religious_booleanValue?:boolean=null;
	religiousAffiliation?:string=null;
	religiousAffiliation_like?:string=null;
	genderServed?:string=null;
	genderServed_like?:string=null;
	emailAddress?:string=null;
	emailAddress_like?:string=null;
	websiteURL?:string=null;
	websiteURL_like?:string=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	paymentBankName?:string=null;
	paymentBankName_like?:string=null;
	paymentBankRouting?:string=null;
	paymentBankRouting_like?:string=null;
	paymentBankAccount?:string=null;
	paymentBankAccount_like?:string=null;
	paymentAddress1?:string=null;
	paymentAddress1_like?:string=null;
	paymentAddress2?:string=null;
	paymentAddress2_like?:string=null;
	paymentCity?:string=null;
	paymentCity_like?:string=null;
	fK_State_Payment?:number=null;
	fK_State_Payment_display?:string=null;
	fK_State_Payment_like?:string=null;
	fK_State_Payment_fromValue?:number=null;
	fK_State_Payment_toValue?:number=null;
	paymentZipcode?:string=null;
	paymentZipcode_like?:string=null;
	paymentPayTo?:string=null;
	paymentPayTo_like?:string=null;
	fK_SchoolType?:number=null;
	fK_SchoolType_display?:string=null;
	fK_SchoolType_like?:string=null;
	fK_SchoolType_fromValue?:number=null;
	fK_SchoolType_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

