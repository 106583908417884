<div>
  <div class="row">
    <div class="col-3">
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 1"></i>
          <span (click)="doChangePage(1)" class="btn font-weight-bold"> School Profile</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 2"></i>
          <span (click)="doChangePage(2)" class="btn font-weight-bold"> AAA Users</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 3"></i>
          <span (click)="doChangePage(3)" class="btn font-weight-bold"> Published Tuition & Fees</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 m-1 px-4">
          <i class="fas fa-chevron-right" *ngIf="pageNumber == 4"></i>
          <span (click)="doChangePage(4)" class="btn font-weight-bold"> AAA Certification</span>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div *ngIf="pageNumber == 1" class="form">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2 class="text-header">School Profile</h2>
            <button type="button" class="btn btn-primary" (click)="doSchoolEdit()" *ngIf="isSchoolAdmin()">
              <i class="fas fa-pen"></i>
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <h3 class="text-subtitle">School Information</h3>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <div class="form-group">
              <span class="form-control no-border">{{ model?.Name }}</span>
              <div class="no-padding">
                <span class="form-control no-border">{{ model?.Physical_StreetAddress1 }}</span>
                <span class="form-control no-border" *ngIf="model?.Physical_StreetAddress2">
                  {{ model?.Physical_StreetAddress2 }}
                </span>
                <span class=" form-control no-border">
                  {{ model?.Physical_City }}, {{ model?.FK_State_Physical_Display }} {{ model?.Physical_Zipcode }}
                </span>
              </div>
              <span class="form-control no-border">{{ model?.Phone }}</span>
              <span class="form-control no-border" *ngIf="model?.EmailAddress">
                {{ model?.EmailAddress }}
              </span>
              <span class="form-control no-border">{{ model?.WebsiteURL }}</span>
            </div>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <h3 class="text-subtitle">DOE/School Code</h3>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <div class="form-group">
              <span class="form-control no-border">{{ model?.DOECode }}</span>
            </div>
          </div>
        </div>
        <div class="row p-1 mt-3">
          <div class="col-12">
            <h2 class="text-subtitle">Mailing Address</h2>
          </div>
        </div>
        <div class="row p-1" *ngIf="isMailingAddressSamePhysicalAddressView">
          <div class="col-12">
            <div class="form-group">
              <span class="form-control no-border">
                School Mailing Address is the same as School Physical Address
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="!isMailingAddressSamePhysicalAddressView">
          <div class="row p-1">
            <div class="col-12">
              <div class="form-group">
                <div class="no-padding">
                  <span class="form-control no-border">{{ model?.Mailing_StreetAddress1 }}</span>
                  <span class="form-control no-border" *ngIf="model?.Mailing_StreetAddress2">
                    {{ model?.Mailing_StreetAddress2 }}
                  </span>
                  <span class="form-control no-border">
                    {{ model?.Mailing_City }}, {{ model?.FK_State_MailingAddress_Display }} {{ model?.Mailing_Zipcode }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!isSchoolWithGardinerStudents">
          <div class="row p-1 mt-3">
            <div class="col-12">
              <h2 class="text-subtitle">Payment Information</h2>
            </div>
          </div>
          <div class="row p-1 mt-3" *ngIf="!hasBankAccount && isSchoolAdmin()">
            <div class="col-12">
              <button type="button" class="btn btn-primary" (click)="doAddBankAccount()">
                Add Bank Account
              </button>
            </div>
          </div>
          <div *ngIf="hasBankAccount">
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <span class="form-control no-border">{{ getFilteredBankAccount }}</span>
                </div>
              </div>
            </div>
            <div class="row p-1 mt-3">
              <div class="col-12">
                <button type="button" class="btn btn-primary" (click)="doAddBankAccount()">
                  Update Account
                </button>
              </div>
            </div>
          </div>
        </div>

        <p-sidebar [(visible)]="editViewDisplay" position="right" [style]="{width:'60%'}" [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class=" row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h2 class="text-header">School Profile</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <h3 class="text-subtitle">School Information</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">School Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Name" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Phone<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Phone" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Email Address</label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.EmailAddress" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">DOE/School Code<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.DOECode" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Website<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.WebsiteURL" />
                </div>
              </div>
            </div>
            <div class="row p-1 mt-3">
              <div class="col-12">
                <h2 class="text-subtitle">Physical Address</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Address Line 1<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Physical_StreetAddress1"
                    (ngModelChange)="onPhysicalAddressChange()" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Address Line 2</label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Physical_StreetAddress2"
                    (ngModelChange)="onPhysicalAddressChange()" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">City<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Physical_City"
                    (ngModelChange)="onPhysicalAddressChange()" />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label class="form-label font-weight-bold">State<span class="text-danger">*</span></label>
                  <p-dropdown [options]="stateOptions" class="form-control no-border"
                    [(ngModel)]="editModel.FK_State_Physical" (ngModelChange)="onPhysicalAddressChange()">
                  </p-dropdown>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label class="form-label font-weight-bold">ZIP Code<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.Physical_Zipcode"
                    (ngModelChange)="onPhysicalAddressChange()" />
                </div>
              </div>
            </div>
            <div class="row p-1 mt-3">
              <div class="col-12">
                <h2 class="text-subtitle">Mailing Address</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="d-flex justify-content-left align-items-center">
                  <input type="checkbox" [(ngModel)]="isSamePhysicalAddress"
                    (ngModelChange)="onSamePhysicalAddressChange()" class="mr-1" />
                  <span class="form-control no-border">
                    School Mailing Address is the same as School Physical Address
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="!isSamePhysicalAddress">
              <div class="row p-1">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">Address Line 1<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="editModel.Mailing_StreetAddress1" />
                  </div>
                </div>
              </div>
              <div class="row p-1">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">Address Line 2</label>
                    <input type="text" class="form-control" [(ngModel)]="editModel.Mailing_StreetAddress2" />
                  </div>
                </div>
              </div>
              <div class="row p-1">
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">City<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="editModel.Mailing_City" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">State<span class="text-danger">*</span></label>
                    <p-dropdown [options]="stateOptions" class="form-control no-border"
                      [(ngModel)]="editModel.FK_State_MailingAddress">
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">Zip Code<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="editModel.Mailing_Zipcode" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveProfile()"
                  [disabled]="!isValidProfile()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelProfile()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>

        <p-sidebar [(visible)]="editBankAccountViewDisplay" position="right" [style]="{width:'60%'}"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class=" row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h2 class="text-header">Payment Bank Account</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Account Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.PaymentBankName" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Routing Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.PaymentBankRouting" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Account Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editModel.PaymentBankAccount" maxlength="9" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveBankAccount()"
                  [disabled]="!isValidBankAccount()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelBankAccount()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 2">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2 class="text-header">AAA Users</h2>
            <button type="button" class="btn btn-primary" (click)="doAAAContactEdit()" *ngIf="isSchoolAdmin()">
              <i class="fas fa-pen"></i>
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <h4 class="h4 mt-3" *ngIf="isSchoolAdmin()">
              AAA Administrative Contact
            </h4>
            <h4 class="h4 mt-3" *ngIf="isSchoolUser()">
              AAA User Contact
            </h4>
          </div>
        </div>
        <div class="row p-1 mt-2">
          <div class="col-12">
            <p>
              {{ contactModel.FirstName }} {{ contactModel.LastName }} <br />{{
              contactModel.Title
              }}
            </p>
            <div class="d-flex">
              <p>{{ contactModel.Phone }}</p>
              <p class="pl-3">Ext. {{ contactModel.Extension }}</p>
            </div>
            <p>{{ contactModel.Email }}</p>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h4 class="h4 mt-3">Additional Users & Accounts</h4>
            <button type="button" class="btn btn-primary" (click)="addNewContact()" *ngIf="isSchoolAdmin()">
              Add User
            </button>
          </div>
        </div>
        <div class="row p-1 d-flex align-items-end">
          <div class="col">
            <label class="form-label">Search</label>
            <input type="text" class="form-control" [(ngModel)]="contactsSearchText"
              (ngModelChange)="onSearchTextChanged()" />
          </div>
          <div class="col d-flex align-items-center h-100">
            <p-inputSwitch [(ngModel)]="contactsActive" (ngModelChange)="onContactsActiveChange()">
            </p-inputSwitch>
            <span class="pl-1 align-middle h-100"><strong>Show Active Accounts Only</strong></span>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <app-custom-table [data]="contactsTableData" [columns]="contactsTableColumns" [rows]="10"
              [showPaginator]="true" [showSummary]="true" [useLazyLoad]="true" (onLoadData)="loadContactsData($event)"
              [isLoading]="contactsTableLoading" [totalRecords]="contactsTableTotalRecords"
              (onRowTableClicked)="doContactRowClicked($event)">
            </app-custom-table>
          </div>
        </div>

        <p-sidebar [(visible)]="editContactViewDisplay" position="right" [style]="{ width: '60%' }"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
            <div class="row p-1">
              <div class="col-12 d-flex justify-content-between">
                <h4 class="font-weight-bold">{{ (!isNewContact) ? 'Edit' : 'Add' }} AAA Contact</h4>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">First Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.FirstName" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Last Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.LastName" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Title<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.Title" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Phone Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.Phone" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Ext.</label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.Extension" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Email Address<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="editContactModel.Email" />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12 d-flex justify-content-between buttons-contact">
                <div class="d-flex justify-content-start">
                  <button class="btn btn-primary mr-2" type="button" (click)="saveContact()"
                    [disabled]="!isValidContact()" *ngIf="!isNewContact">
                    Save
                  </button>
                  <button class="btn btn-primary mr-2" type="button" *ngIf="isNewContact"
                    (click)="saveAndInviteContact()" [disabled]="!isValidContact()" [disabled]="!isValidContact()">
                    Save & Invite
                  </button>
                  <button class="btn btn-outline-secondary mx-2" type="button" (click)="cancelContact()">
                    Cancel
                  </button>
                </div>
                <div class="d-flex justify-content-end">
                  <p-checkbox [(ngModel)]="editContactModel.IsEnabled" [binary]="true" label="Is Enabled"
                    class="form-label font-weight-bold" [disabled]="!isValidContact()">
                  </p-checkbox>
                </div>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 3">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2 class="text-header">Published Tuition & Fees</h2>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-4">
            <div class="form-group">
              <label class="form-label font-weight-bold">Select Year</label>
              <p-dropdown [options]="schoolYearOptions" class="form-control no-border" [(ngModel)]="schoolCalendar">
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <h3 class="text-subtitle">{{ schoolCalendar?.FK_SchoolYear_Display }} School Year</h3>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-3">
            <div class="form-group">
              <label class="form-label font-weight-bold">Start Date<span class="text-danger">*</span></label>
              <span class="form-control no-border">{{ schoolCalendar?.SchoolYearStart | date:'MM/dd/yyyy' }}</span>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="form-label font-weight-bold">End Date<span class="text-danger">*</span></label>
              <span class="form-control no-border">{{ schoolCalendar?.SchoolYearEnd | date:'MM/dd/yyyy' }}</span>
            </div>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-primary" (click)="doSchoolYearEdit()" *ngIf="isSchoolAdmin()">
              <i class="fas fa-pen"></i>
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <button class="btn btn-outline-secondary w-25" type="button" (click)="addNewFee()"
              [disabled]="!schoolCalendar" *ngIf="isSchoolAdmin()">
              Add Fee Schedule
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <app-custom-table [data]="schoolFeeTableData[schoolCalendar?.PK_Id]" [columns]="schoolFeeTableColumns"
              [rows]="10" (onRowTableClicked)="doFeeTableRowClicked($event)">
            </app-custom-table>
          </div>
        </div>

        <p-sidebar [(visible)]="editSchoolYearViewDisplay" position="right" [style]="{width:'60%'}"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class="row p-1">
              <div class="col-12">
                <h2 class="text-header">Edit School Year</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <h3 class="text-subtitle">{{ editSchoolYearModel?.FK_SchoolYear_Display }} School Year</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Start Date<span class="text-danger">*</span></label>
                  <p-calendar [(ngModel)]="editSchoolYearModel.SchoolYearStart" class="form-control no-border"
                    dateFormat="mm/dd/yy" [showIcon]="true" appendTo="body" [monthNavigator]="true"
                    [yearNavigator]="true" [yearRange]="yearCalendarRange" icon="fas fa-calendar">
                  </p-calendar>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">End Date<span class="text-danger">*</span></label>
                  <p-calendar [(ngModel)]="editSchoolYearModel.SchoolYearEnd" class="form-control no-border"
                    dateFormat="mm/dd/yy" [showIcon]="true" appendTo="body" [monthNavigator]="true"
                    [yearNavigator]="true" [yearRange]="yearCalendarRange" icon="fas fa-calendar">
                  </p-calendar>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveSchoolYear()"
                  [disabled]="!isValidSchoolYear()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelSchoolYear()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>

        <p-sidebar [(visible)]="editFeeViewDisplay" position="right" [style]="{width:'60%'}" [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class="row p-1">
              <div class="col-12">
                <h2 class="text-header">Edit Tuition and Fees</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <h3 class="text-subtitle">{{ selectedSchoolCalendar?.FK_SchoolYear_Display }} School Year</h3>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Grade<span class="text-danger">*</span></label>
                  <div class="d-flex justify-content-left align-items-center w-50">
                    <p-dropdown [options]="gradeOptions" class="form-control no-border"
                      [(ngModel)]="editFeeModel.FK_GradeLevel" (ngModelChange)="onFeeGradeChange($event)">
                    </p-dropdown>
                    <span class="form-label font-weight-bold mx-2">through</span>
                    <p-dropdown [options]="gradeOptions" class="form-control no-border"
                      [(ngModel)]="editFeeModel.FK_GradeLevel_To" (ngModelChange)="onFeeGradeToChange($event)">
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div *ngFor="let feeModel of editFeeModel.List; let i = index">
              <div class="row p-1">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label font-weight-bold">
                      {{ feeModel.FK_FeeType_Display }}<span class="text-danger">*</span>
                    </label>
                    <p-inputNumber class="form-control no-border" [(ngModel)]="editFeeModel.List[i].Amount"
                      mode="currency" currency="USD" locale="en-US">
                    </p-inputNumber>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Total</label>
                  <p-inputNumber class="form-control no-border" [(ngModel)]="editFeeModel.Total" mode="currency"
                    currency="USD" locale="en-US" [disabled]="true">
                  </p-inputNumber>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveFee()"
                  [disabled]="!isValidGrade()">
                  {{ (isNewFee) ? 'Save' : 'Update' }}
                </button>
                <button *ngIf="!isNewFee" class="btn btn-danger mx-2 w-25" type="button" (click)="deleteFee()">
                  Delete
                </button>
                <button class=" btn btn-outline-secondary mx-2 w-25" type="button" (click)="cancelFee()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
      <div *ngIf="pageNumber == 4">
        <div class="row p-1">
          <div class="col-12 d-flex justify-content-between">
            <h2 class="text-header">AAA Certification</h2>
          </div>
        </div>
        <div class="row p-1 mt-3" *ngIf="showCompleteCertification && isSchoolAdmin()">
          <div class="col-12 d-flex justify-content-between">
            <span>Your certification for the upcoming school year is ready to be completed.</span>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12" *ngIf="showCompleteCertification && isSchoolAdmin()">
            <button type="button" class="btn btn-primary" (click)="doCompleteCertification()">
              Complete Certification
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-12">
            <app-custom-table [data]="schoolCertificationTableData" [columns]="schoolCertificationTableColumns"
              [rows]="10">
            </app-custom-table>
          </div>
        </div>

        <p-sidebar [(visible)]="editAAACertificationViewDisplay" position="right" [style]="{width:'60%'}"
          [showCloseIcon]="false">
          <p-scrollPanel [style]="{width: '100%', height: '95vh'}">
            <div class="row p-1">
              <div class="col-12">
                <h2 class="text-header">AAA Certification</h2>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <h3>{{ selectedSchoolYear?.Name }} School Year</h3>
              </div>
            </div>

            <div *ngFor="let schoolEligibility of schoolEligibilitiesList; let i = index">
              <div class="row p-1">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label font-weight-bold"
                      [innerHTML]="schoolEligibility.FK_SchoolEligibility_Display">
                    </label>
                    <span class="d-flex justify-content-left">
                      <div class="p-field-radiobutton d-flex align-items-start h-100">
                        <p-radioButton name="{{ schoolEligibility.PK_Id }}" [value]="true"
                          [(ngModel)]="schoolEligibilitiesResponses[schoolEligibility.PK_Id]"
                          inputId="{{ schoolEligibility.PK_Id }}Yes">
                        </p-radioButton>
                        <label for="{{ schoolEligibility.PK_Id }}Yes">Yes</label>
                      </div>
                      <div class="p-field-radiobutton d-flex align-items-start h-100 ml-4">
                        <p-radioButton name="{{ schoolEligibility.PK_Id }}" [value]="false"
                          [(ngModel)]="schoolEligibilitiesResponses[schoolEligibility.PK_Id]"
                          inputId="{{ schoolEligibility.PK_Id }}No">
                        </p-radioButton>
                        <label for="{{ schoolEligibility.PK_Id }}No">No</label>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-12">
                <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveCertification()"
                  [disabled]="!isValidCertification()">
                  Save
                </button>
                <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelCertification()">
                  Cancel
                </button>
              </div>
            </div>
          </p-scrollPanel>
        </p-sidebar>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog key="messageDialog"></p-confirmDialog>
<p-confirmDialog key="confirmDialog" styleClass="custom-confirm-dialog"></p-confirmDialog>