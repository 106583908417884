import { Component, OnInit, Directive } from '@angular/core';
import { ISchoolTransactionNoteListParams } from '../../models/entities/SchoolTransactionNoteModel.response';
import { SchoolTransactionNoteModel } from '../../models/entities/SchoolTransactionNoteModel';
import { PagingData } from '../../paging/paging.component';
import { SchoolTransactionNoteListBaseComponent } from './schoolTransactionNoteList.base';

@Component({
    selector: 'app-schoolTransactionNoteList',
    templateUrl: './schoolTransactionNoteList.component.html',
})
export class SchoolTransactionNoteListComponent extends SchoolTransactionNoteListBaseComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        super.baseNgOnInit();
    }



}
