<div *ngIf="modalEdit||newModalEdit" class="modal" [style.left]="leftOffset" [style.top]="topOffset">
  <app-vendorTransactionEdit [modalview]="true" [id]="modalEdit" [level]="(level+.5)" (saved)="modalEditSaved($event)" (cancelled)="modalEdit=null;newModalEdit=false"></app-vendorTransactionEdit>
</div>
<div class="card">
  <div class="card-header">
    Vendor Transaction List <a class="right"  (click)="newModalEdit = true" ><i class="fa fa-plus" title="Add Vendor Transaction"></i></a>
    <div><app-search-input [placeholdertext]="'Search'" (changed)="fuzzySearchChange($event)" ></app-search-input></div>

  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div style="grid-template-columns: 100px auto auto auto auto auto auto auto ;" class="table">
          <div class="table-header" style="text-align:left">
            <i (click)="selectAll()" title="Select All" class="fa" [ngClass]="{ 'fa-check-square-o':selectAllStatus,'fa-square-o':!selectAllStatus}"></i>
            <i title='Delete Many' (click)='deleteMany()' class='fa fa-trash icon-link'></i>
          </div>
          <div class="table-header" (click)="sortChange('FK_Vendor_Display')">Vendor <i *ngIf="search._sort=='FK_Vendor_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_Student_Display')">Student <i *ngIf="search._sort=='FK_Student_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_Guardian_Display')">Guardian <i *ngIf="search._sort=='FK_Guardian_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_SchoolYear_Display')">School Year <i *ngIf="search._sort=='FK_SchoolYear_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('Amount')">Amount <i *ngIf="search._sort=='Amount'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('FK_PreAuthorization_Display')">Pre Authorization <i *ngIf="search._sort=='FK_PreAuthorization_Display'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>
          <div class="table-header" (click)="sortChange('Description')">Description <i *ngIf="search._sort=='Description'" class='fa' [ngClass]="{'fa-sort-asc':!search._sortDesc, 'fa-sort-desc': search._sortDesc}"></i></div>





          <ng-container *ngFor="let x of model">
            <div>
              <span class='edit-tools'>
                <i (click)="x.Selected = !x.Selected" class="fa" [ngClass]="{ 'fa-check-square-o':x.Selected,'fa-square-o':!x.Selected}"></i>
                <a (click)="modalEdit = x.PK_Id"><i title='Edit' class='fa fa-pencil icon-link'></i></a> <i title='Delete' (click)='delete(x.PK_Id )' class='fa fa-trash icon-link'></i>
              </span>
            </div>
            <div>
              {{x.FK_Vendor_Display}}
            </div>
            <div>
              {{x.FK_Student_Display}}
            </div>
            <div>
              {{x.FK_Guardian_Display}}
            </div>
            <div>
              {{x.FK_SchoolYear_Display}}
            </div>
            <div>
              {{x.Amount}}
            </div>
            <div>
              {{x.FK_PreAuthorization_Display}}
            </div>
            <div>
              {{x.Description}}
            </div>

          </ng-container>
        </div>

        <app-paging [showpagesize]="true" [data]="pagingData" (changed)="pagingChanged($event)"></app-paging>
      </div>
    </div>
  </div>
</div>

<app-spinner [showspinner]="ShowSpinner"></app-spinner>

