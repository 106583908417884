import { PreAuthorizationStatusTypeModel} from '../entities/PreAuthorizationStatusTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class PreAuthorizationStatusTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: PreAuthorizationStatusTypeModel = new PreAuthorizationStatusTypeModel();
}

export class PreAuthorizationStatusTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<PreAuthorizationStatusTypeModel> = <Array<PreAuthorizationStatusTypeModel >>[]
}

export interface IPreAuthorizationStatusTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class PreAuthorizationStatusTypeListParams implements IPreAuthorizationStatusTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

