import { PreAuthorizationModel} from '../entities/PreAuthorizationModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class PreAuthorizationResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: PreAuthorizationModel = new PreAuthorizationModel();
}

export class PreAuthorizationList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<PreAuthorizationModel> = <Array<PreAuthorizationModel >>[]
}

export interface IPreAuthorizationListParams {
	pK_Id?:number;
	fK_Vendor?:number;
	fK_Vendor_display?:string;
	fK_Vendor_like?:string;
	fK_Vendor_fromValue?:number;
	fK_Vendor_toValue?:number;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	serviceDescription?:string;
	serviceDescription_like?:string;
	educationalDescription?:string;
	educationalDescription_like?:string;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number;
	amount?:number;
	amount_fromValue?:number;
	amount_toValue?:number;
	fK_PreAuthorizationStatusType?:number;
	fK_PreAuthorizationStatusType_display?:string;
	fK_PreAuthorizationStatusType_like?:string;
	fK_PreAuthorizationStatusType_fromValue?:number;
	fK_PreAuthorizationStatusType_toValue?:number;
	dateApproved?:Date;
	dateApproved_fromDate?:string;
	dateApproved_toDate?:string;
	fK_Login_ApprovedBy?:string;
	fK_Login_ApprovedBy_display?:string;
	fK_Login_ApprovedBy_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class PreAuthorizationListParams implements IPreAuthorizationListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_Vendor?:number=null;
	fK_Vendor_display?:string=null;
	fK_Vendor_like?:string=null;
	fK_Vendor_fromValue?:number=null;
	fK_Vendor_toValue?:number=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	serviceDescription?:string=null;
	serviceDescription_like?:string=null;
	educationalDescription?:string=null;
	educationalDescription_like?:string=null;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number=null;
	amount?:number=null;
	amount_fromValue?:number=null;
	amount_toValue?:number=null;
	fK_PreAuthorizationStatusType?:number=null;
	fK_PreAuthorizationStatusType_display?:string=null;
	fK_PreAuthorizationStatusType_like?:string=null;
	fK_PreAuthorizationStatusType_fromValue?:number=null;
	fK_PreAuthorizationStatusType_toValue?:number=null;
	dateApproved?:Date=null;
	dateApproved_fromDate?:string=null;
	dateApproved_toDate?:string=null;
	fK_Login_ApprovedBy?:string=null;
	fK_Login_ApprovedBy_display?:string=null;
	fK_Login_ApprovedBy_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

