import {IListItem } from '../models';
export class SchoolTransactionNoteModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_SchoolTransaction:number = 0;
	public FK_NoteType:number = 0;
	public FK_NoteType_Display:string = null;
	public Note:string = "";
	public NoteActive:boolean = false;
	public CreatedDate:Date = null;
}
