<div class="card">
  <div class="card-header">
    School Eligibility <span class="right">
  <a title="Cancel" *ngIf="!ModalView" routerLink="/schooleligibilitylist"><i class="fa fa-undo icon-link"></i></a>
  <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
  <i class="fa fa-save icon-link" (click)="save()"></i>
</span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        								<div class="col-6">
									<div class="form-group">
										<label>Html Content</label><span title="Required" class="required">*</span>
										<textarea rows="4" [(ngModel)]="model.HtmlContent" class="form-control"></textarea>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">

										<app-checkbox [(model)]="model.IsHeaderContentOnly" [label]="'Is Header Content Only'"></app-checkbox>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">

										<app-checkbox [(model)]="model.RadioButton" [label]="'Radio Button'"></app-checkbox>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">

										<app-checkbox [(model)]="model.ForStudent" [label]="'For Student'"></app-checkbox>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">

										<app-checkbox [(model)]="model.ForSchool" [label]="'For School'"></app-checkbox>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label>School Eligibility Parent</label>
										<select [(ngModel)]="model.FK_SchoolEligibility_Parent" class="form-control">
										<option value=""></option>
										<option *ngFor="let n of fK_SchoolEligibility_Parent_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
										</select>
									</div>
								</div>


      </div>
      

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
