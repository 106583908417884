import { Component, OnInit, Directive } from '@angular/core';
import {  IZengineFormStateLookUpListParams } from '../../models/entities/ZengineFormStateLookUpModel.response';
import { ZengineFormStateLookUpModel } from '../../models/entities/ZengineFormStateLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { ZengineFormStateLookUpListBaseComponent } from './zengineFormStateLookUpList.base';

@Component({
	selector: 'app-zengineFormStateLookUpList',
	templateUrl: './zengineFormStateLookUpList.component.html',
})
export class ZengineFormStateLookUpListComponent extends ZengineFormStateLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
