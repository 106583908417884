import { WithdrawModel} from '../entities/WithdrawModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class WithdrawResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: WithdrawModel = new WithdrawModel();
}

export class WithdrawList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<WithdrawModel> = <Array<WithdrawModel >>[]
}

export interface IWithdrawListParams {
	pK_Id?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number;
	fK_Login_CreatedBy?:string;
	fK_Login_CreatedBy_display?:string;
	fK_Login_CreatedBy_like?:string;
	lastDay?:Date;
	lastDay_fromDate?:string;
	lastDay_toDate?:string;
	balanceDue?:number;
	balanceDue_fromValue?:number;
	balanceDue_toValue?:number;
	createdDate?:Date;
	createdDate_fromDate?:string;
	createdDate_toDate?:string;
	fK_WithdrawReasonType?:number;
	fK_WithdrawReasonType_display?:string;
	fK_WithdrawReasonType_like?:string;
	fK_WithdrawReasonType_fromValue?:number;
	fK_WithdrawReasonType_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class WithdrawListParams implements IWithdrawListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_fromValue?:number=null;
	fK_StudentGradeLevelsSchoolYearsLookUp_toValue?:number=null;
	fK_Login_CreatedBy?:string=null;
	fK_Login_CreatedBy_display?:string=null;
	fK_Login_CreatedBy_like?:string=null;
	lastDay?:Date=null;
	lastDay_fromDate?:string=null;
	lastDay_toDate?:string=null;
	balanceDue?:number=null;
	balanceDue_fromValue?:number=null;
	balanceDue_toValue?:number=null;
	createdDate?:Date=null;
	createdDate_fromDate?:string=null;
	createdDate_toDate?:string=null;
	fK_WithdrawReasonType?:number=null;
	fK_WithdrawReasonType_display?:string=null;
	fK_WithdrawReasonType_like?:string=null;
	fK_WithdrawReasonType_fromValue?:number=null;
	fK_WithdrawReasonType_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

