import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html'
})
export class DatePickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  private _model?: Date = null

  public day?: number = null;
  public month?: number = null;
  public year?: number = null;

  valuesChanged() {
    if (this.day == null && this.month == null && this.year == null) {
      this.changed.emit(null);
      return;
    }

    if (this.day == null || this.month == null || this.year == null) {
      return;
    }

    if (!(this.day > 0 && this.day <= 31) || !(this.year > 1000 && this.year < 3000) || !(this.month > 0 && this.month <= 12)) {
      return;
    }

    var d = new Date(this.year, this.month - 1, this.day);
    var me = this;
    if (d.getTime() === d.getTime()) {
      window.setTimeout(function () {
        me.changed.emit(d);
      }, 500);

      return;
    }

  }

  @Input('model')
  public set model(value: any) {

    if (!value) {
      this.day = null;
      this.month = null;
      this.year = null;
      return;
    }

    var d: Date = null;

    d = new Date(value);
    if (d.getTime() === d.getTime()) {
      this.month = d.getMonth() + 1; //months from 1-12
      this.day = d.getDate();
      this.year = d.getFullYear();

    }

  }

  @Output('modelChange')
  public changed: EventEmitter<Date> = new EventEmitter<Date>();

}
