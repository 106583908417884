import {IListItem } from '../models';
export class StudentGradeLevelsSchoolYearsLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public Archived:boolean = false;
	public CreatedDate:Date = null;
	public PK_Id:number = 0;
	public FK_SchoolYear:number = 0;
	public FK_SchoolYear_Display:string = null;
	public FK_GradeLevel:number = 0;
	public FK_GradeLevel_Display:string = null;
	public FK_Student:number = 0;
	public FK_Student_Display:string = null;
	public FK_State_Application:number = 0;
	public FK_State_Application_Display:string = null;
	public FK_School?:number = null;
	public FK_School_Display:string = null;
	public ZengineStudentId?:number = null;
	public ZengineHouseholdId?:number = null;
	public PreviousSchoolDistrict?:string = null;
	public PreviousSchoolType?:string = null;
	public PreviousSchoolName?:string = null;
	public NameOfZonedSchool?:string = null;
	public FK_Household?:number = null;
}
