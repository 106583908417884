import { Component, OnInit, Directive } from '@angular/core';
import {  IScholarshipTypeListParams } from '../../models/entities/ScholarshipTypeModel.response';
import { ScholarshipTypeModel } from '../../models/entities/ScholarshipTypeModel';
import { PagingData } from '../../paging/paging.component';
import { ScholarshipTypeListBaseComponent } from './scholarshipTypeList.base';

@Component({
	selector: 'app-scholarshipTypeList',
	templateUrl: './scholarshipTypeList.component.html',
})
export class ScholarshipTypeListComponent extends ScholarshipTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
