import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolGradeLevelsLookUpEditBaseComponent } from './schoolGradeLevelsLookUpEdit.base';

@Component({
	selector: 'app-schoolGradeLevelsLookUpEdit',
	templateUrl: './schoolGradeLevelsLookUpEdit.component.html',
})
export class SchoolGradeLevelsLookUpEditComponent extends SchoolGradeLevelsLookUpEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
