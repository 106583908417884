import { Component, OnInit, Directive } from '@angular/core';
import {  IPrepaidCollegeTransferListParams } from '../../models/entities/PrepaidCollegeTransferModel.response';
import { PrepaidCollegeTransferModel } from '../../models/entities/PrepaidCollegeTransferModel';
import { PagingData } from '../../paging/paging.component';
import { PrepaidCollegeTransferListBaseComponent } from './prepaidCollegeTransferList.base';

@Component({
	selector: 'app-prepaidCollegeTransferList',
	templateUrl: './prepaidCollegeTransferList.component.html',
})
export class PrepaidCollegeTransferListComponent extends PrepaidCollegeTransferListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
