<div>
  <p-table [value]="data" [scrollable]="true" [autoLayout]="true" [rows]="rows" [paginator]="_showPaginator"
    [totalRecords]="totalRecords" [loading]="isLoading" [lazy]="useLazyLoad" [lazyLoadOnInit]="false"
    (onLazyLoad)="loadData($event)" styleClass="custom-table">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns" [style.width]="(col.width) ? col.width : ''"
          pSortableColumn="{{ (col.sortable) ? col.fieldName : '' }}">
          <span title="{{ col.fieldCaption }}"
            class="{{ ([fieldTypeDefinitionType.CustomButton, fieldTypeDefinitionType.FontAwesomeIcon].includes(col.fieldType)) ? 'd-flex justify-content-center' : '' }}">
            {{ col.fieldCaption }}
            <p-sortIcon *ngIf="col.sortable" [field]="col.fieldName">
            </p-sortIcon>
          </span>
        </th>
        <th *ngIf="showEditButton" class="custom-table-action-button">Edit</th>
        <th *ngIf="showDeleteButton" class="custom-table-action-button">
          <span title="Delete">Delete</span>
        </th>
        <th *ngIf="showViewButton" class="custom-table-action-button">
          <span title="View">View</span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr (click)="doRowClicked(data)">
        <td *ngFor="let col of columns" [style.width]="(col.width) ? col.width : ''" class="text-truncate">
          <span *ngIf="[fieldTypeDefinitionType.String, fieldTypeDefinitionType.Custom].includes(col.fieldType)">
            <span *ngIf="col.showTooltip" pTooltip="{{ data[col.tooltipFieldName] }}" tooltipPosition="top">
              {{ data[col.fieldName] }}
            </span>
            <span *ngIf="!col.showTooltip">
              {{ data[col.fieldName] }}
            </span>
          </span>
          <span *ngIf="[fieldTypeDefinitionType.Integer, fieldTypeDefinitionType.Decimal].includes(col.fieldType)"
            class="d-flex justify-content-right">
            <span *ngIf="col.showTooltip" pTooltip="{{ data[col.tooltipFieldName] }}" tooltipPosition="top">
              {{ data[col.fieldName] }}
            </span>
            <span *ngIf="!col.showTooltip">
              {{ data[col.fieldName] }}
            </span>
          </span>
          <span *ngIf="col.fieldType == fieldTypeDefinitionType.Money" class="d-flex justify-content-right">
            <span *ngIf="col.showTooltip" pTooltip="{{ data[col.tooltipFieldName] }}" tooltipPosition="top">
              {{ data[col.fieldName] === "N/A" ? "N/A" : (data[col.fieldName] | currency: "USD") }}
            </span>
            <span *ngIf="!col.showTooltip">
              {{ data[col.fieldName] === "N/A" ? "N/A" : (data[col.fieldName] | currency: "USD") }}
            </span>
          </span>
          <span *ngIf="col.fieldType == fieldTypeDefinitionType.Boolean" class="d-flex justify-content-center">
            <span *ngIf="col.showTooltip" pTooltip="{{ data[col.tooltipFieldName] }}" tooltipPosition="top">
              {{ data[col.fieldName] ? "Yes" : "No" }}
            </span>
            <span *ngIf="!col.showTooltip">
              {{ data[col.fieldName] ? "Yes" : "No" }}
            </span>
          </span>
          <span *ngIf="col.fieldType == fieldTypeDefinitionType.DateTime" class="d-flex justify-content-center">
            <span *ngIf="col.showTooltip" pTooltip="{{ data[col.tooltipFieldName] }}" tooltipPosition="top">
              {{ data[col.fieldName] | date: "MM/dd/yyyy hh:mm:ss aaa" }}
            </span>
            <span *ngIf="!col.showTooltip">
              {{ data[col.fieldName] | date: "MM/dd/yyyy hh:mm:ss aaa" }}
            </span>
          </span>
          <span *ngIf="col.fieldType == fieldTypeDefinitionType.Date" class="d-flex justify-content-center">
            <span *ngIf="col.showTooltip" pTooltip="{{ data[col.tooltipFieldName] }}" tooltipPosition="top">
              {{ data[col.fieldName] | date: "MM/dd/yyyy" }}
            </span>
            <span *ngIf="!col.showTooltip">
              {{ data[col.fieldName] | date: "MM/dd/yyyy" }}
            </span>
          </span>
          <span *ngIf="col.fieldType == fieldTypeDefinitionType.HTML" class="d-flex justify-content-left">
            <div [innerHTML]="data[col.fieldName]" *ngIf="col.showTooltip" pTooltip="{{ data[col.tooltipFieldName] }}"
              tooltipPosition="top"></div>
            <div [innerHTML]="data[col.fieldName]" *ngIf="!col.showTooltip"></div>
          </span>
          <span *ngIf="col.fieldType == fieldTypeDefinitionType.Time" class="d-flex justify-content-center">
            <span *ngIf="col.showTooltip" pTooltip="{{ data[col.tooltipFieldName] }}" tooltipPosition="top">
              {{ data[col.fieldName] | date: "hh:mm:ss aaa" }}
            </span>
            <span *ngIf="!col.showTooltip">
              {{ data[col.fieldName] | date: "hh:mm:ss aaa" }}
            </span>
          </span>
          <span *ngIf="col.fieldType == fieldTypeDefinitionType.CustomButton">
            <button *ngIf="!col.fieldNameCustomButtonVisible || data[col.fieldNameCustomButtonVisible]"
              class="btn btn-primary" (click)="doCustomButton(data)">
              {{ col.fieldName }}
            </button>
            <span *ngIf="!(!col.fieldNameCustomButtonVisible || data[col.fieldNameCustomButtonVisible])">
              {{ col.customButtonNotVisibleText }}
            </span>
          </span>
          <span *ngIf="col.fieldType == fieldTypeDefinitionType.FontAwesomeIcon" class="d-flex justify-content-center">
            <i class="{{ data[col.fieldName] }}" *ngIf="col.showTooltip" pTooltip="{{ data[col.tooltipFieldName] }}"
              tooltipPosition="top">
            </i>
            <i class="{{ data[col.fieldName] }}" *ngIf="!col.showTooltip"></i>
          </span>
        </td>
        <td *ngIf="showEditButton" class="custom-table-action-button">
          <button class="btn btn-primary" (click)="doEditButton(data)">
            <i class="fas fa-pen"></i>
          </button>
        </td>
        <td *ngIf="showDeleteButton" class="custom-table-action-button">
          <button class="btn btn-danger" (click)="doDeleteButton(data)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
        <td *ngIf="showViewButton" class="custom-table-action-button">
          <button class="btn btn-info" (click)="doViewButton(data)">
            <i class="fas fa-search"></i>
          </button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns?.length + (showEditButton ? 1 : 0)">
          No data to display
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft" let-state *ngIf="showSummary">
      Showing
      {{ state.first + 1 }}
      -
      {{
      ((state.rows * (state.page + 1)) > state.totalRecords) ? state.totalRecords : (state.rows * (state.page + 1))
      }}
      of
      {{ state.totalRecords }}
      items
    </ng-template>
    <ng-template pTemplate="paginatorright" let-state *ngIf="showSummary">
    </ng-template>
  </p-table>
</div>