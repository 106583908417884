<div>
  <div class="row">
    <div class="col-2"></div>
    <div class="col-8">
      <div class="row p-3">
        <div class="col-12 d-flex justify-content-between">
          <h2 class="text-header">
            {{ contactModel?.FirstName ? contactModel?.FirstName : 'N/A' }}
            {{ contactModel?.LastName ? contactModel?.LastName : 'N/A' }}
          </h2>
          <button type="button" class="btn btn-primary" (click)="doUserEdit()">
            <i class="fas fa-pen"></i>
          </button>
        </div>
      </div>
      <div class="row pt-3 px-3">
        <div class="col-12">
          <div class="form-group">
            <h3 class="text-subtitle">User Information</h3>
            <span class="form-control no-border font-weight-bold p-0">User Type</span>
          </div>
        </div>
      </div>
      <div class="row pb-3 px-3">
        <div class="col-12">
          <div class="form-group">
            <div class="no-padding">
              <span class="form-control no-border p-0">
                {{ contactModel?.FirstName ? contactModel?.FirstName : 'N/A' }},
                {{ contactModel?.LastName ? contactModel?.LastName : 'N/A' }}
              </span>
              <span class="form-control no-border p-0">
                {{ userTypeModel ? userTypeModel.Name : 'N/A' }}
              </span>
              <span class="form-control no-border p-0">
                {{ contactModel?.Email ? contactModel?.Email : model?.Email }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row p-3">
        <div class="col-12">
          <div class="form-group">
            <h3 class="text-subtitle">Permissions</h3>
          </div>
        </div>
      </div>

      <p-sidebar [(visible)]="editUserViewDisplay" position="right" [style]="{ width: '60%' }" [showCloseIcon]="false">
        <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
          <div class="row p-1">
            <div class="col-12 d-flex justify-content-between">
              <h2 class="text-header">Edit User</h2>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold">User Type<span class="text-danger">*</span></label>
                <p-dropdown [options]="userTypeOptions" class="form-control no-border" [(ngModel)]="userTypeSelected">
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label font-weight-bold">Created On</label>
                <span class="form-control no-border px-0">
                  {{ editModel.CreatedDate | date: "MM/dd/yyyy" }}
                  {{ (editModel.FK_Login_CreatedBy_Display) ? 'by ' + editModel.FK_Login_CreatedBy_Display : '' }}
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label font-weight-bold">Last Login</label>
                <span class="form-control no-border px-0">MM/DD/YYYY 00:00am/pm</span>
              </div>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label font-weight-bold">First Name<span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="editContactModel.FirstName" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label font-weight-bold">Last Name<span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="editContactModel.LastName" />
              </div>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold">Title<span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="editContactModel.Title" />
              </div>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold">Email Address<span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="editContactModel.Email" />
              </div>
            </div>
          </div>
          <div class="row p-1">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-start">
                  <button class="btn btn-primary mr-2 w-50" type="button" (click)="saveUser()"
                    [disabled]="!isValidContact()">
                    Save
                  </button>
                  <button class="btn btn-outline-secondary mx-2 w-50" type="button" (click)="cancelUser()">
                    Cancel
                  </button>
                </div>
                <div class="d-flex justify-content-end">
                  <p-checkbox [(ngModel)]="editModel.IsEnabled" [binary]="true" label="Is Enabled"
                    class="form-label font-weight-bold">
                  </p-checkbox>
                </div>
              </div>
            </div>
          </div>
        </p-scrollPanel>
      </p-sidebar>
    </div>
  </div>
  <div class="col-2"></div>
</div>