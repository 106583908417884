import { SchoolFeeModel } from './SchoolFeeModel'

export class SchoolFeeListModel {
  private _FK_GradeLevel: number = 0;
  public set FK_GradeLevel(value: number) {
    this._FK_GradeLevel = value;
    this.List.forEach(item => item.FK_GradeLevel = value);
  }
  public get FK_GradeLevel(): number {
    return this._FK_GradeLevel;
  }

  private _FK_GradeLevel_Display: string = null;
  public set FK_GradeLevel_Display(value: string) {
    this._FK_GradeLevel_Display = value;
    this.List.forEach(item => item.FK_GradeLevel_Display = value);
  }
  public get FK_GradeLevel_Display(): string {
    return this._FK_GradeLevel_Display;
  }

  private _FK_GradeLevel_To: number = 0;
  public set FK_GradeLevel_To(value: number) {
    this._FK_GradeLevel_To = value;
    this.List.forEach(item => item.FK_GradeLevel_To = value);
  }
  public get FK_GradeLevel_To(): number {
    return this._FK_GradeLevel_To;
  }

  private _FK_GradeLevel_To_Display: string = null;
  public set FK_GradeLevel_To_Display(value: string) {
    this._FK_GradeLevel_To_Display = value;
    this.List.forEach(item => item.FK_GradeLevel_To_Display = value);
  }
  public get FK_GradeLevel_To_Display(): string {
    return this._FK_GradeLevel_To_Display;
  }

  public Id: string;
  public List: SchoolFeeModel[] = [];
  public get Total(): number {
    return this.List.map(item => item.Amount).reduce((a, b) => a + b, 0);
  }
}