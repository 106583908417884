import { FeeDiscountModel} from '../entities/FeeDiscountModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class FeeDiscountResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: FeeDiscountModel = new FeeDiscountModel();
}

export class FeeDiscountList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<FeeDiscountModel> = <Array<FeeDiscountModel >>[]
}

export interface IFeeDiscountListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	fK_SchoolFee?:number;
	fK_SchoolFee_display?:string;
	fK_SchoolFee_like?:string;
	fK_SchoolFee_fromValue?:number;
	fK_SchoolFee_toValue?:number;
	customAmount?:boolean;
	customAmount_booleanValue?:boolean;
	amount?:number;
	amount_fromValue?:number;
	amount_toValue?:number;
	description?:string;
	description_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class FeeDiscountListParams implements IFeeDiscountListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	fK_SchoolFee?:number=null;
	fK_SchoolFee_display?:string=null;
	fK_SchoolFee_like?:string=null;
	fK_SchoolFee_fromValue?:number=null;
	fK_SchoolFee_toValue?:number=null;
	customAmount?:boolean=null;
	customAmount_booleanValue?:boolean=null;
	amount?:number=null;
	amount_fromValue?:number=null;
	amount_toValue?:number=null;
	description?:string=null;
	description_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

