import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentNoteEditBaseComponent } from './studentNoteEdit.base';

@Component({
    selector: 'app-studentNoteEdit',
    templateUrl: './studentNoteEdit.component.html',
})
export class StudentNoteEditComponent extends StudentNoteEditBaseComponent implements OnInit {
    constructor(r: ActivatedRoute) {
        super(r);
    }


    ngOnInit() {
        super.baseNgOnInit();
    }

}
