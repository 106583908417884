<div class="card">
  <div class="card-header">
    Zengine Form <span class="right">
      <a title="Cancel" *ngIf="!ModalView" routerLink="/zengineformlist"><i class="fa fa-undo icon-link"></i></a>
      <a title="Cancel" *ngIf="ModalView" (click)="cancel()"><i class="fa fa-undo icon-link"></i></a>
      <i class="fa fa-save icon-link" (click)="save()"></i>
    </span>
  </div>
  <div class="card-body" [ngClass]="{'scroll-body': ModalView}">
    <div class="form">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Zengine Form Id</label><span title="Required" class="required">*</span>
            <input [(ngModel)]="model.ZengineFormId" type="text" class="form-control">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Zengine Form Name</label><span title="Required" class="required">*</span>
            <input [(ngModel)]="model.ZengineFormName" type="text" class="form-control">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Zengine Form Type</label>
            <select [(ngModel)]="model.FK_ZengineFormType" class="form-control">
              <option value=""></option>
              <option *ngFor="let n of fK_ZengineFormType_options" [value]="n.PK_Id">{{n.ListDisplay}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label></label>
            <app-zengineFormStateLookUpCheckBoxListByZengineForm (saved)="childComponentSaved()"  [commit]="commit_ZengineFormStateLookUp" [id]="model.PK_Id"></app-zengineFormStateLookUpCheckBoxListByZengineForm>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label></label>
            <app-zengineFormYearLookUpCheckBoxListByZengineForm (saved)="childComponentSaved()"  [commit]="commit_ZengineFormYearLookUp" [id]="model.PK_Id"></app-zengineFormYearLookUpCheckBoxListByZengineForm>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<app-spinner [showspinner]="ShowSpinner"></app-spinner>
