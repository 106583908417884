<div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" ><i class="fa fa-search"></i></span>
  </div>
  <div class="input-group-prepend">
    <span class="input-group-text" ><i (click)="clear()" class="fa fa-remove"></i></span>
  </div>
  <input 
         type="text"
         class="form-control"
         [(ngModel)]="model"
         [ngbTypeahead]="search"
         [resultFormatter]="resultFormat"
         [inputFormatter]="inputFormat"
         (selectItem)="selected($event);"
          />

</div>
									
