import { Component, OnInit, Directive } from '@angular/core';
import { HouseholdModel } from '../../models/entities/HouseholdModel';
import { ListItemModel, IListItem, UpdateEvent } from '../../models/models';
import { BaseEditComponent } from '../../BaseEditComponent';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class HouseholdEditBaseComponent extends BaseEditComponent  {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	public model: HouseholdModel = <HouseholdModel>{};
	public fK_State_options:Array<ListItemModel> = <Array<ListItemModel>>[];
	public fK_County_options:Array<ListItemModel> = <Array<ListItemModel>>[];

	protected baseNgOnInit() {
		super.baseNgOnInit();
	}


	protected loadOptions() {
				this.getFK_State_Options();
	}

	protected getData() {
	this.ShowSpinner = true;
		var sub = this.api.getHousehold(this.id).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.getFK_County_Options();
			}
			this.ShowSpinner = false;
			this.getDataComplete();
			sub.unsubscribe();
		});
	}
	public getFK_State_Options(clear?: boolean) {
		if (clear) {  this.model.FK_State = null; }
		var sub = this.api.getStateList({_pageSize:0}).subscribe(x => {
			if (x.Success) {
				this.fK_State_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}
	public getFK_County_Options(clear?: boolean) {
		if(!this.model.FK_State){ return;}
		if (clear) {  this.model.FK_County = null; }
		var sub = this.api.getCountyList({_pageSize:0,fK_State: this.model.FK_State}).subscribe(x => {
			if (x.Success) {
				this.fK_County_options = <Array<IListItem>>x.Data;
			}
			sub.unsubscribe();
		});
	}

	public save(){
		if (this.id) {
		var sub = this.api.updateHousehold(this.model).subscribe(x=>{
			if (x.Success) {
				this.saved.emit(this.id);
			}
			sub.unsubscribe();
		});
		} else {
		var sub = this.api.insertHousehold(this.model).subscribe(x=>{
			if (x.Success) {
				this._id = x.Data;
				this.saved.emit(x.Data);
			}
			sub.unsubscribe();
		});
		}
	}
}
