import {IListItem } from '../models';
export class GuardianReimbursementModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public CreatedDate:Date = null;
	public FK_StudentGradeLevelsSchoolYearsLookUp:number = 0;
	public Amount:number = 0;
	public FK_GuardianReimbursementStatusType:number = 0;
	public FK_GuardianReimbursementStatusType_Display:string = null;
	public PK_Id:number = 0;
	public FK_Login_CreatedBy:string = "";
	public FK_Login_CreatedBy_Display:string = null;
	public FK_Login_ApprovedBy?:string = null;
	public FK_Login_ApprovedBy_Display:string = null;
	public DateApproved?:Date = null;
}
