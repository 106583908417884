import { Component, OnInit, Directive } from '@angular/core';
import {  IStateListParams } from '../../models/entities/StateModel.response';
import { StateModel } from '../../models/entities/StateModel';
import { PagingData } from '../../paging/paging.component';
import { StateListBaseComponent } from './stateList.base';

@Component({
	selector: 'app-stateList',
	templateUrl: './stateList.component.html',
})
export class StateListComponent extends StateListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
