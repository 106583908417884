import { Component, OnInit, Directive } from '@angular/core';
import {  IScholarshipDisbursementCategoriesLookUpListParams } from '../../models/entities/ScholarshipDisbursementCategoriesLookUpModel.response';
import { ScholarshipDisbursementCategoriesLookUpModel } from '../../models/entities/ScholarshipDisbursementCategoriesLookUpModel';
import { PagingData } from '../../paging/paging.component';
import { ScholarshipDisbursementCategoriesLookUpListBaseComponent } from './scholarshipDisbursementCategoriesLookUpList.base';

@Component({
	selector: 'app-scholarshipDisbursementCategoriesLookUpList',
	templateUrl: './scholarshipDisbursementCategoriesLookUpList.component.html',
})
export class ScholarshipDisbursementCategoriesLookUpListComponent extends ScholarshipDisbursementCategoriesLookUpListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
