<div class="body-login">
  <div class="d-flex flex-column align-items-center">
    <img src="assets/aaa-logo.png" class="p-5 mt-5" />
    <div class="card p-3">
      <div class="card-header pt-3">
        <div class="d-flex flex-column">
          <span class="text-header pb-3">
            Welcome{{ username ? ", " + username : null }}
          </span>
          <span class="text-dark font-weight-light">
            Please set a new password for your account.
          </span>
        </div>
      </div>
      <div class="card-body">
        <div class="form">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold">Password</label>
                <input
                  [(ngModel)]="model.Password"
                  type="password"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label font-weight-bold"
                  >Password Confirm</label
                >
                <input
                  [(ngModel)]="model.Password2"
                  type="password"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <button
                  (click)="submit()"
                  class="btn btn-primary form-control"
                  [disabled]="!validatePasswords()"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
