<div class="p-2">
  <p-tabView>
    <p-tabPanel header="SCFs">
      <div class="row">
        <div class="col-12">
          <app-custom-table [data]="studentTableData" [columns]="scfRequestTableColumns" [rows]="10"
            showPaginator="true" [showSummary]="true" [useLazyLoad]="true" (onLoadData)="loadStudentData($event)"
            [isLoading]="usersTableLoading" [totalRecords]="usersTableTotalRecords">
          </app-custom-table>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Verification Reports">
      <div class="row">
        <div class="col-12">
          <app-custom-table [data]="verificationReportsTableData" [columns]="verificationReportsTableColumns"
            [rows]="10">
          </app-custom-table>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Payment Requests">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label font-weight-bold">Search Reimbursement Requests</label>
            <div class="d-flex flex-row align-items-center">
              <input type="text" class="form-control w-25" [(ngModel)]="reimbursementSearchText" />
              <p-inputSwitch [(ngModel)]="reimbursementActive" class="px-2"></p-inputSwitch>
              <span class="text-subtitle font-weight-bold">Show Open requests only</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-custom-table [data]="paymentRequestTableData" [columns]="paymentRequestTableColumns" [rows]="10">
          </app-custom-table>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Withdrawals">

    </p-tabPanel>
    <p-tabPanel header="Vendor Requests">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label font-weight-bold">Service Category</label>
            <p-dropdown [options]="categoryOptions" class="form-control no-border w-25" [(ngModel)]="categorySelected">
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-custom-table [data]="vendorRequestTableData" [columns]="vendorRequestTableColumns" [rows]="10">
          </app-custom-table>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="School Requests">

    </p-tabPanel>
  </p-tabView>
</div>