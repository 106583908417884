<div class="p-2">
  <p-tabView>
    <p-tabPanel header="Provider">
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-3 d-flex flex-column">
          <span class="form-label font-weight-bold">Search Providers</span>
          <input type="text" class="form-control" [(ngModel)]="searchInstructorsText" (ngModelChange)="searchInstructors($event)" />
        </div>
        <div class="col-3 d-flex flex-column">
          <span class="form-label font-weight-bold">Service Category</span>
          <p-dropdown [options]="serviceCategoriesOptions" class="form-control no-border" [(ngModel)]="serviceCategory" optionLabel="name">
          </p-dropdown>
        </div>
        <div class="col-3 d-flex align-items-center h-100">
          <p-inputSwitch (onChange)="handleShowActiveInstructorsOnly($event)" [(ngModel)]="activeInstructors">
          </p-inputSwitch>
          <span class="pl-1 align-middle h-100"><strong>Show Active/Pending Providers Only</strong></span>
        </div>
        <div class="col-3 d-flex flex-column">
          <button (click)="openAddInstructor()" class="btn btn-primary form-control">
            Add Provider
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-custom-table [data]="instructorsTableData" [columns]="instructorsTableColumns" [rows]="10" [showPaginator]="true">
          </app-custom-table>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Direct Payments">
      <div class="row mb-3">
        <div class="col-12 mb-5">Please note that approved payments are processed every Wednesday for payment the same Friday.</div>
        <div class="col-3 d-flex flex-column">
          <span class="form-label font-weight-bold">Search Payments</span>
          <input type="text" class="form-control" [(ngModel)]="searchPaymentsText" (ngModelChange)="searchPayments($event)" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 px-3">
          <app-custom-table [data]="paymentsTableData" [columns]="paymentsTableColumns" [rows]="10" [showPaginator]="true"
            (onRowTableClicked)="paymentsRowClicked($event)">
          </app-custom-table>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
  <p-sidebar [(visible)]="paymentDetailsDisplay" position="right" [style]="{ width: '60%' }" [showCloseIcon]="false">
    <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
      <div class="row p-1">
        <div class="col-12 d-flex justify-content-between">
          <h2>Payment Details</h2>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <p>Please note that approved payments are processed every Wednesday for payment the same Friday.</p>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-12">
          <h4>Receipts</h4>
        </div>
        <div class="col-12 d-flex align-items-end pb-3">
          <a class="link"><i class="fas fa-file-pdf"></i>Invoice - Archer Tutoring.pdf</a>
        </div>
      </div>
    </p-scrollPanel>
  </p-sidebar>
  <p-sidebar [(visible)]="addInstructorDisplay" position="right" [style]="{ width: '60%' }" [showCloseIcon]="false">
    <p-scrollPanel [style]="{ width: '100%', height: '95vh' }">
      <div class="row p-1">
        <div class="col-12 d-flex justify-content-between">
          <h4>Add/Edit Provider</h4>
        </div>
      </div>
      <div class="row p-1 mt-5">
        <div class="col-12">
          <button class="btn btn-primary mx-2 w-25" type="button" (click)="saveInstructor()">
            Save
          </button>
          <button class="btn btn-outline-secondary w-25" type="button" (click)="cancelInstructor()">
            Cancel
          </button>
        </div>
      </div>
    </p-scrollPanel>
  </p-sidebar>
</div>