import { Component, OnInit, Directive } from '@angular/core';
import {  IAccreditationListParams } from '../../models/entities/AccreditationModel.response';
import { AccreditationModel } from '../../models/entities/AccreditationModel';
import { PagingData } from '../../paging/paging.component';
import { AccreditationListBaseComponent } from './accreditationList.base';

@Component({
	selector: 'app-accreditationList',
	templateUrl: './accreditationList.component.html',
})
export class AccreditationListComponent extends AccreditationListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
