import { Component, OnInit, Directive } from '@angular/core';
import { IVendorTransactionListParams,VendorTransactionListParams } from '../../models/entities/VendorTransactionModel.response';
import { VendorTransactionModel} from '../../models/entities/VendorTransactionModel';
import { BaseListComponent } from '../../BaseListComponent';
import { PagingData } from '../../paging/paging.component';

@Directive()
export class VendorTransactionListBaseComponent extends BaseListComponent {
	constructor() {
		super();
	}
	public model: Array<VendorTransactionModel> = <Array<VendorTransactionModel>>[];
	public search: VendorTransactionListParams = new VendorTransactionListParams();
	protected baseNgOnInit() {
		this.search._sort='';
		super.baseNgOnInit();
	}

	protected getData() {
		this.ShowSpinner = true;
		var sub = this.api.getVendorTransactionList(this.search).subscribe(x => {
			if (x.Success) {
				this.model = x.Data;
				this.pagingData = <PagingData>{ Page: x.Page, RecordsCount: x.RecordsCount, PageSize: this.pagingData.PageSize, Pages:x.Pages };
				this.search._page = x.Page;
				this.search._pageSize = this.pagingData.PageSize;
				this.ShowSpinner = false;
				this.getDataComplete();
			} else {
				this.ShowSpinner = false;
			}
			sub.unsubscribe();
		});
	}

	public clearSearch() {
		this.search.fK_Vendor  = null;
		this.search.fK_Vendor_display  = null;
		this.search.fK_Vendor_like  = null;
		this.search.fK_Vendor_fromValue  = null;
		this.search.fK_Vendor_toValue  = null;
		this.search.fK_Student  = null;
		this.search.fK_Student_display  = null;
		this.search.fK_Student_like  = null;
		this.search.fK_Student_fromValue  = null;
		this.search.fK_Student_toValue  = null;
		this.search.fK_Guardian  = null;
		this.search.fK_Guardian_display  = null;
		this.search.fK_Guardian_like  = null;
		this.search.fK_Guardian_fromValue  = null;
		this.search.fK_Guardian_toValue  = null;
		this.search.fK_SchoolYear  = null;
		this.search.fK_SchoolYear_display  = null;
		this.search.fK_SchoolYear_like  = null;
		this.search.fK_SchoolYear_fromValue  = null;
		this.search.fK_SchoolYear_toValue  = null;
		this.search.fK_TransactionType  = null;
		this.search.fK_TransactionType_display  = null;
		this.search.fK_TransactionType_like  = null;
		this.search.fK_TransactionType_fromValue  = null;
		this.search.fK_TransactionType_toValue  = null;
		this.search.fK_TransactionStatusType  = null;
		this.search.fK_TransactionStatusType_display  = null;
		this.search.fK_TransactionStatusType_like  = null;
		this.search.fK_TransactionStatusType_fromValue  = null;
		this.search.fK_TransactionStatusType_toValue  = null;
		this.search.pK_Id  = null;
		this.search.fK_Login_CreatedBy  = null;
		this.search.fK_Login_CreatedBy_display  = null;
		this.search.fK_Login_CreatedBy_like  = null;
		this.search.createdDate  = null;
		this.search.createdDate_fromDate  = null;
		this.search.createdDate_toDate  = null;
		this.search.amount  = null;
		this.search.amount_fromValue  = null;
		this.search.amount_toValue  = null;
		this.search.fK_PreAuthorization  = null;
		this.search.fK_PreAuthorization_display  = null;
		this.search.fK_PreAuthorization_like  = null;
		this.search.fK_PreAuthorization_fromValue  = null;
		this.search.fK_PreAuthorization_toValue  = null;
		this.search.description  = null;
		this.search.description_like  = null;
		this.getData();
	}

	public delete(id:any) {
		var src = this;
		this.confirmDelete(function () {
		src.ShowSpinner = true;
			var sub = src.api.deleteVendorTransaction(id).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

	public deleteMany() {
		var ids = this.model.filter(x => { return x.Selected }).map(x => x.PK_Id);
		if (!ids.length) {
			return;
		}
		var src = this;
		this.confirmDelete(function () {
			src.ShowSpinner = true;
			var sub = src.api.deleteManyVendorTransaction(ids).subscribe(x => {
				if (x.Success) {
					src.getData();
					src.ShowSpinner = false;
				} else {
				src.ShowSpinner = false;
				}
				sub.unsubscribe()
			});
		});
	}

}
