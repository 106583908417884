import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountyEditBaseComponent } from './countyEdit.base';

@Component({
	selector: 'app-countyEdit',
	templateUrl: './countyEdit.component.html',
})
export class CountyEditComponent extends CountyEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
