import {IListItem } from '../models';
export class HouseholdModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public IsDeleted:boolean = false;
	public CreatedDate:Date = null;
	public PK_Id:number = 0;
	public FK_Login_CreatedBy?:string = null;
	public FK_Login_CreatedBy_Display:string = null;
	public NumberOfGuardiansLivingInHome?:number = null;
	public NumberOfChildrenLivingInHome?:number = null;
	public NumberOfOtherPeopleLivingInHome?:number = null;
	public TotalHouseholdSize?:number = null;
	public PrimarySpokenLanguage?:string = null;
	public HouseholdIncome?:number = null;
	public Address1?:string = null;
	public Address2?:string = null;
	public City?:string = null;
	public FK_State?:number = null;
	public FK_State_Display:string = null;
	public Zipcode?:string = null;
	public FK_County?:number = null;
	public FK_County_Display:string = null;
}
