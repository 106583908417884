import {IListItem } from '../models';
export class SchoolEligibilitiesStateLookUpModelBase {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:number = 0;
	public FK_SchoolEligibility:number = 0;
	public FK_SchoolEligibility_Display:string = null;
	public FK_State:number = 0;
	public FK_State_Display:string = null;
}
