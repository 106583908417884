import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalsService } from './globals.service';
import { ErrorMessagesService } from './error-messages.service';
import { aaaAPIServiceBase } from './aaa.API.service.base';
import { ContactResponse } from '../models/entities/ContactModel.response';
import { StudentList } from '../models/entities/StudentModel.response';
import { VendorResponse } from '../models/entities/VendorModel.response';
import { VendorServiceProviderList } from '../models/entities/VendorServiceProviderModel.response';

@Injectable({
  providedIn: 'root'
})
export class aaaAPIVendorService extends aaaAPIServiceBase {

  constructor(_http: HttpClient, _gl: GlobalsService, _errorMessagesService: ErrorMessagesService) {
    super(_http, _gl, _errorMessagesService);
  }

  public getVendorByContactId(contact_id: number): Observable<VendorResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Vendor/GetVendorByContactId/${contact_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <VendorResponse>res.body;
    }));
  }

  public getContactByLogin(login_id: string): Observable<ContactResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Contact/?fk_login=${login_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ContactResponse>res.body;
    }));
  }

  public getStudentsWithScholarshipTypeIdOnSchoolId(scholarshiptTypeId: number, schoolId: number): Observable<StudentList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/School/GetStudentsByScholarshipType/${scholarshiptTypeId}/${schoolId}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <StudentList>res.body;
    }));
  }

  public getContactByVendorId(vendor_id: number): Observable<ContactResponse> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/Contact/GetContactByVendorId/${vendor_id}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <ContactResponse>res.body;
    }));
  }

  public doSearchVendorServiceProviderList(vendor_id: number, searchText: string, isEnabled: boolean, _sort?: string, _sortDesc?: boolean, _page?: number, _pageSize?: number): Observable<VendorServiceProviderList> {
    this.errorMessagesService.showSpinner = true;
    const url = `${this.gl.BaseUrl}/api/VendorServiceProvider/SearchVendorServiceProviderList/?vendor_id=${(vendor_id != null ? encodeURI(vendor_id.toString()) : '')}&searchText=${(searchText != null ? encodeURI(searchText.toString()) : '')}&_isEnabled=${(isEnabled != null ? encodeURI(isEnabled.toString()) : '')}&_sort=${(_sort != null ? encodeURI(_sort.toString()) : '')}&_sortDesc=${(_sortDesc != null ? encodeURI(_sortDesc.toString()) : '')}&_page=${(_page != null ? encodeURI(_page.toString()) : '')}&_pageSize=${(_pageSize != null ? encodeURI(_pageSize.toString()) : '')}`;
    console.log(url);
    return this.http.get(url, {
      headers: new HttpHeaders({ 'x-token': this.gl.Token }),
      observe: 'response'
    }).pipe(catchError(this.gl.handleError), map((res: any) => {
      console.log(res);
      this.gl.SetValidate(res);
      return <VendorServiceProviderList>res.body;
    }));
  }
}