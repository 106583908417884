import { SchoolCommitmentModel} from '../entities/SchoolCommitmentModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class SchoolCommitmentResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: SchoolCommitmentModel = new SchoolCommitmentModel();
}

export class SchoolCommitmentList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<SchoolCommitmentModel> = <Array<SchoolCommitmentModel >>[]
}

export interface ISchoolCommitmentListParams {
	pK_Id?:number;
	fK_School?:number;
	fK_School_display?:string;
	fK_School_like?:string;
	fK_School_fromValue?:number;
	fK_School_toValue?:number;
	fK_SchoolCommitmentStatusType?:number;
	fK_SchoolCommitmentStatusType_display?:string;
	fK_SchoolCommitmentStatusType_like?:string;
	fK_SchoolCommitmentStatusType_fromValue?:number;
	fK_SchoolCommitmentStatusType_toValue?:number;
	fK_Student?:number;
	fK_Student_display?:string;
	fK_Student_like?:string;
	fK_Student_fromValue?:number;
	fK_Student_toValue?:number;
	fK_SchoolYear?:number;
	fK_SchoolYear_display?:string;
	fK_SchoolYear_like?:string;
	fK_SchoolYear_fromValue?:number;
	fK_SchoolYear_toValue?:number;
	studentStartDate?:Date;
	studentStartDate_fromDate?:string;
	studentStartDate_toDate?:string;
	fK_Withdraw?:number;
	fK_Withdraw_display?:string;
	fK_Withdraw_like?:string;
	fK_Withdraw_fromValue?:number;
	fK_Withdraw_toValue?:number;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class SchoolCommitmentListParams implements ISchoolCommitmentListParams, ISearchModel  {
	pK_Id?:number=null;
	fK_School?:number=null;
	fK_School_display?:string=null;
	fK_School_like?:string=null;
	fK_School_fromValue?:number=null;
	fK_School_toValue?:number=null;
	fK_SchoolCommitmentStatusType?:number=null;
	fK_SchoolCommitmentStatusType_display?:string=null;
	fK_SchoolCommitmentStatusType_like?:string=null;
	fK_SchoolCommitmentStatusType_fromValue?:number=null;
	fK_SchoolCommitmentStatusType_toValue?:number=null;
	fK_Student?:number=null;
	fK_Student_display?:string=null;
	fK_Student_like?:string=null;
	fK_Student_fromValue?:number=null;
	fK_Student_toValue?:number=null;
	fK_SchoolYear?:number=null;
	fK_SchoolYear_display?:string=null;
	fK_SchoolYear_like?:string=null;
	fK_SchoolYear_fromValue?:number=null;
	fK_SchoolYear_toValue?:number=null;
	studentStartDate?:Date=null;
	studentStartDate_fromDate?:string=null;
	studentStartDate_toDate?:string=null;
	fK_Withdraw?:number=null;
	fK_Withdraw_display?:string=null;
	fK_Withdraw_like?:string=null;
	fK_Withdraw_fromValue?:number=null;
	fK_Withdraw_toValue?:number=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

