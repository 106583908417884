import { PrepaidCollegeTransferStatusTypeModel} from '../entities/PrepaidCollegeTransferStatusTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class PrepaidCollegeTransferStatusTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: PrepaidCollegeTransferStatusTypeModel = new PrepaidCollegeTransferStatusTypeModel();
}

export class PrepaidCollegeTransferStatusTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<PrepaidCollegeTransferStatusTypeModel> = <Array<PrepaidCollegeTransferStatusTypeModel >>[]
}

export interface IPrepaidCollegeTransferStatusTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class PrepaidCollegeTransferStatusTypeListParams implements IPrepaidCollegeTransferStatusTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

