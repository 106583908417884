import { Component, OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HouseholdEditBaseComponent } from './householdEdit.base';

@Component({
	selector: 'app-householdEdit',
	templateUrl: './householdEdit.component.html',
})
export class HouseholdEditComponent extends HouseholdEditBaseComponent implements OnInit {
	constructor( r: ActivatedRoute) {
		super(r);
	}
	

	ngOnInit() {
		super.baseNgOnInit();
	}

}
