import { GenderTypeModel} from '../entities/GenderTypeModel';
import { ManagerResponse, ISearchModel, IListItem } from '../models';
export class GenderTypeResponse extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: GenderTypeModel = new GenderTypeModel();
}

export class GenderTypeList extends ManagerResponse {
	constructor() {
		super();
	}
	public Data: Array<GenderTypeModel> = <Array<GenderTypeModel >>[]
}

export interface IGenderTypeListParams {
	pK_Id?:number;
	name?:string;
	name_like?:string;
	_sort?: string;
	_sortDesc?: boolean;
	_page?: number;
	_pageSize?: number;
	_fuzzySearch?: string;
}

export class GenderTypeListParams implements IGenderTypeListParams, ISearchModel  {
	pK_Id?:number=null;
	name?:string=null;
	name_like?:string=null;
	public _sort?: string=null;
	public _sortDesc?: boolean=null;
	public _page?: number=null;
	public _pageSize?: number=null;
	public _fuzzySearch?: string=null;
}

