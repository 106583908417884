import { Component, OnInit, Directive } from '@angular/core';
import {  IGuardianReimbursementStatusTypeListParams } from '../../models/entities/GuardianReimbursementStatusTypeModel.response';
import { GuardianReimbursementStatusTypeModel } from '../../models/entities/GuardianReimbursementStatusTypeModel';
import { PagingData } from '../../paging/paging.component';
import { GuardianReimbursementStatusTypeListBaseComponent } from './guardianReimbursementStatusTypeList.base';

@Component({
	selector: 'app-guardianReimbursementStatusTypeList',
	templateUrl: './guardianReimbursementStatusTypeList.component.html',
})
export class GuardianReimbursementStatusTypeListComponent extends GuardianReimbursementStatusTypeListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
