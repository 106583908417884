import { Component, OnInit, Directive } from '@angular/core';
import {  IAmazonRequestListParams } from '../../models/entities/AmazonRequestModel.response';
import { AmazonRequestModel } from '../../models/entities/AmazonRequestModel';
import { PagingData } from '../../paging/paging.component';
import { AmazonRequestListBaseComponent } from './amazonRequestList.base';

@Component({
	selector: 'app-amazonRequestList',
	templateUrl: './amazonRequestList.component.html',
})
export class AmazonRequestListComponent extends AmazonRequestListBaseComponent implements OnInit {
	constructor() {
		super();
	}
	
	ngOnInit() {
    super.baseNgOnInit();
	}

	

}
