import {IListItem } from '../models';
export class DocumentModelBase implements IListItem {
	public ListDisplay:string = null;
	public Selected: boolean = false;
	public PK_Id:string = "";
	public FK_NoteType:number = 0;
	public FK_NoteType_Display:string = null;
	public CreatedDate:Date = null;
	public FileName:string = "";
	public FileExtension:string = "";
	public FK_Login_CreatedBy?:string = null;
	public FK_Login_CreatedBy_Display:string = null;
	public FK_StudentGradeLevelsSchoolYearsLookUp?:number = null;
	public FK_AwardDetermination?:number = null;
	public FK_AwardDetermination_Display:string = null;
	public FK_School?:number = null;
	public FK_School_Display:string = null;
	public FK_Vendor?:number = null;
	public FK_Vendor_Display:string = null;
	public FK_Scholarship?:number = null;
	public FK_Scholarship_Display:string = null;
}
